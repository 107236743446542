import { Tabs, Flex } from "@mantine/core"
import _ from "lodash"
import { Dispatch, SetStateAction } from "react"

import { FilterButton } from "@kiosk/reporting/components/FilterButton"
import { CsrdCategory } from "@kiosk/reporting/legacy/types/data/dato"

export const SelectTopicPanel = ({
  topicId,
  setTopicId,
  csrdCategory,
}: {
  readonly topicId: string | undefined
  readonly csrdCategory: CsrdCategory
  readonly setTopicId: Dispatch<SetStateAction<string | undefined>>
}) => (
  <Tabs.Panel value={csrdCategory.id}>
    <Flex direction="row" gap="sm" px={24} py={16} wrap="wrap">
      {_.orderBy(csrdCategory.topics, ({ name }) => name).map((topic) => (
        <FilterButton
          isSelected={topicId === topic.id}
          key={topic.id}
          label={topic.name}
          onClick={() => setTopicId(topic.id)}
        />
      ))}
    </Flex>
  </Tabs.Panel>
)
