import {
  Stack,
  Table,
  TableThead,
  TableTr,
  TableTh,
  Flex,
  TableTbody,
  TableTd,
  Skeleton,
} from "@mantine/core"
import { useTranslation } from "react-i18next"

import { PageLayout } from "@kiosk/reporting/components/layout/PageLayout"
import { CURRENT_REPORT_YEAR } from "@kiosk/reporting/legacy/shared/constants/common/phaseIn"

export const SubjectFormSkeleton = () => {
  const { t } = useTranslation("onboarding")

  return (
    <PageLayout>
      <Stack mx="10%">
        <Table>
          <TableThead bg="white">
            <TableTr>
              <TableTh c="gray.5" fw="normal" py="xl" w="55%">
                {t("subjects.tableFirstColumn")}
              </TableTh>
              <TableTh c="gray.5" fw="normal">
                <Flex align="center" direction="row" justify="center" py="xl">
                  {CURRENT_REPORT_YEAR}
                </Flex>
              </TableTh>
              <TableTh c="gray.5" fw="normal">
                <Flex align="center" direction="row" justify="center" py="xl">
                  {CURRENT_REPORT_YEAR + 1}
                </Flex>
              </TableTh>
              <TableTh c="gray.5" fw="normal">
                <Flex align="center" direction="row" justify="center" py="xl">
                  {CURRENT_REPORT_YEAR + 2}
                </Flex>
              </TableTh>
              <TableTh c="gray.5" fw="normal">
                <Flex align="center" direction="row" justify="center" py="xl">
                  {CURRENT_REPORT_YEAR + 3}
                </Flex>
              </TableTh>
            </TableTr>
          </TableThead>
          <TableTbody>
            {Array.from({ length: 10 }, (_, index) => (
              <TableTr key={index}>
                <TableTd>
                  <Skeleton height={30} my="md" radius="xs" width="100%" />
                </TableTd>
                <TableTd align="center">
                  <Skeleton circle height={30} />
                </TableTd>
                <TableTd align="center">
                  <Skeleton circle height={30} />
                </TableTd>
                <TableTd align="center">
                  <Skeleton circle height={30} />
                </TableTd>
                <TableTd align="center">
                  <Skeleton circle height={30} />
                </TableTd>
              </TableTr>
            ))}
          </TableTbody>
        </Table>
      </Stack>
    </PageLayout>
  )
}
