import { Badge, rem } from "@mantine/core"

interface EsrsBadgeProps {
  readonly topic: string
  readonly variant?: "default" | "secondary"
  readonly isNonMaterialEsrs?: boolean
}

export function EsrsBadge({
  topic,
  variant = "default",
  isNonMaterialEsrs,
}: EsrsBadgeProps) {
  const baseStyles = {
    fs: "italic",
    fw: 400,
    h: 26,
    px: 12,
    fz: "xxs",
  }

  const badgeStyles = {
    default: {
      ...baseStyles,
      c: isNonMaterialEsrs ? "gray.5" : "gray.7",
      color: "gray.1",
      w: 70,
      style: {
        flexShrink: "0",
      },
    },
    secondary: {
      ...baseStyles,
      c: "gray.5",
      color: "white",
      style: {
        borderColor: "var(--mantine-color-gray-2)",
        borderWidth: rem(1),
        flexShrink: "0",
      },
    },
  }

  const selectedStyle = badgeStyles[variant] ?? badgeStyles.default

  return <Badge {...selectedStyle}>{topic}</Badge>
}
