import { z } from "zod"

import { Option } from "@kiosk/reporting/legacy/shared/utils/helpers"

export interface Onboarding {
  companyId: string
  headCount: number
  firstExerciseYear: number
  headOffice?: string
  registeredCapital?: number
  legalForm?: string
  activity?: string
}

export const onboardingSchema = z.object({
  companyId: z.string(),
  headCount: z.coerce.number(),
  firstExerciseYear: z.coerce.number(),
  headOffice: z.string().optional(),
  registeredCapital: z.coerce.number().optional(),
  legalForm: z.string().optional(),
  activity: z.string().optional(),
})

export function parseOnboarding(obj: object): Option<Onboarding> {
  const result = onboardingSchema.safeParse(obj)

  if (result.success) {
    return result.data
  }

  return undefined
}
