import GreensterLogo from "@kiosk/reporting/assets/img/greenster.svg"
import KioskLogo from "@kiosk/reporting/components/logo.svg"
import { Branding } from "@kiosk/reporting/lib/entities/company"

interface LogoProps {
  readonly height?: number
  readonly branding: Branding
}

const logoComponents: Record<Branding, string> = {
  KIOSK: KioskLogo,
  GREENSTER: GreensterLogo,
}

export default function Logo(props: LogoProps) {
  const source = logoComponents[props.branding]

  return <img height={props.height ?? 28} src={source} />
}
