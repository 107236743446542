import { DotLottieReact } from "@lottiefiles/dotlottie-react"
import {
  Modal,
  Image,
  Text,
  List,
  ListItem,
  Button,
  Badge,
  Group,
  Stack,
} from "@mantine/core"
import {
  IconCircleCheckFilled,
  IconCircleXFilled,
  IconCloudDownload,
} from "@tabler/icons-react"
import { Dispatch, SetStateAction, useState } from "react"
import { useTranslation } from "react-i18next"

import ExportGIF from "@kiosk/reporting/assets/img/export-loading.gif"
import classes from "@kiosk/reporting/components/loader/ExportProgressModal/ExportProgressModal.module.css"
import { RingProgressBar } from "@kiosk/reporting/components/loader/RingProgressBar/RingProgressBar"

export type ProgressState = "idle" | "inProgress" | "completed"

type ExportProgressModalProps = {
  readonly opened: boolean
  readonly downloadProgress: number
  readonly close: () => void
  readonly link?: string
  readonly failedDownload: boolean
  readonly fileName: string
}

export const ExportProgressModal = ({
  opened,
  downloadProgress,
  close,
  link,
  failedDownload,
  fileName,
}: ExportProgressModalProps) => {
  const { t } = useTranslation(["common", "csrd"])
  const [reviewingReportState, setReviewingReportState] =
    useState<ProgressState>("idle")
  const [generatingTagsState, setGeneratingTagsState] =
    useState<ProgressState>("idle")
  const [generatingReportState, setGeneratingReportState] =
    useState<ProgressState>("idle")

  const allComplete =
    reviewingReportState === "completed" &&
    generatingTagsState === "completed" &&
    generatingReportState === "completed"

  const isDownloadable = allComplete && !failedDownload

  const progressItems = [
    {
      id: "reviewing",
      label: t("csrd:reportGeneration.reviewingReport"),
      state: reviewingReportState,
      setState: setReviewingReportState,
      delay: 0,
    },
    {
      id: "generating-tags",
      label: t("csrd:reportGeneration.generatingTags"),
      state: generatingTagsState,
      setState: setGeneratingTagsState,
      delay: 2,
    },
    {
      id: "generating-report",
      label: t("csrd:reportGeneration.generatingReport"),
      state: generatingReportState,
      setState: setGeneratingReportState,
      delay: 4,
      failedDownload,
      downloadProgress,
    },
  ]

  const resetProgressState = () => {
    setReviewingReportState("idle")
    setGeneratingTagsState("idle")
    setGeneratingReportState("idle")
  }

  return (
    <Modal
      centered
      classNames={{ body: classes["export-progress__modal--body"] }}
      onClose={() => {
        resetProgressState()
        close()
      }}
      opened={opened}
      size="lg"
      title={t("csrd:reportGeneration.creatingReport")}
    >
      {allComplete ? (
        <DownloadStatus failedDownload={failedDownload} />
      ) : (
        <Image src={ExportGIF} w={308} />
      )}
      <List
        classNames={{
          root: classes["export-progress__list--root"],
          item: classes["export-progress__list--item"],
          itemLabel: classes["export-progress__list--item-label"],
        }}
      >
        {progressItems.map((item) => (
          <ListItem key={item.id}>
            <ProgressItem
              allComplete={allComplete}
              delay={item.delay}
              downloadProgress={item.downloadProgress}
              failedDownload={item.failedDownload}
              label={item.label}
              setState={item.setState}
              state={item.state}
            />
          </ListItem>
        ))}
      </List>
      <Group className={classes["export-progress__button"]}>
        <Button
          classNames={{
            root: classes["export-progress__button-cancel--root"],
            label: classes["export-progress__button-cancel--label"],
          }}
          color="var(--mantine-color-gray-2)"
          onClick={() => {
            resetProgressState()
            close()
          }}
        >
          {t("common:buttons.cancel")}
        </Button>
        {isDownloadable && (
          <Button
            component="a"
            download={fileName}
            href={link}
            leftSection={<IconCloudDownload />}
            onClick={() => {
              resetProgressState()
              close()
            }}
          >
            {t("csrd:reportGeneration.download")}
          </Button>
        )}
      </Group>
    </Modal>
  )
}

const DownloadStatus = ({
  failedDownload,
}: {
  readonly failedDownload: boolean
}) => {
  const { t } = useTranslation("csrd")

  if (failedDownload) {
    return (
      <Stack className={classes["export-progress__modal--completed"]}>
        <DotLottieReact
          autoplay
          src="https://lottie.host/b58961c1-f85b-4133-a9cd-f1213decc840/QJv2p8c0Xh.lottie"
        />
        <Text className={classes["export-progress__modal--completed-text"]}>
          {t("reportGeneration.downloadFailed")}
        </Text>
      </Stack>
    )
  }

  return (
    <Stack className={classes["export-progress__modal--completed"]}>
      <DotLottieReact
        autoplay
        className={classes["export-progress__modal--completed-lottie"]}
        segment={[0, 145]}
        src="https://lottie.host/7869a612-08e4-41a6-a3f5-9e54173dde29/ABOKKmfu6p.lottie"
      />
      <Text className={classes["export-progress__modal--completed-text"]}>
        {t("reportGeneration.downloadComplete")}
      </Text>
    </Stack>
  )
}

type ProgressItemProps = {
  readonly label: string
  readonly delay: number
  readonly downloadProgress?: number
  readonly failedDownload?: boolean
  readonly state: ProgressState
  readonly setState: Dispatch<SetStateAction<ProgressState>>
  readonly allComplete: boolean
}

const ProgressItem = ({
  label,
  state,
  setState,
  allComplete,
  delay,
  downloadProgress,
  failedDownload = false,
}: ProgressItemProps) => {
  return (
    <>
      {state === "completed" ? (
        failedDownload ? (
          <IconCircleXFilled color="var(--mantine-color-red-8)" size={22} />
        ) : (
          <IconCircleCheckFilled
            color="var(--mantine-color-kiosk-green-0)"
            size={22}
          />
        )
      ) : (
        <RingProgressBar
          completionValue={downloadProgress}
          delay={delay}
          setProgressState={setState}
        />
      )}
      <Text
        className={
          allComplete || state === "inProgress"
            ? classes["export-progress__list--item-label-completed"]
            : classes["export-progress__list--item-label-default"]
        }
      >
        {label}
      </Text>
      {state === "completed" && (
        <Badge
          classNames={{
            label: classes["export-progress__badge-ok--label"],
          }}
          color={
            failedDownload
              ? "var(--mantine-color-red-0)"
              : "var(--mantine-color-emerald-0)"
          }
        >
          {failedDownload ? "KO" : "OK"}
        </Badge>
      )}
    </>
  )
}
