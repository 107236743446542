import { useUser as useClerkUser } from "@clerk/remix"
import { Center, Group, Loader, Stack, TextInput } from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import { useNavigate } from "@remix-run/react"
import { Dispatch, SetStateAction } from "react"
import { useTranslation } from "react-i18next"

import { useSetupUserAccountMutation } from "@kiosk/reporting/api/users/setupAccount"
import { useUser } from "@kiosk/reporting/components/auth/UserContext"
import { setupUserAccountSchema } from "@kiosk/reporting/legacy/shared/schemas/users"
import { USER_ROLES } from "@kiosk/reporting/legacy/types/user"
import { UserCreationLayout } from "@kiosk/reporting/routes/_onboarding.onboarding/components/UserCreationLayout"
import { routes } from "@kiosk/reporting/utils/constants/routes"

type Props = {
  readonly setActive: Dispatch<SetStateAction<number>>
}

export const UserCreationStep = ({ setActive }: Props) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const user = useUser()
  const { user: clerkUser, isLoaded } = useClerkUser()

  const { getInputProps, values, isValid } = useForm({
    validate: zodResolver(setupUserAccountSchema),
    initialValues: {
      firstName: user.firstName ?? "",
      lastName: user.lastName ?? "",
    },
    validateInputOnBlur: true,
    validateInputOnChange: true,
  })

  const { mutateAsync: setupUserAccountMutation, isPending } =
    useSetupUserAccountMutation(() =>
      user.roles.includes(USER_ROLES.KIOSK_ADMIN) &&
      user.company.status !== "ONBOARDED"
        ? setActive(1)
        : navigate(routes.CONGRATULATIONS.path),
    )

  const { firstName, lastName } = values

  const handleUserCreation = async () => {
    if (user) {
      await setupUserAccountMutation({
        userId: user?.id,
        data: {
          firstName,
          lastName,
        },
      })
    }
  }

  if (!isLoaded)
    return (
      <UserCreationLayout disableContinue={true} isPending={false}>
        <Center h="20em">
          <Loader />
        </Center>
      </UserCreationLayout>
    )

  if (!clerkUser) throw new Error("User not found")

  return (
    <UserCreationLayout
      disableContinue={!isValid()}
      handleUserCreation={handleUserCreation}
      isPending={isPending}
    >
      <form
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flex: "1 1 0%",
        }}
      >
        <Stack flex={1} gap={32} w="100%">
          <Group align="flex-start" gap={16} grow>
            <TextInput
              label={t("fields.firstNameLabel")}
              placeholder={t("fields.firstNamePlaceholder")}
              type="text"
              {...getInputProps("firstName")}
            />

            <TextInput
              error
              label={t("fields.lastNameLabel")}
              placeholder={t("fields.lastNamePlaceholder")}
              type="text"
              {...getInputProps("lastName")}
            />
          </Group>
        </Stack>
      </form>
    </UserCreationLayout>
  )
}
