import { Anchor, Flex, Stack, Switch, Text, Image } from "@mantine/core"
import { DisclosureRequirementParagraph } from "@prisma/client"
import { Link } from "@remix-run/react"
import { useState } from "react"
import { useTranslation } from "react-i18next"

import { useAIGenerateDisclosureRequirementParagraphMutation } from "@kiosk/reporting/api/dashboard/mutations"
import { useDisclosureRequirementParagraphByDisclosureRequirementId } from "@kiosk/reporting/api/dashboard/queries"
import {
  useDisclosureRequirementAnswersQuery,
  useMaterialityMutation,
} from "@kiosk/reporting/api/reports/index"
import PersonOnComputer from "@kiosk/reporting/assets/img/person-on-computer.svg"
import { DatoStructuredText } from "@kiosk/reporting/components/DatoStructuredText"
import { CSRDForm } from "@kiosk/reporting/components/csrd/CSRDForm"
import { DisclosureRequirementPreview } from "@kiosk/reporting/components/csrd/DisclosureRequirementPreview/DisclosureRequirementPreview"
import { DisclosureRequirementTab } from "@kiosk/reporting/components/csrd/DisclosureRequirementTab"
import { EsrsBadge } from "@kiosk/reporting/components/csrd/EsrsBadge"
import { MaterialitySwitch } from "@kiosk/reporting/components/csrd/MaterialSwitch"
import { extractEsrs } from "@kiosk/reporting/components/csrd/utils"
import { MultiQueryWrapper } from "@kiosk/reporting/components/layout/QueryWrapper"
import { clientConfig } from "@kiosk/reporting/config"
import configIro2, {
  findEsrsByCode,
} from "@kiosk/reporting/features/iro2/config"
import { Framework } from "@kiosk/reporting/lib/entities/company"
import { useAIGenerateDisclosureRequirementParagraphMutationMock } from "@kiosk/reporting/specs/mocks/disclosureRequirementQueriesMocks"
import { DisclosureRequirementsDetailsWithCompanyData } from "@kiosk/reporting/utils/types/csrd"

interface DisclosureRequirementFormLayoutProps {
  readonly disclosureRequirement: DisclosureRequirementsDetailsWithCompanyData
  readonly progress?: number
  readonly datoTopicId: string
  readonly datoSubTopicId: string
  readonly esrsIsMaterial: boolean
  readonly framework: Framework
}

export const DisclosureRequirementFormLayout = ({
  disclosureRequirement,
  progress,
  datoTopicId,
  datoSubTopicId,
  esrsIsMaterial,
  framework,
}: DisclosureRequirementFormLayoutProps) => {
  const { esrsCode, name, instructions } = disclosureRequirement
  const { t } = useTranslation("csrd")
  const disclosureRequirementAnswersQuery =
    useDisclosureRequirementAnswersQuery(disclosureRequirement.id)

  const esrs = extractEsrs(esrsCode)
  const hasMaterialitySwitch = esrs !== "ESRS2"

  const disclosureRequirementParagraphQuery =
    useDisclosureRequirementParagraphByDisclosureRequirementId(
      disclosureRequirement.id,
    )

  const queries = {
    disclosureRequirementAnswers: {
      query: disclosureRequirementAnswersQuery,
      allowEmptyArray: true,
    },
    disclosureRequirementParagraph: {
      query: disclosureRequirementParagraphQuery,
      allowEmptyObject: true,
    },
  }

  const { mutate: mutateMateriality } = useMaterialityMutation()
  const [isMaterial, setIsMaterial] = useState(true)
  const [showXml, setShowXml] = useState(false)
  const [tab, setTab] = useState(t("preview.tabForm"))
  const [
    disclosureRequirementParagraphCurrentlyGenerated,
    setDisclosureRequirementParagraphCurrentlyGenerated,
  ] = useState<DisclosureRequirementParagraph | undefined>(undefined)

  const useConfigAiGeneratedDisclosureRequirementParagraphMutation =
    clientConfig.enableAICalls
      ? useAIGenerateDisclosureRequirementParagraphMutation
      : useAIGenerateDisclosureRequirementParagraphMutationMock

  const { mutateAsync: aiGenerateParagraph, isPending: isPendingParagraph } =
    useConfigAiGeneratedDisclosureRequirementParagraphMutation({
      onSuccess: (data: DisclosureRequirementParagraph) => {
        if (!data.ixbrlParagraph) return
        setTab(t("preview.tabPreview"))
        setDisclosureRequirementParagraphCurrentlyGenerated(data)
      },
    })

  return (
    <MultiQueryWrapper absolute fluid queries={queries}>
      {({ disclosureRequirementAnswers, disclosureRequirementParagraph }) => {
        const _esrs = findEsrsByCode(esrs)

        return (
          <Stack flex={1} h="100%" pos="relative">
            <Stack align="stretch" h="100%">
              {
                // Disclosure requirement header
              }
              <Stack p="lg" top={0}>
                <Flex align="center" gap={16} mt={12} wrap="nowrap">
                  <EsrsBadge topic={esrsCode} variant="secondary" />
                  <Text c="gray.9" flex="1" fw={600} fz="xl" lineClamp={2}>
                    {name}
                  </Text>

                  {hasMaterialitySwitch && tab === t("preview.tabForm") ? (
                    <MaterialitySwitch
                      disabled={!esrsIsMaterial}
                      onClick={() => {
                        setIsMaterial(!isMaterial)
                        mutateMateriality(_esrs!.datoId)
                      }}
                      value={esrsIsMaterial && isMaterial}
                    />
                  ) : null}

                  {tab === t("preview.tabPreview") && (
                    <Switch
                      checked={showXml}
                      label={t("preview.showXbrl")}
                      onChange={() => setShowXml(!showXml)}
                    />
                  )}

                  {(disclosureRequirementParagraph ||
                    disclosureRequirementParagraphCurrentlyGenerated) && (
                    <DisclosureRequirementTab
                      onChange={(selectedTab) => setTab(selectedTab)}
                      value={tab}
                    />
                  )}
                </Flex>
              </Stack>

              {tab === t("preview.tabForm") && (
                <Stack px={24}>
                  <DatoStructuredText content={instructions} />
                </Stack>
              )}

              {tab === t("preview.tabForm") ? (
                <>
                  {!hasMaterialitySwitch || esrsIsMaterial ? (
                    <CSRDForm
                      aiGenerateParagraph={aiGenerateParagraph}
                      datoSubTopicId={datoSubTopicId}
                      datoTopicId={datoTopicId}
                      disclosureRequirement={disclosureRequirement}
                      framework={framework}
                      initialValues={disclosureRequirementAnswers}
                      isPendingParagraph={isPendingParagraph}
                      key={disclosureRequirement.id}
                      progress={progress}
                    />
                  ) : (
                    <NonMaterialEsrsComponent />
                  )}
                </>
              ) : (
                <DisclosureRequirementPreview
                  loading={isPendingParagraph}
                  onGenerate={() => {
                    aiGenerateParagraph(disclosureRequirement.id)
                  }}
                  previewParagraph={
                    disclosureRequirementParagraphCurrentlyGenerated ||
                    disclosureRequirementParagraph
                  }
                  xmlMode={showXml}
                />
              )}
            </Stack>
          </Stack>
        )
      }}
    </MultiQueryWrapper>
  )
}

const NonMaterialEsrsComponent = () => {
  const { t } = useTranslation("csrd")

  return (
    <Stack align="center" flex={1} justify="center" pb={100} px={32}>
      <Image alt="" src={PersonOnComputer} w={350} />
      <Text>{t("nonMaterialEsrs.message")}</Text>
      <Text>
        {t("nonMaterialEsrs.edit")}{" "}
        <Anchor
          component={Link}
          to={`/csrd/${configIro2.esrs2IRO}/${configIro2.disclosureRequirement.datoId}`}
        >
          {t("nonMaterialEsrs.page")}
        </Anchor>
        .
      </Text>
    </Stack>
  )
}
