import { Stack } from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import { Dispatch, SetStateAction } from "react"

import CSRDQuestionTree from "@kiosk/reporting/components/csrd/CSRDQuestionTree/CSRDQuestionTree"
import { PhaseInVoluntaryQuestionsWrapper } from "@kiosk/reporting/components/csrd/PhaseInVoluntaryWrapper/PhaseInVoluntaryWrapper"
import { FormShape } from "@kiosk/reporting/components/csrd/types"
import { CSRDQuestionAnswer } from "@kiosk/reporting/legacy/types/csrd"
import { Framework } from "@kiosk/reporting/lib/entities/company"
import { EnrichedDimensionBreakdown } from "@kiosk/reporting/utils/dimensions"
import {
  ContentWithCompanyData,
  CSRDQuestionsWithCompanyData,
} from "@kiosk/reporting/utils/types/csrd"

interface RelatedQuestionsTreeProps {
  readonly relatedQuestions: CSRDQuestionsWithCompanyData<ContentWithCompanyData>[]
  readonly breakdown: EnrichedDimensionBreakdown
  readonly form: UseFormReturnType<FormShape>
  readonly parentCollapsed?: boolean
  readonly externalAnswers: Record<string, CSRDQuestionAnswer>
  readonly setExternalAnswers: Dispatch<
    SetStateAction<Record<string, CSRDQuestionAnswer>>
  >
  readonly totalQuestions?: number
  readonly framework: Framework
}

/**
 * Renders a tree of questions as nested questions.
 */
export const RelatedQuestionsTree = ({
  relatedQuestions,
  breakdown,
  form,
  parentCollapsed,
  externalAnswers,
  setExternalAnswers,
  totalQuestions,
  framework,
}: RelatedQuestionsTreeProps) => (
  <Stack bg="white" gap="xs" p="md">
    <PhaseInVoluntaryQuestionsWrapper
      framework={framework}
      isParentCollapsed={parentCollapsed ?? false}
      questions={relatedQuestions}
      totalQuestions={totalQuestions}
    >
      {(relatedQuestion, isParentCollapsed) => (
        <CSRDQuestionTree
          breakdown={breakdown}
          externalAnswers={externalAnswers}
          form={form}
          framework={framework}
          parentCollapsed={isParentCollapsed}
          question={relatedQuestion}
          setExternalAnswers={setExternalAnswers}
          totalQuestions={totalQuestions}
        />
      )}
    </PhaseInVoluntaryQuestionsWrapper>
  </Stack>
)
