import { useLoaderData, useParams } from "@remix-run/react"
import { DataFunctionArgs } from "remix-typedjson/dist/remix"

import { useCompanyUsersQuery } from "@kiosk/reporting/api/companies/queryCompanyUsers"
import { useListDimensionsQuery } from "@kiosk/reporting/api/dimensions/queries"
import { useGetContributorAssignmentsQuery } from "@kiosk/reporting/api/surveys/assignments/getContributorAssignments"
import { PageLayout } from "@kiosk/reporting/components/layout/PageLayout"
import { MultiQueryWrapper } from "@kiosk/reporting/components/layout/QueryWrapper"
import { Spinner } from "@kiosk/reporting/components/loader/Spinner"
import { serverConfig } from "@kiosk/reporting/config.server"
import { CategoryAggregateDatoRepository } from "@kiosk/reporting/features/dato/infrastructure/repositories/category-aggregates"
import { Locale } from "@kiosk/reporting/i18n"
import { CompanyDimensionPostgresRepository } from "@kiosk/reporting/lib/repositories/company-dimension-repository.server"
import { CompanySurveyQuestionRepository } from "@kiosk/reporting/lib/repositories/company-survey-question.server"
import { createCompanySurveyQuestionService } from "@kiosk/reporting/lib/services/company-survey-question.service.server"
import { AnswerSurveyContent } from "@kiosk/reporting/routes/answer_survey.$id/AnswerSurveyContent"
import { requireUser } from "@kiosk/reporting/utils/auth.server"

export const handle = {
  title: () => "answerSurvey",
}

const companySurveyQuestionService = createCompanySurveyQuestionService(
  CompanySurveyQuestionRepository,
)

export const loader = async (args: DataFunctionArgs) => {
  const { token, company, locale } = await requireUser(args)

  const [companyDimensions, rawDataPointsQuestions] = await Promise.all([
    CompanyDimensionPostgresRepository.getDimensionsWithOptions(company.id),
    CategoryAggregateDatoRepository.fetchRawDataPoints(token, locale as Locale),
  ])

  const companySurveyQuestions = serverConfig.hasCompanyQuestions
    ? await companySurveyQuestionService.getLocalizedCompanySurveyQuestions(
        company.id,
        locale as Locale,
      )
    : undefined

  return {
    companyDimensions,
    companySurveyQuestions,
    rawDataPointsQuestions,
    userCompany: company.id,
  }
}

export default function AnswerSurvey() {
  const { id } = useParams() as { id: string }
  const getContributorAssignmentsQuery = useGetContributorAssignmentsQuery(id)
  const { data: allDimensions } = useListDimensionsQuery()
  const {
    companyDimensions,
    companySurveyQuestions,
    rawDataPointsQuestions,
    userCompany,
  } = useLoaderData<typeof loader>()

  const companyUsersQuery = useCompanyUsersQuery(userCompany)

  const queries = {
    companyUsers: {
      query: companyUsersQuery,
      allowEmptyArray: true,
    },
    survey: {
      query: getContributorAssignmentsQuery,
      allowEmptyArray: true,
    },
  }

  // TODO: better loading
  if (!allDimensions) return <Spinner fluid />

  return (
    <PageLayout contained>
      <MultiQueryWrapper fluid queries={queries}>
        {({ companyUsers, survey }) => (
          <AnswerSurveyContent
            allDimensions={[...allDimensions, ...companyDimensions]}
            companySurveyQuestions={companySurveyQuestions}
            companyUsers={companyUsers}
            rawDataPointsQuestions={rawDataPointsQuestions}
            survey={survey}
          />
        )}
      </MultiQueryWrapper>
    </PageLayout>
  )
}
