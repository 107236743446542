import { LoaderFunctionArgs, redirect } from "@remix-run/node"

import { clientConfig } from "@kiosk/reporting/config"
import { requireUser } from "@kiosk/reporting/utils/auth.server"

export const handle = {
  title: () => "csrd",
}

export const loader = async (args: LoaderFunctionArgs) => {
  const { company } = await requireUser(args)

  const defaultESRS = `/csrd/${company.framework === "CSRD" ? clientConfig.esrs.default_csrd : clientConfig.esrs.default_vsme}`

  return redirect(defaultESRS)
}
