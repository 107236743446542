import { Stack, Text } from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"

import { CSRDQuestionContent } from "@kiosk/reporting/components/csrd/CSRDQuestionContent"
import { CollapsibleCard } from "@kiosk/reporting/components/csrd/cards/sections/CollapsibleCard"
import { FormShape } from "@kiosk/reporting/components/csrd/types"
import { buildCSRDReportAnswerFormKey } from "@kiosk/reporting/components/csrd/utils"
import { CSRDQuestionAnswer } from "@kiosk/reporting/legacy/types/csrd"
import { CSRDQuestions, Content } from "@kiosk/reporting/legacy/types/data/dato"
import { EnrichedDimensionBreakdown } from "@kiosk/reporting/utils/dimensions"

export type CardType = "Policy" | "Action" | "Target" | "Metric"

interface RelatedQuestionsCardProps {
  title: string
  relatedQuestions: CSRDQuestions<Content>[]
  dimensionBreakdowns: EnrichedDimensionBreakdown[]
  form: UseFormReturnType<FormShape>
  type: CardType
  readonly externalAnswers: Record<string, CSRDQuestionAnswer>
}

const flattenQuestions = (
  question: CSRDQuestions<Content>,
): Array<CSRDQuestions<Content>> =>
  [question].concat(question.relatedQuestions?.flatMap(flattenQuestions) ?? [])

/**
 * Renders a tree of questions as a card.
 *
 * Within a card, there is only one, transveral breakdown.
 * That is, there is one *value* for each dimension (axis).
 *
 * Questions are flattened.
 */
export const RelatedQuestionsCard = ({
  relatedQuestions,
  dimensionBreakdowns,
  form,
  type,
  externalAnswers,
}: RelatedQuestionsCardProps) => {
  const flattenedQuestions = relatedQuestions
    .map(flattenQuestions)
    .reduce((acc, qs) => acc.concat(qs))

  return dimensionBreakdowns.map((dbd, idx) => {
    const dimensionKey = dbd
      .map((bd) => [bd.dimensionId, bd.optionId].join(":"))
      .join("-")

    // TODO: the design did not account for dimension, so this is a «placeholder»
    const title = dbd.map((bd) => bd.optionLabel).join(" & ")

    return (
      <CollapsibleCard
        isOpened={idx === 0}
        key={dimensionKey}
        pill={type}
        title={title}
      >
        {flattenedQuestions.map((q) => {
          const key = buildCSRDReportAnswerFormKey(q.id, dbd)

          return (
            <Stack key={key}>
              <Text fw="bold">{q.label}</Text>

              <CSRDQuestionContent
                breakdown={dbd}
                content={q.content}
                datoQuestionId={q.id}
                externalAnswers={externalAnswers}
                formProps={form.getInputProps(key)}
              />
            </Stack>
          )
        })}
      </CollapsibleCard>
    )
  })
}
