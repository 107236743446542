import { notifications } from "@mantine/notifications"
import { User } from "@prisma/client"
import { useMutation } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"

import { companyKeys } from "@kiosk/reporting/api/companies/companyKeys"
import { useUser } from "@kiosk/reporting/components/auth/UserContext"
import { apiClient } from "@kiosk/reporting/lib/apiClient"
import { authorizationHeader } from "@kiosk/reporting/lib/headers"
import { queryClient } from "@kiosk/reporting/lib/queryClient"

export const deleteUser = async (
  userId: string,
  token: string,
): Promise<User> => {
  const response = await apiClient.delete(`/users/${userId}`, {
    headers: authorizationHeader(token),
  })

  return response.data
}

export const useDeleteUserMutation = (companyId: string) => {
  const { t } = useTranslation()
  const { token } = useUser()

  return useMutation({
    mutationFn: (userId: string) => deleteUser(userId, token),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: companyKeys.companyUsers(companyId),
      })
      notifications.show({
        title: t("messages.success"),
        message: t("messages.deleteUserSuccess"),
        color: "green",
      })
    },
    onError: ({ message }) => {
      notifications.show({
        title: t("messages.error"),
        message,
        color: "red",
      })
    },
  })
}
