import { DisclosureRequirementParagraph } from "@prisma/client"
import { UseQueryResult, useQuery } from "@tanstack/react-query"
import _ from "lodash"

import { dashboardKeys } from "@kiosk/reporting/api/dashboard/dashboardKeys"
import {
  DashboardCategory,
  DataPoint,
} from "@kiosk/reporting/api/dashboard/types"
import { listDimensions } from "@kiosk/reporting/api/dimensions/queries"
import { getSurveyQuestionsDataModel } from "@kiosk/reporting/api/surveys/questions/model/surveyQuestionsDataModel"
import { useUser } from "@kiosk/reporting/components/auth/UserContext"
import { DashboardResponses } from "@kiosk/reporting/legacy/types/endpoints/dashboard"
import { apiClient } from "@kiosk/reporting/lib/apiClient"
import { authorizationHeader } from "@kiosk/reporting/lib/headers"
import { useAppRouteContext } from "@kiosk/reporting/lib/hooks/use-app-context"
import { enrichDimensionBreakdowns } from "@kiosk/reporting/utils/dimensions"

export const useListDashboardDataPointsQuery = () => {
  const { user } = useAppRouteContext()

  return useQuery<DashboardCategory[]>({
    queryKey: dashboardKeys.allDataPoints(),
    queryFn: async () => {
      const token = user.token

      // TODO: this function should be in the backend and return the formatted data directly
      const [dataPoints, csrdCategories, dimensions] = await Promise.all([
        apiClient
          .get<
            DashboardResponses.DataPoint[]
          >("/dashboard", { headers: { Authorization: `Bearer ${token}` } })
          .then((response) => response.data),
        getSurveyQuestionsDataModel(token),
        listDimensions(token),
      ])

      const dataPointsByQuestionId = _.groupBy(
        dataPoints,
        (dataPoint) => dataPoint.datoQuestionId,
      )
      const answeredQuestionIds = Object.keys(dataPointsByQuestionId)

      return csrdCategories
        .map((category) => {
          const categoryTopics = category.topics.map((topic) => {
            const topicQuestions = topic.disclosureRequirements.flatMap(
              (dr) => dr.questions,
            )

            const questionsToDisplay = topicQuestions.filter(
              (question) =>
                answeredQuestionIds.includes(question.id) ||
                question.isMandatoryInDashboard,
            )

            const answeredQuestionsWithDimensions: DataPoint[] =
              questionsToDisplay.map((question) => {
                if (!dataPointsByQuestionId[question.id]) {
                  return {
                    ..._.omit(question, ["dimensionIds"]),
                    type: "single-value",
                    value: null,
                  }
                }

                if (question.dimensionIds.length === 0) {
                  const answerValue =
                    dataPointsByQuestionId[question.id][0].answer.value

                  return {
                    ..._.omit(question, ["dimensionIds"]),
                    type: "single-value",
                    value: answerValue ? Number(answerValue) : null,
                  }
                }

                return {
                  ..._.omit(question, ["dimensionIds"]),
                  type: "dimensioned",
                  values: dataPointsByQuestionId[question.id].map(
                    (dataPoint) => {
                      const questionDimensions = enrichDimensionBreakdowns(
                        dimensions,
                        dataPoint.dimensions,
                      )

                      return {
                        value: dataPoint.answer.value
                          ? Number(dataPoint.answer.value)
                          : null,
                        dimensions: questionDimensions,
                      }
                    },
                  ),
                }
              })

            return {
              ..._.omit(topic, ["disclosureRequirements"]),
              dataPoints: answeredQuestionsWithDimensions,
            }
          })

          return {
            ...category,
            topics: categoryTopics.filter(
              (topic) => topic.dataPoints.length > 0,
            ),
          }
        })
        .filter((category) => category.topics.length > 0)
    },
  })
}

/**
 * @function useDisclosureRequirementParagraphByDisclosureRequirementId
 * @description returns a list of all the disclosure requirement paragraph for the current company by disclosure requirement id
 * @param {string} disclosureRequirementId
 * @returns {UseQueryResult<DisclosureRequirementParagraph>}
 */

export const useDisclosureRequirementParagraphByDisclosureRequirementId = (
  disclosureRequirementId: string,
): UseQueryResult<DisclosureRequirementParagraph> => {
  const user = useUser()
  const companyId = user.company.id

  return useQuery<DisclosureRequirementParagraph>({
    queryKey: dashboardKeys.allDataPointsWithDependency(
      disclosureRequirementId,
    ),
    queryFn: async () => {
      const data = await apiClient.get<DisclosureRequirementParagraph>(
        `/disclosureRequirement/${companyId}/${disclosureRequirementId}/xbrl_paragraphs`,
        { headers: authorizationHeader(user.token) },
      )

      return data.data
    },
  })
}
