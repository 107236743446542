import { Task } from "@prisma/client"
import { useQuery } from "@tanstack/react-query"

import { taskKeys } from "@kiosk/reporting/api/tasks/taskKeys"
import { useUser } from "@kiosk/reporting/components/auth/UserContext"
import { apiClient } from "@kiosk/reporting/lib/apiClient"
import { authorizationHeader } from "@kiosk/reporting/lib/headers"

export const getTask = async (id: string, token: string): Promise<Task> => {
  const response = await apiClient.get<Task>(`/tasks/${id}`, {
    headers: authorizationHeader(token),
  })

  return response.data
}

export const useGetTaskQuery = (id: string) => {
  const { token } = useUser()

  return useQuery({
    queryKey: taskKeys.id(id),
    queryFn: async () => await getTask(id, token),
    enabled: !!id,
  })
}
