import { Stack, Text } from "@mantine/core"
import { TypedDimensionOption } from "@prisma/client"
import { IconChevronRight } from "@tabler/icons-react"
import { useState } from "react"
import { useTranslation } from "react-i18next"

import { TypedDimension } from "@kiosk/reporting/api/dimensions/types"
import { EditCompanyDimensionDrawer } from "@kiosk/reporting/components/dimensions/EditCompanyDimensionDrawer"
import { EditDimensionDrawer } from "@kiosk/reporting/components/dimensions/EditDimensionDrawer"
import { Table } from "@kiosk/reporting/components/generic/index"
import { CompanyDimensionAggregate } from "@kiosk/reporting/lib/aggregates/companyDimensionAggregate"
import { CompanyDimensionOption } from "@kiosk/reporting/lib/entities/companyDimensionOption"

type Props = {
  readonly label: string
  readonly dimensions?: TypedDimension[]
  readonly companyDimensions?: CompanyDimensionAggregate[]
}

export const DimensionsTable = ({
  label,
  dimensions,
  companyDimensions,
}: Props) => {
  const { t } = useTranslation("dimensions")

  const [editingDimension, setEditingDimension] = useState<
    TypedDimension | undefined
  >(undefined)

  const [editingCompanyDimension, setEditingCompanyDimension] = useState<
    CompanyDimensionAggregate | undefined
  >(undefined)

  const columns = (isCompanyDimensions: boolean) => {
    return [
      {
        colId: "label",
        key: "label",
        title: t("columns.label"),
      },
      {
        colId: isCompanyDimensions ? "companyDimensionOptions" : "options",
        key: "options",
        title: t("columns.count"),
        width: 100,
        render: (
          options: TypedDimensionOption[] | CompanyDimensionOption[],
        ) => <Text>{options?.length}</Text>,
      },
      {
        key: "arrow",
        colId: "arrow",
        title: "",
        width: 20,
        render: () => <IconChevronRight />,
      },
    ]
  }

  const dimensionsColumns = columns(false)
  const companyDimensionsColumns = columns(true)

  return (
    <Stack>
      <Text fw={500} size="lg">
        {label}
      </Text>
      {dimensions && (
        <>
          <Table
            columns={dimensionsColumns}
            dataSource={dimensions}
            onRowClick={(dimension) => setEditingDimension(dimension)}
            withColumnBorders
          />
          <EditDimensionDrawer
            dimension={editingDimension}
            onClose={() => setEditingDimension(undefined)}
          />
        </>
      )}
      {companyDimensions && (
        <>
          <Table
            columns={companyDimensionsColumns}
            dataSource={companyDimensions}
            onRowClick={(companyDimensions) =>
              setEditingCompanyDimension(companyDimensions)
            }
            withColumnBorders
          />
          <EditCompanyDimensionDrawer
            dimension={editingCompanyDimension}
            onClose={() => setEditingCompanyDimension(undefined)}
          />
        </>
      )}
    </Stack>
  )
}
