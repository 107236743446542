import { Dispatch, SetStateAction } from "react"

import { useCompanyUsersQuery } from "@kiosk/reporting/api/companies/queryCompanyUsers"
import { useUser } from "@kiosk/reporting/components/auth/UserContext"
import { QueryWrapper } from "@kiosk/reporting/components/layout/QueryWrapper"
import { CreateAssignmentBody } from "@kiosk/reporting/legacy/shared/schemas/assignments"
import { ConsolidatedDimension } from "@kiosk/reporting/legacy/types/utils/dimensions"
import { CompanyDimensionAggregate } from "@kiosk/reporting/lib/aggregates/companyDimensionAggregate"
import { AssignmentsTable } from "@kiosk/reporting/routes/sources_.edit_question.$id/components/AssignmentsTable/AssignmentsTable"

type AssignmentsProps = {
  readonly currentAssignments: CreateAssignmentBody[]
  readonly setCurrentAssignments: Dispatch<
    SetStateAction<CreateAssignmentBody[]>
  >
  readonly allDimensions: Array<
    ConsolidatedDimension | CompanyDimensionAggregate
  >
}

export const Assignments = ({
  currentAssignments,
  setCurrentAssignments,
  allDimensions,
}: AssignmentsProps) => {
  const user = useUser()
  const companyUsersQuery = useCompanyUsersQuery(user.companyId)

  if (!user) return null

  return (
    <QueryWrapper query={companyUsersQuery}>
      {({ data: companyUsers }) => (
        <AssignmentsTable
          allDimensions={allDimensions}
          companyUsers={companyUsers}
          currentAssignments={currentAssignments}
          setCurrentAssignments={setCurrentAssignments}
        />
      )}
    </QueryWrapper>
  )
}
