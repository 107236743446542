import { ActionFunctionArgs, json } from "@remix-run/server-runtime"

import { updateUser } from "@kiosk/reporting/api/users/updateUser"
import { localeCookie } from "@kiosk/reporting/cookies.server"
import { Language } from "@kiosk/reporting/routes/settings.language/components/Language"
import { requireUser } from "@kiosk/reporting/utils/auth.server"

export const handle = {
  breadcrumb: () => "language",
  title: () => "language",
}

export const action = async (args: ActionFunctionArgs) => {
  const user = await requireUser(args)

  const form = await args.request.formData()
  const language = form.get("language")?.toString()

  if (language !== "fr" && language !== "en") {
    throw new Response("cannot change language: invalid type", { status: 422 })
  }

  await updateUser({ userId: user.id, data: { locale: language } }, user.token)

  const cookieHeader = await localeCookie.serialize(language)

  return json(
    { success: true },
    {
      headers: {
        "Set-Cookie": cookieHeader,
      },
    },
  )
}

export default function LanguagePage() {
  return <Language />
}
