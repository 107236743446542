import { z } from "zod"

const surveyQuestionDataSchema = z.object({
  datoId: z.string().nullish(),
  companySurveyQuestionId: z.string().nullish(),
  label: z.string(),
  unit: z.string(),
  numberType: z.enum(["decimal", "integer", "currency", "percent"]),
  instructions: z.string().nullable(),
})

const fullSurveyQuestionSchema = surveyQuestionDataSchema.extend({
  id: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  surveyId: z.string(),
  _count: z.object({ assignments: z.number(), assignees: z.number() }),
})

export const getSurveyQuestionSchema = fullSurveyQuestionSchema
export const listSurveyQuestionsSchema = z.array(fullSurveyQuestionSchema)

export const createSurveyQuestionSchema = surveyQuestionDataSchema
export type CreateSurveyQuestionBody = z.infer<
  typeof createSurveyQuestionSchema
>

export const updateSurveyQuestionSchema = createSurveyQuestionSchema
export type UpdateSurveyQuestionBody = z.infer<
  typeof updateSurveyQuestionSchema
>
