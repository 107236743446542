import React from "react"

interface MultiLineTranslationProps {
  readonly text: string
}

const MultiLineTranslation = (props: MultiLineTranslationProps) => {
  const lines = props.text.split("\\n")

  return (
    <>
      {lines.map((line, index) => (
        <React.Fragment key={index}>
          {line}

          {index < lines.length - 1 && <br />}
        </React.Fragment>
      ))}
    </>
  )
}

export default MultiLineTranslation
