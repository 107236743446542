import { notifications } from "@mantine/notifications"
import { useMutation } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"

import { surveysKeys } from "@kiosk/reporting/api/surveys/surveysKeys"
import { useUser } from "@kiosk/reporting/components/auth/UserContext"
import {
  CreateSurveyQuestionBody,
  createSurveyQuestionSchema,
} from "@kiosk/reporting/legacy/shared/schemas/surveyQuestions"
import { SurveyQuestionResponses } from "@kiosk/reporting/legacy/types/endpoints/surveyQuestions"
import { apiClient } from "@kiosk/reporting/lib/apiClient"
import { authorizationHeader } from "@kiosk/reporting/lib/headers"
import { queryClient } from "@kiosk/reporting/lib/queryClient"

export const useCreateSurveyQuestionMutation = (surveyId: string) => {
  const { token } = useUser()
  const { t } = useTranslation()

  return useMutation({
    mutationFn: async (
      data: CreateSurveyQuestionBody,
    ): Promise<SurveyQuestionResponses.SurveyQuestion> => {
      const response = await apiClient.post(
        `/surveys/${surveyId}/questions`,
        await createSurveyQuestionSchema.parseAsync(data),
        { headers: authorizationHeader(token) },
      )

      return response.data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: surveysKeys.list(),
      })
      await queryClient.invalidateQueries({
        queryKey: surveysKeys.get(surveyId),
        refetchType: "all",
      })
    },
    onError: ({ message }) => {
      notifications.show({
        title: t("common:messages.error"),
        message,
        color: "red",
      })
    },
  })
}
