import { notifications } from "@mantine/notifications"
import { useMutation } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"

import { surveysKeys } from "@kiosk/reporting/api/surveys/surveysKeys"
import { useUser } from "@kiosk/reporting/components/auth/UserContext"
import { SurveyCreation } from "@kiosk/reporting/legacy/shared/domain/entities/survey"
import { SurveyResponses } from "@kiosk/reporting/legacy/types/endpoints/surveys"
import { apiClient } from "@kiosk/reporting/lib/apiClient"
import { authorizationHeader } from "@kiosk/reporting/lib/headers"
import { queryClient } from "@kiosk/reporting/lib/queryClient"

export const useCreateSurveyMutation = () => {
  const { t } = useTranslation()
  const { token } = useUser()

  return useMutation({
    mutationFn: async (
      data: SurveyCreation,
    ): Promise<SurveyResponses.Survey> => {
      const response = await apiClient.post("/surveys", data, {
        headers: authorizationHeader(token),
      })

      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: surveysKeys.list(),
      })
    },
    onError: ({ message }) => {
      notifications.show({
        title: t("messages.error"),
        message,
        color: "red",
      })
    },
  })
}
