import { notifications, showNotification } from "@mantine/notifications"
import { useMutation } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"

import { assignmentsKeys } from "@kiosk/reporting/api/surveys/assignments/assignmentsKeys"
import { useUser } from "@kiosk/reporting/components/auth/UserContext"
import { apiClient } from "@kiosk/reporting/lib/apiClient"
import { authorizationHeader } from "@kiosk/reporting/lib/headers"
import { queryClient } from "@kiosk/reporting/lib/queryClient"

export const useAnswerAssignmentsMutation = (surveyId: string) => {
  const { t } = useTranslation()
  const { token } = useUser()

  return useMutation({
    mutationFn: async (
      answers: {
        id: string
        value: number | null
        comment: string | null
        fileName: string | null
        filePath: string | null
        assigneeId?: string | null
      }[],
    ): Promise<null> => {
      await apiClient.put(`/assignments/${surveyId}/answers`, answers, {
        headers: authorizationHeader(token),
      })

      return null
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: assignmentsKeys.getContributorAssignments(surveyId),
      })
      showNotification({
        message: `${t("messages.answerSaved")}`,
      })
    },
    onError: ({ message }) => {
      notifications.show({
        title: t("messages.error"),
        message,
        color: "red",
      })
    },
  })
}
