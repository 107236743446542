import {
  Button,
  Group,
  Input,
  Menu,
  Select,
  Stack,
  Text,
  ThemeIcon,
} from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { useNavigate } from "@remix-run/react"
import { IconChevronRight, IconPlus, IconSearch } from "@tabler/icons-react"
import dayjs from "dayjs"
import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import { Table } from "@kiosk/reporting/components/generic/index"
import { SourcesResponses } from "@kiosk/reporting/legacy/types/endpoints/sources"
import { SourceType } from "@kiosk/reporting/legacy/types/sources"
import { EmptySources } from "@kiosk/reporting/routes/sources/components/EmptySources"
import NewFileModal from "@kiosk/reporting/routes/sources/components/Files/NewFileModal"
import { BackToYouModal } from "@kiosk/reporting/routes/sources/components/Integrations/IntegrationRequestSentModal"
import { NewIntegrationModal } from "@kiosk/reporting/routes/sources/components/Integrations/NewIntegrationModal"
import { SourceTypeItem } from "@kiosk/reporting/routes/sources/components/SourceTypeItem"
import { SOURCE_TYPES_INFO } from "@kiosk/reporting/routes/sources/utils"

export default function SourcesList({
  sources,
}: {
  readonly sources: SourcesResponses.Source[]
}) {
  const { t } = useTranslation("sources")
  const navigate = useNavigate()

  const [search, setSearch] = useState("")
  const [typeFilter, setTypeFilter] = useState<SourceType>()

  const filteredSources = useMemo(() => {
    let filteredData = sources

    if (search) {
      filteredData = filteredData.filter((item) => {
        return item.name.toLowerCase().includes(search.toLowerCase())
      })
    }

    if (typeFilter) {
      filteredData = filteredData.filter((item) => {
        return item.type === typeFilter
      })
    }

    return filteredData
  }, [search, sources, typeFilter])

  // TODO: use normal mantine table
  const columns = useMemo(
    () => [
      {
        colId: "name",
        key: "name",
        title: t("columns.name"),
      },
      {
        colId: "type",
        key: "type",
        title: t("columns.sourceType"),
        render: (sourceType: SourceType) => (
          <SourceTypeItem sourceType={sourceType} />
        ),
      },
      {
        colId: "lastUpdate",
        key: "lastUpdate",
        title: t("columns.lastUpdate"),
        render: (lastUpdate: Date) => dayjs(lastUpdate).format("LLL"),
      },
      {
        key: "arrow",
        colId: "arrow",
        title: "",
        width: 20,
        render: () => (
          <ThemeIcon c="black">
            <IconChevronRight />
          </ThemeIcon>
        ),
      },
    ],
    [t],
  )

  const handleRowClick = (source: SourcesResponses.Source) => {
    if (source.link.includes("http")) {
      // External link
      window.open(source.link, "_blank")
    } else {
      navigate(source.link)
    }
  }

  // When clicking on «add integration»:
  // `NewIntegrationModal` is opened, where users can select their integration
  // `BackToYouModal` is opened when the user clicks on «Request»
  const [
    newIntegrationOpened,
    { open: openNewIntegration, close: closeNewIntegration },
  ] = useDisclosure(false)
  const [backToYouOpened, { open: openBackToYou, close: closeBackToYou }] =
    useDisclosure(false)

  const [newFileOpened, { open: openNewFile, close: closeNewFile }] =
    useDisclosure(false)

  return (
    <Stack gap={32} mb={32}>
      <Group justify="space-between">
        <Group gap={24}>
          <Input
            leftSection={<IconSearch />}
            onChange={(event) => setSearch(event.currentTarget.value)}
            placeholder={t("placeholders.search")}
            value={search}
            w={400}
          />

          <Select
            clearable
            data={SOURCE_TYPES_INFO.map((sourceType) => ({
              value: sourceType.key,
              label: sourceType.name,
            }))}
            onChange={(value) => setTypeFilter(value as SourceType)}
            placeholder={t("placeholders.filter")}
            renderOption={({ option }) => (
              <SourceTypeItem sourceType={option.value as SourceType} />
            )}
            value={typeFilter}
          />
        </Group>

        <NewFileModal onClose={closeNewFile} opened={newFileOpened} />

        <BackToYouModal onClose={closeBackToYou} opened={backToYouOpened} />

        <NewIntegrationModal
          onClose={closeNewIntegration}
          onRequest={() => {
            closeNewIntegration()
            openBackToYou()
          }}
          opened={newIntegrationOpened}
        />

        <Menu position="bottom-end">
          <Menu.Target>
            <Button leftSection={<IconPlus />}>{t("actions.new")}</Button>
          </Menu.Target>

          <Menu.Dropdown>
            {SOURCE_TYPES_INFO.map((sourceType) => {
              if (sourceType.isActive) {
                return (
                  <Menu.Item
                    key={sourceType.key}
                    onClick={() => {
                      if (sourceType.ctaLink) {
                        navigate(sourceType.ctaLink)

                        return
                      }

                      switch (sourceType.key) {
                        case "TABLE":
                          navigate("/sources/tables")

                          return
                        case "INTEGRATION":
                          openNewIntegration()

                          return
                        case "FILE":
                          openNewFile()

                          return
                        default:
                          return
                      }
                    }}
                  >
                    <Group gap={6}>
                      <sourceType.icon height={18} />
                      <Text>{sourceType.cta}</Text>
                    </Group>
                  </Menu.Item>
                )
              }
            })}
          </Menu.Dropdown>
        </Menu>
      </Group>

      {sources.length ? (
        <Table
          columns={columns}
          dataSource={filteredSources}
          onRowClick={handleRowClick}
          withColumnBorders
        />
      ) : (
        <EmptySources />
      )}
    </Stack>
  )
}
