import { Group, Avatar, Stack, ThemeIcon, Text } from "@mantine/core"
import { IconCircleCheckFilled, IconCircleXFilled } from "@tabler/icons-react"
import { t } from "i18next"

import { Column } from "@kiosk/reporting/components/generic/types"
import { User, USER_ROLES, UserRole } from "@kiosk/reporting/legacy/types/user"
import { roleTranslations } from "@kiosk/reporting/utils/constants"
import { getInitials } from "@kiosk/reporting/utils/helpers"

export const userColumns: Array<Column<User>> = [
  {
    colId: "firstName",
    key: "name",
    title: t("columns.name"),
    render: (firstName, { lastName, email, avatarUrl }) => (
      <Group gap={14}>
        <Avatar color="green" src={avatarUrl}>
          {getInitials(firstName, lastName)}
        </Avatar>
        <Stack gap={6}>
          <Text c="gray.7" fw={500} m={0}>{`${firstName} ${lastName}`}</Text>
          <Text c="gray.7" m={0}>
            {email}
          </Text>
        </Stack>
      </Group>
    ),
  },
  {
    colId: "roles",
    title: t("columns.roles"),
    key: "roles",
    width: 180,
    render: (roles: UserRole[]) => {
      if (roles.includes(USER_ROLES.KIOSK_ADMIN)) {
        return roleTranslations[USER_ROLES.KIOSK_ADMIN]
      }

      return roles.map((role) => roleTranslations[role]).join(", ")
    },
  },
  {
    colId: "department",
    title: t("columns.department"),
    key: "department",
    width: 180,
    render: (department: string) => department,
  },
  {
    colId: "jobTitle",
    title: t("columns.jobTitle"),
    key: "jobTitle",
    width: 180,
    render: (jobTitle: string) => jobTitle,
  },
  {
    colId: "isSignedUp",
    title: t("columns.verified"),
    key: "isSignedUp",
    render: (isSignedUp) => (
      <Group gap={14}>
        {isSignedUp ? (
          <ThemeIcon color="green.5" variant="white">
            <IconCircleCheckFilled />
          </ThemeIcon>
        ) : (
          <ThemeIcon color="red.8" variant="white">
            <IconCircleXFilled />
          </ThemeIcon>
        )}
      </Group>
    ),
  },
]
