import { i18n } from "@kiosk/reporting/legacy/i18n"
import { UserRole } from "@kiosk/reporting/legacy/types/user"

export const roleTranslations: Record<UserRole, string> = {
  KIOSK_ADMIN: i18n.t("roles.kioskAdmin"),
  COMPANY_ADMIN: i18n.t("roles.companyAdmin"),
  CONTRIBUTOR: i18n.t("roles.companyContributor"),
  VIEWER: i18n.t("roles.companyViewer"),
  AUDITOR: i18n.t("roles.companyAuditor"),
  VERIFIER: i18n.t("roles.companyVerifier"),
  HR_OWNER: i18n.t("roles.hrOwner"),
  FINANCE_OWNER: i18n.t("roles.financeOwner"),
}

export const HIDDEN_DIMENSION = [
  "cyEDF6B9TIWxmuM8dYF4_A", // label : Horizon temporel
  "LiYfTgG6QFydb7y2B34HRQ", // label : Portée
]
