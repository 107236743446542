import { DateTime } from "luxon"
import { z } from "zod"

import { DimensionBreakdown } from "@kiosk/reporting/legacy/types/dimensions"

export const DataSource = z.enum(["report", "file", "survey"]).default("report")
export type DataSource = z.infer<typeof DataSource>

export const BooleanAnswer = z.object({
  type: z.literal("BooleanRecord"),
  value: z.boolean(),
})

export const TextBlockAnswer = z.object({
  type: z.literal("TextBlockRecord"),
  value: z.string(),
})

export const NumberAnswer = z.object({
  type: z.literal("NumberRecord"),
  value: z.number(),
  unit: z.string(),
})

export const DateAnswer = z.object({
  type: z.literal("DateRecord"),
  value: z.string().refine((val) => DateTime.fromISO(val).isValid, {
    message: "Invalid date format",
  }),
})

export const ChoiceAnswer = z.object({
  type: z.literal("ChoiceRecord"),
  value: z.union([z.array(z.string()), z.string()]),
})

export const ConditionalAnswer = z.object({
  type: z.literal("ConditionalAnswerRecord"),
  value: z.boolean(),
})

export const UploadAnswer = z.object({
  type: z.literal("UploadRecord"),
  value: z.string(), // file's path
})

export const CardAnswer = z.object({
  type: z.literal("CardRecord"),
  value: z.unknown(), // TODO: Define more specific schema when card structure is finalized
})

export const CSRDAnswerType = z.discriminatedUnion("type", [
  BooleanAnswer,
  CardAnswer,
  ChoiceAnswer,
  ConditionalAnswer,
  DateAnswer,
  NumberAnswer,
  TextBlockAnswer,
  UploadAnswer,
])

export const CSRDQuestionAnswer = z.object({
  datoQuestionId: z.string(),
  answer: CSRDAnswerType,
  dimensions: DimensionBreakdown,
  source: DataSource,
})

export const HydratedAnswer = CSRDQuestionAnswer.extend({
  dimensionId: z.string().optional(),
})

// This type is used in responses for the frontend
export const CSRDReportAnswer = CSRDQuestionAnswer.extend({
  id: z.string(),
  companyId: z.string(),
})

export type CSRDReportAnswer = z.infer<typeof CSRDReportAnswer>
export type BooleanAnswer = z.infer<typeof BooleanAnswer>
export type TextBlockAnswer = z.infer<typeof TextBlockAnswer>
export type NumberAnswer = z.infer<typeof NumberAnswer>
export type DateAnswer = z.infer<typeof DateAnswer>
export type ChoiceAnswer = z.infer<typeof ChoiceAnswer>
export type ConditionalAnswer = z.infer<typeof ConditionalAnswer>
export type UploadAnswer = z.infer<typeof UploadAnswer>
export type CardAnswer = z.infer<typeof CardAnswer>
export type CSRDAnswerType = z.infer<typeof CSRDAnswerType>
export type CSRDQuestionAnswer = z.infer<typeof CSRDQuestionAnswer>
export type HydratedAnswer = z.infer<typeof HydratedAnswer>

export const CSRDQuestionAnswerEntity = {
  isExternal(self: CSRDQuestionAnswer): boolean {
    return self.source !== "report"
  },
}
