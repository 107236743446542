import { Flex, Space } from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import { useNavigate } from "@remix-run/react"
import {
  IconCalendar,
  IconFlagExclamation,
  IconMail,
} from "@tabler/icons-react"
import dayjs from "dayjs"
import _ from "lodash"
import { useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"

import { useUpdateSurveyMutation } from "@kiosk/reporting/api/surveys/updateSurvey"
import { BackButton } from "@kiosk/reporting/components/BackButton"
import { DateInput } from "@kiosk/reporting/components/DateInput"
import { EditableTitle } from "@kiosk/reporting/components/EditableTitle"
import {
  Survey,
  surveySchema,
} from "@kiosk/reporting/legacy/shared/domain/entities/survey"
import { CsrdCategory } from "@kiosk/reporting/legacy/types/data/dato"
import { SurveyQuestionResponses } from "@kiosk/reporting/legacy/types/endpoints/surveyQuestions"
import { SurveyResponses } from "@kiosk/reporting/legacy/types/endpoints/surveys"
import { LocalizedCompanySurveyQuestion } from "@kiosk/reporting/lib/services/company-survey-question.service.server"
import { QuestionsList } from "@kiosk/reporting/routes/sources_.edit_survey.$id/components/QuestionsList"
import { isSurveyStarted } from "@kiosk/reporting/routes/sources_.edit_survey.$id/utils"
import { routes } from "@kiosk/reporting/utils/constants"

type Props = {
  readonly survey: SurveyResponses.Survey
  readonly surveyQuestions: SurveyQuestionResponses.SurveyQuestion[]
  readonly companySurveyQuestions?: LocalizedCompanySurveyQuestion[]
  readonly rawDataPointsQuestions: CsrdCategory[]
}

export default function EditSurveyForm({
  survey,
  surveyQuestions,
  companySurveyQuestions,
  rawDataPointsQuestions,
}: Props) {
  const navigate = useNavigate()
  const { t } = useTranslation("survey")

  const form = useForm<Survey>({
    mode: "controlled",
    initialValues: _.pick(survey, [
      "id",
      "title",
      "launchDate",
      "followUpDate",
      "dueDate",
    ]),
    validate: zodResolver(surveySchema),
  })

  const { mutateAsync: updateSurvey } = useUpdateSurveyMutation(survey.id)

  const nextDays = useMemo(() => {
    const today = new Date()

    return [
      dayjs(today).add(0, "day").toDate(),
      dayjs(today).add(1, "day").toDate(),
      dayjs(today).add(2, "days").toDate(),
      dayjs(today).add(3, "days").toDate(),
    ]
  }, [])

  const handleSubmit = useCallback(
    (url: string) => {
      return (values: Survey) => {
        updateSurvey(values)
        navigate(url)
      }
    },
    [updateSurvey, navigate],
  )

  const getOnNavigate = useCallback(
    (url: string) => form.onSubmit(handleSubmit(url)),
    [form, handleSubmit],
  )

  return (
    <form>
      <Flex direction="column" gap={30}>
        <Flex align="center" gap={16}>
          <BackButton onClick={getOnNavigate(routes.SURVEYS.path)} />

          <EditableTitle
            {...form.getInputProps("title")}
            onChange={(v) => {
              const inputProps = form.getInputProps("title")

              inputProps.onChange(v)
              updateSurvey({ ...form.values, title: v })
                // eslint-disable-next-line no-console
                .then(console.log)
                .catch(console.error)
            }}
            readOnly={isSurveyStarted(survey)}
          />
        </Flex>

        <Flex gap={24}>
          <DateInput
            icon={IconCalendar}
            label={t("labels.launchDate")}
            minDate={nextDays[0]}
            placeholder={t("labels.launchDate")}
            readOnly={isSurveyStarted(survey)}
            {...form.getInputProps("launchDate")}
          />

          <DateInput
            icon={IconMail}
            label={t("labels.followUpDate")}
            minDate={nextDays[1]}
            placeholder={t("labels.followUpDate")}
            readOnly={isSurveyStarted(survey)}
            {...form.getInputProps("followUpDate")}
          />

          <DateInput
            icon={IconFlagExclamation}
            label={t("labels.dueDate")}
            minDate={nextDays[2]}
            placeholder={t("labels.dueDate")}
            readOnly={isSurveyStarted(survey)}
            {...form.getInputProps("dueDate")}
          />
        </Flex>
      </Flex>

      <Space h="xl" />

      <QuestionsList
        companySurveyQuestions={companySurveyQuestions}
        getOnNavigate={getOnNavigate}
        rawDataPointsQuestions={rawDataPointsQuestions}
        survey={survey}
        surveyQuestions={surveyQuestions}
      />
    </form>
  )
}
