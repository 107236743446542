import { z } from "zod"

import { Option } from "@kiosk/reporting/legacy/shared/utils/helpers"

export type Survey = {
  id: string
  title: string
  launchDate: Date
  followUpDate: Date
  dueDate: Date
}

const dateValidation = (
  survey: Survey | Omit<Survey, "id">,
  ctx: z.RefinementCtx,
) => {
  if (survey.launchDate >= survey.followUpDate) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Follow-up date must be after launch date",
      path: ["followUpDate"],
    })
  }

  if (survey.launchDate >= survey.dueDate) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Due date must be after launch date",
      path: ["dueDate"],
    })
  }

  if (survey.followUpDate >= survey.dueDate) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Due date must be after follow-up date",
      path: ["dueDate"],
    })
  }
}

export const surveySchema = z
  .object({
    id: z.string(),
    title: z.string(),
    launchDate: z.coerce.date(),
    followUpDate: z.coerce.date(),
    dueDate: z.coerce.date(),
  })
  .superRefine(dateValidation)

export const surveySchemaCreation = z
  .object({
    title: z.string(),
    launchDate: z.coerce.date(),
    followUpDate: z.coerce.date(),
    dueDate: z.coerce.date(),
  })
  .superRefine(dateValidation)

//This should be in the repositorie
export type SurveyCreation = Omit<Survey, "id">

export function parseSurvey(obj: object): Option<Survey> {
  const result = surveySchema.safeParse(obj)

  if (result.success) {
    return result.data as Survey
  }

  console.error("Survey parsing failed:", result.error.format())

  return undefined
}
