import _ from "lodash"

import {
  CSRDQuestionAnswer,
  CSRDQuestionAnswerEntity,
  DataSource,
} from "@kiosk/reporting/legacy/types/csrd"
import { CSRDQuestions, Content } from "@kiosk/reporting/legacy/types/data/dato"
import { DimensionBreakdownValue } from "@kiosk/reporting/legacy/types/dimensions"

/**
 * Builds the form key for question and its dimension breakdown.
 *
 * @example "datoQuestionId-dim1:dimOpt1-dim2:dimOpt2"
 */
export function buildCSRDReportAnswerFormKey(
  datoQuestionId: string,
  dimensionBreakdown?: DimensionBreakdownValue[],
): string {
  if (!dimensionBreakdown?.length) return datoQuestionId

  return `${datoQuestionId}-${dimensionBreakdown.map((dimension) => `${dimension.dimensionId}:${dimension.optionId}`).join("-")}`
}

// TODO: nice to have a test here
const findChildrenAnswers = (
  question: CSRDQuestions<Content>,
  savedAnswers: FormAnswers,
): CSRDQuestionAnswer[] => {
  const flatAnswers = Object.values(savedAnswers)

  if (!question.relatedQuestions) return []

  return question.relatedQuestions.flatMap((relatedQuestion) => {
    const relatedAnswers = flatAnswers.filter(
      (answer) => answer.datoQuestionId === relatedQuestion.id,
    )

    return [
      ...relatedAnswers,
      ...findChildrenAnswers(relatedQuestion, savedAnswers),
    ]
  })
}

// TODO: nice to have a test here
export const getDimensionBreakdownParentQuestion = (
  question: CSRDQuestions<Content>,
  savedAnswers: FormAnswers,
): DimensionBreakdownValue[] => {
  if (!question.dimensions?.length) return []
  const childrenAnswers = findChildrenAnswers(question, savedAnswers)

  return _.uniqBy(
    childrenAnswers.flatMap((answer) => answer.dimensions),
    (dimension) => `${dimension.dimensionId}:${dimension.optionId}`,
  )
}

// The «string» key here is made using `buildCSRDReportAnswerFormKey` above.
// It’s a serialization of the question ID and dimension breakdown
// to generate a unique key.
export type FormAnswers = Record<string, CSRDQuestionAnswer>

export const FormAnswers = {
  filterSource(self: FormAnswers, source: DataSource): FormAnswers {
    return FormAnswers.filterValues(self, (v) => v.source === source)
  },

  filterExternal(self: FormAnswers) {
    return FormAnswers.filterValues(self, CSRDQuestionAnswerEntity.isExternal)
  },

  filterValues(
    self: FormAnswers,
    predicate: (v: CSRDQuestionAnswer) => boolean,
  ) {
    const filtered: FormAnswers = {}

    for (const [k, v] of Object.entries(self)) {
      if (predicate(v)) {
        filtered[k] = v
      }
    }

    return filtered
  },
}

export const extractEsrs = (esrsCode: string): string => {
  // split by "-" to remove any suffix (e.g., "E1.GOV-3" → "E1.GOV" or "E3-5.1" → "E3")
  // split by "." to remove any subcategory (e.g., "E1.GOV" → "E1" or "E3" → "E3")
  return esrsCode.split("-")[0].split(".")[0]
}
