import { useOutletContext } from "@remix-run/react"

import { User } from "@kiosk/reporting/legacy/types/user"

export interface AppRouteContext {
  user: User & { token: string }
}

export function useAppRouteContext(): AppRouteContext {
  const ctx = useOutletContext<AppRouteContext | undefined>()

  if (!ctx) {
    throw new Error("useAppRouteContext is only valid in app routes")
  }

  return ctx
}
