import { notifications, showNotification } from "@mantine/notifications"
import { useMutation } from "@tanstack/react-query"
import { Dispatch, SetStateAction } from "react"
import { useTranslation } from "react-i18next"

import { companyKeys } from "@kiosk/reporting/api/companies/companyKeys"
import { useUser } from "@kiosk/reporting/components/auth/UserContext"
import { CompanyRequests } from "@kiosk/reporting/legacy/types/endpoints/companies"
import { apiClient } from "@kiosk/reporting/lib/apiClient"
import { authorizationHeader } from "@kiosk/reporting/lib/headers"
import { queryClient } from "@kiosk/reporting/lib/queryClient"

export const inviteCompanyUser = async (
  companyId: string,
  data: CompanyRequests.InviteUser,
  token: string,
): Promise<{ message: string }> => {
  const response = await apiClient.post<{ message: string }>(
    `/companies/${companyId}/invite-user`,
    data,
    { headers: authorizationHeader(token) },
  )

  return response.data
}

export const useInviteCompanyUser = (
  companyId: string,
  onSuccess: () => void,
  setEmailExists: Dispatch<SetStateAction<boolean>>,
) => {
  const { t } = useTranslation()
  const { token } = useUser()

  return useMutation({
    mutationFn: (data: CompanyRequests.InviteUser) =>
      inviteCompanyUser(companyId, data, token),
    onSuccess: async (data: { message: string }) => {
      await queryClient.invalidateQueries({
        queryKey: companyKeys.companyUsers(companyId),
      })
      showNotification({
        message: data.message,
      })
      setEmailExists(false)
      onSuccess()
    },
    onError: (error: Error) => {
      if (error.message === "User already exist") {
        setEmailExists(true)
      } else {
        notifications.show({
          title: t("messages.error"),
          message: t("messages.somethingWentWrong"),
          color: "red",
        })
      }
    },
  })
}
