import { ActionFunctionArgs, LoaderFunctionArgs } from "@remix-run/node"

import { Locale } from "@kiosk/reporting/i18n"
import { handleCompanyRequest } from "@kiosk/reporting/lib/infra/company-infra.server"
import {
  getSubjectsData,
  handleSubjectRequest,
} from "@kiosk/reporting/lib/infra/subjects-infra.server"
import { Onboarding } from "@kiosk/reporting/routes/_onboarding.onboarding/components"
import { requireUser } from "@kiosk/reporting/utils/auth.server"
import { getFormId } from "@kiosk/reporting/utils/common/formRequest"

export const handle = {
  title: () => "onboarding",
}

export async function action(args: ActionFunctionArgs) {
  const { companyId, token } = await requireUser(args)
  const formId = await getFormId(args.request)

  switch (formId) {
    case "onboardingCompanySettings":
      return handleCompanyRequest(args.request)
    case "onboardingSubjectsSettings":
      return handleSubjectRequest(args.request, companyId, token)
    default:
      return null
  }
}

export async function loader(args: LoaderFunctionArgs) {
  const { companyId, locale, token, company } = await requireUser(args)

  const subjectsData = await getSubjectsData({
    companyId,
    locale: locale as Locale,
    token,
  })

  const onboarding = subjectsData.onboarding

  return { subjectsData, company, onboarding }
}

export default function OnboardingPage() {
  return <Onboarding />
}
