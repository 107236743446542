import { Alert, Text } from "@mantine/core"
import { IconBulb } from "@tabler/icons-react"
import { ReactNode } from "react"

type Props = {
  readonly title?: string
  readonly message: string
  readonly icon?: ReactNode
}

export const Callout = ({ title, message, icon: Icon }: Props) => (
  <Alert
    bg="blue.0"
    c="blue.8"
    icon={Icon ?? <IconBulb />}
    radius="md"
    title={title}
    variant="light"
  >
    <Text c="blue.8" component="span">
      {message}
    </Text>
  </Alert>
)
