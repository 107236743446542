import { Accordion, Group, rem, Stack, Text } from "@mantine/core"
import { NavLink } from "@remix-run/react"
import _ from "lodash"
import { useMemo } from "react"

import { Completion } from "@kiosk/reporting/components/Completion"
import { useUser } from "@kiosk/reporting/components/auth/UserContext"
import { EsrsBadge } from "@kiosk/reporting/components/csrd/EsrsBadge"
import { ReportsResponses } from "@kiosk/reporting/legacy/types/endpoints/reports"
import classes from "@kiosk/reporting/routes/csrd_.$datoTopicId/components/CSRDTopicsSideBar.module.css"
import { routes } from "@kiosk/reporting/utils/constants/index"

type Props = {
  readonly categories: ReportsResponses.GetCategories
  readonly activeTopicId: string
  readonly nonMaterialEsrs: string[]
}

export const CSRDTopicsSideBar = ({
  categories,
  activeTopicId,
  nonMaterialEsrs,
}: Props) => {
  const user = useUser()

  const activeCategoryId = useMemo(() => {
    return categories.find(
      (category) =>
        !!category.topics.find((topic) => topic.id === activeTopicId),
    )!.id
  }, [activeTopicId, categories])

  return (
    <Stack w="100%">
      <Accordion
        chevronPosition="right"
        defaultValue={[activeCategoryId]}
        multiple
        py={8}
        styles={{
          item: { border: "none" },
          content: { padding: 0 },
          label: { paddingTop: rem(8), paddingBottom: rem(8) },
          control: { boxSizing: "border-box" },
        }}
      >
        {_.sortBy(categories, (category) => category.order).map(
          ({ id, name, progress, topics, companies }) => {
            const isAllTopicsHidden = topics.every((topic) => {
              return topic.isHidden
            })

            if (
              isAllTopicsHidden ||
              (companies && !companies.includes(user.company.name))
            )
              return

            return (
              <Accordion.Item key={id} value={id}>
                <Accordion.Control>
                  <Group gap={16}>
                    <Completion progress={progress} />
                    <Text fw={500} fz="lg">
                      {name}
                    </Text>
                  </Group>
                </Accordion.Control>

                <Accordion.Panel>
                  {_.sortBy(topics, (t) => t.code)
                    .filter((topic) => !topic.isHidden)
                    .map(({ id: topicId, code, name: topicName, progress }) => {
                      const isNonMaterialEsrs =
                        nonMaterialEsrs.includes(topicId)

                      return (
                        <NavLink
                          key={topicId}
                          to={routes.CSRD.path.replace(":datoTopicId", topicId)}
                        >
                          {({ isActive }) => (
                            <Group
                              className={`${classes.topic__item} ${isActive ? classes["topic__item--active"] : ""}`}
                            >
                              <Completion
                                isNonMaterialEsrs={isNonMaterialEsrs}
                                progress={progress}
                              />
                              <EsrsBadge
                                isNonMaterialEsrs={isNonMaterialEsrs}
                                topic={code}
                              />
                              <Text
                                className={`${classes.topic__title} ${isActive ? classes["topic__title--active"] : ""} ${isNonMaterialEsrs ? classes["topic__title--non-material"] : ""}`}
                              >
                                {topicName}
                              </Text>
                            </Group>
                          )}
                        </NavLink>
                      )
                    })}
                </Accordion.Panel>
              </Accordion.Item>
            )
          },
        )}
      </Accordion>
    </Stack>
  )
}
