import { z } from "zod"

const baseSurveyDataStructure = {
  title: z.string().min(1),
  launchDate: z.coerce.date(),
  followUpDate: z.coerce.date(),
  dueDate: z.coerce.date(),
} as const

const fullSurveySchema = z.object({
  ...baseSurveyDataStructure,
  id: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  companyId: z.string(),
  creatorId: z.string(),
  status: z.union([
    z.literal("NOT_STARTED"),
    z.literal("IN_PROGRESS"),
    z.literal("MISSING_ASSIGNEE"),
    z.literal("DONE"),
  ]),
  assignmentsCount: z.number(),
  answersCount: z.number(),
})

export const getSurveySchema = fullSurveySchema
export const listSurveysSchema = z.array(fullSurveySchema)
