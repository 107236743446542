import {
  Group,
  HoverCard,
  ActionIcon,
  Text,
  Modal,
  Button,
  Stack,
} from "@mantine/core"
import { useDisclosure, useHover } from "@mantine/hooks"
import { IconMessage } from "@tabler/icons-react"
import { useTranslation } from "react-i18next"

import { AssignmentResponses } from "@kiosk/reporting/legacy/types/endpoints/assignments"

export const AnswerCell = ({
  value,
  record,
}: {
  readonly value: string
  readonly record: AssignmentResponses.Assignment
}) => {
  const { t } = useTranslation("survey")
  const [opened, { open, close }] = useDisclosure(false)
  const { hovered, ref } = useHover<HTMLButtonElement>()

  return (
    <>
      <Modal
        onClose={close}
        opened={opened}
        styles={{
          body: {
            padding: 0,
          },
        }}
        title={t("commentOnResponse")}
      >
        <Stack gap={0}>
          <Group
            px={24}
            py={16}
            style={{
              borderTop: "1px solid var(--mantine-color-gray-3)",
              borderBottom: "1px solid var(--mantine-color-gray-3)",
            }}
          >
            <Text>{record.comment}</Text>
          </Group>

          <Group justify="end">
            <Button mx={24} my={16} onClick={close}>
              {t("actions.close")}
            </Button>
          </Group>
        </Stack>
      </Modal>

      <Group flex={1} justify="space-between">
        <Text>{value}</Text>

        {record.comment && (
          <HoverCard position="top">
            <HoverCard.Target>
              <ActionIcon
                color={hovered ? "green" : "gray.4"}
                onClick={open}
                ref={ref as React.Ref<HTMLButtonElement>}
                variant="subtle"
              >
                <IconMessage size={24} />
              </ActionIcon>
            </HoverCard.Target>

            <HoverCard.Dropdown>
              <Text size="sm">{t("displayComment")}</Text>
            </HoverCard.Dropdown>
          </HoverCard>
        )}
      </Group>
    </>
  )
}
