import { User } from "@prisma/client"
import { useQuery } from "@tanstack/react-query"

import { usersKeys } from "@kiosk/reporting/api/users/usersKeys"
import { useUser } from "@kiosk/reporting/components/auth/UserContext"
import { apiClient } from "@kiosk/reporting/lib/apiClient"
import { authorizationHeader } from "@kiosk/reporting/lib/headers"

export const getUser = async (
  token: string,
  userId?: string,
): Promise<User | null> => {
  const response = await apiClient.get<User | null>(`/users/${userId}`, {
    headers: authorizationHeader(token),
  })

  return response.data
}

export const useUserQuery = (userId: string) => {
  const { token } = useUser()

  return useQuery({
    queryKey: usersKeys.get(userId),
    queryFn: () => getUser(token, userId),
    enabled: !!userId,
  })
}
