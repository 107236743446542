import {
  Button,
  Group,
  Menu,
  Progress,
  rem,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { IconChevronDown, IconDownload } from "@tabler/icons-react"
import { useState } from "react"
import { useTranslation } from "react-i18next"

import { ExportProgressModal } from "@kiosk/reporting/components/loader/ExportProgressModal/ExportProgressModal"
import { clientConfig } from "@kiosk/reporting/config"

export function ExportMenu() {
  const { t } = useTranslation(["common", "csrd"])
  const [downloadProgress, setDownloadProgress] = useState(0)
  const [opened, { open, close }] = useDisclosure(false)
  const [link, setLink] = useState<string>()
  const [failedDownload, setFailedDownload] = useState(false)
  const [fileName, setFileName] = useState("")

  const handleDownload = async (url: string) => {
    try {
      setDownloadProgress(0)
      open()

      const response = await fetch(url)

      if (!response.ok) {
        setFailedDownload(true)
        throw new Error("Download failed")
      }

      const reader = response.body?.getReader()

      if (!reader) throw new Error("No reader available")

      const contentDisposition = response.headers.get("Content-Disposition")

      if (contentDisposition) {
        const match = contentDisposition.match(/filename="?([^"]+)"?/)

        if (match) {
          setFileName(match[1])
        }
      }

      const contentLength = response.headers.get("Content-Length")
      const total = contentLength ? parseInt(contentLength, 10) : 0
      let received = 0
      const chunks: Uint8Array[] = []

      let done = false

      while (!done) {
        const { done: chunkDone, value } = await reader.read()

        done = chunkDone

        if (value) {
          chunks.push(value)
          received += value.length
          setDownloadProgress(total ? Math.round((received / total) * 100) : 0)
        }
      }

      const blob = new Blob(chunks)
      const objectUrl = URL.createObjectURL(blob)

      setLink(objectUrl)
    } catch (error) {
      setFailedDownload(true)
      console.error(error)
    } finally {
      setDownloadProgress(100)
    }
  }

  return (
    <>
      <Menu position="bottom-end">
        <Menu.Target>
          <Tooltip label={t("csrd:finalizeReport")} position="top-end">
            <Button
              disabled={!clientConfig.featureFlags.enableReportExport}
              rightSection={<IconChevronDown size={20} />}
            >
              {t("csrd:actions.export")}
            </Button>
          </Tooltip>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item
            leftSection={<IconDownload size={20} />}
            onClick={() => {
              handleDownload("/report/download/docx")
            }}
          >
            {t("csrd:actions.exportDoc")}
          </Menu.Item>
          <Menu.Item leftSection={<IconDownload size={20} />}>
            {t("csrd:actions.exportPdf")}
          </Menu.Item>
          <Menu.Item
            leftSection={<IconDownload size={20} />}
            onClick={() => {
              handleDownload("/report/download/xhtml")
            }}
          >
            {t("csrd:actions.exportXhtml")}
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
      <ExportProgressModal
        close={close}
        downloadProgress={downloadProgress}
        failedDownload={failedDownload}
        fileName={fileName}
        link={link}
        opened={opened}
      />
    </>
  )
}

export const CSRDHeader = ({ progress }: { readonly progress: number }) => {
  const { t } = useTranslation("csrd")

  return (
    <Group
      justify="space-between"
      px={24}
      py={16}
      style={{
        borderBottomColor: "var(--mantine-color-gray-2)",
        borderBottomWidth: rem(1),
        borderBottomStyle: "solid",
      }}
    >
      <Stack
        px={24}
        py={16}
        style={{
          borderStyle: "solid",
          borderWidth: rem(1),
          borderColor: "var(--mantine-color-gray-2)",
          borderRadius: rem(6),
        }}
        w={360}
      >
        <Group justify="space-between">
          <Group gap={8}>
            <Text c="gray.6" fw={500} fz="sm">
              {t("overallCompletion")}
            </Text>

            <Text c="gray.9" fw={600} fz="md">
              {progress}%
            </Text>
          </Group>
        </Group>

        <Progress value={progress} />
      </Stack>

      <Stack gap={8}>
        <ExportMenu />
      </Stack>
    </Group>
  )
}
