import { Stack, Text } from "@mantine/core"
import { LoaderFunctionArgs, redirect } from "@remix-run/server-runtime"
import { useTranslation } from "react-i18next"

import { requireUser } from "@kiosk/reporting/utils/auth.server"

export const loader = async (args: LoaderFunctionArgs) => {
  const user = await requireUser(args)

  if (user.roles.includes("CONTRIBUTOR")) return null

  return redirect("/dashboard")
}

export default function NoSurveysPage() {
  const { t } = useTranslation("survey")

  return (
    <Stack align="center" gap={24} mt={56}>
      <Text fz={64}>🌱</Text>

      <Stack align="center" gap={0}>
        <Text fz={32}>{t("noSurveysYet")}</Text>
      </Stack>
    </Stack>
  )
}
