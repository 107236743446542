import {
  ComboboxItem,
  useMantineTheme,
  Stack,
  Select,
  Text,
} from "@mantine/core"
import { useTranslation } from "react-i18next"

interface PhaseInSelectProps {
  readonly availableYears: number[]
  readonly selectedYear: number
  readonly onChange: (value: string | null, option: ComboboxItem) => void
}

export const PhaseInSelect = (props: PhaseInSelectProps) => {
  const { t } = useTranslation("csrd")

  const phaseInOptions = [
    {
      group: t("phaseInSelectInstruction"),
      items: props.availableYears.map((year) => ({
        value: String(year),
        label: `${t("phaseIn")}: ${year}`,
      })),
    },
  ]

  const { colors } = useMantineTheme()

  return (
    <Stack justify="center" style={{ userSelect: "none" }}>
      <Stack w="10em">
        <Select
          comboboxProps={{ width: "20em", position: "bottom-start" }}
          data={phaseInOptions}
          mr="1em"
          onChange={props.onChange}
          placeholder={t("phaseIn")}
          renderOption={(item) => <Text>{item.option.value}</Text>}
          style={{ borderRadius: "50em" }}
          styles={{
            input: {
              borderRadius: "5em",
              backgroundColor: colors.gray[1],
              border: 0,
              paddingRight: "1em",
            },
            groupLabel: {
              borderBottom: "solid",
              borderWidth: 0.5,
              borderColor: colors.gray[3],
              margin: 0,
              display: "block",
              fontWeight: 500,
              color: colors.gray[9],
              fontSize: "calc(0.875rem * 1)", //mantine sm size
            },
          }}
          value={String(props.selectedYear)}
        />
      </Stack>
    </Stack>
  )
}
