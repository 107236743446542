import { Divider, Flex, Group, ScrollArea, Stack } from "@mantine/core"
import { useParams } from "@remix-run/react"
import _ from "lodash"
import { useMemo } from "react"

import {
  useDisclosureRequirementCategoriesQuery,
  useMaterialityQuery,
} from "@kiosk/reporting/api/reports"
import { MultiQueryWrapper } from "@kiosk/reporting/components/layout/QueryWrapper"
import { ReportsResponses } from "@kiosk/reporting/legacy/types/endpoints/reports"
import { CSRDHeader } from "@kiosk/reporting/routes/csrd/components/CSRDHeader"
import { CSRDSubTopicsList } from "@kiosk/reporting/routes/csrd_.$datoTopicId/components/CSRDSubTopicsList"
import { CSRDTopicsSideBar } from "@kiosk/reporting/routes/csrd_.$datoTopicId/components/CSRDTopicsSideBar"
import {
  getOverallProgress,
  getTopicById,
} from "@kiosk/reporting/services/report"

// todo: don’t pipe the dato response like that
interface Props {
  categories: ReportsResponses.GetCategories
  nonMaterialEsrs: string[]
}

const CSRDCategories: React.FC<Props> = ({ categories, nonMaterialEsrs }) => {
  const params = useParams()
  const activeTopicId = params.datoTopicId!
  const activeTopic = useMemo(
    () => getTopicById(categories, activeTopicId),
    [activeTopicId, categories],
  )

  const progress = useMemo(
    () => getOverallProgress(categories, nonMaterialEsrs),
    [categories, nonMaterialEsrs],
  )

  return (
    <Stack gap={0} h="100%" w="100%">
      <CSRDHeader progress={_.round(progress, 0)} />

      <Group align="flex-start" flex={1} gap={0}>
        <Flex w="30%">
          <CSRDTopicsSideBar
            activeTopicId={activeTopicId}
            categories={categories}
            nonMaterialEsrs={nonMaterialEsrs}
          />
        </Flex>

        <Divider color="gray.2" orientation="vertical" />

        <ScrollArea flex={1} h="75vh">
          <CSRDSubTopicsList
            nonMaterialEsrs={nonMaterialEsrs}
            topic={activeTopic}
          />
        </ScrollArea>
      </Group>
    </Stack>
  )
}

export default function CSRDTopicPage() {
  const csrdCategoriesQuery = useDisclosureRequirementCategoriesQuery()
  const materialityQuery = useMaterialityQuery()

  const queries = {
    nonMaterialEsrs: {
      query: materialityQuery,
      allowEmptyArray: true,
    },
    csrdCategories: {
      query: csrdCategoriesQuery,
      allowEmptyArray: true,
    },
  }

  return (
    <MultiQueryWrapper fluid queries={queries}>
      {({ nonMaterialEsrs, csrdCategories }) => (
        <CSRDCategories
          categories={csrdCategories}
          nonMaterialEsrs={nonMaterialEsrs}
        />
      )}
    </MultiQueryWrapper>
  )
}
