import { notifications } from "@mantine/notifications"
import { useMutation } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"

import { taskKeys } from "@kiosk/reporting/api/tasks/taskKeys"
import { useUser } from "@kiosk/reporting/components/auth/UserContext"
import { CreateTaskBody } from "@kiosk/reporting/legacy/shared/schemas/tasks"
import { apiClient } from "@kiosk/reporting/lib/apiClient"
import { authorizationHeader } from "@kiosk/reporting/lib/headers"
import { queryClient } from "@kiosk/reporting/lib/queryClient"

export const CreateTask = async (data: CreateTaskBody, token: string) => {
  const response = await apiClient.post("/tasks", data, {
    headers: authorizationHeader(token),
  })

  return response.data
}

export const useCreateTaskMutation = () => {
  const { t } = useTranslation(["task", "common"])
  const { token } = useUser()

  return useMutation({
    mutationFn: async (data: CreateTaskBody) => await CreateTask(data, token),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: taskKeys.all(),
      })
      await queryClient.invalidateQueries({ queryKey: taskKeys.owners() })
      notifications.show({
        title: t("common:messages.success"),
        message: t("taskCreateSuccess"),
        color: "green",
      })
    },
    onError: ({ message }) => {
      notifications.show({
        title: t("common:messages.error"),
        message,
        color: "red",
      })
    },
  })
}
