import { Flex, Badge } from "@mantine/core"
import { IconPlus } from "@tabler/icons-react"
import _ from "lodash"

import { findDimensionOptionById } from "@kiosk/reporting/components/surveys/utils"
import { CreateAssignmentBody } from "@kiosk/reporting/legacy/shared/schemas/assignments"
import { ConsolidatedDimension } from "@kiosk/reporting/legacy/types/utils/dimensions"
import { CompanyDimensionAggregate } from "@kiosk/reporting/lib/aggregates/companyDimensionAggregate"

export const AssignmentDimensionsCell = ({
  assignmentDimensions,
  allDimensions,
}: {
  readonly assignmentDimensions: CreateAssignmentBody["assignmentDimensions"]
  readonly allDimensions: Array<
    ConsolidatedDimension | CompanyDimensionAggregate
  >
}) => {
  const orderedDimensions = _.orderBy(
    assignmentDimensions,
    ({ dimensionDatoId }) => dimensionDatoId,
  )

  const validOptions = orderedDimensions.map(
    ({ dimensionDatoId, optionId }) => {
      return findDimensionOptionById(dimensionDatoId, optionId, allDimensions)
    },
  )

  return (
    <Flex align="center" gap={4} wrap="wrap">
      {validOptions.map((option, index) => {
        if (option)
          return (
            <Flex align="center" gap={4} key={option.id}>
              <Badge
                fw={500}
                size="lg"
                style={(theme) => ({
                  color: theme.colors.purple[8],
                  backgroundColor: theme.colors.purple[1],
                })}
                tt="none"
              >
                {option.label}
              </Badge>
              {index < validOptions.length - 1 && (
                <IconPlus key={`icon-plus-${option.id}`} size={16} />
              )}
            </Flex>
          )
      })}
    </Flex>
  )
}
