import {
  TableTr,
  Group,
  Stack,
  Flex,
  Text,
  Switch,
  Table,
  TableTbody,
  TableThead,
  useMantineTheme,
  TableTh,
} from "@mantine/core"
import { CSSProperties } from "react"
import { useTranslation } from "react-i18next"

import { SubjectModule } from "@kiosk/reporting/components/onboarding/SubjectModule"
import { CURRENT_REPORT_YEAR } from "@kiosk/reporting/legacy/shared/constants/common/phaseIn"

import type { SubjectData } from "@kiosk/reporting/lib/infra/subjects-infra.server"

interface SubjectFormProps {
  readonly loaderState: SubjectData
  readonly sticky?: boolean
}

export const SubjectForm = (props: SubjectFormProps) => {
  const { t } = useTranslation("onboarding")
  const { colors } = useMantineTheme()

  const stickyHeaderStyle: CSSProperties = {
    position: "sticky",
    top: "8.5em",
    boxShadow: `0 1px 0 0 ${colors.gray[2]}`,
  }

  return (
    <Stack>
      <Table>
        <TableThead
          bg="white"
          style={props.sticky ? stickyHeaderStyle : undefined}
        >
          <TableTr>
            <TableTh c="gray.5" fw="normal" py="xl" w="55%">
              {t("subjects.tableFirstColumn")}
            </TableTh>
            <TableTh c="gray.5" fw="normal">
              <Flex align="center" direction="row" justify="center" py="xl">
                {CURRENT_REPORT_YEAR}
              </Flex>
            </TableTh>
            <TableTh c="gray.5" fw="normal">
              <Flex align="center" direction="row" justify="center" py="xl">
                {CURRENT_REPORT_YEAR + 1}
              </Flex>
            </TableTh>
            <TableTh c="gray.5" fw="normal">
              <Flex align="center" direction="row" justify="center" py="xl">
                {CURRENT_REPORT_YEAR + 2}
              </Flex>
            </TableTh>
            <TableTh c="gray.5" fw="normal">
              <Flex align="center" direction="row" justify="center" py="xl">
                {CURRENT_REPORT_YEAR + 3}
              </Flex>
            </TableTh>
          </TableTr>
        </TableThead>
        <TableTbody>
          <SubjectModule
            phaseInOptions={
              props.loaderState.phaseInOptions?.userPhaseInOptions?.esrs
            }
            scope="esrs"
            subjects={props.loaderState.phaseInOptions?.esrs}
          />
          <SubjectModule
            phaseInOptions={
              props.loaderState.phaseInOptions?.userPhaseInOptions?.dr
            }
            scope="dr"
            subjects={props.loaderState.phaseInOptions?.dr}
          />
          <SubjectModule
            phaseInOptions={
              props.loaderState.phaseInOptions?.userPhaseInOptions
                ?.thematicQuestionGroups
            }
            scope="tqg"
            subjects={props.loaderState.phaseInOptions?.thematicQuestionGroups}
          />
          <SubjectModule
            phaseInOptions={
              props.loaderState.phaseInOptions?.userPhaseInOptions?.questions
            }
            scope="question"
            subjects={props.loaderState.phaseInOptions?.questions}
          />
        </TableTbody>
      </Table>

      <Flex direction="row">
        <Group flex={2}>
          <Stack gap={0} my="sm">
            <Text c="gray.9" fw="bold">
              {t("subjects.hideVoluntaryQuestions")}
            </Text>
            <Text c="gray.5" fw="normal">
              {t("subjects.voluntaryExplanation")}
            </Text>
          </Stack>
        </Group>
        <Stack align="flex-end" flex={1} justify="center">
          <Switch
            defaultChecked={
              props.loaderState.phaseInOptions?.userPhaseInOptions
                ?.hideVoluntary ?? false
            }
            name="hideVoluntary"
            size="lg"
          />
        </Stack>
      </Flex>
      <Flex direction="row">
        <Group flex={2}>
          <Stack gap={0} my="sm">
            <Text c="gray.9" fw="bold">
              {t("subjects.hidePhaseInQuestions")}
            </Text>
            <Text c="gray.5" fw="normal">
              {t("subjects.phaseInExplanation")}
            </Text>
          </Stack>
        </Group>
        <Stack align="flex-end" flex={1} justify="center">
          <Switch
            defaultChecked={
              props.loaderState.phaseInOptions?.userPhaseInOptions
                ?.hidePhaseIn ?? false
            }
            name="hidePhaseIn"
            size="lg"
          />
        </Stack>
      </Flex>
      <input hidden name="formId" readOnly value="onboardingSubjectsSettings" />
    </Stack>
  )
}
