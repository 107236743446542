import { Stack } from "@mantine/core"
import { PropsWithChildren } from "react"

interface RelatedQuestionsWrapperProps extends PropsWithChildren {
  readonly hiddenInForm?: boolean
  readonly isTitle?: boolean
}

export const RelatedQuestionsWrapper = ({
  children,
  hiddenInForm = false,
  isTitle = false,
}: RelatedQuestionsWrapperProps) => (
  <Stack
    gap={32}
    ml={hiddenInForm || isTitle ? 0 : "xs"}
    mt={hiddenInForm ? 0 : "sm"}
    pl={hiddenInForm || isTitle ? 0 : "xl"}
    style={{
      borderLeft:
        hiddenInForm || isTitle
          ? "none"
          : "1px solid var(--mantine-color-gray-2)",
    }}
  >
    {children}
  </Stack>
)
