import { Button, Combobox, Flex, Text, useCombobox } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { IconChevronDown, IconPlus, IconSearch } from "@tabler/icons-react"
import { useState } from "react"
import { useTranslation } from "react-i18next"

import { CreateAssigneeForm } from "@kiosk/reporting/routes/sources_.edit_question.$id/components/CreateAssigneeForm"
import { Assignee } from "@kiosk/reporting/routes/sources_.edit_question.$id/components/EditQuestion"
import { searchInArray } from "@kiosk/reporting/utils/text"

export const AssigneeSelect = ({
  companyContributors,
  assignee,
  onSelect,
  label,
}: {
  readonly assignee?: Assignee | null
  readonly companyContributors: Assignee[]
  readonly label: string
  readonly onSelect: (assignee: Assignee) => void
}) => {
  const { t } = useTranslation("survey")

  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption()
      combobox.focusTarget()
      setSearch("")
    },
    onDropdownOpen: () => {
      combobox.focusSearchInput()
    },
  })

  const [search, setSearch] = useState("")

  const options = searchInArray(
    companyContributors,
    search,
    "fullName",
    "email",
  )
    .slice(0, 10)
    .map((user) => (
      <Combobox.Option key={user.id} value={user.fullName}>
        <Flex direction="column">
          <Text>{user.fullName}</Text>

          <Text c="gray.5" fz="sm">
            {user.email}
          </Text>
        </Flex>
      </Combobox.Option>
    ))

  const [opened, { open, close }] = useDisclosure(false)

  return (
    <Combobox
      onOptionSubmit={(val) => {
        const assignee = companyContributors.find(
          ({ fullName }) => fullName === val,
        )

        if (assignee) onSelect(assignee)
        combobox.closeDropdown()
      }}
      store={combobox}
      withinPortal={false}
    >
      <Combobox.Target>
        <Flex align="center" justify="space-between" w="100%">
          <Button
            color="black"
            fullWidth
            justify="space-between"
            onClick={() => combobox.toggleDropdown()}
            rightSection={<IconChevronDown color="gray" size={20} />}
            type="button"
            variant="transparent"
          >
            <Flex align="center" gap="sm" justify="space-between">
              {assignee ? (
                <Flex align="start" direction="column">
                  <Text style={{ wrap: "nowrap" }}>{assignee.fullName}</Text>

                  <Text c="gray.5" fz="xs">
                    {assignee.email}
                  </Text>
                </Flex>
              ) : (
                <Text>{label}</Text>
              )}
            </Flex>
          </Button>
        </Flex>
      </Combobox.Target>

      <CreateAssigneeForm
        onClose={close}
        onCreateNewAssignee={(user) => onSelect(user)}
        opened={opened}
      />

      <Combobox.Dropdown>
        <Combobox.Search
          leftSection={<IconSearch color="gray" size={20} />}
          onChange={(event) => setSearch(event.currentTarget.value)}
          placeholder={t("nameOrEmail")}
          value={search}
        />

        <Combobox.Options>{options}</Combobox.Options>

        <Combobox.Footer>
          <Button
            c="black"
            color="gray.3"
            fullWidth
            leftSection={<IconPlus color="green" size={20} />}
            onClick={() => {
              open()
              combobox.toggleDropdown()
            }}
            variant="outline"
          >
            {t("addAssigneeModal.newAssignee")}
          </Button>
        </Combobox.Footer>
      </Combobox.Dropdown>
    </Combobox>
  )
}
