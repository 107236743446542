import { Stack } from "@mantine/core"
import { useForm } from "@mantine/form"
import { useEffect, useState } from "react"

import {
  useMarkDisclosureRequirementAsDoneMutation,
  useUnmarkDisclosureRequirementAsDoneMutation,
  useUpsertDisclosureRequirementAnswersMutation,
} from "@kiosk/reporting/api/reports/index"
import classes from "@kiosk/reporting/components/csrd/CSRDForm.module.css"
import CSRDQuestionTree from "@kiosk/reporting/components/csrd/CSRDQuestionTree/CSRDQuestionTree"
import { PhaseInVoluntaryQuestionsWrapper } from "@kiosk/reporting/components/csrd/PhaseInVoluntaryWrapper/PhaseInVoluntaryWrapper"
import { SaveFooter } from "@kiosk/reporting/components/csrd/SaveFooter/SaveFooter"
import { FormShape } from "@kiosk/reporting/components/csrd/types"
import { FormAnswers } from "@kiosk/reporting/components/csrd/utils"
import { Framework } from "@kiosk/reporting/lib/entities/company"
import { DisclosureRequirementsDetailsWithCompanyData } from "@kiosk/reporting/utils/types/csrd"

type FormProps = {
  // TODO: weird type. To clean.
  readonly disclosureRequirement: DisclosureRequirementsDetailsWithCompanyData
  readonly initialValues: FormAnswers
  readonly progress?: number
  readonly aiGenerateParagraph: (disclosureRequirementId: string) => void
  readonly isPendingParagraph?: boolean
  readonly datoTopicId: string
  readonly datoSubTopicId: string
  readonly framework: Framework
}

export const CSRDForm = ({
  disclosureRequirement,
  initialValues,
  progress,
  aiGenerateParagraph,
  isPendingParagraph,
  datoTopicId,
  datoSubTopicId,
  framework,
}: FormProps) => {
  const [externalAnswers, setExternalAnswers] = useState(
    FormAnswers.filterExternal(initialValues),
  )

  useEffect(() => {
    setExternalAnswers(FormAnswers.filterExternal(initialValues))
  }, [initialValues])

  const form = useForm<FormShape>({
    mode: "uncontrolled",
    initialValues: FormAnswers.filterSource(initialValues, "report"),
  })

  const { mutateAsync: upsertDisclosureRequirements, isPending } =
    useUpsertDisclosureRequirementAnswersMutation(disclosureRequirement.id)

  const { mutateAsync: markDisclosureRequirementAsDone } =
    useMarkDisclosureRequirementAsDoneMutation()

  const { mutateAsync: unmarkDisclosureRequirementAsDone } =
    useUnmarkDisclosureRequirementAsDoneMutation()

  return (
    <form
      className={classes.CSRDForm}
      onSubmit={form.onSubmit((values) =>
        upsertDisclosureRequirements(Object.values(values)),
      )}
    >
      <Stack className={classes.CSRDForm__body}>
        <Stack>
          <PhaseInVoluntaryQuestionsWrapper
            framework={framework}
            isParentCollapsed={false}
            questions={disclosureRequirement.questions}
            totalQuestions={disclosureRequirement.totalQuestions}
          >
            {(question, isParentCollapsed) => (
              <CSRDQuestionTree
                datoSubTopicId={datoSubTopicId}
                datoTopicId={datoTopicId}
                externalAnswers={externalAnswers}
                form={form}
                framework={framework}
                key={question.id}
                parentCollapsed={isParentCollapsed}
                question={question}
                setExternalAnswers={setExternalAnswers}
                totalQuestions={disclosureRequirement.totalQuestions}
              />
            )}
          </PhaseInVoluntaryQuestionsWrapper>
        </Stack>
        <SaveFooter
          hasUnsavedChanges={form.isDirty()}
          isCompleted={progress === 100}
          isPendingGeneration={Boolean(isPendingParagraph)}
          isPendingUpdate={isPending}
          onGenerate={() => {
            aiGenerateParagraph(disclosureRequirement.id)
          }}
          onMarkAsDone={() =>
            markDisclosureRequirementAsDone(disclosureRequirement.id)
          }
          onSave={() => form.resetDirty()}
          onUnmarkAsDone={() =>
            unmarkDisclosureRequirementAsDone(disclosureRequirement.id)
          }
        />
      </Stack>
    </form>
  )
}
