import { Button, Divider, Drawer, Group, Stack, Text } from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import { useDisclosure } from "@mantine/hooks"
import { useState } from "react"
import { useTranslation } from "react-i18next"

import { useInviteCompanyUser } from "@kiosk/reporting/api/companies/inviteUser"
import { PictureUploader } from "@kiosk/reporting/components/PictureUploader"
import { useUser } from "@kiosk/reporting/components/auth/UserContext"
import {
  InviteUserBody,
  inviteUserSchema,
} from "@kiosk/reporting/legacy/shared/schemas/users"
import { UserFormFields } from "@kiosk/reporting/routes/settings.users/components/UserFormFields"

export const AddUser = () => {
  const user = useUser()
  const [opened, { open, close }] = useDisclosure(false)
  const [emailExists, setEmailExists] = useState(false)
  const { t } = useTranslation(["common", "settings"])

  const { reset, getInputProps, isValid, onSubmit } = useForm<InviteUserBody>({
    validate: zodResolver(inviteUserSchema),
  })

  const handleCancel = () => {
    reset()
    setEmailExists(false)
    close()
  }

  const { mutateAsync: inviteUserToOrganization, isPending } =
    useInviteCompanyUser(user.companyId, handleCancel, setEmailExists)

  const handleAddUser = onSubmit(async (data, event) => {
    event?.preventDefault()

    const {
      email,
      firstName,
      lastName,
      jobTitle,
      department,
      roles,
      avatarUrl,
    } = data

    await inviteUserToOrganization({
      email,
      firstName,
      lastName,
      jobTitle,
      department,
      roles,
      avatarUrl,
    })
  })

  return (
    <>
      <Button onClick={open}>{t("settings:users.newUser")}</Button>
      <Drawer.Root onClose={close} opened={opened} position="right" size="md">
        <Drawer.Overlay />
        <Drawer.Content>
          <Stack gap={32} h="100%">
            <Drawer.Header
              p={24}
              style={{ borderBottom: "1px solid var(--mantine-color-gray-3)" }}
            >
              <Drawer.Title c="black" fw={600} fz="lg">
                {t("settings:users.createUser")}
              </Drawer.Title>
              <Drawer.CloseButton />
            </Drawer.Header>
            <Stack px={24}>
              <Text c="black" fw={500}>
                {t("settings:account.profileImage")}
              </Text>
              <Group gap={24}>
                <PictureUploader
                  destination="user-avatars"
                  placeholder="MC"
                  {...getInputProps("avatarUrl")}
                />
              </Group>
              <Divider />
            </Stack>
            <Stack h="100%" justify="space-between">
              <Drawer.Body px={24}>
                <form id="add-user" onSubmit={handleAddUser}>
                  <UserFormFields
                    currentUser={user}
                    emailExists={emailExists}
                    getInputProps={getInputProps}
                  />
                </form>
              </Drawer.Body>
              <Group
                gap={10}
                justify="flex-end"
                p={24}
                style={{ borderTop: "1px solid var(--mantine-color-gray-3)" }}
              >
                <Button color="gray" onClick={handleCancel} variant="outline">
                  {t("buttons.cancel")}
                </Button>
                <Button
                  disabled={!isValid()}
                  form="add-user"
                  loading={isPending}
                  type="submit"
                >
                  {t("settings:users.createUser")}
                </Button>
              </Group>
            </Stack>
          </Stack>
        </Drawer.Content>
      </Drawer.Root>
    </>
  )
}
