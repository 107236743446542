import { Group, Switch, Text } from "@mantine/core"
import _ from "lodash"
import { useTranslation } from "react-i18next"

import { colors } from "@kiosk/reporting/assets/theme/colors"

type SwitchState = "material" | "nonMaterial"

interface ComponentColors {
  background: string
  text: string
}

interface SwitchAttributes {
  colors: ComponentColors
}

const componentAttributes: Record<SwitchState, SwitchAttributes> = {
  material: {
    colors: {
      text: "black",
      background: colors["emerald"][0],
    },
  },
  nonMaterial: {
    colors: {
      text: colors["gray"][4],
      background: colors["gray"][0],
    },
  },
}

interface MaterialitySwitchProps {
  readonly value: boolean
  readonly onClick?: () => void
  readonly onChange?: () => void
  readonly isPending?: boolean
  readonly disabled?: boolean
}

export function MaterialitySwitch(props: MaterialitySwitchProps) {
  const { t } = useTranslation("common")
  const switchState = props.value ? "material" : "nonMaterial"
  const text =
    switchState === "material"
      ? t("switchState.material")
      : t("switchState.nonMaterial")

  const { colors } = componentAttributes[switchState]

  return (
    <Group
      bg={colors.background}
      h={40}
      p={10}
      style={{ borderRadius: 40 }}
      wrap="nowrap"
    >
      <Text c={colors.text} w={85}>
        {_.capitalize(text)}
      </Text>

      <Switch
        checked={props.value}
        color="green"
        disabled={props.disabled || props.isPending}
        onChange={props.onChange ?? props.onClick}
        size="xs"
      />
    </Group>
  )
}
