import { LoaderFunctionArgs } from "@remix-run/node"
import { useLoaderData } from "@remix-run/react"
import { z } from "zod"
import { zx } from "zodix"

import { Locale } from "@kiosk/reporting/i18n"
import { CURRENT_REPORT_YEAR } from "@kiosk/reporting/legacy/shared/constants/common/phaseIn"
import { OnboardingPostgresRepository } from "@kiosk/reporting/lib/repositories/onboarding-repository.server"
import { PhaseInPostgresRepository } from "@kiosk/reporting/lib/repositories/phase-in-repository.server"
import { ReportPostgresRepository } from "@kiosk/reporting/lib/repositories/report-repository.server"
import CSRDSubTopicPageComponent from "@kiosk/reporting/routes/csrd_.$datoTopicId_.$datoSubTopicId/components/CsrdSubTopicPage"
import { getDatoDisclosureRequirementQuestionTree } from "@kiosk/reporting/routes/csrd_.$datoTopicId_.$datoSubTopicId/get-dr-question-tree.server"
import { mapDrQuestionTreeWithCompanyData } from "@kiosk/reporting/routes/csrd_.$datoTopicId_.$datoSubTopicId/map-dr-question-tree-with-company-data.server"
import { requireUser, requireToken } from "@kiosk/reporting/utils/auth.server"
import { DisclosureRequirementsDetailsWithCompanyData } from "@kiosk/reporting/utils/types/csrd"

const onboardingRepository = new OnboardingPostgresRepository()
const reportRepository = new ReportPostgresRepository()
const phaseInRepository = new PhaseInPostgresRepository()

export const handle = {
  title: () => "csrd",
}

export const loader = async (args: LoaderFunctionArgs) => {
  try {
    const { datoTopicId, datoSubTopicId } = zx.parseParams(args.params, {
      datoTopicId: z.string(),
      datoSubTopicId: z.string(),
    })
    const { company, locale } = await requireUser(args)
    const token = await requireToken(args)

    const [onboarding, currentReport, questionScopedPhaseIns] =
      await Promise.all([
        onboardingRepository.findByCompanyId(company.id),
        reportRepository.findByCompanyIdAndYear(
          company.id,
          CURRENT_REPORT_YEAR,
        ),
        phaseInRepository.getQuestionScopedByCompanyID(company.id),
      ])

    if (!currentReport)
      throw new Error(`company haven't report for ${CURRENT_REPORT_YEAR}`)

    const disclosureRequirementDetails =
      await getDatoDisclosureRequirementQuestionTree({
        token,
        locale: locale as Locale,
        disclosureRequirementID: datoSubTopicId,
      })

    const disclosureRequirementDetailsWithCompanyData =
      mapDrQuestionTreeWithCompanyData({
        companyHeadcount: onboarding!.headCount!,
        disclosureRequirementDetails,
        isVoluntaryHidden: currentReport?.hideVoluntary ?? false,
        isPhaseInHidden: currentReport?.hidePhaseIn ?? false,
        questionScopedPhaseIns,
        reportID: currentReport.id,
      })

    return {
      disclosureRequirementDetailsWithCompanyData,
      datoTopicId,
      datoSubTopicId,
      framework: company.framework,
    }
  } catch (err) {
    console.error(err)
    throw err
  }
}

export default function CSRDSubTopicPage() {
  const loaderState = useLoaderData<typeof loader>()

  return (
    <CSRDSubTopicPageComponent
      datoSubTopicId={loaderState.datoSubTopicId}
      datoTopicId={loaderState.datoTopicId}
      disclosureRequirementDetailsWithCompanyData={
        loaderState.disclosureRequirementDetailsWithCompanyData as DisclosureRequirementsDetailsWithCompanyData
      }
      framework={loaderState.framework}
    />
  )
}
