import {
  TableTr,
  TableTd,
  Group,
  Stack,
  RadioGroup,
  Radio,
  Text,
  TableTh,
  Grid,
  GridCol,
} from "@mantine/core"

import { CURRENT_REPORT_YEAR } from "@kiosk/reporting/legacy/shared/constants/common/phaseIn"

interface SubjectRowProps {
  readonly subject: { name: string; example: string }
  readonly max: number
  readonly initialValue?: number
  readonly phaseInId: string
  readonly scope: "esrs" | "dr" | "tqg" | "question"
  readonly index: number
}

export const SubjectRow = ({
  subject,
  max,
  initialValue,
  phaseInId,
  scope,
  index,
}: SubjectRowProps) => {
  return (
    <TableTr>
      <TableTh>
        <Group>
          <Stack gap={0} my="sm">
            <Text c="gray.9" fw="bold">
              {subject.name}
            </Text>
            <Text c="gray.5" fw="normal">
              {subject.example}
            </Text>
          </Stack>
        </Group>
      </TableTh>
      <TableTd colSpan={4}>
        <RadioGroup
          defaultValue={JSON.stringify({
            id: phaseInId,
            phaseInYear: CURRENT_REPORT_YEAR + (initialValue ?? 0),
          })}
          name={`${scope}[${index}]`}
          variant="horizontal"
        >
          <Grid columns={4}>
            {Array.from({ length: max + 1 }, (_, index) => (
              <GridCol
                key={index}
                span={1}
                style={{ justifyContent: "center", display: "flex" }}
              >
                <Radio
                  size="md"
                  value={JSON.stringify({
                    id: phaseInId,
                    phaseInYear: CURRENT_REPORT_YEAR + index,
                  })}
                />
              </GridCol>
            ))}
          </Grid>
        </RadioGroup>
      </TableTd>
    </TableTr>
  )
}
