import { useQuery } from "@tanstack/react-query"

import { accountKeys } from "@kiosk/reporting/api/account/accountKeys"
import { useUser } from "@kiosk/reporting/components/auth/UserContext"
import { AuthResponses } from "@kiosk/reporting/legacy/types/endpoints/auth"
import { User } from "@kiosk/reporting/legacy/types/user"
import { apiClient } from "@kiosk/reporting/lib/apiClient"
import { authorizationHeader } from "@kiosk/reporting/lib/headers"

type AuthState =
  | {
      isLoading: true
      isSignedIn: undefined
      isOnboarded: undefined
      user: undefined
    }
  | {
      isLoading: false
      isSignedIn: false
      isOnboarded: undefined
      user: undefined
    }
  | {
      isLoading: false
      isSignedIn: true
      isOnboarded: false
      user: undefined
    }
  | {
      isLoading: false
      isSignedIn: true
      isOnboarded: true
      user: User
    }
  | {
      isLoading: false
      isSignedIn: true
      isOnboarded: undefined
      user: User
    }

export const useAuthState = (): AuthState => {
  const dbUser = useUser()
  const isLoaded = true // legacy
  const isSignedIn = true

  const clerkUser = {
    id: dbUser.clerkId,
    organizationMemberships: [{ organization: { id: dbUser.company.clerkId } }],
  }

  const {
    data: user,
    isLoading: isMeLoading,
    isError,
    // TODO: type error
  } = useQuery<User | undefined, { error: string }>({
    queryKey: accountKeys.me(clerkUser?.id),
    queryFn: async () => {
      if (!isLoaded || !isSignedIn) return undefined
      const response = await apiClient.get<AuthResponses.Me>("/account/me", {
        headers: authorizationHeader(dbUser.token),
      })

      return response.data
    },
    enabled: isLoaded && isSignedIn,
    retry: 1,
  })

  const isLoading = !isLoaded || isMeLoading

  if (isLoading)
    return {
      isLoading: true,
      isSignedIn: undefined,
      isOnboarded: undefined,
      user: undefined,
    }

  if (!isSignedIn) {
    return {
      isLoading: false,
      isSignedIn: false,
      isOnboarded: undefined,
      user: undefined,
    }
  }

  if (isError || !user) {
    // Considering for now that an existing Clerk user without a Prisma user means a non-onboarded user.
    // TODO: handle this specific case with error codes
    return {
      isLoading: false,
      isSignedIn: true,
      isOnboarded: false,
      user: undefined,
    }
  }

  if (user && !user.isSignedUp) {
    return {
      isLoading: false,
      isSignedIn: true,
      isOnboarded: undefined,
      user,
    }
  }

  return {
    isLoading,
    isSignedIn: true,
    isOnboarded: true,
    user,
  }
}
