import { Divider, Group, Stack } from "@mantine/core"
import { useParams } from "@remix-run/react"
import { useMemo } from "react"

import {
  useDisclosureRequirementCategoriesQuery,
  useMaterialityQuery,
} from "@kiosk/reporting/api/reports"
import { DisclosureRequirementFormLayout } from "@kiosk/reporting/components/csrd/DisclosureRequirementFormLayout"
import { extractEsrs } from "@kiosk/reporting/components/csrd/utils"
import { MultiQueryWrapper } from "@kiosk/reporting/components/layout/QueryWrapper"
import { clientConfig } from "@kiosk/reporting/config"
import { findEsrsByCode } from "@kiosk/reporting/features/iro2/config"
import { ReportsResponses } from "@kiosk/reporting/legacy/types/endpoints/reports"
import { Framework } from "@kiosk/reporting/lib/entities/company"
import { IRO2 } from "@kiosk/reporting/routes/csrd/components/IRO-2"
import { DisclosureRequirementSideBar } from "@kiosk/reporting/routes/csrd_.$datoTopicId_.$datoSubTopicId/components/DisclosureRequirementSideBar"
import { DisclosureRequirementsDetailsWithCompanyData } from "@kiosk/reporting/utils/types/csrd"

type Props = {
  readonly categories: ReportsResponses.GetCategories
  readonly datoTopicId: string
  readonly datoSubTopicId: string
  readonly nonMaterialEsrs: string[]
} & CSRDSubTopicPageComponentProps

const DisclosureRequirementLayout = ({
  categories,
  disclosureRequirementDetailsWithCompanyData,
  datoTopicId,
  datoSubTopicId,
  nonMaterialEsrs,
  framework,
}: Props) => {
  const params = useParams()
  const activeTopicId = params.datoTopicId!
  const activeSubTopicId = params.datoSubTopicId!

  const activeTopic = useMemo(() => {
    return categories
      .flatMap((category) => category.topics)
      .find((topic) => topic.id === activeTopicId)!
  }, [activeTopicId, categories])

  const currentDisclosureRequirementProgress = useMemo(() => {
    return activeTopic.disclosureRequirements.find(
      (dr) => dr.id === activeSubTopicId,
    )?.progress
  }, [activeSubTopicId, activeTopic.disclosureRequirements])

  const esrs = extractEsrs(disclosureRequirementDetailsWithCompanyData.esrsCode)
  const _esrs = findEsrsByCode(esrs)
  const isESRS2 = esrs === "ESRS2"
  const esrsIsMaterial =
    (!isESRS2 && !nonMaterialEsrs.includes(_esrs?.datoId ?? "")) || isESRS2

  return (
    <Stack gap={0} h="100%">
      <Group align="flex-start" flex={1} gap={0} h="100%">
        <Stack
          h="100%"
          miw={400}
          pb={16}
          style={{ overflow: "scroll" }}
          w="25%"
        >
          <DisclosureRequirementSideBar
            esrsIsMaterial={esrsIsMaterial}
            topic={activeTopic}
          />
        </Stack>

        <Divider color="gray.2" orientation="vertical" />
        <Stack flex={1} h="100%" pos="relative" style={{ overflow: "scroll" }}>
          {activeSubTopicId === clientConfig.disclosureRequirements.iro2 ? (
            <IRO2 progress={currentDisclosureRequirementProgress} />
          ) : (
            <DisclosureRequirementFormLayout
              datoSubTopicId={datoSubTopicId}
              datoTopicId={datoTopicId}
              disclosureRequirement={
                disclosureRequirementDetailsWithCompanyData
              }
              esrsIsMaterial={esrsIsMaterial}
              framework={framework}
              progress={currentDisclosureRequirementProgress}
            />
          )}
        </Stack>
      </Group>
    </Stack>
  )
}

type CSRDSubTopicPageComponentProps = {
  readonly disclosureRequirementDetailsWithCompanyData: DisclosureRequirementsDetailsWithCompanyData
  readonly datoTopicId: string
  readonly datoSubTopicId: string
  readonly framework: Framework
}

export default function CSRDSubTopicPageComponent({
  disclosureRequirementDetailsWithCompanyData,
  datoTopicId,
  datoSubTopicId,
  framework,
}: CSRDSubTopicPageComponentProps) {
  const csrdCategoriesQuery = useDisclosureRequirementCategoriesQuery()
  const materialityQuery = useMaterialityQuery()

  const queries = {
    nonMaterialEsrs: {
      query: materialityQuery,
      allowEmptyArray: true,
    },
    csrdCategories: {
      query: csrdCategoriesQuery,
      allowEmptyArray: true,
    },
  }

  return (
    <MultiQueryWrapper fluid queries={queries}>
      {({ nonMaterialEsrs, csrdCategories }) => (
        <DisclosureRequirementLayout
          categories={csrdCategories}
          datoSubTopicId={datoSubTopicId}
          datoTopicId={datoTopicId}
          disclosureRequirementDetailsWithCompanyData={
            disclosureRequirementDetailsWithCompanyData
          }
          framework={framework}
          nonMaterialEsrs={nonMaterialEsrs}
        />
      )}
    </MultiQueryWrapper>
  )
}
