import { useQuery } from "@tanstack/react-query"

import { i18n } from "@kiosk/reporting/legacy/i18n/index"
import {
  ALL_CATEGORIES_TOPICS_NAMES_QUERY,
  CSRD_DISCLOSURE_REQUIREMENT_BY_ID,
} from "@kiosk/reporting/legacy/shared/datocms/queries"
import {
  DatoDisclosureRequirementNamesResponse,
  DatoDisclosureRequirementResponse,
  DatoRequest,
} from "@kiosk/reporting/legacy/types/data/dato"
import { DatoProxyRequest } from "@kiosk/reporting/legacy/types/endpoints/dato"
import { apiClient } from "@kiosk/reporting/lib/apiClient"
import { authorizationHeader } from "@kiosk/reporting/lib/headers"

type PaginatedDatoRequest = DatoRequest & {
  fieldName: string
  metaFieldName: string
  token: string
}

export interface WithToken {
  token: string
}

export async function makeDatoRequest({
  query,
  variables,
  paginated,
  token,
}: DatoProxyRequest & WithToken) {
  return await apiClient.post(
    "/dato",
    {
      data: {
        query,
        variables: { locale: i18n.language, ...variables },
        paginated,
      },
    },
    { headers: authorizationHeader(token) },
  )
}

export async function datoClientSideQuery<T>({
  query,
  variables,
  paginated,
  token,
}: DatoProxyRequest & WithToken) {
  const response = await makeDatoRequest({ query, variables, paginated, token })

  return response.data.data as T
}

export const makeDatoPaginatedRequest = async <T>({
  query,
  variables,
  fieldName,
  metaFieldName,
  token,
}: PaginatedDatoRequest): Promise<T> => {
  const response = await makeDatoRequest({
    query,
    variables,
    paginated: {
      metadataField: metaFieldName,
      field: fieldName,
    },
    token,
  })

  return response.data.data
}

export const useDatoQuery = <T>({
  query,
  variables,
  token,
}: DatoRequest & WithToken) => {
  return useQuery<T>({
    queryKey: ["dato", query, variables],
    queryFn: async () => {
      const response = await makeDatoRequest({ query, variables, token })

      return response.data.data
    },
  })
}

export const useDatoDisclosureRequirement = (id: string, token: string) => {
  return useDatoQuery<DatoDisclosureRequirementResponse>({
    query: CSRD_DISCLOSURE_REQUIREMENT_BY_ID,
    variables: { id },
    token,
  })
}

export const useDatoCategoriesTopicsNames = (token: string) => {
  return useDatoQuery<DatoDisclosureRequirementNamesResponse>({
    query: ALL_CATEGORIES_TOPICS_NAMES_QUERY,
    token,
  })
}
