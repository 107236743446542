import { Flex, Group, Title, Text } from "@mantine/core"
import { useFetcher, useLoaderData } from "@remix-run/react"
import { Dispatch, SetStateAction, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import { SubjectForm } from "@kiosk/reporting/components/onboarding/SubjectForm"
import { SubjectData } from "@kiosk/reporting/lib/infra/subjects-infra.server"
import { Footer } from "@kiosk/reporting/routes/_onboarding.onboarding/components/Footer"

type Props = {
  readonly setActive: Dispatch<SetStateAction<number>>
  readonly handleOnboardingSkip: () => void
}

export const SubjectsStep = ({ setActive, handleOnboardingSkip }: Props) => {
  const { t } = useTranslation("onboarding")
  const fetcher = useFetcher()

  const [isLoading, setIsLoading] = useState(false)
  const ref = useRef<HTMLFormElement>(null)
  const { subjectsData } = useLoaderData() as { subjectsData: SubjectData }

  if (fetcher.data) {
    setIsLoading(false)
    setActive(3)
  }

  return (
    <Flex align="center" direction="column" flex={1} gap={40} w="100%">
      <Flex
        align="flex-start"
        direction="column"
        gap={40}
        justify="flex-start"
        w={837}
      >
        <Group>
          <Title c="gray.9" fw={600} fz="xxl">
            {t("subjects.title")}
          </Title>

          <Text c="gray.5" fz="md">
            {t("subjects.description")}
          </Text>

          <Text c="gray.5" fz="md">
            {t("subjects.instructions")}
          </Text>
        </Group>
        <fetcher.Form action="/onboarding" method="post" ref={ref}>
          <SubjectForm loaderState={subjectsData} />
        </fetcher.Form>
      </Flex>
      <Footer
        disableContinue={false}
        isLoading={isLoading}
        onContinue={() => {
          setIsLoading(true)
          fetcher.submit(ref.current)
        }}
        onSkipOnboarding={handleOnboardingSkip}
      />
    </Flex>
  )
}
