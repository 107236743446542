import { Box, Group, Text } from "@mantine/core"
import React, { useState } from "react"

import { colors } from "@kiosk/reporting/assets/theme/colors"

interface SegmentData {
  label: string
  value: string
}

interface CustomSegmentedControlProps {
  readonly data: SegmentData[]
  readonly onChange: (value: string) => void
  readonly initialValue?: string
}

const CustomSegmentedControl: React.FC<CustomSegmentedControlProps> = ({
  data,
  onChange,
  initialValue,
}) => {
  const [activeSegment, setActiveSegment] = useState<string>(
    initialValue || data[0].value,
  )

  const handleSegmentClick = (value: string) => {
    setActiveSegment(value)
    onChange(value)
  }

  return (
    <Box
      style={{
        border: "1px solid #ced4da",
        borderRadius: "6px",
        overflow: "hidden",
        display: "inline-block",
      }}
    >
      <Group gap={0} style={{ display: "flex" }}>
        {data.map((segment) => {
          const isActive = activeSegment === segment.value

          return (
            <Box
              bg={isActive ? colors.green[1] : "transparent"}
              key={segment.value}
              onClick={() => handleSegmentClick(segment.value)}
              px="20px"
              py="10px"
              style={{
                cursor: "pointer",
                color: "black",
                transition: "background-color 0.2s, color 0.2s",
                flex: 1,
                borderStyle: isActive ? "solid" : undefined,
                borderRadius: "6px",
                borderColor: isActive ? colors.green[5] : undefined,
                borderWidth: isActive ? "1px" : undefined,
              }}
              ta="center"
            >
              <Text
                c={isActive ? "black" : "gray.6"}
                fw={isActive ? "bold" : "normal"}
              >
                {segment.label}
              </Text>
            </Box>
          )
        })}
      </Group>
    </Box>
  )
}

export default CustomSegmentedControl
