import { Button, Group, Text } from "@mantine/core"
import { IconPlus } from "@tabler/icons-react"
import { useTranslation } from "react-i18next"

import { colors } from "@kiosk/reporting/assets/theme/colors"

interface IntegrationRequestProps {
  readonly name: string
  readonly Logo: React.FC
  readonly onRequest: () => void
}

const style = {
  border: "1px solid",
  borderColor: colors.gray[2],
  borderRadius: 6,
}

export const IntegrationRequest = (props: IntegrationRequestProps) => {
  const { t } = useTranslation("sources")

  return (
    <Group justify="space-between" p={10} style={style}>
      <Group>
        <props.Logo />

        <Text fw="bold">{props.name}</Text>
      </Group>

      <Button
        c="black"
        color={colors.gray[1]}
        h={42}
        onClick={props.onRequest}
        px={16}
        py={10}
      >
        <Group gap={12}>
          <IconPlus size={20} />

          <Text>{t("requestIntegration")}</Text>
        </Group>
      </Button>
    </Group>
  )
}
