import {
  ActionIcon,
  Button,
  Group,
  Modal,
  Stack,
  Text,
  TextInput,
  Title,
  rem,
} from "@mantine/core"
import { useDisclosure, useHover } from "@mantine/hooks"
import { Prisma } from "@prisma/client"
import { IconCheck, IconPencil, IconTrash, IconX } from "@tabler/icons-react"
import { useState } from "react"
import { useTranslation } from "react-i18next"

import { useDeleteCategoryMutation } from "@kiosk/reporting/api/tasks/deleteCategory"
import { useUpdateCategoryMutation } from "@kiosk/reporting/api/tasks/updateCategory"
import { useUpdateTaskMutation } from "@kiosk/reporting/api/tasks/updateTask"
import { CategoryTaskTable } from "@kiosk/reporting/routes/tasks/components/CategoryTaskTable"
import { CreateTaskDrawer } from "@kiosk/reporting/routes/tasks/components/CreateTaskModal"
import { EditTaskModal } from "@kiosk/reporting/routes/tasks/components/EditTaskModal"

interface Props {
  readonly title: string
  readonly data: Prisma.TaskGetPayload<{
    include: { owner: { select: { firstName: true; lastName: true } } }
  }>[]
  readonly ownerId: string | undefined
  readonly taskId?: string
  readonly categoryId: string
}

export const TaskTable = ({ title, data, ownerId, categoryId }: Props) => {
  const { t } = useTranslation(["common", "task"])
  const [createTaskOpened, { open: openCreateTask, close: closeCreateTask }] =
    useDisclosure(false)
  const [editTaskOpened, { open: openEditTask, close: closeEditTask }] =
    useDisclosure(false)
  const [
    categoryDeleteModalOpened,
    { open: openCategoryDeleteModal, close: closeCategoryDeleteModal },
  ] = useDisclosure(false)
  const [editable, setEditable] = useState(false)
  const [editableTitle, setEditableTitle] = useState<string>(title)
  const [hoveredPencilIcon, setHoveredPencilIcon] = useState(false)
  const [hoveredTrashIcon, setHoveredTrashIcon] = useState(false)
  const { hovered, ref } = useHover()

  const [taskId, setTaskId] = useState<string | undefined>(undefined)

  const { mutate: updateTaskStatus } = useUpdateTaskMutation()
  const { mutate: deleteCategoryMutation } =
    useDeleteCategoryMutation(closeCreateTask)
  const { mutate: updateCategoryMutation } =
    useUpdateCategoryMutation(setEditable)

  const handleDeleteCategory = () => {
    deleteCategoryMutation(categoryId)
  }

  const handleUpdateCategory = () => {
    updateCategoryMutation({ categoryId, data: { name: editableTitle } })
    setHoveredTrashIcon(false)
    setHoveredPencilIcon(false)
  }

  const openEditTaskModal = (value: string) => {
    setTaskId(value)
    openEditTask()
  }

  return (
    <Stack gap={15}>
      <Group gap={16} h={40} ref={ref}>
        {editable ? (
          <TextInput
            onChange={(e) => setEditableTitle(e.target.value)}
            radius="md"
            styles={{
              root: {
                flex: 1,
                maxWidth: rem(400),
              },
              input: {
                border: `${rem(1)} solid var(--mantine-color-gray-3)`,
              },
            }}
            value={editableTitle}
            w="fit"
          />
        ) : (
          <Title c="gray.9" fw={500} fz="lg">
            {title}
          </Title>
        )}

        {hovered && !editable && (
          <Group gap={8}>
            <ActionIcon
              color={hoveredPencilIcon ? "gray.2" : "transparent"}
              display="flex"
              h={40}
              onClick={() => setEditable(true)}
              onMouseEnter={() => setHoveredPencilIcon(true)}
              onMouseLeave={() => setHoveredPencilIcon(false)}
              radius="xl"
              style={{
                cursor: "pointer",
              }}
              variant="filled"
              w={40}
            >
              <IconPencil color="var(--mantine-color-gray-5)" />
            </ActionIcon>

            <ActionIcon
              color={hoveredTrashIcon ? "gray.2" : "transparent"}
              display="flex"
              h={40}
              onClick={openCategoryDeleteModal}
              onMouseEnter={() => setHoveredTrashIcon(true)}
              onMouseLeave={() => setHoveredTrashIcon(false)}
              radius="xl"
              style={{
                cursor: "pointer",
              }}
              w={40}
            >
              <IconTrash color="var(--mantine-color-gray-5)" />
            </ActionIcon>
          </Group>
        )}

        {editable && (
          <Group gap={8}>
            <ActionIcon
              display="flex"
              h={40}
              onClick={handleUpdateCategory}
              style={{
                cursor: "pointer",
              }}
              variant="transparent"
              w={40}
            >
              <IconCheck color="var(--mantine-color-green-7)" />
            </ActionIcon>

            <ActionIcon
              display="flex"
              h={40}
              onClick={() => {
                setEditableTitle(title)
                setEditable(false)
                setHoveredTrashIcon(false)
                setHoveredPencilIcon(false)
              }}
              style={{
                cursor: "pointer",
              }}
              variant="transparent"
              w={40}
            >
              <IconX color="var(--mantine-color-gray-5)" />
            </ActionIcon>
          </Group>
        )}
      </Group>

      <Modal.Root
        centered
        onClose={closeCategoryDeleteModal}
        opened={categoryDeleteModalOpened}
      >
        <Modal.Overlay />

        <Modal.Content p={8} style={{ borderRadius: rem(14) }}>
          <Modal.Header>
            <Modal.Title c="gray.9" fw={600} fz="xxl">
              {t("task:actions.permanentlyDeleteCategory")}
            </Modal.Title>

            <Modal.CloseButton />
          </Modal.Header>

          <Modal.Body>
            <Text c="gray.7" fz="md" mb={34}>
              {t("task:actions.deleteExistingTasksInCategory")}
            </Text>

            <Group justify="flex-end">
              <Button
                c="gray.9"
                color="gray.2"
                onClick={closeCategoryDeleteModal}
              >
                {t("common:buttons.cancel")}
              </Button>

              <Button color="red.8" onClick={handleDeleteCategory}>
                {t("task:actions.deleteCategory")}
              </Button>
            </Group>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>

      <CategoryTaskTable
        openCreateTask={openCreateTask}
        openEditTaskModal={openEditTaskModal}
        tasks={data}
        updateTaskStatus={updateTaskStatus}
      />

      <CreateTaskDrawer
        categoryId={categoryId}
        close={closeCreateTask}
        open={openCreateTask}
        opened={createTaskOpened}
        ownerId={ownerId}
      />

      {taskId && (
        <EditTaskModal
          close={closeEditTask}
          open={openEditTask}
          opened={editTaskOpened}
          ownerId={ownerId}
          taskId={taskId}
        />
      )}
    </Stack>
  )
}
