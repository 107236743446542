import { Divider, Group, Text, ThemeIcon } from "@mantine/core"
import { Link, useLocation } from "@remix-run/react"
import { IconSettings } from "@tabler/icons-react"
import { useTranslation } from "react-i18next"

import UserPopover from "@kiosk/reporting/components/UserPopover"
import { User } from "@kiosk/reporting/legacy/types/user"

export type Title =
  | "account"
  | "answerSurvey"
  | "companies"
  | "congratulations"
  | "csrd"
  | "vsme"
  | "dashboard"
  | "dimensions"
  | "editQuestion"
  | "editSurvey"
  | "language"
  | "onboarding"
  | "settings"
  | "sources"
  | "sourcesAll"
  | "subjects"
  | "surveyAnswers"
  | "surveys"
  | "tables"
  | "tasks"
  | "users"

interface AppHeaderProps {
  readonly title: Title
  readonly user: User & { token: string }
}

export default function AppHeader(props: AppHeaderProps) {
  const { pathname } = useLocation()
  const { t } = useTranslation()

  const isSettingsActive = pathname.startsWith("/settings")

  const translations: Record<Title, string> = {
    account: t("routes.account"),
    answerSurvey: t("routes.answerSurvey"),
    companies: t("routes.companies"),
    congratulations: t("routes.congratulations"),
    csrd: t("routes.csrd"),
    vsme: t("routes.vsme"),
    dashboard: t("routes.dashboard"),
    dimensions: t("routes.dimensions"),
    editQuestion: t("routes.editQuestion"),
    editSurvey: t("routes.editSurvey"),
    language: t("routes.language"),
    onboarding: t("routes.onboarding"),
    settings: t("routes.settings"),
    sources: t("routes.sources"),
    sourcesAll: t("routes.sourcesAll"),
    subjects: t("routes.subjects"),
    surveyAnswers: t("routes.surveyAnswers"),
    surveys: t("routes.surveys"),
    tables: t("routes.tables"),
    tasks: t("routes.tasks"),
    users: t("routes.users"),
  }

  return (
    <Group gap={0} h={74} justify="space-between" px={24} py={16} w="100%">
      <Text c="gray.9" fw="bold" fz="xl">
        {translations[props.title]}
      </Text>

      <Group>
        <Link to="/settings/account/edit">
          <ThemeIcon
            color={isSettingsActive ? "gray.7" : "gray.5"}
            style={{ background: "transparent" }}
            variant="white"
          >
            <IconSettings />
          </ThemeIcon>
        </Link>

        <Divider orientation="vertical" />

        <UserPopover user={props.user} />
      </Group>
    </Group>
  )
}
