import { Stack } from "@mantine/core"
import { useTranslation } from "react-i18next"

import { TypedDimension } from "@kiosk/reporting/api/dimensions/types"
import { Callout } from "@kiosk/reporting/components/Callout"
import { CompanyDimensionAggregate } from "@kiosk/reporting/lib/aggregates/companyDimensionAggregate"
import { DimensionsTable } from "@kiosk/reporting/routes/dimensions/components/DimensionsTable"

type Props = {
  readonly dimensions: TypedDimension[]
  readonly companyDimensions: CompanyDimensionAggregate[]
}

export const DimensionsList = ({ dimensions, companyDimensions }: Props) => {
  const { t } = useTranslation("dimensions")

  return (
    <Stack gap={32} pb={24}>
      <Callout message={t("callout")} />
      {companyDimensions.length !== 0 && (
        <DimensionsTable
          companyDimensions={companyDimensions}
          label={t("categories.company")}
        />
      )}
      <DimensionsTable dimensions={dimensions} label={t("categories.csrd")} />
    </Stack>
  )
}
