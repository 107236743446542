import { Stack, Table } from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import _ from "lodash"
import { useTranslation } from "react-i18next"

import { CSRDQuestionContent } from "@kiosk/reporting/components/csrd/CSRDQuestionContent"
import classes from "@kiosk/reporting/components/csrd/RelatedQuestionsTable/RelatedQuestionsTable.module.css"
import { FormShape } from "@kiosk/reporting/components/csrd/types"
import { buildCSRDReportAnswerFormKey } from "@kiosk/reporting/components/csrd/utils"
import { isEmpty } from "@kiosk/reporting/legacy/shared/utils/helpers"
import { CSRDQuestionAnswer } from "@kiosk/reporting/legacy/types/csrd"
import { CSRDQuestions, Content } from "@kiosk/reporting/legacy/types/data/dato"
import { EnrichedDimensionBreakdown } from "@kiosk/reporting/utils/dimensions"

interface RelatedQuestionsTableProps {
  readonly title: string
  readonly relatedQuestions: CSRDQuestions<Content>[]
  readonly dimensionBreakdowns?: EnrichedDimensionBreakdown[]
  readonly form: UseFormReturnType<FormShape>
  readonly externalAnswers: Record<string, CSRDQuestionAnswer>
}

/**
 * Renders a tree of questions as a table.
 *
 * Current assumptions
 * -------------------
 * - only quantitative questions are in the related questions list
 * - there is only one axis (dimension) (that will change later)
 * - questions are not nested
 * - the person who chose the Table type for the main question knows these assumptions
 */
export const RelatedQuestionsTable = ({
  relatedQuestions,
  dimensionBreakdowns,
  form,
  title,
  externalAnswers,
}: RelatedQuestionsTableProps) => {
  const availableDimensions = _.uniqBy(
    dimensionBreakdowns?.flat(),
    (dbd) => dbd.dimensionId,
  )

  const availableOptions = _.uniqBy(
    dimensionBreakdowns?.flat(),
    (dbd) => dbd.optionId,
  )

  const tableOptions = availableDimensions.flatMap((dimension) =>
    availableOptions.filter(
      (option) => option.dimensionId === dimension.dimensionId,
    ),
  )

  const { t } = useTranslation("tables")

  return (
    <Stack gap={16} p={24}>
      <Table.ScrollContainer minWidth="100%">
        <Table
          highlightOnHover={false}
          pt={20}
          stickyHeader={false}
          withColumnBorders
          withTableBorder
        >
          <Table.Thead>
            <Table.Tr>
              {!isEmpty(availableDimensions) && (
                <>
                  <Table.Th />

                  {availableDimensions.map((dimension) => (
                    <Table.Th
                      className={classes["question-table__breakdown"]}
                      colSpan={
                        availableOptions.filter(
                          (option) =>
                            option.dimensionId === dimension?.dimensionId,
                        ).length
                      }
                      key={dimension?.dimensionId}
                    >
                      {dimension?.dimensionLabel}
                    </Table.Th>
                  ))}
                </>
              )}
            </Table.Tr>

            <Table.Tr>
              <Table.Th>{title}</Table.Th>

              {isEmpty(tableOptions) && (
                <Table.Th className={classes["question-table__breakdown"]}>
                  {t("value")}
                </Table.Th>
              )}

              {!isEmpty(tableOptions) &&
                tableOptions.map((option) => (
                  <Table.Th
                    className={classes["question-table__breakdown"]}
                    key={option.optionId}
                  >
                    {option?.optionLabel}
                  </Table.Th>
                ))}
            </Table.Tr>
          </Table.Thead>

          <Table.Tbody>
            {relatedQuestions.flatMap((q) => (
              <Table.Tr key={q.label}>
                <Table.Td>{q.label}</Table.Td>

                {isEmpty(tableOptions) && (
                  <Table.Td className={classes["question-table__breakdown"]}>
                    <Table.Td>
                      <CSRDQuestionContent
                        breakdown={[]}
                        content={q.content}
                        datoQuestionId={q.id}
                        externalAnswers={externalAnswers}
                        formProps={form.getInputProps(
                          buildCSRDReportAnswerFormKey(q.id),
                        )}
                      />
                    </Table.Td>
                  </Table.Td>
                )}

                {tableOptions.map((dbd) => {
                  const key = buildCSRDReportAnswerFormKey(q.id, [dbd])

                  return (
                    <Table.Td key={key}>
                      <CSRDQuestionContent
                        breakdown={[dbd]}
                        content={q.content}
                        datoQuestionId={q.id}
                        externalAnswers={externalAnswers}
                        formProps={form.getInputProps(key)}
                      />
                    </Table.Td>
                  )
                })}
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      </Table.ScrollContainer>
    </Stack>
  )
}
