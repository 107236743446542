import {
  Content,
  CSRDQuestions,
  DisclosureRequirementDetails,
} from "@kiosk/reporting/legacy/types/data/dato"

export const findQuestionInDR = (
  dr: DisclosureRequirementDetails,
  questionID: string,
): CSRDQuestions<Content> | undefined => {
  for (const question of dr.questions) {
    if (question.id === questionID) {
      return question
    }

    if (!question?.relatedQuestions) {
      continue
    }

    for (const relatedQuestion of question.relatedQuestions) {
      const match = findQuestionInSubQuestions(relatedQuestion, questionID)

      if (match) {
        return match
      }
    }
  }

  return
}

export const findQuestionInSubQuestions = (
  question: CSRDQuestions<Content>,
  questionID: string,
): CSRDQuestions<Content> | undefined => {
  if (question.id === questionID) {
    return question
  }

  return question.relatedQuestions?.find((q) =>
    findQuestionInSubQuestions(q, questionID),
  )
}
