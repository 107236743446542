import { Button, Loader, Stack } from "@mantine/core"
import { showNotification } from "@mantine/notifications"
import { Form, useAsyncValue, useFetcher } from "@remix-run/react"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import { PageLayout } from "@kiosk/reporting/components/layout/PageLayout"
import { SubjectForm } from "@kiosk/reporting/components/onboarding/SubjectForm"

import type { SubjectData } from "@kiosk/reporting/lib/infra/subjects-infra.server"

export const SubjectSettingsPage = () => {
  const loaderState = useAsyncValue() as SubjectData

  const { t } = useTranslation(["common", "settings"])
  const fetcher = useFetcher()
  const isFetching =
    fetcher.state === "submitting" || fetcher.state === "loading"

  const [canShowNotification, setCanShowNotification] = useState(false)

  const ref = useRef<HTMLFormElement>(null)

  useEffect(() => {
    if (canShowNotification && !isFetching) {
      showNotification({
        message: t("settings:subjects.saved"),
        color: "green",
        autoClose: 2000,
      })
      setCanShowNotification(false)
    }
  }, [canShowNotification, isFetching, t])

  return (
    <PageLayout>
      <Stack align="center" mx="10%">
        <Form action="/onboarding" method="post" ref={ref}>
          <SubjectForm loaderState={loaderState} sticky />

          <Stack align="flex-end" w="100%">
            <Button
              onClick={() => {
                if (isFetching) return
                fetcher.submit(ref.current)
                setCanShowNotification(true)
              }}
            >
              {isFetching ? (
                <Loader color="gray.0" m="sm" size="sm" />
              ) : (
                t("common:buttons.save")
              )}
            </Button>
          </Stack>
        </Form>
      </Stack>
    </PageLayout>
  )
}
