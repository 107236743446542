import { Modal, ModalBaseProps } from "@mantine/core"
import { useTranslation } from "react-i18next"

export const BackToYouModal = (props: ModalBaseProps) => {
  const { t } = useTranslation("sources")

  return (
    <Modal.Root
      centered={true}
      onClose={props.onClose}
      opened={props.opened}
      size="520px"
    >
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>{t("integrationRequestSent")}</Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body mb={12} mx={8}>
          {t("integrationRequestBackToYou")}
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  )
}
