import { colors } from "@kiosk/reporting/theme"

export const CLERK_THEME = {
  layout: {
    logoPlacement: "none",
    socialButtonsPlacement: "bottom",
    termsPageUrl: "https://www.meetkiosk.com/mentions-legales",
    privacyPageUrl: "https://www.meetkiosk.com/politique-de-confidentialite",
  },
  variables: {
    colorPrimary: colors.green[6],
    colorSuccess: colors.green[5],
    // TODO: add colors
    // colorDanger: colors.red[400],
    // colorWarning: colors.orange[400],
    colorText: colors.gray[8],
    colorTextSecondary: colors.gray[6],
    colorInputText: colors.gray[8],
  },
} as const
