import { ConsolidatedDimension } from "@kiosk/reporting/legacy/types/utils/dimensions"
import { CompanyDimensionAggregate } from "@kiosk/reporting/lib/aggregates/companyDimensionAggregate"

export const findDimensionById = (
  dimensionId: string,
  allDimensions: Array<ConsolidatedDimension | CompanyDimensionAggregate>,
) => {
  const dimension = allDimensions.find(({ id }) => id === dimensionId)

  if (!dimension)
    throw new Error(`Could not find dimension for dimensionId ${dimensionId}`)

  return dimension
}

export const findDimensionOptionById = (
  dimensionId: string,
  optionId: string,
  allDimensions: Array<ConsolidatedDimension | CompanyDimensionAggregate>,
) => {
  const dimension = findDimensionById(dimensionId, allDimensions)

  const dimensionOptions =
    "options" in dimension
      ? dimension.options
      : dimension.companyDimensionOptions

  const option = dimensionOptions.find(({ id }) => id === optionId)

  if (!option) return

  return option
}
