import {
  Group,
  parseThemeColor,
  Text,
  ThemeIcon,
  useMantineTheme,
} from "@mantine/core"
import { IconQuestionMark } from "@tabler/icons-react"

import { topicIcons } from "@kiosk/reporting/routes/dashboard/components/demo/DashboardIcons"
import { TablerIconComponent } from "@kiosk/reporting/utils/icon"

type Props = {
  readonly icon?: TablerIconComponent
  readonly topicId: string
  readonly iconColor?: string
  readonly iconBackgroundColor?: string
  readonly name?: string
}

// TODO: add icons to each topic in Dato
//   How ?
//   - Add all icons as svg/img in the assets folder
//   - Add a field in the topic model referencing a tabler icon (ex: Topic.icon = "IconHome", then do a dynamic import from @tabler/icons-react)
export function TopicTitleDemo({
  iconColor,
  topicId,
  iconBackgroundColor,
  name,
}: Props) {
  const theme = useMantineTheme()
  const iconParsedColor = parseThemeColor({ color: iconColor, theme })
  const parsedColor = parseThemeColor({ color: iconBackgroundColor, theme })

  const Icon = topicId in topicIcons ? topicIcons[topicId] : IconQuestionMark

  return (
    <Group gap={16}>
      <ThemeIcon
        bg={parsedColor.value}
        style={{ width: "40px", height: "40px" }}
        variant="light"
      >
        <Icon
          color={iconParsedColor.value}
          style={{ width: "24px", height: "24px" }}
        />
      </ThemeIcon>

      <Text c="gray.6" fw={600} size="xl">
        {name}
      </Text>
    </Group>
  )
}
