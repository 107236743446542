import { useState, useRef, useEffect } from "react"

export const usePosition = <T extends Element>() => {
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const ref = useRef<T>(null)

  const { x, y } = ref.current?.getBoundingClientRect() ?? {}

  useEffect(() => {
    if (x !== undefined && y !== undefined) setPosition({ x, y })
  }, [x, y])

  return [ref, position] as const
}
