import { SignedOut, SignIn } from "@clerk/remix"
import { Box, Group, Image, Stack } from "@mantine/core"
import { useLoaderData } from "@remix-run/react"
import { LoaderFunctionArgs } from "@remix-run/server-runtime"

import Logo from "@kiosk/reporting/components/Logo"
import background from "@kiosk/reporting/routes/welcome.$/auth-background.png"
import { requireUser } from "@kiosk/reporting/utils/auth.server"

export const loader = async (args: LoaderFunctionArgs) => {
  const user = await requireUser(args)
  const branding = user.company.branding

  return { branding }
}

export default function WelcomePage() {
  const { branding } = useLoaderData<typeof loader>()

  return (
    <SignedOut>
      <Stack align="flex-start" h="100vh">
        <Group gap={0} h="100%" p={24} w="100vw">
          <Stack align="flex-start" h="100%" justify="center" w="50%">
            <Box h={28}>
              <Logo branding={branding} />
            </Box>

            <Stack align="center" h="100%" justify="center" w="100%">
              <SignIn
                // Hide the sign-up button on login form
                afterSignOutUrl="/welcome"
                appearance={{
                  elements: { footerAction: { display: "none" } },
                }}
                fallbackRedirectUrl="/dashboard"
                forceRedirectUrl="/dashboard"
              />
            </Stack>
          </Stack>

          <Stack h="100%" w="50%">
            <Image
              alt="auth image"
              fit="cover"
              flex={1}
              h="100%"
              radius="md"
              src={background}
              w="100%"
            />
          </Stack>
        </Group>
      </Stack>
    </SignedOut>
  )
}
