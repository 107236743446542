import { AllDimensions } from "@kiosk/reporting/legacy/types/data/dato"
import { DimensionResponses } from "@kiosk/reporting/legacy/types/endpoints/dimensions"
import { ConsolidatedDimension } from "@kiosk/reporting/legacy/types/utils/dimensions"

export const cleanUpDimensionLabel = (label: string) => {
  const labelTokens = label.split(" - ")

  return labelTokens[labelTokens.length - 1]
}

export const consolidateDimensions = (
  datoDimensions: AllDimensions,
  typedDimensions: DimensionResponses.TypedDimensions,
): ConsolidatedDimension[] => {
  return datoDimensions.allDimensions.map((d) => {
    const periodType =
      d._allReferencingQuestions && d._allReferencingQuestions.length > 0
        ? d._allReferencingQuestions[0].periodType
        : "duration"

    if (d.dimensionType === "explicit") {
      return {
        id: d.id,
        label: cleanUpDimensionLabel(d.label),
        dimensionType: "explicit",
        xbrlCode: d.xbrlCode,
        options: d.options.map((option) => ({
          id: option.id,
          label: option.label,
          group: option.group,
          xbrlCode: option.xbrlCode,
        })),
        periodType,
      }
    }

    const typedDimensionOptions = typedDimensions[d.id] ?? []

    return {
      id: d.id,
      label: cleanUpDimensionLabel(d.label),
      dimensionType: "typed",
      xbrlCode: d.xbrlCode,
      options: typedDimensionOptions.map((option) => ({
        id: option.id,
        label: option.label,
      })),
      periodType,
    }
  })
}
