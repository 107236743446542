import { Flex, Stack, Image, Text } from "@mantine/core"
import { useTranslation } from "react-i18next"

import emptySources from "@kiosk/reporting/assets/img/empty-survey.png"

export const EmptySources = () => {
  const { t } = useTranslation("sources")

  return (
    <Flex align="center" direction="row" h="80%" justify="center" w="100%">
      <Stack gap={16} w={300}>
        <Image alt="Empty Sources" src={emptySources} w="100%" />

        <Text fw={500} fz="xl" ta="center">
          {t("empty.title")}
        </Text>

        <Text c="gray.6" fz="lg" ta="center">
          {t("empty.subtitle")}
        </Text>
      </Stack>
    </Flex>
  )
}
