import { Tooltip } from "@mantine/core"
import { IconHelpCircleFilled } from "@tabler/icons-react"

import { colors } from "@kiosk/reporting/assets/theme/colors"

interface Props {
  readonly content: string
}

export const QuestionTooltip = ({ content }: Props) => (
  <Tooltip label={content} multiline position="bottom-start" w={220}>
    <IconHelpCircleFilled color={colors.gray[4]} size={16} />
  </Tooltip>
)

export const PhaseInQuestionTooltip = ({ content }: Props) => {
  return (
    <Tooltip
      arrowOffset={10}
      arrowSize={4}
      label={content}
      multiline
      w="50em"
      withArrow
    >
      <IconHelpCircleFilled color={colors.gray[5]} size={16} />
    </Tooltip>
  )
}
