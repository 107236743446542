import _ from "lodash"

import {
  DashboardCategory,
  DashboardCategoryDemo,
} from "@kiosk/reporting/api/dashboard/types"

export const sortCategoryByOrder = (categoryList: DashboardCategory[]) => {
  return _.sortBy(categoryList, (category) => category.order)
}

export const sortCategoryByOrderDemo = (
  categoryList: DashboardCategoryDemo[],
) => {
  return _.sortBy(categoryList, (category) => category.order)
}
