import {
  Checkbox,
  Table,
  TableTbody,
  TableTd,
  TableTh,
  TableThead,
  TableTr,
} from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import _ from "lodash"
import { Dispatch, SetStateAction, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import { BulkAction } from "@kiosk/reporting/components/BulkAction/BulkAction"
import { useUser } from "@kiosk/reporting/components/auth/UserContext"
import { AssignmentDimensionsCell } from "@kiosk/reporting/components/surveys/AssignmentDimensionsCell"
import { CsrdCategory } from "@kiosk/reporting/legacy/types/data/dato"
import { AssignmentResponses } from "@kiosk/reporting/legacy/types/endpoints/assignments"
import { CompanyResponses } from "@kiosk/reporting/legacy/types/endpoints/companies"
import { ConsolidatedDimension } from "@kiosk/reporting/legacy/types/utils/dimensions"
import { CompanyDimensionAggregate } from "@kiosk/reporting/lib/aggregates/companyDimensionAggregate"
import { LocalizedCompanySurveyQuestion } from "@kiosk/reporting/lib/services/company-survey-question.service.server"
import { Assignee } from "@kiosk/reporting/routes/answer_survey.$id/AnswerSurveyContent"
import { AnswersCell } from "@kiosk/reporting/routes/answer_survey.$id/AnswerSurveyTable/AnswersCell"
import { EditComment } from "@kiosk/reporting/routes/answer_survey.$id/AnswerSurveyTable/EditComment"
import { FilesCell } from "@kiosk/reporting/routes/answer_survey.$id/AnswerSurveyTable/FilesCell"
import { QuestionsCell } from "@kiosk/reporting/routes/answer_survey.$id/AnswerSurveyTable/QuestionsCell"
import { AssigneeSelect } from "@kiosk/reporting/routes/sources_.edit_question.$id/components/AssigneeSelect"

export type Files = Record<
  string,
  {
    name: string
    path: string
    signedUrl: string
  } | null
>

type Form = UseFormReturnType<
  {
    [x: string]: number | null
  },
  (values: { [x: string]: number | null }) => {
    [x: string]: number | null
  }
>

type DataSource = {
  label: string
  instructions: string | null
  unit: string
  datoId: string | null
  companySurveyQuestionId: string | null
  id: string
  assignmentDimensions: AssignmentResponses.AssignmentDimension[]
  questionId: string
  numberType: "decimal" | "integer" | "currency" | "percent"
}[]

type SharedSurveyProps = {
  readonly companySurveyQuestions?: LocalizedCompanySurveyQuestion[]
  readonly rawDataPointsQuestions: CsrdCategory[]
  readonly allDimensions: Array<
    ConsolidatedDimension | CompanyDimensionAggregate
  >
  readonly comments: Record<string, string | null>
  readonly setComments: Dispatch<SetStateAction<Record<string, string | null>>>
  readonly files: Files
  readonly setFiles: Dispatch<SetStateAction<Files>>
  readonly form: Form
  readonly dataSource: DataSource
  readonly isSubmitted: boolean
  readonly companyUsers: CompanyResponses.GetCompanyUsers
  readonly setAssignees: Dispatch<
    SetStateAction<Record<string, Assignee | undefined>>
  >
  readonly assignees?: Record<string, Assignee | undefined>
}

type AnswerSurveyTableProps = SharedSurveyProps

type AnswerSurveyRowProps = {
  selectedIndices: number[]
  setSelectedIndices: Dispatch<SetStateAction<number[]>>
  companyContributors: Assignee[]
} & SharedSurveyProps

export const AnswerSurveyTable = ({
  companySurveyQuestions,
  allDimensions,
  comments,
  setComments,
  files,
  setFiles,
  form,
  dataSource,
  isSubmitted,
  rawDataPointsQuestions,
  companyUsers,
  setAssignees,
  assignees,
}: AnswerSurveyTableProps) => {
  const { t } = useTranslation("survey")
  const [selectedIndices, setSelectedIndices] = useState<number[]>([])
  const [isAllSelected, setIsAllSelected] = useState(false)

  const user = useUser()

  const companyContributors = useMemo(() => {
    const contributors = _(companyUsers.data)
      .filter((u) => u.id !== user.id)
      .map(({ id, email, firstName, lastName, locale, jobTitle }) => ({
        id,
        email,
        fullName: `${firstName} ${lastName}`,
        locale,
        jobTitle,
      }))
      .uniqBy((u) => u.email)
      .sortBy((u) => u.fullName)
      .value()

    return contributors
  }, [companyUsers.data, user.id])

  const handleSelectAll = () => {
    if (isAllSelected) {
      setSelectedIndices([])
    } else {
      setSelectedIndices(dataSource.map((_, index) => index))
    }
    setIsAllSelected(!isAllSelected)
  }

  const handleBulkAssign = (assignee: Assignee | null) => {
    if (!assignee) return

    setAssignees((prev) => {
      const updatedAssignees = { ...prev }

      selectedIndices.forEach((index) => {
        const surveyId = dataSource[index].id

        if (surveyId) {
          updatedAssignees[surveyId] = assignee
        }
      })

      return updatedAssignees
    })

    setSelectedIndices([])
    setIsAllSelected(false)
  }

  return (
    <Table withColumnBorders>
      <TableThead>
        <TableTr>
          <TableTh>
            <Checkbox
              checked={
                isAllSelected ||
                (dataSource.length !== 0 &&
                  selectedIndices.length === dataSource.length)
              }
              indeterminate={
                selectedIndices.length > 0 &&
                selectedIndices.length < dataSource.length
              }
              onChange={handleSelectAll}
            />
            {selectedIndices.length > 0 && (
              <BulkAction hasAllSelect nbSelected={selectedIndices.length}>
                <AssigneeSelect
                  companyContributors={companyContributors}
                  label={t("select")}
                  onSelect={handleBulkAssign}
                />
              </BulkAction>
            )}
          </TableTh>
          <TableTh>{t("answerSurvey.table.question")}</TableTh>
          <TableTh>{t("answerSurvey.table.dimensionBreakdowns")}</TableTh>
          <TableTh>{t("answerSurvey.table.answer")}</TableTh>
          <TableTh>{t("answerSurvey.table.comment")}</TableTh>
          <TableTh>{t("answerSurvey.table.file")}</TableTh>
          <TableTh>{t("answerSurvey.table.assignee")}</TableTh>
        </TableTr>
      </TableThead>
      <TableTbody>
        <AnswerSurveyRow
          allDimensions={allDimensions}
          assignees={assignees}
          comments={comments}
          companyContributors={companyContributors}
          companySurveyQuestions={companySurveyQuestions}
          companyUsers={companyUsers}
          dataSource={dataSource}
          files={files}
          form={form}
          isSubmitted={isSubmitted}
          rawDataPointsQuestions={rawDataPointsQuestions}
          selectedIndices={selectedIndices}
          setAssignees={setAssignees}
          setComments={setComments}
          setFiles={setFiles}
          setSelectedIndices={setSelectedIndices}
        />
      </TableTbody>
    </Table>
  )
}

const AnswerSurveyRow = ({
  companySurveyQuestions,
  allDimensions,
  comments,
  setComments,
  files,
  setFiles,
  form,
  dataSource,
  isSubmitted,
  rawDataPointsQuestions,
  setAssignees,
  assignees,
  selectedIndices,
  setSelectedIndices,
  companyContributors,
}: AnswerSurveyRowProps) => {
  const { t } = useTranslation("survey")

  const handleAssign = async (surveyId: string, assignee: Assignee) => {
    setAssignees((prev) => ({
      ...prev,
      [surveyId]: assignee,
    }))
  }

  return dataSource.map((survey, index) => (
    <TableTr key={survey.id ?? survey.companySurveyQuestionId! + index}>
      <TableTd>
        <Checkbox
          checked={selectedIndices.includes(index)}
          onChange={() =>
            selectedIndices.includes(index)
              ? setSelectedIndices((l) => l.filter((i) => i !== index))
              : setSelectedIndices((l) => [
                  ...l.filter((i) => i !== index),
                  index,
                ])
          }
        />
      </TableTd>
      <TableTd>
        <QuestionsCell
          companySurveyQuestions={companySurveyQuestions}
          instructions={survey.instructions ? survey.instructions : undefined}
          questionDatoId={survey.datoId ?? survey.companySurveyQuestionId!}
          rawDataPointsQuestions={rawDataPointsQuestions}
        />
      </TableTd>
      <TableTd>
        <AssignmentDimensionsCell
          allDimensions={allDimensions}
          assignmentDimensions={survey.assignmentDimensions}
        />
      </TableTd>
      <TableTd>
        <AnswersCell
          form={form}
          id={survey.id}
          isSubmitted={isSubmitted}
          question={survey}
        />
      </TableTd>
      <TableTd>
        <EditComment
          comment={comments[survey.id]}
          setComment={(comment: string | null) =>
            setComments((comments) => ({ ...comments, [survey.id]: comment }))
          }
        />
      </TableTd>
      <TableTd>
        <FilesCell files={files} setFiles={setFiles} surveyID={survey.id} />
      </TableTd>
      <TableTd w={240}>
        <AssigneeSelect
          assignee={assignees?.[survey.id]}
          companyContributors={companyContributors}
          label={t("select")}
          onSelect={(assignee) => handleAssign(survey.id, assignee)}
        />
      </TableTd>
    </TableTr>
  ))
}
