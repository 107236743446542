interface FollowUpQuestion {
  datoId: string
}

interface Esrs {
  datoId: string
  category: string
  code: string
  name: string
  followUpQuestion: FollowUpQuestion
  iro2EnumLabel: string
}

interface DisclosureRequirement {
  datoId: string
  nonMaterialTopicsQuestionId: string
  materialityAssessmentQuestionId: string
}

interface Config {
  esrs2IRO: string
  disclosureRequirement: DisclosureRequirement
  esrs: Esrs[]
}

const categoryIds = {
  general: "VRtVBEmyTFei5I0RPZ6rKw",
  environment: "M55kKl_JSYWin3SXO8A-7w",
  social: "OTMsUdUNTCa3ki6w01R_fg",
  governance: "bEDJYzyhThCV76nAgMwYLg",
}

const config: Config = {
  esrs2IRO: "ei7usRGzTKGCbKh6LMLczw",
  disclosureRequirement: {
    datoId: "FEsb2ApWRsWDzuF_cWdPpw",
    nonMaterialTopicsQuestionId: "OiCT2_QDR9KJIl8ZpOjkOg",
    materialityAssessmentQuestionId: "LBVj4FHPQSu4187YdtwzWA",
  },
  esrs: [
    {
      datoId: "PEKTiN73RNePd2WtSg_Obg",
      category: categoryIds.environment,
      code: "ESRS-E1",
      name: "Climate change",
      iro2EnumLabel: "ESRS E1 Climate Change",
      followUpQuestion: {
        datoId: "YjNZ6cbZQb2btcAIe_V7Bg",
      },
    },
    {
      datoId: "RCaidi5PTUucWlgnMyz_9w",
      category: categoryIds.environment,
      code: "ESRS-E2",
      name: "Pollution",
      iro2EnumLabel: "ESRS E2 Pollution",
      followUpQuestion: {
        datoId: "XdhnHCpLQ9CrENgS238dpw",
      },
    },
    {
      datoId: "IwUxd4RtS9mHgItbOLI1pw",
      category: categoryIds.environment,
      code: "ESRS-E3",
      name: "Water",
      iro2EnumLabel: "ESRS E3 Water and marine resources",
      followUpQuestion: {
        datoId: "V3IC9Z9GSdeG4rejOrM48Q",
      },
    },
    {
      datoId: "cuVBHEMjQeyWkrQR9RtVdQ",
      category: categoryIds.environment,
      code: "ESRS-E4",
      name: "Biodiversity",
      iro2EnumLabel: "ESRS E4 Biodiversity and ecosystems",
      followUpQuestion: {
        datoId: "dX0QDyc8Q2-bGskdItaVtQ",
      },
    },
    {
      datoId: "TkKw9IRoTZypbBupOyQbHw",
      category: categoryIds.environment,
      code: "ESRS-E5",
      name: "Circular Economy",
      iro2EnumLabel: "ESRS E5 Resource use and circular economy",
      followUpQuestion: {
        datoId: "co9Q3DoYTLGAB_XOUzovLA",
      },
    },
    {
      datoId: "ba14hTq8Qbek5zbxozkpkw",
      category: categoryIds.social,
      code: "ESRS-S1",
      name: "Own Workers",
      iro2EnumLabel: "ESRS S1 Own Workforce",
      followUpQuestion: {
        datoId: "BGn5UwSQQk254Fpd7Sr2AQ",
      },
    },
    {
      datoId: "T5ZK8Y3UQ36ha-GWC-OURw",
      category: categoryIds.social,
      code: "ESRS-S2",
      name: "Chain Workers",
      iro2EnumLabel: "ESRS S2 Workers in value chain",
      followUpQuestion: {
        datoId: "Y_d5lx41R0asWGc_2ywO_g",
      },
    },
    {
      datoId: "QeW2I1LEQTCJ7IwYoe-Bpw",
      category: categoryIds.social,
      code: "ESRS-S3",
      name: "Affected communities",
      iro2EnumLabel: "ESRS S3 Affected communities",
      followUpQuestion: {
        datoId: "CdhNk1o_TiWt0Q8dKTL2pg",
      },
    },
    {
      datoId: "EqbsGwVOS0-YiGyjzzsk4A",
      category: categoryIds.social,
      code: "ESRS-S4",
      name: "Consumers and end-users",
      iro2EnumLabel: "ESRS S4 Consumers and end-users",
      followUpQuestion: {
        datoId: "Qr3vUgzSSr2gHRuBGFtY9w",
      },
    },
    {
      datoId: "Ce1OrBfJRH26wrXjebQyHA",
      category: categoryIds.governance,
      code: "ESRS-G1",
      name: "Business conduct",
      iro2EnumLabel: "ESRS G1 Business conduct",
      followUpQuestion: {
        datoId: "SJf40lyRSsuWropB1oIxrw",
      },
    },
  ],
} as const

export function findEsrsById(id: string): Esrs | undefined {
  return config.esrs.find((esrs) => esrs.datoId === id)
}

export function findEsrsByCode(code: string): Esrs | undefined {
  return config.esrs.find((esrs) => esrs.code.match(code))
}

export function findEsrsByIro2Label(label: string): Esrs | undefined {
  return config.esrs.find((esrs) => esrs.iro2EnumLabel === label)
}

export function getEsrsToLabelMap(): Record<string, string> {
  return Object.fromEntries(
    config.esrs.map((esrs) => [esrs.datoId, esrs.iro2EnumLabel]),
  )
}

export default config
