import { Stack, Text } from "@mantine/core"
import { useTranslation } from "react-i18next"

interface VoluntaryBadgeProps {
  readonly withPhaseIn?: boolean
}

export const VoluntaryBadge = (props: VoluntaryBadgeProps) => {
  const { t } = useTranslation("csrd")

  return (
    <Stack
      justify="center"
      ml={props.withPhaseIn ? 3 : undefined}
      style={{ userSelect: "none", whiteSpace: "nowrap" }}
    >
      <Stack
        bg="cyan.0.5"
        mr="xs"
        px={props.withPhaseIn ? "xs" : "md"}
        py={props.withPhaseIn ? 1 : 5}
        style={{ borderRadius: "5em" }}
      >
        <Text c="gray.6">{t("voluntary")}</Text>
      </Stack>
    </Stack>
  )
}
