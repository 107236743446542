import { Stack, TextInput, MultiSelect, Select } from "@mantine/core"
import { useForm } from "@mantine/form"
import { useTranslation } from "react-i18next"

import { useLanguageOptions } from "@kiosk/reporting/constants/settings/languageOption"
import { USER_ROLES, User, UserRole } from "@kiosk/reporting/legacy/types/user"
import { roleTranslations } from "@kiosk/reporting/utils/constants"

type UserFormProps = {
  readonly getInputProps: ReturnType<typeof useForm>["getInputProps"]
  readonly currentUser: User
  readonly disabledEmail?: boolean
  readonly emailExists?: boolean
}

export const UserFormFields = ({
  getInputProps,
  currentUser,
  disabledEmail,
  emailExists,
}: UserFormProps) => {
  const { t } = useTranslation("common")

  const languageOptions = useLanguageOptions()

  const companyAdminRolesOption: Record<
    Extract<UserRole, "COMPANY_ADMIN" | "CONTRIBUTOR">,
    string
  > = {
    COMPANY_ADMIN: roleTranslations.COMPANY_ADMIN,
    CONTRIBUTOR: roleTranslations.CONTRIBUTOR,
  }

  const rolesOption = Object.entries(
    currentUser.roles.includes(USER_ROLES.COMPANY_ADMIN)
      ? companyAdminRolesOption
      : roleTranslations,
  ).map(([key, value]) => ({
    value: key as UserRole,
    label: value,
  }))

  const roleIsDisabled = !currentUser.roles.some(
    (role) =>
      role === USER_ROLES.KIOSK_ADMIN || role === USER_ROLES.COMPANY_ADMIN,
  )

  return (
    <Stack>
      <TextInput
        label={t("fields.firstNameLabel")}
        placeholder={t("fields.firstNamePlaceholder")}
        {...getInputProps("firstName")}
      />
      <TextInput
        label={t("fields.lastNameLabel")}
        placeholder={t("fields.lastNamePlaceholder")}
        {...getInputProps("lastName")}
      />
      <TextInput
        disabled={disabledEmail}
        label={t("fields.emailLabel")}
        placeholder={t("fields.emailPlaceholder")}
        {...getInputProps("email")}
        error={emailExists && t("messages.emailExist")}
      />
      <Select
        data={languageOptions}
        label={t("fields.locale")}
        placeholder={t("fields.locale")}
        {...getInputProps("locale")}
      />
      <TextInput
        label={t("fields.jobTitleLabel")}
        placeholder={t("fields.jobTitlePlaceholder")}
        {...getInputProps("jobTitle")}
      />
      <TextInput
        label={t("fields.departmentLabel")}
        placeholder={t("fields.departmentPlaceholder")}
        {...getInputProps("department")}
      />
      <MultiSelect
        data={rolesOption}
        disabled={roleIsDisabled}
        label={t("fields.roles")}
        placeholder={t("fields.rolesPlaceholder")}
        {...getInputProps("roles")}
      />
    </Stack>
  )
}
