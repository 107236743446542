import { TextInput, Text, ActionIcon, Group } from "@mantine/core"
import { IconCheck, IconEdit, IconX } from "@tabler/icons-react"
import { useState } from "react"

interface Props {
  readonly value?: string
  readonly readOnly?: boolean
  readonly onChange: (v: string) => void
}

export const EditableTitle = ({ value, readOnly = false, onChange }: Props) => {
  const [isEdit, setIsEdit] = useState(false)
  const [current, setCurrent] = useState(value)

  if (!isEdit)
    return (
      <Group>
        <Text fw={600} size="xl">
          {current}
        </Text>

        <ActionIcon
          color="gray.3"
          disabled={readOnly}
          onClick={() => {
            setIsEdit(true)
          }}
          variant="transparent"
        >
          <IconEdit />
        </ActionIcon>
      </Group>
    )

  return (
    <Group align="center">
      <TextInput
        fz={16}
        onChange={(e) => setCurrent(e.target.value)}
        size="xs"
        styles={{
          input: {
            fontSize: 20,
          },
          wrapper: {
            borderRadius: 6,
          },
        }}
        value={current}
      />

      <ActionIcon
        onClick={() => {
          setIsEdit(false)
          onChange(current!)
        }}
        variant="subtle"
      >
        <IconCheck size={20} />
      </ActionIcon>

      <ActionIcon
        color="gray.5"
        onClick={() => {
          setIsEdit(false)
          setCurrent(value)
        }}
        variant="subtle"
      >
        <IconX size={20} />
      </ActionIcon>
    </Group>
  )
}
