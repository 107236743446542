import { Stack } from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import { Framework } from "@prisma/client"
import _ from "lodash"
import { Dispatch, SetStateAction, useMemo, useState } from "react"

import { CSRDQuestionContent } from "@kiosk/reporting/components/csrd/CSRDQuestionContent"
import { CSRDQuestion } from "@kiosk/reporting/components/csrd/CSRDQuestionTree/CSRDQuestion/CSRDQuestion"
import { CSRDQuestionHeader } from "@kiosk/reporting/components/csrd/CSRDQuestionTree/CSRDQuestionHeader/CSRDQuestionHeader"
import { FormShape } from "@kiosk/reporting/components/csrd/types"
import {
  buildCSRDReportAnswerFormKey,
  getDimensionBreakdownParentQuestion,
} from "@kiosk/reporting/components/csrd/utils"
import { CSRDQuestionAnswer } from "@kiosk/reporting/legacy/types/csrd"
import { EnrichedDimensionBreakdown } from "@kiosk/reporting/utils/dimensions"
import {
  ContentWithCompanyData,
  CSRDQuestionsWithCompanyData,
} from "@kiosk/reporting/utils/types/csrd"

type QuestionTreeProps = {
  readonly question: CSRDQuestionsWithCompanyData<ContentWithCompanyData>
  readonly form: UseFormReturnType<FormShape>
  readonly breakdown?: EnrichedDimensionBreakdown
  readonly parentCollapsed?: boolean
  readonly isPreviousIsTitle?: boolean
  readonly datoTopicId?: string
  readonly datoSubTopicId?: string
  readonly externalAnswers: Record<string, CSRDQuestionAnswer>
  readonly setExternalAnswers: Dispatch<
    SetStateAction<Record<string, CSRDQuestionAnswer>>
  >
  readonly totalQuestions?: number
  readonly framework: Framework
}

/**
 * CSRDQuestionTree is a recursive component that renders a CSRD
 * question tree as nested components.
 *
 * The tree is part of a form and is responsible for handling its
 * part of the form.
 */
export default function CSRDQuestionTree({
  question,
  breakdown,
  form,
  parentCollapsed,
  isPreviousIsTitle,
  datoTopicId,
  datoSubTopicId,
  externalAnswers,
  setExternalAnswers,
  totalQuestions,
  framework,
}: QuestionTreeProps) {
  const [, rerenderOnConditionalQuestion] = useState(0)

  const formInputKey = useMemo(
    () =>
      buildCSRDReportAnswerFormKey(
        question.id,
        breakdown?.map((d) => ({
          dimensionId: d.dimensionId,
          optionId: d.optionId,
        })),
      ),
    [breakdown, question.id],
  )

  // TODO: this complex hook demonstrate why this should be better to have conditional follow up questions stored in `question.relationQuestions` instead of its own field
  const getRelatedQuestions = () => {
    const questionType = question.content?.__typename

    if (questionType === "ConditionalAnswerRecord") {
      const { followUpTriggerAnswer, followUpQuestions } = question.content
      const conditionalAnswer = _.get(form.getValues(), formInputKey)
      const isMatchingCondition =
        conditionalAnswer?.answer.value === followUpTriggerAnswer

      if (isMatchingCondition)
        return followUpQuestions.concat(question.relatedQuestions ?? [])
    }

    return question.relatedQuestions ?? []
  }

  const relatedQuestions = getRelatedQuestions()

  const initialBreakdowns = useMemo(
    () =>
      getDimensionBreakdownParentQuestion(question, {
        ...form.values,
        ...externalAnswers,
      }),
    [form.values, question, externalAnswers],
  )

  const [showDimensionsBreakdown, setShowDimensionsBreakdown] = useState(
    initialBreakdowns.length > 0,
  )

  const [dimensionBreakdowns, setDimensionBreakdowns] =
    useState<EnrichedDimensionBreakdown[]>()

  const isHiddenInForm = question.hiddenInForm
  const isTitle = question.isTitle

  const hasOnlyOneDimensionWithOneDimensionValue =
    question.dimensions?.length === 1 &&
    question.dimensions?.[0]?.options?.length === 1

  const handleResetBreakdown = () => {
    const formValues = form.getValues()

    const questionFormIDs = getQuestionFormIDs(
      formValues,
      question.relatedQuestions,
    )

    questionFormIDs.forEach((formKey) => {
      delete formValues[formKey]
    })

    form.setValues({ ...formValues })

    setExternalAnswers((prevSurveyAnswers) => {
      const questionToRemove = new Set([
        question.id,
        ...(question.relatedQuestions ?? []).map((q) => q.id),
      ])

      return Object.fromEntries(
        Object.entries(prevSurveyAnswers).filter(
          ([, value]) => !questionToRemove.has(value.datoQuestionId),
        ),
      )
    })
    rerenderOnConditionalQuestion((prev) => prev + 1)
  }

  return (
    <Stack
      gap={12}
      id={question.id}
      px={isHiddenInForm || isPreviousIsTitle ? 0 : 24}
      style={{
        display:
          isHiddenInForm && relatedQuestions.length === 0 ? "none" : "block",
      }}
      w="100%"
    >
      <Stack
        gap={8}
        style={{
          display:
            isHiddenInForm && relatedQuestions.length === 0 ? "none" : "block",
        }}
      >
        <CSRDQuestionHeader
          datoSubTopicId={datoSubTopicId}
          datoTopicId={datoTopicId}
          hasOnlyOneDimensionWithOneDimensionValue={
            hasOnlyOneDimensionWithOneDimensionValue
          }
          isTitle={isTitle}
          onResetBreakdown={handleResetBreakdown}
          question={question}
          setDimensionBreakdowns={setDimensionBreakdowns}
          setShowDimensionsBreakdown={setShowDimensionsBreakdown}
          showDimensionsBreakdown={showDimensionsBreakdown}
        />

        {question.content && (
          <CSRDQuestionContent
            breakdown={breakdown}
            content={question.content}
            datoQuestionId={question.id}
            externalAnswers={externalAnswers}
            formProps={form.getInputProps(formInputKey)}
          />
        )}
      </Stack>
      <CSRDQuestion
        breakdown={breakdown}
        dimensionBreakdowns={dimensionBreakdowns}
        externalAnswers={externalAnswers}
        form={form}
        framework={framework}
        initialBreakdowns={initialBreakdowns}
        isHiddenInForm={isHiddenInForm}
        isParentCollapsed={parentCollapsed}
        isTitle={isTitle}
        onResetBreakdown={handleResetBreakdown}
        question={question}
        relatedQuestions={relatedQuestions}
        setDimensionBreakdowns={setDimensionBreakdowns}
        setExternalAnswers={setExternalAnswers}
        showDimensionsBreakdown={
          hasOnlyOneDimensionWithOneDimensionValue || showDimensionsBreakdown
        }
        totalQuestions={totalQuestions}
      />
    </Stack>
  )
}

const getQuestionFormIDs = (
  formValues: FormShape,
  relatedQuestions?: CSRDQuestionsWithCompanyData<ContentWithCompanyData>[],
): string[] => {
  if (!relatedQuestions || relatedQuestions.length === 0) {
    return []
  }

  const hasRelatedQuestion = (formKey: string) => {
    const hasRelatedQuestion = relatedQuestions.some((rq) =>
      formKey.includes(rq.id),
    )

    const hasNestedRelatedQuestion = relatedQuestions.some(
      (rq) =>
        rq.relatedQuestions?.length &&
        getQuestionFormIDs(formValues, rq.relatedQuestions).includes(formKey),
    )

    return hasRelatedQuestion || hasNestedRelatedQuestion
  }

  return Object.keys(formValues).filter((formKey) => {
    return hasRelatedQuestion(formKey)
  })
}
