import { CsrdCategory } from "@kiosk/reporting/legacy/types/data/dato"
import { LocalizedCompanySurveyQuestion } from "@kiosk/reporting/lib/services/company-survey-question.service.server"

export const withCompanySurveyQuestion = (
  categories: CsrdCategory[],
  companySurveyQuestions: LocalizedCompanySurveyQuestion[],
) => {
  return [
    ...categories,
    {
      //TODO: handle localization
      name: "✍️ Entreprise",
      baseColor: "",
      order: 4,
      topics: [
        {
          name: "questions personnalisées",
          id: "COMPANY_SURVEY_QUESTIONS_TOPIC",
          disclosureRequirements: [
            {
              questions: companySurveyQuestions.map((question) => ({
                ...question,
                dimensionIds: [],
                unit: "",
                numberType: "integer",
              })),
              id: "COMPANY_SURVEY_QUESTIONS_SUB_TOPIC",
            },
          ],
        },
      ],
      id: "COMPANY_SURVEY_QUESTIONS",
    },
  ] as CsrdCategory[]
}
