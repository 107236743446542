import { notifications } from "@mantine/notifications"
import { useMutation } from "@tanstack/react-query"
import fileSaver from "file-saver"
import { useTranslation } from "react-i18next"

import { useUser } from "@kiosk/reporting/components/auth/UserContext"
import { apiClient } from "@kiosk/reporting/lib/apiClient"
import { authorizationHeader } from "@kiosk/reporting/lib/headers"

const { saveAs } = fileSaver

export const downloadCsv = async (token: string) => {
  const response = await apiClient.post(
    "/tasks/download-csv",
    {},
    {
      headers: authorizationHeader(token),
    },
  )

  saveAs(new Blob([response.data]), "tasks.csv")
}

export const useDownloadCsv = () => {
  const { t } = useTranslation()
  const { token } = useUser()

  return useMutation({
    mutationFn: async () => await downloadCsv(token),
    onError: ({ message }) => {
      notifications.show({
        title: t("messages.error"),
        message,
        color: "red",
      })
    },
  })
}
