import Users from "@kiosk/reporting/routes/settings.users/components/Users"

export const handle = {
  breadcrumb: () => "users",
  title: () => "users",
}

export default function UserSettingsPage() {
  return <Users />
}
