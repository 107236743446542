/**
 * For now, let’s split the logic in Demo and Production file.
 *
 * This allows the logic to be encapsulated.
 */

import { Stack } from "@mantine/core"

import {
  DashboardCategoryDemo,
  DataPointDemo,
} from "@kiosk/reporting/api/dashboard/types"
import { FAKE_DASHBOARD_DATA } from "@kiosk/reporting/constants/dashboard/data"
import { DIMENSIONS } from "@kiosk/reporting/constants/dashboard/dimension"
import {
  CategoryDashboardDemo,
  DashboardStyleKey,
} from "@kiosk/reporting/routes/dashboard/components/demo/CategoryDashboardDemo"
import { sortCategoryByOrderDemo } from "@kiosk/reporting/utils/sort"

interface Props {
  readonly categories: DashboardCategoryDemo[]
}

interface Filters {
  zone: string
  subsidiary: string
  time: string
}

export function loadDemoData(filters: Filters): DashboardCategoryDemo[] {
  return FAKE_DASHBOARD_DATA.map((category) => ({
    ...category,
    topics: category.topics.map((topic) => ({
      ...topic,
      dataPoints: filterDataPoints(topic.dataPoints, filters),
    })),
  }))
}

export function DemoDashboardContent(props: Props) {
  return (
    <Stack gap={48} pb={40}>
      {sortCategoryByOrderDemo(props.categories).map((category) => (
        <CategoryDashboardDemo
          categoryId={category.id}
          key={category.id}
          name={category.name as DashboardStyleKey}
          nameEN={category.nameEN}
          nameFR={category.nameFR}
          topics={category.topics}
        />
      ))}
    </Stack>
  )
}

function filterDataPoints(
  dataPoints: DataPointDemo[],
  filters: Filters,
): DataPointDemo[] {
  const { zone, subsidiary, time } = filters

  return dataPoints.map((dataPoint) => {
    const dimension = DIMENSIONS.find(
      (dim) => dim.id === String(dataPoint.dimensionId),
    )

    const zoneMatch = zone === "all" || dimension?.country === zone
    const subsidiaryMatch =
      subsidiary === "all" ||
      (dimension && dimension.business_unit === subsidiary)
    const timeMatch = time === "all" || dataPoint.time === parseInt(time, 10)

    if (dataPoint.type === "dimensioned") {
      return {
        ...dataPoint,
        values:
          zoneMatch && subsidiaryMatch && timeMatch
            ? dataPoint.values
            : undefined,
      }
    }

    return {
      ...dataPoint,
      value:
        zoneMatch && subsidiaryMatch && timeMatch ? dataPoint.value : undefined,
    }
  })
}
