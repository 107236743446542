import { Group, Pill, Stack, Text } from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import _ from "lodash"
import { Dispatch, SetStateAction } from "react"
import { useTranslation } from "react-i18next"

import {
  CardType,
  RelatedQuestionsCard,
} from "@kiosk/reporting/components/csrd/RelatedQuestionsCard"
import { RelatedQuestionsTable } from "@kiosk/reporting/components/csrd/RelatedQuestionsTable"
import { RelatedQuestionsTree } from "@kiosk/reporting/components/csrd/RelatedQuestionsTree"
import { FormShape } from "@kiosk/reporting/components/csrd/types"
import { CSRDQuestionAnswer } from "@kiosk/reporting/legacy/types/csrd"
import { CardRecordType } from "@kiosk/reporting/legacy/types/data/dato"
import { Framework } from "@kiosk/reporting/lib/entities/company"
import { EnrichedDimensionBreakdown } from "@kiosk/reporting/utils/dimensions"
import {
  ContentWithCompanyData,
  CSRDQuestionsWithCompanyData,
} from "@kiosk/reporting/utils/types/csrd"

const cardTypeToName: Record<CardRecordType, CardType> = {
  ActionCardRecord: "Action",
  PolicyCardRecord: "Policy",
  ResultsCardRecord: "Metric",
  TargetCardRecord: "Target",
}

type CSRDDimensionsQuestionProps = {
  readonly question: CSRDQuestionsWithCompanyData<ContentWithCompanyData>
  readonly form: UseFormReturnType<FormShape>
  readonly relatedQuestions: CSRDQuestionsWithCompanyData<ContentWithCompanyData>[]
  readonly isParentCollapsed?: boolean
  readonly breakdown?: EnrichedDimensionBreakdown
  readonly hasDimensions: boolean
  readonly dimensionBreakdowns?: EnrichedDimensionBreakdown[]
  readonly showDimensionsBreakdown: boolean
  readonly externalAnswers: Record<string, CSRDQuestionAnswer>
  readonly setExternalAnswers: Dispatch<
    SetStateAction<Record<string, CSRDQuestionAnswer>>
  >
  readonly totalQuestions?: number
  readonly framework: Framework
}

export const CSRDDimensionsQuestion = ({
  question,
  form,
  relatedQuestions,
  isParentCollapsed,
  breakdown,
  hasDimensions,
  dimensionBreakdowns,
  showDimensionsBreakdown,
  externalAnswers,
  setExternalAnswers,
  totalQuestions,
  framework,
}: CSRDDimensionsQuestionProps) => {
  const { t } = useTranslation("csrd")

  const isCard = question.content?.__typename === "CardRecord"
  const isTable = question.content?.__typename === "TableRecord"
  const hasDimensionBreakdowns = !_.isEmpty(dimensionBreakdowns)

  const defaultDimensionBreakdowns = getDefaultDimension(question)

  const isSingleDimensionBreakdown = dimensionBreakdowns?.length === 1

  const showDefaultDimensionQuestion =
    defaultDimensionBreakdowns && !showDimensionsBreakdown

  const hasOnlyOneDimensionWithOneDimensionValue =
    hasDimensions && hasDimensionBreakdowns && isSingleDimensionBreakdown

  const isOneDimensionBreakdownsValid =
    isSingleDimensionBreakdown || !dimensionBreakdowns

  const showDefaultRelatedQuestionsTree =
    (hasOnlyOneDimensionWithOneDimensionValue ||
      showDefaultDimensionQuestion) &&
    isOneDimensionBreakdownsValid

  const activeDimensionBreakdowns =
    dimensionBreakdowns ||
    (!showDimensionsBreakdown && defaultDimensionBreakdowns)

  if (isTable && activeDimensionBreakdowns) {
    return (
      <RelatedQuestionsTable
        dimensionBreakdowns={dimensionBreakdowns ?? []}
        externalAnswers={externalAnswers}
        form={form}
        relatedQuestions={relatedQuestions}
        title={question.label}
      />
    )
  }

  if (isCard && hasDimensionBreakdowns) {
    return (
      <RelatedQuestionsCard
        dimensionBreakdowns={dimensionBreakdowns!}
        externalAnswers={externalAnswers}
        form={form}
        relatedQuestions={relatedQuestions}
        title={question.label}
        type={
          (question.content.__typename === "CardRecord" &&
            cardTypeToName[question.content.cardType.__typename]) ||
          "Policy"
        } // TODO
      />
    )
  }

  if (showDefaultRelatedQuestionsTree) {
    if (dimensionBreakdowns) {
      return dimensionBreakdowns?.map((breakdown, index) => (
        <RelatedQuestionsTree
          breakdown={breakdown}
          externalAnswers={externalAnswers}
          form={form}
          framework={framework}
          key={breakdown
            .map((breakdownItem) => breakdownItem.dimensionId + index)
            .join("-")}
          parentCollapsed={isParentCollapsed}
          relatedQuestions={relatedQuestions}
          setExternalAnswers={setExternalAnswers}
          totalQuestions={totalQuestions}
        />
      ))
    }

    return (
      <RelatedQuestionsTree
        breakdown={breakdown!}
        externalAnswers={externalAnswers}
        form={form}
        framework={framework}
        parentCollapsed={isParentCollapsed}
        relatedQuestions={relatedQuestions}
        setExternalAnswers={setExternalAnswers}
        totalQuestions={totalQuestions}
      />
    )
  }

  return (
    <>
      {dimensionBreakdowns &&
        dimensionBreakdowns?.map((breakdown, index) => (
          <Stack
            bg="gray.0"
            key={breakdown
              .map((breakdownItem) => breakdownItem.dimensionId + index)
              .join("-")}
            style={{
              border: "1px solid var(--mantine-color-gray-2)",
              borderRadius: 6,
            }}
          >
            <Stack gap="sm" p="md">
              <Group justify="space-between">
                <Text fw={400} size="md">
                  {t("breakdown.title")}
                </Text>

                <Text fw={400} size="md">
                  {index + 1} / {dimensionBreakdowns.length}
                </Text>
              </Group>
              <Group gap="xs">
                {breakdown.map((breakdownItem) => {
                  if (breakdownItem.optionLabel !== "N/A") {
                    return (
                      <Pill key={breakdownItem.optionId}>
                        {breakdownItem.optionLabel}
                      </Pill>
                    )
                  }
                })}
              </Group>
            </Stack>
            <RelatedQuestionsTree
              breakdown={breakdown}
              externalAnswers={externalAnswers}
              form={form}
              framework={framework}
              parentCollapsed={isParentCollapsed}
              relatedQuestions={relatedQuestions}
              setExternalAnswers={setExternalAnswers}
              totalQuestions={totalQuestions}
            />
          </Stack>
        ))}
    </>
  )
}

const getDefaultDimension = (
  question: CSRDQuestionsWithCompanyData<ContentWithCompanyData>,
): EnrichedDimensionBreakdown[] | undefined => {
  if (!question.dimensions?.length) {
    return
  }

  return [
    [
      {
        dimensionId: question.dimensions[0].id,
        optionId: "N/A",
        dimensionType: "explicit",
        dimensionLabel: question.dimensions[0].label.split(" - ")[1],
        optionLabel: "N/A",
      },
    ],
  ]
}
