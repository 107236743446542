import {
  Accordion,
  AccordionControl,
  AccordionItem,
  AccordionPanel,
  Text,
} from "@mantine/core"
import { IconChevronDown } from "@tabler/icons-react"
import React, { Fragment, useState } from "react"
import { useTranslation } from "react-i18next"

import classes from "@kiosk/reporting/components/csrd/PhaseInVoluntaryWrapper/PhaseInVoluntaryWrapper.module.css"
import { PhaseInQuestionTooltip } from "@kiosk/reporting/components/csrd/QuestionTooltip"
import { Framework } from "@kiosk/reporting/lib/entities/company"
import {
  ContentWithCompanyData,
  CSRDQuestionsWithCompanyData,
} from "@kiosk/reporting/utils/types/csrd"

interface PhaseInVoluntaryCollapsePropsType {
  readonly onCollapse: () => void
  readonly isCollapsed: boolean
  readonly children: React.ReactNode
  readonly totalQuestions?: number
}

const PhaseInVoluntaryCollapse = ({
  onCollapse,
  isCollapsed,
  children,
  totalQuestions,
}: PhaseInVoluntaryCollapsePropsType) => {
  const { t } = useTranslation("csrd")

  if (!Array.isArray(children) || children.length === 0) return null

  return (
    <Accordion
      chevron={<IconChevronDown />}
      chevronPosition="left"
      classNames={{
        item: classes["phase-in-accordion__item"],
        label: classes["phase-in-accordion__label"],
        chevron: classes["phase-in-accordion__chevron"],
        panel: classes["phase-in-accordion__panel"],
      }}
      variant="filled"
    >
      <AccordionItem value="Phase in voluntary">
        <AccordionControl onClick={onCollapse}>
          <Text>
            {isCollapsed
              ? t("displayVoluntaryAndPhaseIn")
              : t("hideVoluntaryAndPhaseIn")}{" "}
            ({totalQuestions})
          </Text>
          <PhaseInQuestionTooltip
            content={t("hintVoluntaryAndPhaseInCollapse")}
          />
        </AccordionControl>
        <AccordionPanel>{children}</AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}

type QuestionChunks = {
  isCollapsible: boolean
  questions: CSRDQuestionsWithCompanyData<ContentWithCompanyData>[]
  id: number
}[]

const resolveQuestionChunks = (
  questions: CSRDQuestionsWithCompanyData<ContentWithCompanyData>[],
): QuestionChunks => {
  let isCollapsible = questions[0].isPhaseIn || questions[0].isVoluntary
  let chunkId = 0
  let buffer: CSRDQuestionsWithCompanyData<ContentWithCompanyData>[] = []
  const chunks: QuestionChunks = []

  for (const question of questions) {
    if (isCollapsible === (question.isPhaseIn || question.isVoluntary)) {
      buffer.push(question)
      if (question.id !== questions.at(-1)?.id) continue
    }
    chunks.push({ isCollapsible, questions: buffer, id: chunkId })
    buffer = [question]
    isCollapsible = question.isPhaseIn || question.isVoluntary
    chunkId++
  }

  return chunks
}

type PhaseInVoluntaryQuestionsWrapperPropsType = {
  readonly questions: CSRDQuestionsWithCompanyData<ContentWithCompanyData>[]
  readonly totalQuestions?: number

  readonly isParentCollapsed: boolean
  readonly children: (
    question: CSRDQuestionsWithCompanyData<ContentWithCompanyData>,
    isParentCollapsed: boolean,
  ) => React.ReactNode
  readonly framework: Framework
}

export const PhaseInVoluntaryQuestionsWrapper = ({
  questions,
  children,
  isParentCollapsed,
  totalQuestions,
  framework,
}: PhaseInVoluntaryQuestionsWrapperPropsType) => {
  if (framework === "VSME") {
    return questions.map((question) => (
      <Fragment key={question.id}>{children(question, false)}</Fragment>
    ))
  }

  const questionChunks = resolveQuestionChunks(questions)

  // This hook will never be evaluated if the framework is VSME

  const [wrapperCollapseState, setWrapperCollapseState] = useState(
    questionChunks
      .filter((questionChunk) => questionChunk.isCollapsible)
      .map((questionChunk) => ({ id: questionChunk.id, isCollapsed: true })),
  )

  const isCollapsed = (id: number) =>
    //we don't use null coalescing operator because left value can be undefined of false
    wrapperCollapseState.find((item) => item.id === id)?.isCollapsed || false

  const handleCollapse = (id: number) => {
    setWrapperCollapseState((prevState) =>
      prevState.map((item) =>
        item.id === id ? { ...item, isCollapsed: !item.isCollapsed } : item,
      ),
    )
  }

  return (
    <>
      {questionChunks.map((questionChunk) => {
        const canCollapse = questionChunk.isCollapsible && !isParentCollapsed
        const childCollapseFlag = isParentCollapsed || canCollapse

        if (canCollapse)
          return (
            <PhaseInVoluntaryCollapse
              isCollapsed={isCollapsed(questionChunk.id)}
              key={questionChunk.id}
              onCollapse={() => handleCollapse(questionChunk.id)}
              totalQuestions={totalQuestions}
            >
              {questionChunk.questions
                .filter((question) => !question.isHidden)
                .map((question) => (
                  <Fragment key={question.id}>
                    {children(question, childCollapseFlag)}
                  </Fragment>
                ))}
            </PhaseInVoluntaryCollapse>
          )

        return questionChunk.questions.map((question) => (
          <Fragment key={question.id}>
            {children(question, childCollapseFlag)}
          </Fragment>
        ))
      })}
    </>
  )
}
