import { Checkbox, Flex, Text } from "@mantine/core"
import _ from "lodash"
import { Dispatch, SetStateAction } from "react"

import { CsrdCategory } from "@kiosk/reporting/legacy/types/data/dato"
import { SurveyQuestionResponses } from "@kiosk/reporting/legacy/types/endpoints/surveyQuestions"
import { SurveyResponses } from "@kiosk/reporting/legacy/types/endpoints/surveys"
import { LocalizedCompanySurveyQuestion } from "@kiosk/reporting/lib/services/company-survey-question.service.server"
import { isSurveyStarted } from "@kiosk/reporting/routes/sources_.edit_survey.$id/utils"
import { useQuestionLabel } from "@kiosk/reporting/utils/useQuestionLabel"

type QuestionsCellProps = {
  readonly questionDatoId: string
  readonly record: SurveyQuestionResponses.SurveyQuestion
  readonly survey: SurveyResponses.Survey
  readonly selectedIds: string[]
  readonly setSelectedIds: Dispatch<SetStateAction<string[]>>
  readonly companySurveyQuestions?: LocalizedCompanySurveyQuestion[]
  readonly rawDataPointsQuestions: CsrdCategory[]
}

export const QuestionsCell = ({
  questionDatoId,
  survey,
  selectedIds,
  setSelectedIds,
  record,
  companySurveyQuestions,
  rawDataPointsQuestions,
}: QuestionsCellProps) => {
  const questionLabel = useQuestionLabel(
    questionDatoId,
    rawDataPointsQuestions,
    companySurveyQuestions,
  )

  return (
    <Flex align="center" gap={12}>
      {!isSurveyStarted(survey) && (
        <Checkbox
          checked={selectedIds.includes(record.id)}
          onChange={() =>
            selectedIds.includes(record.id)
              ? setSelectedIds((ids) => _.difference(ids, [record.id]))
              : setSelectedIds((ids) => _.union(ids, [record.id]))
          }
        />
      )}
      <Text>{questionLabel}</Text>
    </Flex>
  )
}
