import { Group } from "@mantine/core"
import { IconSparkles } from "@tabler/icons-react"
import { useTranslation } from "react-i18next"

import classes from "@kiosk/reporting/components/csrd/DisclosureRequirementPreview/DisclosureRequirementPreview.module.css"
import { SparklingButton } from "@kiosk/reporting/components/generic/SparklingButton"

interface PreviewFooterProps {
  readonly isPendingGeneration: boolean
  readonly onGenerate: () => void
}

export const PreviewFooter = (props: PreviewFooterProps) => {
  const { t } = useTranslation("csrd")

  return (
    <Group className={classes["dr-preview__footer"]}>
      <SparklingButton
        icon={<IconSparkles />}
        loading={props.isPendingGeneration}
        loadingLabel={t("aiGeneration.loading")}
        onPress={() => {
          props.onGenerate()
        }}
      >
        {t("aiGeneration.regenerate")}
      </SparklingButton>
    </Group>
  )
}
