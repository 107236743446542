import { Button, Group, Stack, Text, rem } from "@mantine/core"
import { useLoaderData, useNavigate } from "@remix-run/react"
import { useTranslation } from "react-i18next"

import Logo from "@kiosk/reporting/components/Logo"
import { loader } from "@kiosk/reporting/routes/congratulations/route"

export const Congratulations = () => {
  const { branding } = useLoaderData<typeof loader>()
  const { t } = useTranslation(["common"])
  const navigate = useNavigate()

  return (
    <Stack align="center" gap={40} mih="100vh">
      <Group
        justify="center"
        mih={74}
        px={24}
        py={16}
        style={{ borderBottom: "solid 1px var(--mantine-color-gray-3)" }}
        w="100%"
      >
        <Logo branding={branding} height={28} />
      </Group>

      <Stack align="center" gap={rem(24)}>
        <Text fz={64}>🎉</Text>

        <Stack align="center" gap={rem(0)}>
          <Text fw={600} style={{ fontSize: "32px" }}>
            {t("congratulations")}
          </Text>

          <Text c="gray.5" fz="xxl">
            {t("setupYourWorkspace")}
          </Text>
        </Stack>

        <Button onClick={() => navigate("/")}>{t("goToDashboard")}</Button>
      </Stack>
    </Stack>
  )
}
