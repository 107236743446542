import { SurveyResponses } from "@kiosk/reporting/legacy/types/endpoints/surveys"
import { ConsolidatedDimension } from "@kiosk/reporting/legacy/types/utils/dimensions"

export const findDatoDimensionById = (
  dimensionDatoId: string,
  allDimensions: ConsolidatedDimension[],
) => {
  const datoDimension = allDimensions.find(({ id }) => id === dimensionDatoId)

  if (!datoDimension)
    throw new Error(
      `Could not find dimension for dimensionDatoId ${dimensionDatoId}`,
    )

  return datoDimension
}

export const findDimensionOptionById = (
  dimensionDatoId: string,
  optionId: string,
  allDimensions: ConsolidatedDimension[],
) => {
  const datoDimension = findDatoDimensionById(dimensionDatoId, allDimensions)

  const datoOption = datoDimension.options.find(({ id }) => id === optionId)

  if (!datoOption)
    throw new Error(
      `Could not find option for dimensionDatoId ${dimensionDatoId} optionId ${optionId}`,
    )

  return datoOption
}

export const isSurveyStarted = (survey: SurveyResponses.Survey) => {
  switch (survey.status) {
    case "NOT_STARTED":
      return false
    case "MISSING_ASSIGNEE":
    case "IN_PROGRESS":
    case "DONE":
      return true
  }
}

export const isSurveyOver = (survey: SurveyResponses.Survey): boolean => {
  return survey.status === "DONE"
}

export const isSurveyEmpty = (survey: SurveyResponses.Survey) =>
  survey.answersCount === 0
