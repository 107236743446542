import { Box, Button, Modal, ModalBaseProps, Stack, Text } from "@mantine/core"
import _ from "lodash"
import { useCallback, useEffect, useState } from "react"
import { createPortal } from "react-dom"
import { useTranslation } from "react-i18next"
import Swal from "sweetalert2"

import { useUploadDsnMutation } from "@kiosk/reporting/api/files/dsn"
import { sourcesKeys } from "@kiosk/reporting/api/sources/keys"
import CustomSegmentedControl from "@kiosk/reporting/components/CustomSegmentedControl"
import { DashedDropzone } from "@kiosk/reporting/components/DashedDropzone"
import { DataPoint } from "@kiosk/reporting/legacy/types/data-points"
import { queryClient } from "@kiosk/reporting/lib/queryClient"
import { DSNSummary } from "@kiosk/reporting/routes/sources/components/Files/DSNSummary"

type SupportedFileType = "dsn" | "custom"

interface Translations {
  tabLabel: string
  title: string
  description: string
}

const useSuccessModal = (dataPoints: DataPoint[]) => {
  const { t } = useTranslation("sources")
  const [show, setShow] = useState(false)

  const showSwal = useCallback(() => {
    Swal.fire({
      icon: "success",
      showCloseButton: true,
      showConfirmButton: false,
      didOpen: () => setShow(true),
      didClose: () => setShow(false),
    })
  }, [])

  const SwalLoader = () => (
    <>
      {show &&
        createPortal(
          <Stack my={40}>
            <DSNSummary dataPoints={dataPoints} />

            <Box>
              <Button onClick={() => Swal.close()}>{t("actions.close")}</Button>
            </Box>
          </Stack>,
          Swal.getHtmlContainer()!,
        )}
    </>
  )

  return { showSwal, SwalLoader }
}

export default function NewFileModal(props: ModalBaseProps) {
  const { t } = useTranslation("sources")

  const errorMessages: Record<number, string> = {
    409: t("error.dataPointConflict"),
    500: t("error.processingError"),
  }

  // this is very silly, but it all breaks down if we don’t statically
  // reference the translations
  const translations: Record<SupportedFileType, Translations> = {
    dsn: {
      tabLabel: t("newFile.dsn.tabLabel"),
      title: t("newFile.dsn.title"),
      description: t("newFile.dsn.description"),
    },
    custom: {
      tabLabel: t("newFile.custom.tabLabel"),
      title: t("newFile.custom.title"),
      description: t("newFile.custom.description"),
    },
  }

  const [activeSegment, setActiveSegment] = useState<SupportedFileType>("dsn")
  const { error, isError, isPending, data, isSuccess } = useUploadDsnMutation()
  const { showSwal, SwalLoader } = useSuccessModal(data ?? [])

  const uploadError = error !== null ? new Error(error.error) : null

  useEffect(() => {
    if (!isSuccess) return
    showSwal()
  }, [isSuccess, showSwal])

  useEffect(() => {
    if (!isSuccess) return
    queryClient.invalidateQueries({ queryKey: sourcesKeys.list() })
  }, [isSuccess])

  return (
    <Modal {...props} padding="24px" size="lg" title={t("importFile.title")}>
      <Stack>
        <Text size="md">{t("importFile.description")}</Text>
        <CustomSegmentedControl
          data={[
            {
              label: t("newFile.dsn.tabLabel"),
              value: "dsn",
            },
            {
              label: t("newFile.custom.tabLabel"),
              value: "custom",
            },
          ]}
          onChange={(s) => setActiveSegment(s as SupportedFileType)}
        />

        <SwalLoader />
        <DashedDropzone
          clearFileUrl={_.noop}
          description={translations[activeSegment].description}
          errorMessage={errorMessages[error?.status ?? 500]}
          errorTitle={isError ? t("dsnImport.failTitle") : undefined}
          hasUploadError={isError}
          isUploading={isPending}
          title={translations[activeSegment].title}
          uploadError={uploadError}
        />
      </Stack>
    </Modal>
  )
}
