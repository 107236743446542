import _ from "lodash"
import { z } from "zod"

import { ObjectSet } from "@kiosk/reporting/legacy/shared/utils/object-set"

export const Dimension = z.object({
  name: z.string(),
})

export type Dimension = z.infer<typeof Dimension>

export const Dimensions = z.record(z.string())
export type Dimensions = z.infer<typeof Dimensions>

export const DimensionValue = z.object({
  dimensionName: z.string(),
  value: z.string(),
})
export type DimensionValue = z.infer<typeof DimensionValue>

export const DimensionBreakdownValue = z.object({
  dimensionId: z.string(),
  optionId: z.string(),
})
export type DimensionBreakdownValue = z.infer<typeof DimensionBreakdownValue>

export const DimensionBreakdown = z.array(DimensionBreakdownValue)
export type DimensionBreakdown = z.infer<typeof DimensionBreakdown>
export const DimensionBreakdownEntity = {
  isNA(optionID: string): boolean {
    return optionID === "N/A"
  },

  isNotNA(optionID: string): boolean {
    return !DimensionBreakdownEntity.isNA(optionID)
  },

  sanitize(self: DimensionBreakdown): DimensionBreakdown {
    return DimensionBreakdownEntity.fromObject(
      DimensionBreakdownEntity.toObject(self),
    )
  },

  // Returns a dimension breadkown from an object.
  // The object is assumed to have keys be the dimensions and values be their options.
  fromObject(obj: Record<string, string>): DimensionBreakdown {
    return _(obj)
      .toPairs()
      .filter(([_, optionId]) => !DimensionBreakdownEntity.isNA(optionId))
      .map(([dimensionId, optionId]) => ({
        dimensionId,
        optionId,
      }))
      .value()
  },

  toObject(self: DimensionBreakdown): Record<string, string> {
    const result: Record<string, string> = {}

    for (const { dimensionId, optionId } of self) {
      result[dimensionId] = optionId
    }

    return result
  },
}

// Contains the IDs of the dimensions we are working with.
// Note: this should probably instead contain the list of the dimension *entities*.
export type DimensionSpace = ObjectSet<{ id: string }>
export const DimensionSpaceEntity = {
  equals(space1: DimensionSpace, space2: DimensionSpace): boolean {
    const space1Dimensions = _.sortBy(space1.values(), "id")
    const space2Dimensions = _.sortBy(space2.values(), "id")

    return _.isEqual(space1Dimensions, space2Dimensions)
  },

  fromBreakdown(breakdown: DimensionBreakdown): DimensionSpace {
    const space = new ObjectSet<{ id: string }>()

    for (const dimension of breakdown) {
      space.add({ id: dimension.dimensionId })
    }

    return space
  },

  cardinality(self: DimensionSpace) {
    return self.size
  },

  // JavaScript does not support objects as keys of objects.
  // That’s why we have to use a custom key-builder function, whose
  // goal is to convert an object into a key that should work with
  // equality checks.
  toKey(self: DimensionSpace): string {
    return self
      .values()
      .map((v) => v.id)
      .sort()
      .join("\x1e")
  },

  fromKey(key: string): DimensionSpace {
    const dimensions = key.split("\x1e")
    const space = new ObjectSet<{ id: string }>()

    for (const dimension of dimensions) {
      space.add({ id: dimension })
    }

    return space
  },
}
