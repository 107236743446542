import { Button, Dialog, Group, Text } from "@mantine/core"
import { showNotification } from "@mantine/notifications"
import { IconCopy, IconTrash } from "@tabler/icons-react"
import _ from "lodash"
import { useTranslation } from "react-i18next"

interface RowActionProps {
  readonly selectedIds: number[]
  readonly deleteRecords: (id: number[]) => Promise<void> | void
  readonly duplicateRecords: (id: number[]) => Promise<void> | void
  readonly isPending: boolean
  readonly position: {
    left: number
    top: number
  }
}

export const RowAction = ({
  selectedIds,
  deleteRecords,
  duplicateRecords,
  isPending,
  position,
}: RowActionProps) => {
  const { t } = useTranslation("tables")

  return (
    <Dialog opened={!_.isEmpty(selectedIds)} position={position}>
      <Group gap={0}>
        <Group px={12} py={8}>
          <Text c="green" fw={600}>
            {t("nbSelected", { count: selectedIds.length })}
          </Text>
        </Group>

        <Group
          px={12}
          py={8}
          style={{ borderLeft: "1px solid var(--mantine-color-gray-3)" }}
        >
          <Button
            c="gray"
            leftSection={<IconTrash />}
            loading={isPending}
            onClick={async () => {
              await deleteRecords(selectedIds)
              showNotification({
                color: "green",
                message: t("recordsDeleted", {
                  count: selectedIds.length,
                }),
              })
            }}
            size="compact-md"
            variant="transparent"
          >
            {t("actions.delete")}
          </Button>
        </Group>

        <Group
          px={12}
          py={8}
          style={{ borderLeft: "1px solid var(--mantine-color-gray-3)" }}
        >
          <Button
            c="gray"
            leftSection={<IconCopy />}
            loading={isPending}
            onClick={async () => {
              await duplicateRecords(selectedIds)
              showNotification({
                color: "green",
                message: t("recordsDuplicated", {
                  count: selectedIds.length,
                }),
              })
            }}
            size="compact-md"
            variant="transparent"
          >
            {t("actions.duplicate")}
          </Button>
        </Group>
      </Group>
    </Dialog>
  )
}
