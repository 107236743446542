import { useDisclosure } from "@mantine/hooks"
import _ from "lodash"
import { useState } from "react"

import { useCreateSurveyQuestionMutation } from "@kiosk/reporting/api/surveys/questions/createSurveyQuestion"
import { useDeleteSurveyQuestionMutation } from "@kiosk/reporting/api/surveys/questions/deleteSurveyQuestion"
import { AddQuestionsModal } from "@kiosk/reporting/components/questions/AddQuestionsModal"
import { CsrdCategory, Question } from "@kiosk/reporting/legacy/types/data/dato"
import { SurveyQuestionResponses } from "@kiosk/reporting/legacy/types/endpoints/surveyQuestions"
import { SurveyResponses } from "@kiosk/reporting/legacy/types/endpoints/surveys"
import { LocalizedCompanySurveyQuestion } from "@kiosk/reporting/lib/services/company-survey-question.service.server"
import { QuestionsListTable } from "@kiosk/reporting/routes/sources_.edit_survey.$id/components/QuestionsListTable/QuestionsListTable"

type Props = {
  readonly survey: SurveyResponses.Survey
  readonly surveyQuestions: SurveyQuestionResponses.SurveyQuestion[]
  readonly getOnNavigate: (url: string) => () => void
  readonly companySurveyQuestions?: LocalizedCompanySurveyQuestion[]
  readonly rawDataPointsQuestions: CsrdCategory[]
}

export const QuestionsList = ({
  surveyQuestions,
  survey,
  getOnNavigate,
  companySurveyQuestions,
  rawDataPointsQuestions,
}: Props) => {
  const [opened, { open, close: onClose }] = useDisclosure(false)
  const { mutateAsync: deleteSurveyQuestion, isPending } =
    useDeleteSurveyQuestionMutation(survey.id)
  const [selectedIds, setSelectedIds] = useState<string[]>([])

  const { mutateAsync: createSurveyQuestion } = useCreateSurveyQuestionMutation(
    survey.id,
  )

  const handleCreateQuestion = (question: Question) => {
    const isCompanyQuestion =
      companySurveyQuestions?.some(
        (companySurveyQuestion) => companySurveyQuestion.id === question.id,
      ) ?? false

    createSurveyQuestion({
      ..._.omit(question, "id"),
      datoId: !isCompanyQuestion ? question.id : undefined,
      companySurveyQuestionId: isCompanyQuestion ? question.id : undefined,
      instructions: null,
    })
  }

  return (
    <>
      <QuestionsListTable
        companySurveyQuestions={companySurveyQuestions}
        deleteSurveyQuestion={deleteSurveyQuestion}
        getOnNavigate={getOnNavigate}
        isPending={isPending}
        open={open}
        rawDataPointsQuestions={rawDataPointsQuestions}
        selectedIds={selectedIds}
        setSelectedIds={setSelectedIds}
        survey={survey}
        surveyQuestions={surveyQuestions}
      />

      <AddQuestionsModal
        companySurveyQuestions={companySurveyQuestions}
        onClose={onClose}
        onSave={(questions: Question[]) =>
          questions.forEach(handleCreateQuestion)
        }
        opened={opened}
        rawDataPointsQuestions={rawDataPointsQuestions}
      />
    </>
  )
}
