import { Avatar, Button, FileButton, Group, Loader } from "@mantine/core"
import { ReactNode } from "react"
import { useTranslation } from "react-i18next"

import { useUploadPublicFileMutation } from "@kiosk/reporting/api/uploadFile"
import { useAppRouteContext } from "@kiosk/reporting/lib/hooks/use-app-context"

type Props = {
  readonly destination: string
  readonly placeholder: ReactNode
  readonly value?: string
  readonly onChange: (pictureURL?: string) => void
}

export const PictureUploader = ({
  destination,
  value,
  placeholder,
  onChange,
}: Props) => {
  const { t } = useTranslation()

  const { user } = useAppRouteContext()

  const { mutateAsync: uploadPicture, isPending: isUploading } =
    useUploadPublicFileMutation({
      destination,
      token: user.token,
      sendNotificationOnError: true,
      onSuccess: (uploadedPicture) => {
        onChange(uploadedPicture?.fileUrl)
      },
    })

  return (
    <FileButton
      accept="image/*"
      disabled={isUploading}
      onChange={(file) => uploadPicture(file)}
    >
      {(formProps) => (
        <Group>
          <Avatar
            bg={value ? "transparent" : "green.1"}
            color="green"
            radius="sm"
            size="xl"
            src={value}
            style={{ cursor: "pointer" }}
            {...formProps}
          >
            {isUploading ? <Loader /> : placeholder}
          </Avatar>

          <Button {...formProps}>{t("pictureUploader.upload")}</Button>

          <Button
            color="gray"
            onClick={() => {
              onChange("")
            }}
            variant="outline"
          >
            {t("pictureUploader.remove")}
          </Button>
        </Group>
      )}
    </FileButton>
  )
}
