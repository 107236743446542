import { json, useLoaderData } from "@remix-run/react"

import { PageLayout } from "@kiosk/reporting/components/layout/PageLayout"
import { serverConfig } from "@kiosk/reporting/config.server"
import { apiClient } from "@kiosk/reporting/lib/apiClient"
import SourcesList from "@kiosk/reporting/routes/sources/components/SourcesList"
import { DEMO_DATA } from "@kiosk/reporting/routes/sources/constants/demo-data"
import { requireToken } from "@kiosk/reporting/utils/auth.server"

import type { LoaderFunctionArgs } from "@remix-run/node"

export const handle = {
  title: () => "sourcesAll",
}

export const loader = async (args: LoaderFunctionArgs) => {
  if (serverConfig.isDemo) return DEMO_DATA

  const token = await requireToken(args)

  const response = await apiClient.get("/sources", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  return json(response.data)
}

export default function Sources() {
  const data = useLoaderData<typeof loader>()

  return (
    <PageLayout contained>
      <SourcesList sources={data} />
    </PageLayout>
  )
}
