import { Button, Group } from "@mantine/core"
import _ from "lodash"
import { useTranslation } from "react-i18next"

type Props = {
  readonly isLoading: boolean
  readonly onContinue?: () => void
  readonly disableContinue?: boolean
  readonly onSkipStep?: () => void
  readonly onSkipOnboarding?: () => void
}

export const Footer = ({
  isLoading,
  onContinue,
  disableContinue,
  onSkipStep,
  onSkipOnboarding,
}: Props) => {
  const { t } = useTranslation("common")

  const hasSkipButton = _.isFunction(onSkipStep)

  const hasSkipOnboardingButton = _.isFunction(onSkipOnboarding)

  return (
    <Group
      h={81}
      justify="center"
      px={24}
      py={16}
      style={{ borderTop: "solid 1px var(--mantine-color-gray-3)" }}
      w="100%"
    >
      <Group justify="flex-end" maw={830} w="100%">
        <Group>
          {hasSkipButton && (
            <Button color="green" onClick={onSkipStep} variant="subtle">
              {t("buttons.skip")}
            </Button>
          )}

          {hasSkipOnboardingButton && (
            <Button color="green" onClick={onSkipOnboarding} variant="subtle">
              {t("onboarding.skipOnboarding")}
            </Button>
          )}

          <Button
            disabled={disableContinue}
            h={42}
            loading={isLoading}
            onClick={onContinue}
          >
            {t("buttons.continue")}
          </Button>
        </Group>
      </Group>
    </Group>
  )
}
