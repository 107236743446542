import {
  ActionIcon,
  Box,
  Button,
  Drawer,
  Group,
  Stack,
  TextInput,
} from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import { IconPlus, IconTrash } from "@tabler/icons-react"
import { useTranslation } from "react-i18next"

import { useEditTypedDimensionMutation } from "@kiosk/reporting/api/dimensions/queries"
import { TypedDimension } from "@kiosk/reporting/api/dimensions/types"
import {
  dimensionOptionsSchema,
  EditDimensionForm,
} from "@kiosk/reporting/legacy/types/endpoints/dimensions"

type DrawerProps = {
  readonly dimension?: TypedDimension
  readonly onClose: () => void
}

type FormProps = DrawerProps & {
  readonly dimension: TypedDimension
}

const _EditDimensionDrawer = ({ dimension, onClose }: FormProps) => {
  const { t } = useTranslation("dimensions")

  const form = useForm<EditDimensionForm>({
    initialValues: {
      dimensions: dimension.options.length
        ? dimension.options.map((option) => ({
            id: option.id,
            label: option.label,
            isNew: false,
            isDeleted: false,
          }))
        : [{ isNew: true, label: "", isDeleted: false }],
    },
    validate: zodResolver(dimensionOptionsSchema),
  })

  const { mutateAsync: editDimension, isPending } =
    useEditTypedDimensionMutation(dimension.id, onClose)

  return (
    <Stack gap={32} h="100%">
      <Drawer.Header
        p={24}
        style={{ borderBottom: "1px solid var(--mantine-color-gray-3)" }}
      >
        <Drawer.Title c="black" fw={600} fz="lg">
          {dimension.label}
        </Drawer.Title>

        <Drawer.CloseButton />
      </Drawer.Header>

      <Stack h="100%" justify="space-between">
        <Drawer.Body px={24}>
          <form
            id="add-user"
            onSubmit={form.onSubmit((values) => editDimension(values))}
          >
            <Stack>
              {form.values.dimensions.map((dimension, index) => {
                if (dimension.isDeleted) return null

                return (
                  <Group
                    justify="space-between"
                    key={`dimension-${index}`}
                    style={{
                      borderBottom: "1px solid var(--mantine-color-gray-3)",
                    }}
                    w="100%"
                    wrap="nowrap"
                  >
                    <TextInput
                      placeholder={t("drawer.fields.labelPlaceholder")}
                      variant="unstyled"
                      w="90%"
                      {...form.getInputProps(`dimensions.${index}.label`)}
                    />

                    <ActionIcon
                      color="gray.5"
                      onClick={() => {
                        if (!dimension.isNew) {
                          form.setFieldValue(
                            `dimensions.${index}.isDeleted`,
                            true,
                          )
                        } else {
                          form.removeListItem("dimensions", index)
                        }
                      }}
                      size="lg"
                      variant="transparent"
                    >
                      <IconTrash size={20} />
                    </ActionIcon>
                  </Group>
                )
              })}

              <Box
                style={{
                  borderBottom: "1px solid var(--mantine-color-gray-3)",
                }}
              >
                <Button
                  color="gray.6"
                  leftSection={<IconPlus color="green" />}
                  miw={100}
                  ml={-20}
                  onClick={() =>
                    form.insertListItem("dimensions", {
                      label: "",
                      isNew: true,
                      isDeleted: false,
                    })
                  }
                  variant="transparent"
                >
                  {t("drawer.actions.newMember")}
                </Button>
              </Box>
            </Stack>
          </form>
        </Drawer.Body>

        <Group
          gap={10}
          justify="flex-end"
          p={24}
          style={{ borderTop: "1px solid var(--mantine-color-gray-3)" }}
        >
          <Button color="gray" onClick={onClose} variant="outline">
            {t("drawer.actions.cancel")}
          </Button>

          <Button
            disabled={!form.isValid()}
            form="add-user"
            loading={isPending}
            type="submit"
          >
            {t("drawer.actions.save")}
          </Button>
        </Group>
      </Stack>
    </Stack>
  )
}

export const EditDimensionDrawer = ({ dimension, onClose }: DrawerProps) => {
  return (
    <Drawer.Root
      onClose={onClose}
      opened={!!dimension}
      position="right"
      size="md"
    >
      <Drawer.Overlay />

      <Drawer.Content>
        {dimension && (
          <_EditDimensionDrawer dimension={dimension} onClose={onClose} />
        )}
      </Drawer.Content>
    </Drawer.Root>
  )
}
