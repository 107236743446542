import { useTranslation } from "react-i18next"

export const useLanguageOptions = () => {
  const { t } = useTranslation("settings")

  return [
    { value: "en", label: t("language.english") },
    { value: "fr", label: t("language.french") },
  ]
}
