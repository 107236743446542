import { notifications } from "@mantine/notifications"
import { useMutation } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"

import { surveysKeys } from "@kiosk/reporting/api/surveys/surveysKeys"
import { useUser } from "@kiosk/reporting/components/auth/UserContext"
import { apiClient } from "@kiosk/reporting/lib/apiClient"
import { authorizationHeader } from "@kiosk/reporting/lib/headers"
import { queryClient } from "@kiosk/reporting/lib/queryClient"

export const useDeleteSurveyQuestionMutation = (surveyId: string) => {
  const { t } = useTranslation()
  const { token } = useUser()

  return useMutation({
    mutationFn: async (id: string) => {
      const data = await apiClient.delete(`/survey_questions/${id}`, {
        headers: authorizationHeader(token),
      })

      return data?.data
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: surveysKeys.list(),
      })
      queryClient.invalidateQueries({
        queryKey: surveysKeys.get(surveyId),
        refetchType: "all",
      })
    },
    onError: ({ message }) => {
      queryClient.invalidateQueries({
        queryKey: surveysKeys.list(),
      })
      queryClient.invalidateQueries({
        queryKey: surveysKeys.get(surveyId),
        refetchType: "all",
      })
      notifications.show({
        title: t("messages.error"),
        message,
        color: "red",
      })
    },
  })
}
