import {
  Button,
  Checkbox,
  FileInput,
  Group,
  NumberInput,
  Select,
  Text,
} from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { useLocation } from "@remix-run/react"
import { IconDownload, IconPlus } from "@tabler/icons-react"
import _ from "lodash"
import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import { colors } from "@kiosk/reporting/assets/theme/colors"
import { Table } from "@kiosk/reporting/components/generic"
import { PageLayout } from "@kiosk/reporting/components/layout/PageLayout"
import { AddQuestionsModal } from "@kiosk/reporting/components/questions/AddQuestionsModal"
import { increment } from "@kiosk/reporting/legacy/shared/utils/helpers"
import { CsrdCategory, Question } from "@kiosk/reporting/legacy/types/data/dato"
import { RowAction } from "@kiosk/reporting/routes/sources/components/Tables/RowAction"
import { TablePageDescription } from "@kiosk/reporting/routes/sources/components/Tables/TablePageDescription"
import { TablePageNavigation } from "@kiosk/reporting/routes/sources/components/Tables/TablePageNavigation"
import { usePosition } from "@kiosk/reporting/utils/usePosition"

interface TableRecord {
  id: number
  indicator?: string
  geoZone?: string
  organization?: string
  timeFrame?: string
  response?: number
  file?: string
}

const TIME_FRAMES = ["2024", "2025", "2026"].flatMap((y) =>
  ["Q1", "Q2"].map((q) => [q, y].join(" ")),
)

const GEO_ZONES = ["France", "Germany", "Italy", "Spain"]

const ORGANIZATIONS = ["Marketing", "HR", "Tech"]

const selectColors = {
  geoZone: colors.purple[0],
  organization: colors.orange[0],
  timeFrame: colors.gray[0],
}

const BASE_DATA: TableRecord[] = [
  {
    id: 1,
    indicator: "Emissions Scope 3 (tCO2)",
    geoZone: "Montreal",
    organization: "Marketing",
    timeFrame: "Q1 2024",
    response: 42,
    file: undefined,
  },
  {
    id: 2,
    indicator: "Emissions Scope 3 (tCO2)",
    geoZone: "France",
    organization: "Marketing",
    timeFrame: "Q1 2024",
    response: 42,
    file: "foo.csv",
  },
  {
    id: 3,
    indicator: "Emissions Scope 3 (tCO2)",
    geoZone: "Germany",
    organization: "Marketing",
    timeFrame: "Q1 2024",
    response: 42,
    file: undefined,
  },
]

interface TablePageProps {
  readonly rawDataPointsQuestions: CsrdCategory[]
}

export const TablePage = (props: TablePageProps) => {
  const { t } = useTranslation("tables")
  const location = useLocation()
  const baseData = location.state as TableRecord[] | undefined

  const [data, setData] = useState(baseData ?? BASE_DATA)
  const [tableRef, tablePosition] = usePosition<HTMLTableElement>()
  const [selectedRows, setSelectedRows] = useState<number[]>([])

  useEffect(
    () => () => {
      setSelectedRows([])
    },
    [data],
  )

  const lastIndex = useMemo(() => _.max(data.map((d) => d.id)) ?? 0, [data])

  const addRecords = (rows: Array<Omit<TableRecord, "id">>) => {
    setData(
      _.sortBy(
        [
          ...data,
          ...rows.map((r, idx) => ({ ...r, id: increment(lastIndex + idx) })),
        ],
        (r) => r.id,
      ),
    )
  }

  const updateRecord = (record: TableRecord) =>
    setData(
      _.sortBy(
        [...data.filter((r) => r.id !== record.id), record],
        (r) => r.id,
      ),
    )

  const columns = [
    {
      key: "indicator",
      colId: "indicator",
      title: t("columns.indicator"),
      width: 250,
      render: (v: string, record: TableRecord) => (
        <Group wrap="nowrap">
          <Checkbox
            checked={selectedRows.includes(record.id)}
            onChange={() =>
              setSelectedRows(
                selectedRows.includes(record.id)
                  ? _.difference(selectedRows, [record.id])
                  : _.union(selectedRows, [record.id]),
              )
            }
          />

          <Text lineClamp={2}>{v}</Text>
        </Group>
      ),
    },
    {
      key: "geoZone",
      colId: "geoZone",
      title: t("columns.geoZone"),
      width: 200,
      render: (v: string, record: TableRecord) => (
        <Select
          bg={selectColors.geoZone}
          data={GEO_ZONES}
          onChange={(v) => updateRecord({ ...record, geoZone: v ?? undefined })}
          pl={8}
          placeholder={t("placeholder.select")}
          style={{ borderRadius: 6 }}
          value={v}
          variant="unstyled"
        />
      ),
    },
    {
      key: "organization",
      colId: "organization",
      title: t("columns.organization"),
      width: 200,
      render: (v: string, record: TableRecord) => (
        <Select
          bg={selectColors.organization}
          data={ORGANIZATIONS}
          onChange={(v) =>
            updateRecord({ ...record, organization: v ?? undefined })
          }
          pl={8}
          placeholder={t("placeholder.select")}
          style={{ borderRadius: 6 }}
          value={v}
          variant="unstyled"
        />
      ),
    },
    {
      key: "timeFrame",
      colId: "timeFrame",
      title: t("columns.timeFrame"),
      width: 200,
      render: (v: string, record: TableRecord) => (
        <Select
          bg={selectColors.timeFrame}
          data={TIME_FRAMES}
          onChange={(v) =>
            updateRecord({ ...record, timeFrame: v ?? undefined })
          }
          pl={8}
          placeholder={t("placeholder.select")}
          style={{ borderRadius: 6 }}
          value={v}
          variant="unstyled"
        />
      ),
    },
    {
      key: "response",
      colId: "response",
      title: t("columns.response"),
      width: 200,
      render: (v: string) => (
        <NumberInput
          hideControls={true}
          placeholder={t("placeholder.addResponse")}
          value={v}
          variant="unstyled"
        />
      ),
    },
    {
      key: "file",
      colId: "file",
      title: t("columns.file"),
      width: 200,
      render: (v: string, record: TableRecord) =>
        v ? (
          <Group gap={8} wrap="nowrap">
            <IconDownload color={colors.gray[6]} size={20} />

            <Text c={colors.gray[8]}>{v}</Text>
          </Group>
        ) : (
          <FileInput
            maw={150}
            multiple={false}
            onChange={(v) => updateRecord({ ...record, file: v!.name })}
            placeholder={t("placeholder.chooseFile")}
          />
        ),
    },
  ]

  const [opened, { open, close }] = useDisclosure(false)

  return (
    <PageLayout>
      <TablePageNavigation />

      <TablePageDescription />

      <Table
        columns={columns}
        dataSource={data}
        getRowKey={(r) => r.id}
        ref={tableRef}
        verticalSpacing={12}
        withColumnBorders={true}
      />

      <RowAction
        deleteRecords={(ids: number[]) => {
          setData(data.filter((d) => !ids.includes(d.id)))
        }}
        duplicateRecords={(ids: number[]) => {
          const newRecords = data.filter((d) => ids.includes(d.id))

          addRecords(newRecords)
        }}
        isPending={false}
        // FIXME
        position={{
          left: tablePosition.x,
          top: tablePosition.y,
        }}
        selectedIds={selectedRows}
      />

      <AddQuestionsModal
        onClose={close}
        onSave={(questions: Question[]) => {
          const records = questions.map((q) => ({
            indicator: q.label,
          }))

          addRecords(records)
        }}
        opened={opened}
        rawDataPointsQuestions={props.rawDataPointsQuestions}
      />

      <Button c="black" onClick={open} variant="transparent">
        <IconPlus color={colors.emerald[5]} />

        <Text c={colors.gray[5]} ml={6}>
          {t("actions.newRow")}
        </Text>
      </Button>

      <Group justify="right">
        <Button mt={20} onClick={_.noop} pl={40} pr={40} type="submit">
          {t("placeholder.save")}
        </Button>
      </Group>
    </PageLayout>
  )
}
