import { Stack } from "@mantine/core"
import { Dispatch, SetStateAction } from "react"

import { BooleanQuestion } from "@kiosk/reporting/components/csrd/questions/BooleanQuestion"
import { CardQuestion } from "@kiosk/reporting/components/csrd/questions/CardQuestion"
import { ChoiceQuestion } from "@kiosk/reporting/components/csrd/questions/ChoiceQuestion"
import { DateQuestion } from "@kiosk/reporting/components/csrd/questions/DateQuestion"
import { NumberQuestion } from "@kiosk/reporting/components/csrd/questions/NumberQuestion"
import { TextBlockQuestion } from "@kiosk/reporting/components/csrd/questions/TextBlockQuestion"
import { UploadQuestion } from "@kiosk/reporting/components/csrd/questions/UploadQuestion"
import { InputProps } from "@kiosk/reporting/components/csrd/questions/types"
import {
  CSRDAnswerType,
  CSRDQuestionAnswer,
} from "@kiosk/reporting/legacy/types/csrd"
import { Content } from "@kiosk/reporting/legacy/types/data/dato"
import { EnrichedDimensionBreakdown } from "@kiosk/reporting/utils/dimensions"

type CSRDQuestionContentProps = InputProps<Content> & {
  readonly datoQuestionId: string
  readonly breakdown?: EnrichedDimensionBreakdown
  readonly externalAnswers: Record<string, CSRDQuestionAnswer>
  readonly rerenderOnConditionalQuestion?: Dispatch<SetStateAction<number>>
}

type ValueType = boolean | string[] | string | number

export const CSRDQuestionContent = (props: CSRDQuestionContentProps) => {
  return (
    <Stack mt="8px">
      <QuestionContent {...props} />
    </Stack>
  )
}

const QuestionContent = ({
  datoQuestionId,
  content,
  breakdown,
  formProps,
  externalAnswers,
  rerenderOnConditionalQuestion,
}: CSRDQuestionContentProps) => {
  const type = content.__typename

  const handleChange = (value: ValueType) => {
    if (type === "TableRecord") return

    if (type === "ConditionalAnswerRecord") {
      rerenderOnConditionalQuestion?.((prev) => prev + 1)
    }

    const formattedValue =
      type === "DateRecord" && value instanceof Date
        ? value.toISOString()
        : value

    return formProps.onChange({
      datoQuestionId,
      answer: CSRDAnswerType.parse({
        type,
        value: formattedValue,
        unit: type === "NumberRecord" ? content.unit : "",
      }),
      source: "report",
      dimensions:
        breakdown?.map((breakdownItem) => ({
          dimensionId: breakdownItem.dimensionId,
          optionId: breakdownItem.optionId,
        })) ?? [],
    } satisfies CSRDQuestionAnswer)
  }

  const augmentedFormProps = {
    ...formProps,
    defaultValue: formProps.defaultValue?.answer.value,
    onChange: (value: ValueType) => handleChange(value),
  }

  switch (type) {
    case "BooleanRecord":
      return (
        <BooleanQuestion
          content={content}
          display="plain"
          formProps={augmentedFormProps}
        />
      )
    case "TextBlockRecord":
      return (
        <TextBlockQuestion content={content} formProps={augmentedFormProps} />
      )
    case "NumberRecord":
      return (
        <NumberQuestion
          content={content}
          externalAnswers={externalAnswers}
          formProps={augmentedFormProps}
        />
      )
    case "DateRecord":
      return <DateQuestion content={content} formProps={augmentedFormProps} />
    case "ChoiceRecord":
      return <ChoiceQuestion content={content} formProps={augmentedFormProps} />
    case "ConditionalAnswerRecord":
      return (
        <BooleanQuestion
          content={content.initialQuestion}
          display="plain"
          formProps={augmentedFormProps}
        />
      )
    case "CardRecord":
      return <CardQuestion id={datoQuestionId} />
    case "UploadRecord":
      return <UploadQuestion id={datoQuestionId} />
    case "TableRecord":
      return null
  }
}
