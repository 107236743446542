import { notifications } from "@mantine/notifications"
import { useMutation } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"

import { assignmentsKeys } from "@kiosk/reporting/api/surveys/assignments/assignmentsKeys"
import { useUser } from "@kiosk/reporting/components/auth/UserContext"
import { apiClient } from "@kiosk/reporting/lib/apiClient"
import { authorizationHeader } from "@kiosk/reporting/lib/headers"
import { queryClient } from "@kiosk/reporting/lib/queryClient"

export const useClearAssignmentsMutation = (
  questionId: string,
  invalidateCache: boolean = true,
) => {
  const { token } = useUser()
  const { t } = useTranslation()

  return useMutation({
    mutationFn: async (): Promise<void> => {
      const response = await apiClient.delete(
        `/survey_questions/${questionId}/assignments`,
        { headers: authorizationHeader(token) },
      )

      return response.data
    },
    onSuccess: async () => {
      if (invalidateCache)
        await queryClient.invalidateQueries({
          queryKey: assignmentsKeys.list(questionId),
        })
    },
    onError: ({ message }) => {
      notifications.show({
        title: t("common:messages.error"),
        message,
        color: "red",
      })
    },
  })
}
