import { Button, Flex, Space, TextInput } from "@mantine/core"
import { useDebouncedState } from "@mantine/hooks"
import { useNavigate } from "@remix-run/react"
import { IconPlus, IconSearch } from "@tabler/icons-react"
import dayjs from "dayjs"
import { SetStateAction, useCallback } from "react"
import { useTranslation } from "react-i18next"

import { useCreateSurveyMutation } from "@kiosk/reporting/api/surveys/createSurvey"
import { useListSurveysQuery } from "@kiosk/reporting/api/surveys/listSurveys"
import { PageLayout } from "@kiosk/reporting/components/layout/PageLayout"
import { QueryWrapper } from "@kiosk/reporting/components/layout/QueryWrapper"
import { i18n } from "@kiosk/reporting/legacy/i18n"
import { SurveyCreation } from "@kiosk/reporting/legacy/shared/domain/entities/survey"
import { EmptySurvey } from "@kiosk/reporting/routes/sources_.surveys/components/EmptySurvey"
import { SurveysList } from "@kiosk/reporting/routes/sources_.surveys/components/SurveysList"
import { routes } from "@kiosk/reporting/utils/constants"

export const handle = {
  title: () => "surveys",
}

const getDefaultSurvey = (): SurveyCreation => {
  const tomorrow = dayjs(new Date()).add(1, "day").toDate()

  tomorrow.setUTCHours(0, 0, 0, 0)

  return {
    title: i18n.t("survey:placeholders.surveyTitle"),
    launchDate: tomorrow,
    followUpDate: dayjs(tomorrow).add(1, "day").toDate(),
    dueDate: dayjs(tomorrow).add(2, "days").toDate(),
  }
}

const ActionsRow = ({
  setSearch,
}: {
  readonly setSearch: (newValue: SetStateAction<string>) => void
}) => {
  const { t } = useTranslation("survey")

  const navigate = useNavigate()

  const { mutateAsync: createSurvey, isPending } = useCreateSurveyMutation()

  const onNewSurvey = useCallback(async () => {
    const survey = await createSurvey(getDefaultSurvey())

    navigate(routes.EDIT_SURVEY.path.replace(":id", survey.id))
  }, [navigate, createSurvey])

  return (
    <Flex justify="space-between">
      <TextInput
        disabled={isPending}
        leftSection={<IconSearch size={20} />}
        onChange={(e) => setSearch(e.target.value)}
        placeholder={t("placeholders.findSurvey")}
      />

      <Button
        leftSection={<IconPlus size={20} />}
        loading={isPending}
        onClick={onNewSurvey}
      >
        {t("actions.newSurvey")}
      </Button>
    </Flex>
  )
}

export default function Surveys() {
  const [search, setSearch] = useDebouncedState("", 500)
  const listSurveysQuery = useListSurveysQuery()

  return (
    <PageLayout contained>
      <ActionsRow setSearch={setSearch} />
      <Space h="xl" />
      <QueryWrapper
        emptyPlaceholder={EmptySurvey}
        fluid
        query={listSurveysQuery}
      >
        {({ data: surveys }) => (
          <SurveysList search={search} surveys={surveys} />
        )}
      </QueryWrapper>
    </PageLayout>
  )
}
