import { LoaderFunctionArgs } from "@remix-run/node"
import { useParams } from "react-router-dom"
import { typedjson, useTypedLoaderData } from "remix-typedjson"

import { useGetSurveyQuery } from "@kiosk/reporting/api/surveys/getSurvey"
import { PageLayout } from "@kiosk/reporting/components/layout/PageLayout"
import { QueryWrapper } from "@kiosk/reporting/components/layout/QueryWrapper"
import { serverConfig } from "@kiosk/reporting/config.server"
import { CategoryAggregateDatoRepository } from "@kiosk/reporting/features/dato/infrastructure/repositories/category-aggregates"
import { Locale } from "@kiosk/reporting/i18n"
import { CompanySurveyQuestionRepository } from "@kiosk/reporting/lib/repositories/company-survey-question.server"
import { createCompanySurveyQuestionService } from "@kiosk/reporting/lib/services/company-survey-question.service.server"
import EditSurveyForm from "@kiosk/reporting/routes/sources_.edit_survey.$id/components/EditSurveyForm"
import { requireUser } from "@kiosk/reporting/utils/auth.server"

const companySurveyQuestionService = createCompanySurveyQuestionService(
  CompanySurveyQuestionRepository,
)

export const handle = {
  title: () => "editSurvey",
}

// const fetchSurvey = async (token: string, id: string) => {
//   const surveyPromise = apiClient.get<SurveyResponses.Survey>(
//     `/surveys/${id}`,
//     { headers: authorizationHeader(token) },
//   )
//   const surveyQuestionsPromise = apiClient.get<
//     SurveyQuestionResponses.SurveyQuestion[]
//   >(`/surveys/${id}/questions`, { headers: authorizationHeader(token) })
//   const [surveyResponse, surveyQuestionsResponse] = await Promise.all([
//     surveyPromise,
//     surveyQuestionsPromise,
//   ])

//   return {
//     survey: await getSurveySchema.parseAsync(surveyResponse.data),
//     surveyQuestions: await listSurveyQuestionsSchema.parseAsync(
//       surveyQuestionsResponse.data,
//     ),
//   }
// }

export const loader = async (args: LoaderFunctionArgs) => {
  const { locale, companyId, token } = await requireUser(args)

  const companySurveyQuestions = serverConfig.hasCompanyQuestions
    ? await companySurveyQuestionService.getLocalizedCompanySurveyQuestions(
        companyId,
        locale as Locale,
      )
    : undefined

  const rawDataPointsQuestions =
    await CategoryAggregateDatoRepository.fetchRawDataPoints(
      token,
      locale as Locale,
    )

  // TODO : Use this when removing React Query
  // const survey = await fetchSurvey(token, args.params.id!)

  return typedjson({ companySurveyQuestions, rawDataPointsQuestions })
}

export default function EditSurvey() {
  const { id } = useParams() as { id: string }

  const { companySurveyQuestions, rawDataPointsQuestions } =
    useTypedLoaderData<typeof loader>()

  const getSurveyQuery = useGetSurveyQuery(id)

  return (
    <PageLayout>
      <QueryWrapper query={getSurveyQuery}>
        {({ data }) => (
          <EditSurveyForm
            companySurveyQuestions={companySurveyQuestions}
            rawDataPointsQuestions={rawDataPointsQuestions}
            {...data}
          />
        )}
      </QueryWrapper>
    </PageLayout>
  )
}
