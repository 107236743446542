import { Button, Select, Stack, Text } from "@mantine/core"
import { Form } from "@remix-run/react"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocale } from "remix-i18next/react"

import { PageLayout } from "@kiosk/reporting/components/layout/PageLayout"
import { useLanguageOptions } from "@kiosk/reporting/constants/settings/languageOption"
import { Locale } from "@kiosk/reporting/i18n"

export const Language = () => {
  const { t } = useTranslation(["common", "settings"])
  const locale = useLocale()

  const [selectedLanguage, setSelectedLanguage] = useState<Locale>(
    locale as Locale,
  )

  const languageOptions = useLanguageOptions()

  return (
    <PageLayout>
      <Stack align="center">
        <Form id="language" method="post">
          <Stack gap={21} w={236}>
            <Text>{t("settings:language.chooseLanguage")}</Text>
            <Select
              allowDeselect={false}
              data={languageOptions}
              defaultValue="en"
              label={t("common:routes.language")}
              onChange={(value) =>
                setSelectedLanguage((value as Locale) ?? "en")
              }
              value={selectedLanguage}
            />
            <input name="language" type="hidden" value={selectedLanguage} />
            <Button type="submit">{t("common:buttons.save")}</Button>
          </Stack>
        </Form>
      </Stack>
    </PageLayout>
  )
}
