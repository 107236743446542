import { notifications } from "@mantine/notifications"
import { useMutation } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"

import { useUser } from "@kiosk/reporting/components/auth/UserContext"
import { OnboardingRequests } from "@kiosk/reporting/legacy/types/endpoints/onboarding"
import { apiClient } from "@kiosk/reporting/lib/apiClient"
import { authorizationHeader } from "@kiosk/reporting/lib/headers"

export const useCreateGeoDimensionMutation = (onSuccess: () => void) => {
  const { t } = useTranslation()
  const { token } = useUser()

  return useMutation({
    mutationFn: async (data: OnboardingRequests.GeoDimension[]) => {
      const response = await apiClient.post(
        "/onboarding/geo-dimensions",
        data,
        { headers: authorizationHeader(token) },
      )

      return response.data
    },
    onSuccess,
    onError: (error: Error) => {
      //TODO: Handle errors
      notifications.show({
        title: t("messages.error"),
        message: error.message,
        color: "red",
      })
    },
  })
}
