import { useQuery } from "@tanstack/react-query"

import { companyKeys } from "@kiosk/reporting/api/companies/companyKeys"
import { useUser } from "@kiosk/reporting/components/auth/UserContext"
import { CompanyResponses } from "@kiosk/reporting/legacy/types/endpoints/companies"
import { apiClient } from "@kiosk/reporting/lib/apiClient"
import { authorizationHeader } from "@kiosk/reporting/lib/headers"

export const getCompanies = async (
  token: string,
): Promise<CompanyResponses.GetCompanies> => {
  const response = await apiClient.get<CompanyResponses.GetCompanies>(
    "/companies",
    { headers: authorizationHeader(token) },
  )

  return response.data
}

export const useCompaniesQuery = () => {
  const { token } = useUser()

  return useQuery({
    queryKey: companyKeys.all(),
    queryFn: () => getCompanies(token),
    refetchOnMount: true,
  })
}
