import { MaterialitySwitch } from "@kiosk/reporting/components/csrd/MaterialSwitch"
import { PlainBooleanChoice } from "@kiosk/reporting/components/csrd/PlainBooleanChoice"
import { InputProps } from "@kiosk/reporting/components/csrd/questions/types"
import { BooleanRecord } from "@kiosk/reporting/legacy/types/data/dato"

type DisplayType = "switch" | "plain"

interface BooleanQuestionInputProps extends InputProps<BooleanRecord> {
  readonly display: DisplayType
}

export const BooleanQuestion = ({
  content,
  formProps,
  display,
}: BooleanQuestionInputProps) => {
  const { yesLabel = "Yes" } = content
  const isOn = yesLabel === "Yes"

  if (display === "plain") {
    return <PlainBooleanChoice content={content} formProps={formProps} />
  }

  return (
    <MaterialitySwitch onClick={() => formProps.onChange(!isOn)} value={isOn} />
  )
}
