import { useMemo } from "react"

import { useSurveyQuestionsDataModel } from "@kiosk/reporting/api/surveys/questions/model/surveyQuestionsDataModel"
import { Option } from "@kiosk/reporting/legacy/shared/utils/helpers"
import { CsrdCategory, Question } from "@kiosk/reporting/legacy/types/data/dato"
import { LocalizedCompanySurveyQuestion } from "@kiosk/reporting/lib/services/company-survey-question.service.server"
import { withCompanySurveyQuestion } from "@kiosk/reporting/utils/withCompanySurveyQuestion"

// This means that each question is merging questions from three sources
// to get its label. This is obviously broken.
export const useQuestionLabel = (
  questionId: string,
  rawDataPointsQuestions: CsrdCategory[],
  companySurveyQuestions?: LocalizedCompanySurveyQuestion[],
): Option<string> => {
  const { data: csrdCategories } = useSurveyQuestionsDataModel()

  const csrdCategoriesWithCompanyQuestions = companySurveyQuestions
    ? withCompanySurveyQuestion(csrdCategories ?? [], companySurveyQuestions)
    : csrdCategories

  return useMemo(() => {
    if (!csrdCategoriesWithCompanyQuestions) return undefined

    const allQuestions: Array<Partial<Question>> = [
      ...csrdCategoriesWithCompanyQuestions.flatMap(({ topics }) =>
        topics.flatMap(({ disclosureRequirements }) =>
          disclosureRequirements.flatMap(({ questions }) => questions),
        ),
      ),
      ...(companySurveyQuestions ?? []),
      ...rawDataPointsQuestions.flatMap((category) =>
        category.topics.flatMap((topic) =>
          topic.disclosureRequirements.flatMap(
            (disclosureRequirement) => disclosureRequirement.questions,
          ),
        ),
      ),
    ]

    const question = allQuestions.find(({ id }) => id === questionId)

    return question?.label
  }, [
    csrdCategoriesWithCompanyQuestions,
    questionId,
    companySurveyQuestions,
    rawDataPointsQuestions,
  ])
}
