import {
  Button,
  Container,
  Flex,
  Group,
  Loader,
  Stack,
  Text,
  Textarea,
} from "@mantine/core"
import { useLoaderData } from "@remix-run/react"
import _ from "lodash"
import {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"

import { useListDimensionsQuery } from "@kiosk/reporting/api/dimensions/queries"
import { useBulkCreateAssignmentsMutation } from "@kiosk/reporting/api/surveys/assignments/bulkCreateAssignments"
import { useClearAssignmentsMutation } from "@kiosk/reporting/api/surveys/assignments/clearAssignments"
import { useListAssignmentsQuery } from "@kiosk/reporting/api/surveys/assignments/listAssignments"
import { useGetSurveyQuestionQuery } from "@kiosk/reporting/api/surveys/questions/getSurveyQuestion"
import { useSurveyQuestionsDataModel } from "@kiosk/reporting/api/surveys/questions/model/surveyQuestionsDataModel"
import { findQuestionById } from "@kiosk/reporting/api/surveys/questions/model/utils"
import { useUpdateSurveyQuestionMutation } from "@kiosk/reporting/api/surveys/questions/updateSurveyQuestion"
import { BackButton } from "@kiosk/reporting/components/BackButton"
import { DimensionsBreakdownSelect } from "@kiosk/reporting/components/dimensions/DimensionsBreakdownSelect"
import { PageLayout } from "@kiosk/reporting/components/layout/PageLayout"
import { MultiQueryWrapper } from "@kiosk/reporting/components/layout/QueryWrapper"
import { mergeCategories } from "@kiosk/reporting/components/questions/AddQuestionsModal"
import { CreateAssignmentBody } from "@kiosk/reporting/legacy/shared/schemas/assignments"
import { CsrdCategory } from "@kiosk/reporting/legacy/types/data/dato"
import { DimensionBreakdownValue } from "@kiosk/reporting/legacy/types/dimensions"
import { AssignmentResponses } from "@kiosk/reporting/legacy/types/endpoints/assignments"
import { SurveyQuestionResponses } from "@kiosk/reporting/legacy/types/endpoints/surveyQuestions"
import { ConsolidatedDimension } from "@kiosk/reporting/legacy/types/utils/dimensions"
import { LocalizedCompanySurveyQuestion } from "@kiosk/reporting/lib/services/company-survey-question.service.server"
import { Assignments } from "@kiosk/reporting/routes/sources_.edit_question.$id/components/Assignments"
import { loader } from "@kiosk/reporting/routes/sources_.edit_question.$id/route"
import { EnrichedDimensionBreakdown } from "@kiosk/reporting/utils/dimensions"
import { useQuestionLabel } from "@kiosk/reporting/utils/useQuestionLabel"
import { withCompanySurveyQuestion } from "@kiosk/reporting/utils/withCompanySurveyQuestion"

export type Assignee = {
  id: string
  email: string
  fullName: string
  locale: string
}

const buildAssignment = ({
  assignments,
  breakdown,
  dimensionId,
}: {
  assignments: AssignmentResponses.Assignment[]
  breakdown?: EnrichedDimensionBreakdown
  dimensionId?: string
}): CreateAssignmentBody => {
  const assignment = assignments.find((assignment) => {
    return assignment.assignmentDimensions.every((assignmentDimension) => {
      return breakdown?.find((breakdownItem) => {
        breakdownItem.dimensionId === assignmentDimension.dimensionDatoId &&
          breakdownItem.optionId === assignmentDimension.optionId
      })
    })
  })

  const assigneeId = assignment?.assigneeId ?? null

  return {
    assigneeId,
    assignmentDimensions: breakdown
      ? breakdown?.map((breakdownItem) => ({
          dimensionType: breakdownItem.dimensionType,
          dimensionDatoId: breakdownItem.dimensionId,
          optionId: breakdownItem.optionId,
        }))
      : [
          {
            dimensionType: "explicit",
            dimensionDatoId: dimensionId || "",
            optionId: "N/A",
          },
        ],
  }
}

const extractBreakdownsFromAssignments = (
  assignments: AssignmentResponses.Assignment[],
): DimensionBreakdownValue[] => {
  return _.uniqBy(
    assignments.flatMap((assignment) => {
      return assignment.assignmentDimensions.map((assignmentDimension) => {
        return {
          dimensionId: assignmentDimension.dimensionDatoId,
          optionId: assignmentDimension.optionId,
        }
      })
    }),
    (breakdown) => `${breakdown.dimensionId}-${breakdown.optionId}`,
  )
}

const hashAssignment = (assignment: CreateAssignmentBody) =>
  _.orderBy(assignment.assignmentDimensions.map((d) => d.optionId)).join("-")

type Props = {
  readonly surveyQuestion: SurveyQuestionResponses.SurveyQuestion
  readonly assignments: AssignmentResponses.Assignment[]
  readonly allDimensions: ConsolidatedDimension[]
  readonly csrdCategories: CsrdCategory[]
  readonly rawDataPointsQuestions: CsrdCategory[]
  readonly companySurveyQuestions: LocalizedCompanySurveyQuestion[]
}

const EditQuestionContent = ({
  surveyQuestion,
  assignments,
  allDimensions,
  csrdCategories,
  rawDataPointsQuestions,
  companySurveyQuestions,
}: Props) => {
  const navigate = useNavigate()
  const { t } = useTranslation("survey")
  const { companyDimensions } = useLoaderData<typeof loader>()

  const { mutateAsync: bulkCreateAssignments } =
    useBulkCreateAssignmentsMutation(surveyQuestion.id, surveyQuestion.surveyId)
  const { mutateAsync: clearAssignments, isPending } =
    useClearAssignmentsMutation(surveyQuestion.id, false)

  const { mutateAsync: updateSurveyQuestion } = useUpdateSurveyQuestionMutation(
    surveyQuestion.id,
  )

  const [isDirty, setIsDirty] = useState(false)
  const [instructions, setInstructions] = useState(
    surveyQuestion.instructions || "",
  )

  const initialBreakdowns = useMemo(
    () => extractBreakdownsFromAssignments(assignments),
    [assignments],
  )

  const [currentAssignments, _setCurrentAssignments] = useState<
    CreateAssignmentBody[]
  >(_.orderBy(assignments, hashAssignment))
  const setCurrentAssignments = useCallback(
    (value: SetStateAction<CreateAssignmentBody[]>) => {
      setIsDirty(true)
      _setCurrentAssignments(value)
    },
    [],
  )

  const datoQuestion = useMemo(
    () =>
      findQuestionById(
        withCompanySurveyQuestion(csrdCategories, companySurveyQuestions),
        surveyQuestion.datoId ?? surveyQuestion.companySurveyQuestionId,
      ),
    [
      csrdCategories,
      companySurveyQuestions,
      surveyQuestion.datoId,
      surveyQuestion.companySurveyQuestionId,
    ],
  )

  const commitBreakdowns = (
    breakdowns: EnrichedDimensionBreakdown[] | undefined,
    dimensionId?: string,
  ) => {
    const formattedAssignments = breakdowns?.map((breakdown) =>
      buildAssignment({
        assignments,
        breakdown,
      }),
    )

    const defaultAssignments = buildAssignment({
      assignments,
      dimensionId,
    })

    if (breakdowns) {
      setCurrentAssignments(_.orderBy(formattedAssignments, hashAssignment))
    } else {
      setCurrentAssignments(_.orderBy([defaultAssignments], hashAssignment))
    }
  }

  useEffect(() => {
    const firstDimensionId = datoQuestion.dimensionIds[0]

    if (assignments.length === 0) {
      if (datoQuestion.dimensionIds.length === 0) {
        setCurrentAssignments([
          {
            assignmentDimensions: [],
          },
        ])
      } else {
        setCurrentAssignments([
          {
            assignmentDimensions: [
              {
                dimensionType: "explicit",
                dimensionDatoId: firstDimensionId || "",
                optionId: "N/A",
              },
            ],
          },
        ])
      }
    }
  }, [assignments.length, datoQuestion.dimensionIds, setCurrentAssignments])

  if (!datoQuestion) {
    throw new Error(
      `Could not find question id in Dato model: ${surveyQuestion.datoId}`,
    )
  }

  const handleSave = async () => {
    if (isDirty) {
      await clearAssignments()
      await bulkCreateAssignments(currentAssignments)
      await updateSurveyQuestion({
        datoId: surveyQuestion.datoId,
        label: surveyQuestion.label,
        unit: surveyQuestion.unit,
        numberType: surveyQuestion.numberType,
        instructions,
      })
    }
    navigate(`/sources/edit_survey/${surveyQuestion.surveyId}`)
  }

  const label = useQuestionLabel(
    surveyQuestion.datoId ?? surveyQuestion.companySurveyQuestionId!,
    rawDataPointsQuestions,
  )

  return (
    <Stack gap={30}>
      <Flex align="center" gap={16} justify="space-between">
        <BackButton
          onClick={async () => {
            navigate(`/sources/edit_survey/${surveyQuestion.surveyId}`)
          }}
        />
        <Text flex={1} fw={600} size="xl" truncate="end" variant="unstyled">
          {label}
        </Text>
        <Button
          loading={isPending}
          onClick={handleSave}
          style={{ flexShrink: 0 }}
        >
          {t("saveAndBackToList")}
        </Button>
      </Flex>
      <Group align="start" gap={16}>
        {(datoQuestion.dimensionIds.length > 0 ||
          companyDimensions?.length > 0) && (
          <Stack style={{ maxWidth: 400 }}>
            <DimensionsBreakdownSelect
              dimensionIds={datoQuestion.dimensionIds}
              initialBreakdowns={initialBreakdowns}
              onGenerateBreakdowns={(breakdowns) =>
                commitBreakdowns(breakdowns, datoQuestion.dimensionIds[0])
              }
              showDimensionsBreakdown
            />
          </Stack>
        )}
        <Stack flex={1}>
          <Textarea
            label={t("instructions.label")}
            onChange={(e) => {
              setIsDirty(true)
              setInstructions(e.target.value)
            }}
            placeholder={t("instructions.placeholders")}
            rows={3}
            value={instructions}
          />
          <Assignments
            allDimensions={[...allDimensions, ...companyDimensions]}
            currentAssignments={currentAssignments}
            setCurrentAssignments={setCurrentAssignments}
          />
        </Stack>
      </Group>
    </Stack>
  )
}

interface EditQuestionProps {
  readonly rawDataPointsQuestions: CsrdCategory[]
  readonly companySurveyQuestions: LocalizedCompanySurveyQuestion[]
}

export const EditQuestion = ({
  companySurveyQuestions,
  rawDataPointsQuestions,
}: EditQuestionProps) => {
  const { id } = useParams() as { id: string }
  const getSurveyQuestionQuery = useGetSurveyQuestionQuery(id)
  const { data: assignments } = useListAssignmentsQuery(id)
  const { data: allDimensions } = useListDimensionsQuery()
  const csrdCategoriesQuery = useSurveyQuestionsDataModel()

  // TODO: check if we need dimensions here
  // TODO: better loading
  if (!assignments || !allDimensions)
    return (
      <Container mt="5%">
        <Stack align="center">
          <Loader />
        </Stack>
      </Container>
    )

  return (
    <PageLayout>
      <MultiQueryWrapper
        queries={{
          surveyQuestion: { query: getSurveyQuestionQuery },
          csrdCategories: { query: csrdCategoriesQuery },
        }}
      >
        {({ surveyQuestion, csrdCategories }) => {
          const merged = mergeCategories(csrdCategories, rawDataPointsQuestions)

          return (
            <EditQuestionContent
              allDimensions={allDimensions}
              assignments={assignments}
              companySurveyQuestions={companySurveyQuestions}
              csrdCategories={merged}
              rawDataPointsQuestions={rawDataPointsQuestions}
              surveyQuestion={surveyQuestion}
            />
          )
        }}
      </MultiQueryWrapper>
    </PageLayout>
  )
}
