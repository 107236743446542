// TODO: merge this form with CSRDForm

import {
  Box,
  Flex,
  Group,
  Space,
  Stack,
  Switch,
  Text,
  Textarea,
} from "@mantine/core"
import { useForm } from "@mantine/form"
import { DisclosureRequirementParagraph } from "@prisma/client"
import _ from "lodash"
import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import { useAIGenerateDisclosureRequirementParagraphMutation } from "@kiosk/reporting/api/dashboard/mutations"
import { useDisclosureRequirementParagraphByDisclosureRequirementId } from "@kiosk/reporting/api/dashboard/queries"
import {
  useDatoCategoriesTopicsNames,
  useDatoQuery,
} from "@kiosk/reporting/api/datocms/datocms"
import {
  useDisclosureRequirementAnswersQuery,
  useMarkDisclosureRequirementAsDoneMutation,
  useUnmarkDisclosureRequirementAsDoneMutation,
  useUpsertDisclosureRequirementAnswersMutation,
} from "@kiosk/reporting/api/reports"
import { reportsKeys } from "@kiosk/reporting/api/reports/reportsKey"
import { Callout } from "@kiosk/reporting/components/Callout"
import { DatoStructuredText } from "@kiosk/reporting/components/DatoStructuredText"
import { useUser } from "@kiosk/reporting/components/auth/UserContext"
import { DisclosureRequirementPreview } from "@kiosk/reporting/components/csrd/DisclosureRequirementPreview/DisclosureRequirementPreview"
import { DisclosureRequirementTab } from "@kiosk/reporting/components/csrd/DisclosureRequirementTab"
import { EsrsBadge } from "@kiosk/reporting/components/csrd/EsrsBadge"
import { MaterialitySwitch } from "@kiosk/reporting/components/csrd/MaterialSwitch"
import { SaveFooter } from "@kiosk/reporting/components/csrd/SaveFooter/SaveFooter"
import { MultiQueryWrapper } from "@kiosk/reporting/components/layout/QueryWrapper"
import config, {
  findEsrsById,
  findEsrsByIro2Label,
} from "@kiosk/reporting/features/iro2/config"
import { CSRD_DISCLOSURE_REQUIREMENT_BY_ID } from "@kiosk/reporting/legacy/shared/datocms/queries"
import { isDefined } from "@kiosk/reporting/legacy/shared/utils/helpers"
import { CSRDQuestionAnswer } from "@kiosk/reporting/legacy/types/csrd"
import {
  DatoDisclosureRequirementNamesResponse as DatoCategoriesTopicsNamesResponse,
  DatoDisclosureRequirementResponse,
  DisclosureRequirementDetails,
} from "@kiosk/reporting/legacy/types/data/dato"
import { queryClient } from "@kiosk/reporting/lib/queryClient"
import { findQuestionInDR } from "@kiosk/reporting/utils/tree"

interface Section {
  name: string
  subTopics: {
    esrs: string
    name: string
    datoId: string
  }[]
}

interface IRO2PageProps {
  readonly initialValues: Record<string, CSRDQuestionAnswer>
  readonly disclosureRequirement: DisclosureRequirementDetails
  readonly categoriesTopicsNames: DatoCategoriesTopicsNamesResponse
  readonly disclosureRequirementParagraph: DisclosureRequirementParagraph
  readonly progress?: number
}

/**
 * IRO-2 is a special page.
 * In it, users will say which ESRS are material to them.
 *
 * In IRO-2, companies can specify which ESRS are not material to their business.
 * For that, we need to map the enumeration to the matching ESRS.
 *
 * Note that the page is currently strictly in English and is not updated from Dato
 * like the other ones.
 */
function IRO2Page(props: IRO2PageProps) {
  const { t } = useTranslation("csrd")

  const nonMaterialTopicsQuestionId =
    config.disclosureRequirement.nonMaterialTopicsQuestionId
  const initialNonMaterialTopics = (props.initialValues[
    nonMaterialTopicsQuestionId
  ]?.answer.value ?? []) as string[]

  // Says whether an ESRS (identified by its value in the IRO-2 question enum)
  // is material or not.
  const initialMaterialityState: _.Dictionary<boolean> = Object.fromEntries(
    config.esrs.map((esrs) => [
      esrs.iro2EnumLabel,
      !initialNonMaterialTopics.includes(esrs.iro2EnumLabel),
    ]),
  )

  const form = useForm({
    initialValues: { ...props.initialValues, ...initialMaterialityState },
  })

  const setValues = form.setValues

  // Update the form when the props change
  useEffect(() => {
    const newInitialNonMaterialTopics = (props.initialValues[
      nonMaterialTopicsQuestionId
    ]?.answer.value ?? []) as string[]

    const newMaterialityState = Object.fromEntries(
      config.esrs.map((esrs) => [
        esrs.iro2EnumLabel,
        !newInitialNonMaterialTopics.includes(esrs.iro2EnumLabel),
      ]),
    )

    setValues({ ...props.initialValues, ...newMaterialityState })
    form.resetDirty()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.initialValues, nonMaterialTopicsQuestionId, setValues])

  const materialityState = useMemo(
    () =>
      Object.fromEntries(
        config.esrs.map((esrs) => [
          esrs.iro2EnumLabel,
          form.getValues()[esrs.iro2EnumLabel],
        ]),
      ),
    [form],
  )
  const nonMaterialTopics = Object.entries(materialityState)
    .filter(([_esrs, isMaterial]) => !isMaterial)
    .map(([esrs, _isMaterial]) => esrs)

  const { mutateAsync: markAsDone, isPending: isMarkAsDonePending } =
    useMarkDisclosureRequirementAsDoneMutation()

  const { mutateAsync: unmarkAsDone } =
    useUnmarkDisclosureRequirementAsDoneMutation()

  const {
    mutateAsync: upsertDisclosureRequirements,
    isPending: isUpsertPending,
  } = useUpsertDisclosureRequirementAnswersMutation(
    config.disclosureRequirement.datoId,
  )

  const sections: Section[] = _(config.esrs)
    .groupBy((esrs) => esrs.category)
    .entries()
    .map(([categoryId, esrsList]) => {
      const category = props.categoriesTopicsNames.allCsrdCategories.find(
        (cat) => cat.id === categoryId,
      )!

      return {
        name: category.name,
        subTopics: esrsList.map((esrs) => ({
          esrs: esrs.code,
          name: category.topics.find((topic) => topic.id === esrs.datoId)!.name,
          datoId: esrs.datoId,
        })),
      }
    })
    .value()

  const materialityAssessmentQuestion = findQuestionInDR(
    props.disclosureRequirement,
    config.disclosureRequirement.materialityAssessmentQuestionId,
  )!

  const [tab, setTab] = useState(t("preview.tabForm"))
  const [showXml, setShowXml] = useState(false)

  const [
    disclosureRequirementParagraphCurrentlyGenerated,
    setDisclosureRequirementParagraphCurrentlyGenerated,
  ] = useState<DisclosureRequirementParagraph | undefined>(undefined)

  const { mutateAsync: aiGenerateParagraph, isPending: isPendingParagraph } =
    useAIGenerateDisclosureRequirementParagraphMutation({
      onSuccess: (data: DisclosureRequirementParagraph) => {
        setTab(t("preview.tabPreview"))
        setDisclosureRequirementParagraphCurrentlyGenerated(data)
      },
    })

  return (
    <Stack flex={1} pos="relative">
      <Stack
        align="stretch"
        bg="gray.0"
        justify="center"
        p={24}
        pos="sticky"
        style={{ zIndex: 1 }}
        top={0}
      >
        <Flex align="center" gap={16} wrap="nowrap">
          <Group wrap="nowrap">
            <EsrsBadge topic="2-IRO-2" variant="secondary" />
            <Box>
              <Text c="gray.9" fw={600} fz="md" lineClamp={2}>
                {props.disclosureRequirement.name}
              </Text>
            </Box>
          </Group>

          {tab === t("preview.tabPreview") && (
            <Switch
              checked={showXml}
              label={t("preview.showXbrl")}
              onChange={() => setShowXml(!showXml)}
            />
          )}

          {(props.disclosureRequirementParagraph ||
            disclosureRequirementParagraphCurrentlyGenerated) && (
            <Flex direction="row" flex={1} justify="flex-end">
              <DisclosureRequirementTab
                onChange={(selectedTab) => setTab(selectedTab)}
                value={tab}
              />
            </Flex>
          )}
        </Flex>
      </Stack>

      {props.disclosureRequirement.instructions &&
      tab === t("preview.tabForm") ? (
        <DatoStructuredText
          content={props.disclosureRequirement.instructions}
        />
      ) : null}

      {tab === t("preview.tabForm") ? (
        <form
          onSubmit={form.onSubmit(async (values) => {
            // map the switches (material/non material) to the list of ESRS
            // that are *not* material, as it’s what’s recorded in the DB for IRO-2.
            const nonMaterialTopicsAnswer: CSRDQuestionAnswer = {
              datoQuestionId:
                config.disclosureRequirement.nonMaterialTopicsQuestionId,
              source: "report",
              answer: {
                type: "ChoiceRecord",
                value: config.esrs
                  .filter((esrs) => !values[esrs.iro2EnumLabel])
                  .map((esrs) => esrs.iro2EnumLabel),
              },
              dimensions: [],
            }

            const explanations: CSRDQuestionAnswer[] = []

            for (const esrs of config.esrs) {
              const answer = values[esrs.followUpQuestion.datoId]

              if (isDefined(answer) && typeof answer !== "boolean") {
                explanations.push(answer)
              }
            }

            const payload: CSRDQuestionAnswer[] = [
              nonMaterialTopicsAnswer,
              ...explanations,
            ]

            const result = await upsertDisclosureRequirements(payload)

            await queryClient.invalidateQueries({
              queryKey: reportsKeys.getMateriality(),
            })

            return result
          })}
        >
          <Stack gap="xl" pl={30} pr={30}>
            {sections.map((s) => (
              <Stack key={s.name}>
                <Text c="gray.9" fw={600} fz="md" mb={10}>
                  {s.name}
                </Text>

                <Stack gap="md" ml={30} mr={30}>
                  {s.subTopics.map((st) => {
                    const esrs = findEsrsById(st.datoId)!
                    const inputProps = form.getInputProps(esrs.iro2EnumLabel)

                    return (
                      <Group justify="space-between" key={st.name} w="auto">
                        <Group>
                          <EsrsBadge topic={st.esrs} />
                          <Text>{st.name}</Text>
                        </Group>

                        <MaterialitySwitch
                          {...inputProps}
                          value={inputProps.value}
                        />
                      </Group>
                    )
                  })}
                </Stack>
              </Stack>
            ))}

            {_.isEmpty(nonMaterialTopics) ? null : (
              <>
                <Text c="gray.9" fw={600} fz="md">
                  {materialityAssessmentQuestion!.label}
                </Text>

                {materialityAssessmentQuestion?.hint ? (
                  <Callout message={materialityAssessmentQuestion!.hint!} />
                ) : null}

                {nonMaterialTopics.map((enumValue) => {
                  const esrs = findEsrsByIro2Label(enumValue)!

                  const followUpQuestions = findQuestionInDR(
                    props.disclosureRequirement,
                    config.disclosureRequirement
                      .materialityAssessmentQuestionId,
                  )!.relatedQuestions!

                  const relatedQuestion = followUpQuestions.find(
                    (q) => q!.id === esrs.followUpQuestion.datoId,
                  )!

                  const inputProps = form.getInputProps(
                    esrs.followUpQuestion.datoId,
                  )

                  return (
                    <Stack key={enumValue}>
                      <Text fw="bold">{relatedQuestion.label}</Text>

                      <Textarea
                        {...inputProps}
                        onChange={(event) => {
                          return inputProps.onChange({
                            datoQuestionId: esrs.followUpQuestion.datoId,
                            answer: {
                              type: "TextBlockRecord",
                              value: event.currentTarget.value,
                            },
                            dimensions: [],
                          })
                        }}
                        value={inputProps?.value?.answer.value}
                      />
                    </Stack>
                  )
                })}
              </>
            )}
          </Stack>

          <Space h={120} />

          <SaveFooter
            hasUnsavedChanges={form.isDirty()}
            isCompleted={props.progress === 100}
            isPendingGeneration={Boolean(isPendingParagraph)}
            isPendingUpdate={isUpsertPending || isMarkAsDonePending}
            onGenerate={() => {
              aiGenerateParagraph(config.disclosureRequirement.datoId)
            }}
            onMarkAsDone={() => {
              markAsDone(config.disclosureRequirement.datoId)
            }}
            onSave={_.noop}
            onUnmarkAsDone={() =>
              unmarkAsDone(config.disclosureRequirement.datoId)
            }
          />
        </form>
      ) : (
        <DisclosureRequirementPreview
          loading={isPendingParagraph}
          onGenerate={() => {
            aiGenerateParagraph(config.disclosureRequirement.datoId)
          }}
          previewParagraph={
            props.disclosureRequirementParagraph ||
            disclosureRequirementParagraphCurrentlyGenerated
          }
          xmlMode={showXml}
        />
      )}
    </Stack>
  )
}

export const IRO2 = ({ progress }: { readonly progress?: number }) => {
  const disclosureRequirementAnswersQuery =
    useDisclosureRequirementAnswersQuery(config.disclosureRequirement.datoId)

  const { token } = useUser()

  const disclosureRequirementQuery =
    useDatoQuery<DatoDisclosureRequirementResponse>({
      query: CSRD_DISCLOSURE_REQUIREMENT_BY_ID,
      variables: { id: config.disclosureRequirement.datoId },
      token,
    })

  const disclosureRequirementNamesQuery = useDatoCategoriesTopicsNames(token)

  const disclosureRequirementParagraphQuery =
    useDisclosureRequirementParagraphByDisclosureRequirementId(
      config.disclosureRequirement.datoId,
    )

  return (
    <MultiQueryWrapper
      queries={{
        disclosureRequirementAnswers: {
          query: disclosureRequirementAnswersQuery,
          allowEmptyArray: true,
        },
        disclosureRequirement: {
          query: disclosureRequirementQuery,
        },
        disclosureRequirementNames: {
          query: disclosureRequirementNamesQuery,
        },
        disclosureRequirementParagraph: {
          query: disclosureRequirementParagraphQuery,
          allowEmptyObject: true,
        },
      }}
    >
      {({
        disclosureRequirementAnswers,
        disclosureRequirement,
        disclosureRequirementNames,
        disclosureRequirementParagraph,
      }) => {
        return (
          <IRO2Page
            categoriesTopicsNames={disclosureRequirementNames}
            disclosureRequirement={disclosureRequirement.disclosureRequirement}
            disclosureRequirementParagraph={disclosureRequirementParagraph}
            initialValues={disclosureRequirementAnswers}
            progress={progress}
          />
        )
      }}
    </MultiQueryWrapper>
  )
}
