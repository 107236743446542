import {
  ActionIcon,
  Button,
  Group,
  Select,
  Text,
  Menu,
  rem,
} from "@mantine/core"
import {
  IconChevronDown,
  IconDotsVertical,
  IconPlus,
  IconDownload,
} from "@tabler/icons-react"
import { Dispatch, SetStateAction, useState } from "react"
import { useTranslation } from "react-i18next"

import { useCreateCategoryMutation } from "@kiosk/reporting/api/tasks/createCategory"
import { useDownloadCsv } from "@kiosk/reporting/api/tasks/downloadCsv"
import { useTaskOwnersQuery } from "@kiosk/reporting/api/tasks/ownersQuery"

type Props = {
  readonly ownerId: string | undefined
  readonly setOwnerId: Dispatch<SetStateAction<undefined | string>>
}

export const ActionsBar = ({ ownerId, setOwnerId }: Props) => {
  const { t } = useTranslation(["common", "task"])
  const [showDotsList, setShowDotsList] = useState(false)
  const ownersQuery = useTaskOwnersQuery()
  const ownersQueryOptions =
    ownersQuery.data?.map(({ id, firstName, lastName }) => ({
      value: id,
      label: `${firstName} ${lastName}`,
    })) ?? []
  const ownersOptions = [
    { value: "all", label: t("allOwners") },
    ...ownersQueryOptions,
  ]

  const { mutate: createCategoryMutation } = useCreateCategoryMutation()
  const { mutate: downloadCsvMutation } = useDownloadCsv()

  return (
    <Group justify="space-between">
      <Select
        allowDeselect={false}
        data={ownersOptions}
        onChange={(value) =>
          setOwnerId(value === "all" || !value ? undefined : value)
        }
        rightSection={<IconChevronDown size={20} />}
        value={ownerId ? ownerId : "all"}
      />

      <Group>
        <Button
          c="gray.8"
          color="gray.1"
          h={42}
          onClick={() => createCategoryMutation()}
          px={16}
          py={10}
        >
          <Group gap={8}>
            <IconPlus size={20} />

            {t("task:actions.newCategory")}
          </Group>
        </Button>

        <Menu shadow="md" width={200}>
          <Menu.Target>
            <ActionIcon
              c="gray.8"
              color="gray.1"
              h={42}
              onClick={() => setShowDotsList(!showDotsList)}
              p={11}
              w={42}
            >
              <IconDotsVertical size={20} />
            </ActionIcon>
          </Menu.Target>

          <Menu.Dropdown style={{ borderRadius: rem(8) }}>
            <Menu.Item onClick={() => downloadCsvMutation()}>
              <Group gap={10} w={120}>
                <IconDownload size={18} />

                <Text c="gray.9" size="sm">
                  {t("exportToCSV")}
                </Text>
              </Group>
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Group>
    </Group>
  )
}
