import { showNotification } from "@mantine/notifications"
import { useMutation } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"

import { UploadResponses } from "@kiosk/reporting/legacy/types/endpoints/upload"
import { apiClient } from "@kiosk/reporting/lib/apiClient"

type UseUploadFileMutationInput = {
  destination?: string
  sendNotificationOnError?: boolean
}

type UseUploadPublicFileMutationInput = UseUploadFileMutationInput & {
  onSuccess?: (data?: UploadResponses.PublicFile) => void
  token: string
}

type UseUploadPrivateFileMutationInput = UseUploadFileMutationInput & {
  onSuccess?: (data?: UploadResponses.PrivateFile) => void
}

export const useUploadPublicFileMutation = ({
  destination = "",
  sendNotificationOnError = false,
  onSuccess,
  token,
}: UseUploadPublicFileMutationInput) => {
  const { t } = useTranslation()

  return useMutation({
    mutationFn: async (file: File | null) => {
      if (!file) {
        return
      }

      const form = new FormData()

      form.append("file", file)

      const response = await apiClient.post<UploadResponses.PublicFile>(
        `/upload/public/${destination}`,
        form,
        { headers: { Authorization: `Bearer ${token}` } },
      )

      return response.data
    },
    onSuccess,
    onError: () => {
      if (sendNotificationOnError) {
        // TODO: show error details?
        showNotification({ color: "red", message: t("upload.error") })
      }
    },
  })
}

export const useUploadPrivateFileMutation = ({
  destination = "",
  sendNotificationOnError = false,
  onSuccess,
}: UseUploadPrivateFileMutationInput) => {
  const { t } = useTranslation()

  return useMutation({
    mutationFn: async (file: File | null) => {
      if (!file) {
        return
      }

      const form = new FormData()

      form.append("file", file)

      const response = await apiClient.post<UploadResponses.PrivateFile>(
        `/upload/private/${destination}`,
        form,
      )

      return response.data
    },
    onSuccess,
    onError: () => {
      if (sendNotificationOnError) {
        // TODO: show error details?
        showNotification({ color: "red", message: t("upload.error") })
      }
    },
  })
}
