import { Button } from "@mantine/core"

type Props = {
  readonly label: string
  readonly isSelected: boolean
  readonly onClick: () => void
}

export const FilterButton = ({ label, isSelected, onClick }: Props) => {
  return (
    <Button
      onClick={onClick}
      radius="xl"
      size="xs"
      styles={(theme) => ({
        inner: { color: "black" },
        root: {
          background: isSelected ? theme.colors.emerald[1] : "white",
          borderColor: isSelected ? theme.colors.green[6] : "gray",
        },
      })}
      variant={isSelected ? "light" : "outline"}
    >
      {label}
    </Button>
  )
}
