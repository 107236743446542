import {
  IconBuildingFactory2,
  IconBolt,
  IconRecycle,
  IconScale,
  IconSchool,
  IconMoodSick,
} from "@tabler/icons-react"

import { TablerIconComponent } from "@kiosk/reporting/utils/icon"

export const topicIcons: Record<string, TablerIconComponent> = {
  clm17ghq80001mbelqcqk9x8g: IconBuildingFactory2,
  clm17ghq8000dmbelcnf77tc7: IconBolt,
  clm17ghq8000rmbelzybh17z5: IconRecycle,
  clm17h7zg0001mbel82r2p4sd: IconScale,
  clm17h7zg000fmbel5pxr8tsf: IconSchool,
  clm17h7zg000tmbelgfut9ldn: IconMoodSick,
}
