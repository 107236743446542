import { i18n } from "@kiosk/reporting/legacy/i18n/index"

export const formatNumber = (value: number) => {
  return new Intl.NumberFormat(i18n.language).format(value)
}

export const formatNumberDemo = (value: number) => {
  const minimumFractionDigits = value < 0.05 ? 4 : 0 // Set a higher precision for values less than 1
  const maximumFractionDigits = value < 0.05 ? 4 : 2 // Adjust the maximum fraction digits accordingly

  return new Intl.NumberFormat(i18n.language, {
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(value)
}
