import { Button, Group, Stack, Text } from "@mantine/core"
import { Dropzone, FileWithPath, MIME_TYPES } from "@mantine/dropzone"
import { Form } from "@remix-run/react"
import {
  IconCircleCheck,
  IconCloudUpload,
  IconLoader2,
  IconX,
} from "@tabler/icons-react"
import { useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import MultiLineTranslation from "@kiosk/reporting/components/MultilineTranslation"
import { formatBlobSize } from "@kiosk/reporting/utils/helpers"

type Props = {
  readonly title: string
  readonly description?: string

  readonly uploadError: Error | null
  readonly hasUploadError?: boolean
  readonly isUploading?: boolean
  readonly clearFileUrl?: () => void
  readonly errorTitle?: string
  readonly errorMessage?: string
}
type DropzoneStatus = "accepted" | "idle" | "rejected"

export const DashedDropzone = ({
  uploadError,
  hasUploadError,
  isUploading,
  clearFileUrl,
  title,
  description,
  errorTitle,
  errorMessage,
}: Props) => {
  const openRef = useRef<() => void>(null)
  const acceptedMimeTypes = [
    MIME_TYPES.pdf,
    MIME_TYPES.doc,
    MIME_TYPES.docx,
    MIME_TYPES.xls,
    MIME_TYPES.xlsx,
    MIME_TYPES.ppt,
    MIME_TYPES.pptx,
    "text/plain",
    "application/octet-stream",
  ]

  const { t } = useTranslation(["common", "sources"])
  const [dropzoneStatus, setDropzoneStatus] = useState<DropzoneStatus>("idle")
  const [file, setFile] = useState<FileWithPath[] | null>(null)

  const handleReject = (status: DropzoneStatus) => {
    clearFileUrl && clearFileUrl()
    setFile(null)
    setDropzoneStatus(status)
  }

  const getStatusContent = () => {
    switch (true) {
      case isUploading:
        return (
          <Stack align="center">
            <IconLoader2
              color="var(--mantine-color-green-1)"
              height={52}
              stroke={2}
              width={52}
            />

            <Text c="black" fw={600} inline size="lg">
              {t("onboarding.uploadingFile")}
            </Text>
          </Stack>
        )

      case dropzoneStatus === "idle" && !hasUploadError && !isUploading:
        return (
          <Stack align="center">
            <IconCloudUpload
              color="var(--mantine-color-dimmed)"
              height={52}
              stroke={1.5}
              width={52}
            />

            <Text c="black" fw={600} inline size="lg">
              {title}
            </Text>

            {description ? (
              <Text c="gray.5" fz="md" mt={7} size="sm" ta="center">
                <MultiLineTranslation text={description} />
              </Text>
            ) : null}
          </Stack>
        )

      case dropzoneStatus === "accepted" && !hasUploadError && !isUploading:
        return (
          <Stack align="center">
            <IconCircleCheck
              color="var(--mantine-color-green-6)"
              height={52}
              stroke={1.5}
              width={52}
            />

            <Text c="black" fw={600} inline mb={8} size="lg" ta="center">
              {t("onboarding.successUploadDsnFile")}
            </Text>

            <Group justify="center">
              <Text c="gray.5" fz="md" size="sm" ta="center">
                {file?.[0].name} {formatBlobSize(file?.[0].size || 0)}
              </Text>

              <Button
                c="black"
                onClick={() => handleReject("idle")}
                size="xs"
                style={{ pointerEvents: "all" }}
                variant="transparent"
              >
                <IconX />
              </Button>
            </Group>
          </Stack>
        )

      case (dropzoneStatus === "rejected" || hasUploadError) && !isUploading:
        return (
          <Stack align="center">
            <IconCloudUpload
              color="var(--mantine-color-dimmed)"
              height={52}
              stroke={1.5}
              width={52}
            />

            <Text c="black" fw={600} inline size="lg">
              {errorTitle ?? t("onboarding.errorUploadDsnFile")}
            </Text>

            <Text c="red" fz="md" mt={7} size="sm">
              {uploadError
                ? (errorMessage ?? uploadError?.message)
                : t("onboarding.errorUploadDsnFileDescription")}
            </Text>
          </Stack>
        )

      default:
        return null
    }
  }

  return (
    <>
      <Form
        action="/files/upload"
        encType="multipart/form-data"
        id="file-upload-form"
        method="post"
        name="upload-file"
      >
        <input hidden id="file-input" name="file" type="file" />
      </Form>

      <Dropzone
        accept={acceptedMimeTypes}
        activateOnClick={false}
        mb="24px"
        multiple={false}
        onDrop={(files) => {
          // Dropzone is not directly compatible with forms as far as I understand.
          // Hence this workaround to set the file on the form and submit it.
          const input = document.getElementById(
            "file-input",
          ) as HTMLInputElement

          if (input) {
            const dataTransfer = new DataTransfer()

            files.forEach((file) => {
              dataTransfer.items.add(file)
            })

            input.files = dataTransfer.files
          }

          const form = document.getElementById(
            "file-upload-form",
          ) as HTMLFormElement

          form.submit()
        }}
        onReject={() => handleReject("rejected")}
        openRef={openRef}
        pos="relative"
      >
        <Group justify="center" mih={240} style={{ pointerEvents: "none" }}>
          <Stack align="center" flex={1}>
            {getStatusContent()}
          </Stack>
        </Group>
        <Stack
          bottom="0"
          justify="center"
          left="50%"
          mt="md"
          pos="absolute"
          style={{
            transform: "translate(-50%, 50%)",
            pointerEvents: "auto",
          }}
        >
          <Button
            c="white"
            color="green.6"
            disabled={false}
            loading={false}
            onClick={() => openRef.current?.()}
          >
            <Text fw={500} size="md">
              {t("buttons.selectFile")}
            </Text>
          </Button>
        </Stack>
      </Dropzone>
    </>
  )
}
