import {
  ActionIcon,
  Button,
  Group,
  Modal,
  Progress,
  Stack,
  Text,
} from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { showNotification } from "@mantine/notifications"
import { useNavigate } from "@remix-run/react"
import { IconDownload, IconTrash } from "@tabler/icons-react"
import dayjs from "dayjs"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"

import { useDeleteSurveyMutation } from "@kiosk/reporting/api/surveys/deleteSurvey"
import { Table } from "@kiosk/reporting/components/generic"
import { Column } from "@kiosk/reporting/components/generic/types"
import {
  SurveyResponses,
  SurveyStatus,
} from "@kiosk/reporting/legacy/types/endpoints/surveys"
import { SurveyStatusPill } from "@kiosk/reporting/routes/sources_.surveys/components/SurveyStatusPill"
import {
  isSurveyEmpty,
  isSurveyOver,
  isSurveyStarted,
} from "@kiosk/reporting/routes/sources_.surveys/utils"
import { routes } from "@kiosk/reporting/utils/constants"
import { searchInArray } from "@kiosk/reporting/utils/text"

const DownloadSurveyButton = ({
  survey,
}: {
  readonly survey: SurveyResponses.Survey
}) => {
  const { t } = useTranslation("survey")

  return (
    <ActionIcon
      aria-label={t("actions.download")}
      component="a"
      disabled={isSurveyEmpty(survey)}
      href={
        !isSurveyEmpty(survey)
          ? routes.SURVEY_DOWNLOAD.path.replace(":id", survey.id)
          : undefined
      }
      variant="subtle"
    >
      <IconDownload color="gray" />
    </ActionIcon>
  )
}

const DeleteSurveyButton = ({
  survey,
}: {
  readonly survey: SurveyResponses.Survey
}) => {
  const { t } = useTranslation("survey")
  const [opened, { open, close }] = useDisclosure(false)
  const { mutateAsync: deleteSurvey, isPending } = useDeleteSurveyMutation(
    survey.id,
  )

  const handleDelete = async () => {
    await deleteSurvey()
    showNotification({ color: "green", message: t("surveyDeleted") })
    close()
  }

  return (
    <>
      <ActionIcon
        aria-label={t("actions.delete")}
        disabled={isSurveyOver(survey) && !isSurveyEmpty(survey)}
        onClick={open}
        variant="subtle"
      >
        <IconTrash color="gray" />
      </ActionIcon>

      <Modal onClose={close} opened={opened} title={t("confirmDeleteTitle")}>
        <Stack gap={32}>
          <Text>{t("confirmDeleteContent")}</Text>

          <Group justify="end">
            <Button color="gray" onClick={close} variant="light">
              {t("actions.cancel")}
            </Button>

            <Button color="red" loading={isPending} onClick={handleDelete}>
              {t("actions.delete")}
            </Button>
          </Group>
        </Stack>
      </Modal>
    </>
  )
}

const SurveysTable = ({
  surveys,
}: {
  readonly surveys: SurveyResponses.Survey[]
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation("survey")

  const columns: Column<SurveyResponses.Survey>[] = useMemo(
    () => [
      { key: "title", colId: "title", title: t("labels.name") },
      {
        key: "status",
        width: 160,
        colId: "status",
        title: t("labels.status"),
        render: (status: SurveyStatus) => <SurveyStatusPill status={status} />,
      },
      {
        key: "progress",
        colId: "id",
        width: 160,
        title: t("labels.progress"),
        render: (_: string, record: SurveyResponses.Survey) => {
          const progress =
            (record.answersCount / Math.max(1, record.assignmentsCount)) * 100

          return (
            <Stack flex={1} gap={0}>
              {record.answersCount} / {record.assignmentsCount}
              <Progress
                color={
                  progress < 50
                    ? "yellow.4"
                    : progress < 100
                      ? "green.4"
                      : "green"
                }
                size={4}
                value={progress}
              />
            </Stack>
          )
        },
      },
      {
        key: "launchDate",
        colId: "launchDate",
        title: t("labels.launchDate"),
        render: (launchDate: Date) => dayjs(launchDate).format("ll"),
      },
      {
        key: "followUpDate",
        colId: "followUpDate",
        title: t("labels.followUpDate"),
        render: (followUpDate: Date) => dayjs(followUpDate).format("ll"),
      },
      {
        key: "dueDate",
        colId: "dueDate",
        title: t("labels.dueDate"),
        render: (dueDate: Date) => dayjs(dueDate).format("ll"),
      },
      {
        key: "actions",
        colId: "id",
        title: "",
        render: (id: string, survey: SurveyResponses.Survey) => (
          <Group flex={1} justify="center">
            <Button
              onClick={() =>
                navigate(
                  // isSurveyStarted(survey)
                  // ? routes.SURVEY_ANSWERS.path.replace(":id", id)
                  routes.EDIT_SURVEY.path.replace(":id", id),
                )
              }
              variant="subtle"
            >
              {isSurveyStarted(survey)
                ? t("actions.seeResponse")
                : t("actions.edit")}
            </Button>
          </Group>
        ),
      },
      {
        key: "delete",
        colId: "id",
        title: "",
        render: (_: string, survey: SurveyResponses.Survey) => (
          <DeleteSurveyButton survey={survey} />
        ),
      },
      {
        key: "download",
        colId: "id",
        title: "",
        render: (_: string, survey: SurveyResponses.Survey) => (
          <DownloadSurveyButton survey={survey} />
        ),
      },
    ],
    [navigate, t],
  )

  return (
    <Table
      columns={columns}
      dataSource={surveys.sort(
        (a, b) => new Date(b.dueDate).getTime() - new Date(a.dueDate).getTime(),
      )}
      withColumnBorders
    />
  )
}

interface SurveyListProps {
  readonly search: string
  readonly surveys: SurveyResponses.Survey[]
}

export const SurveysList = ({ search, surveys }: SurveyListProps) => {
  const filteredSurveys = useMemo(
    () => searchInArray(surveys, search, "title"),
    [surveys, search],
  )

  return <SurveysTable surveys={filteredSurveys} />
}
