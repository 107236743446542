import { Flex, Title, Text, TextInput, Group, Button } from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import { Form, useFetcher, useLoaderData } from "@remix-run/react"
import { Dispatch, SetStateAction, useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import { z } from "zod"

import { CURRENT_REPORT_YEAR } from "@kiosk/reporting/legacy/shared/constants/common/phaseIn"
import { onboardingSchema } from "@kiosk/reporting/lib/entities/onboarding"
import { Footer } from "@kiosk/reporting/routes/_onboarding.onboarding/components/Footer"
import {
  action,
  loader,
} from "@kiosk/reporting/routes/_onboarding.onboarding/route"

export const withFormIdSchema = z.object({
  formId: z.string(),
})

type Props = {
  readonly setActive?: Dispatch<SetStateAction<number>>
}

export const CompanyStep = ({ setActive }: Props) => {
  const { t } = useTranslation(["common", "onboarding"])
  const fetcher = useFetcher<typeof action>()

  const { onboarding, company } = useLoaderData<typeof loader>()

  useEffect(() => {
    if (fetcher.data) {
      setActive?.(2)
    }
  }, [fetcher.data, setActive])

  const form = useForm({
    initialValues: {
      companyId: company.id,
      formId: "onboardingCompanySettings",
      companyName: company.name,
      headCount: onboarding?.headCount ?? "",
      firstExerciseYear:
        onboarding?.firstExerciseYear ?? CURRENT_REPORT_YEAR.toString(),
      headOffice: onboarding?.headOffice ?? "",
      registeredCapital: onboarding?.registeredCapital ?? "",
      legalForm: onboarding?.legalForm ?? "",
      activity: onboarding?.activity ?? "",
    },
    validate: zodResolver(onboardingSchema.and(withFormIdSchema)),
  })

  const formRef = useRef<HTMLFormElement>(null)

  const handleContinue = () => {
    fetcher.submit(form.values, {
      method: "post",
      action: "/onboarding",
    })
  }

  return (
    <Flex align="center" direction="column" flex={1} gap={40} w="100%">
      <Flex
        align="flex-start"
        direction="column"
        gap={40}
        justify="flex-start"
        w={837}
      >
        <Form method="post" navigate={false} ref={formRef}>
          <Flex align="center" direction="column" flex={1} gap={40} w="100%">
            <Flex
              align="flex-start"
              direction="column"
              gap={40}
              justify="flex-start"
              w={837}
            >
              {setActive && (
                <Group>
                  <Title c="gray.9" fw={600} fz="xxl">
                    {t("onboarding:company.title")}
                  </Title>

                  <Text c="gray.5" fz="md">
                    {t("onboarding:company.description")}
                  </Text>
                </Group>
              )}

              <TextInput
                label={t("onboarding:company.companyName")}
                placeholder={t("onboarding:company.companyNamePlaceholder")}
                w="100%"
                {...form.getInputProps("companyName")}
                value={form.getInputProps("companyName").value ?? ""}
                withAsterisk
              />

              <TextInput
                label={t("onboarding:company.companyHeadcount")}
                placeholder={t(
                  "onboarding:company.companyHeadCountPlaceholder",
                )}
                type="number"
                w="100%"
                {...form.getInputProps("headCount")}
                value={form.getInputProps("headCount").value ?? ""}
                withAsterisk
              />
              <TextInput
                label={t("onboarding:company.firstExerciseYear")}
                placeholder={String(CURRENT_REPORT_YEAR)}
                type="number"
                w="100%"
                {...form.getInputProps("firstExerciseYear")}
                value={form.getInputProps("firstExerciseYear").value ?? ""}
                withAsterisk
              />
              <TextInput
                label={t("onboarding:company.headOffice")}
                placeholder={t("onboarding:company.headOfficePlaceholder")}
                value={form.getInputProps("headOffice").value ?? ""}
                w="100%"
                {...form.getInputProps("headOffice")}
              />
              <TextInput
                label={t("onboarding:company.registeredCapital")}
                placeholder={t(
                  "onboarding:company.registeredCapitalPlaceholder",
                )}
                type="number"
                w="100%"
                {...form.getInputProps("registeredCapital")}
                value={form.getInputProps("registeredCapital").value ?? ""}
              />
              <TextInput
                label={t("onboarding:company.legalForm")}
                placeholder={t("onboarding:company.legalFormPlaceholder")}
                w="100%"
                {...form.getInputProps("legalForm")}
                value={form.getInputProps("legalForm").value ?? ""}
              />
              <TextInput
                label={t("onboarding:company.companyActivity")}
                placeholder={t("onboarding:company.companyActivityPlaceholder")}
                w="100%"
                {...form.getInputProps("activity")}
                value={form.getInputProps("activity").value ?? ""}
              />
            </Flex>
            {setActive ? (
              <Footer
                disableContinue={!form.isValid()}
                isLoading={fetcher.state !== "idle"}
                onContinue={handleContinue}
              />
            ) : (
              <Group
                h={81}
                justify="center"
                px={24}
                py={16}
                style={{ borderTop: "solid 1px var(--mantine-color-gray-3)" }}
                w="100%"
              >
                <Group justify="flex-end" maw={830} w="100%">
                  <Group>
                    <Button
                      disabled={!form.isValid}
                      h={42}
                      loading={fetcher.state !== "idle"}
                      onClick={handleContinue}
                    >
                      {t("common:buttons.save")}
                    </Button>
                  </Group>
                </Group>
              </Group>
            )}
          </Flex>
        </Form>
      </Flex>
    </Flex>
  )
}
