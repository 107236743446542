import { Alert, Button, Center, Container, Stack, Text } from "@mantine/core"
import { IconAlertCircle } from "@tabler/icons-react"
import { UseQueryResult } from "@tanstack/react-query"
import _ from "lodash"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import { Spinner } from "@kiosk/reporting/components/loader/Spinner"

interface QueryWrapperProps<TData, TError> {
  readonly query: UseQueryResult<TData, TError>
  readonly emptyPlaceholder?: () => JSX.Element | null
  readonly allowEmptyArray?: boolean
  readonly allowEmptyObject?: boolean
  readonly fluid?: boolean
  readonly absolute?: boolean
  readonly children: (props: { data: TData }) => JSX.Element | null
  readonly errorTitle?: string
  readonly errorMessage?: string
}

/**
 * QueryWrapper wraps a component with the query it needs to get its data.
 *
 * This way, we can generalize the pattern of loading/erroring on data loads.
 *
 * Example:
 * <QueryWrapper
 *   query={disclosureRequirementAnswersQuery}
 *   allowEmptyArray
 * >
 *   {({ data }) => {EmptyPlaceholder
 *     return (
 *       <IRO2Page
 *         initialValues={data}
 *         disclosureRequirement={response.disclosureRequirement}
 *       />
 *     )
 *   }}
 * </QueryWrapper>
 */
export const QueryWrapper = <TData, TError>({
  query,
  emptyPlaceholder: EmptyPlaceholder,
  allowEmptyArray = false,
  allowEmptyObject = false,
  children,
  fluid,
  absolute,
  errorTitle,
  errorMessage,
}: QueryWrapperProps<TData, TError>) => {
  const { t } = useTranslation()
  const { data, isLoading, isError } = query

  if (isError) {
    return (
      <Center h="100%" w="100%">
        <Stack w="33%">
          <Stack>
            <Text c="gray.9" fw="bold" size="3rem">
              {errorTitle || t("queryWrapper.error.oops")}
            </Text>
            <Text>{errorMessage || t("queryWrapper.error.message")}</Text>
          </Stack>
          <Center mt="20px">
            <Link to="/">
              <Button>{t("buttons.backToDashboard")}</Button>
            </Link>
          </Center>
        </Stack>
      </Center>
    )
  }

  if (isLoading) return <Spinner absolute={absolute} fluid={fluid} />

  if (
    (!allowEmptyObject && !data) ||
    (_.isEmpty(data) && !allowEmptyArray && !allowEmptyObject)
  ) {
    return EmptyPlaceholder ? (
      <EmptyPlaceholder />
    ) : (
      <Container mt="5%">
        <Alert
          color="red"
          icon={<IconAlertCircle />}
          title={t("queryWrapper.empty.title")}
        >
          {t("queryWrapper.empty.message")}
        </Alert>
      </Container>
    )
  }

  return data ? children({ data }) : null
}

interface QueryConfig<TData, TError> {
  query: UseQueryResult<TData, TError>
  emptyPlaceholder?: () => JSX.Element | null
  allowEmptyArray?: boolean
  allowEmptyObject?: boolean
}

type QueriesConfig<TData, TError> = _.Dictionary<QueryConfig<TData, TError>>

type ExtractData<TData, TError, T extends QueriesConfig<TData, TError>> = {
  [K in keyof T]: NonNullable<T[K]["query"]["data"]>
}

interface MultiQueryWrapperProps<
  TData,
  TError,
  T extends QueriesConfig<TData, TError>,
> {
  readonly queries: T
  readonly children: (data: ExtractData<TData, TError, T>) => JSX.Element | null
  readonly absolute?: boolean
  readonly fluid?: boolean
}

/**
 * MultiQueryWrapper is similar to QueryWrapper, but it allows to wrap multiple queries.
 *
 * This is useful if a component depends on queries from multiple sources.
 * For example, Dato and the backend, or multiple endpoints on the backend.
 */
export function MultiQueryWrapper<
  TData,
  TError,
  T extends QueriesConfig<TData, TError>,
>({
  queries,
  children,
  fluid,
  absolute,
}: MultiQueryWrapperProps<TData, TError, T>): JSX.Element | null {
  const { t } = useTranslation()

  if (Object.values(queries).some(({ query }) => query.isLoading)) {
    return <Spinner absolute={absolute} fluid={fluid} />
  }

  const errorQuery = Object.values(queries).find(({ query }) => query.isError)

  if (errorQuery) {
    return (
      <Container mt="5%">
        <Alert
          color="red"
          icon={<IconAlertCircle />}
          title={t("queryWrapper.error.title")}
        >
          {t("queryWrapper.error.message")}
        </Alert>
      </Container>
    )
  }

  const emptyQuery = Object.entries(queries).find(
    ([_key, { query, allowEmptyArray, allowEmptyObject }]) => {
      return (
        (!allowEmptyObject && !query.data) ||
        (!allowEmptyArray && !allowEmptyObject && _.isEmpty(query.data))
      )
    },
  )

  if (emptyQuery) {
    const [key, { emptyPlaceholder: EmptyPlaceholder }] = emptyQuery

    return EmptyPlaceholder ? (
      <EmptyPlaceholder />
    ) : (
      <Container mt="5%">
        <Alert
          color="red"
          icon={<IconAlertCircle />}
          title={t("queryWrapper.empty.title")}
        >
          {t("queryWrapper.empty.message")} (Query: {key})
        </Alert>
      </Container>
    )
  }

  const data = _.mapValues(
    queries,
    (queryConfig) => queryConfig.query.data,
  ) as ExtractData<TData, TError, T>

  return children(data)
}
