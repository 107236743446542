import {
  Modal,
  Button,
  Flex,
  TextInput,
  ModalProps,
  Select,
  Text,
} from "@mantine/core"
import { useFetcher } from "@remix-run/react"
import { IconMail } from "@tabler/icons-react"
import { useQueryClient } from "@tanstack/react-query"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { companyKeys } from "@kiosk/reporting/api/companies/companyKeys"
import { useUser } from "@kiosk/reporting/components/auth/UserContext"
import { useLanguageOptions } from "@kiosk/reporting/constants/settings/languageOption"

import { action } from "../../create_assignee/route"

import classes from "./CreateAssigneeForm.module.css"
import { Assignee } from "./EditQuestion"

const createAssignee = "create-assignee"

type CreateAssigneeFormProps = {
  readonly onCreateNewAssignee: (user: Assignee) => void
} & ModalProps

export function CreateAssigneeForm({
  onCreateNewAssignee,
  onClose,
  opened,
}: CreateAssigneeFormProps) {
  const { t } = useTranslation(["survey", "error", "common"])
  const user = useUser()
  const languageOptions = useLanguageOptions()
  const [key, setKey] = useState(() => `key-${Date.now()}`)
  const fetcher = useFetcher<typeof action>({ key })
  const queryClient = useQueryClient()

  const errorTranslations: Record<string, string> = {
    emailInvalid: t("error:emailInvalid"),
    fieldEmpty: t("error:fieldEmpty"),
    localeRequired: t("error:localeRequired"),
  }

  const getErrorMessage = (field: string) => {
    if (fetcher.data?.success === false && fetcher.data.errors?.[field]) {
      return (
        errorTranslations[fetcher.data.errors?.[field]] ??
        t("error:somethingWentWrong")
      )
    }
  }

  const handleOnClose = () => {
    setKey(`key-${Date.now()}`)
    onClose()
  }

  useEffect(() => {
    if (fetcher.state === "idle" && fetcher.data?.success) {
      const companyContributor = fetcher.data?.companyContributor

      if (companyContributor) {
        onCreateNewAssignee({
          id: companyContributor.id,
          fullName: `${companyContributor.firstName} ${companyContributor.lastName}`,
          email: companyContributor.email,
          locale: companyContributor.locale,
        })
        queryClient.invalidateQueries({
          queryKey: companyKeys.companyUsers(user.companyId),
        })
        handleOnClose()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetcher.state, fetcher.data])

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.stopPropagation()
    fetcher.submit(event.currentTarget, { method: "post" })
  }

  return (
    <Modal
      classNames={{ body: classes["create-assignee__modal"] }}
      onClose={handleOnClose}
      opened={opened}
      title={t("addAssigneeModal.newAssignee")}
    >
      <fetcher.Form
        action="/create_assignee"
        id={createAssignee}
        method="post"
        onSubmit={handleSubmit}
      >
        <Flex className={classes["create-assignee__body"]} direction="column">
          {fetcher.data?.success === false && fetcher.data?.errors.all && (
            <Text className={classes["create-assignee__body--error-message"]}>
              {t("error:somethingWentWrong")}
            </Text>
          )}
          <Flex className={classes["create-assignee__body--name"]}>
            <TextInput
              autoFocus={true}
              error={getErrorMessage("firstName")}
              label={t("common:fields.firstNameLabel")}
              name="firstName"
              placeholder={t("common:fields.firstNamePlaceholder")}
              withAsterisk
            />

            <TextInput
              error={getErrorMessage("lastName")}
              label={t("common:fields.lastNameLabel")}
              name="lastName"
              placeholder={t("common:fields.lastNamePlaceholder")}
              withAsterisk
            />
          </Flex>

          <TextInput
            error={getErrorMessage("email")}
            label={t("common:fields.emailLabel")}
            leftSection={<IconMail color="gray" size={20} />}
            name="email"
            placeholder={t("common:fields.emailPlaceholder")}
            withAsterisk
          />

          <Select
            data={languageOptions}
            error={getErrorMessage("locale")}
            label={t("common:fields.locale")}
            name="locale"
            placeholder={t("common:fields.locale")}
            withAsterisk
          />

          <TextInput
            label={t("common:fields.jobTitleLabel")}
            name="jobTitle"
            placeholder={t("common:fields.jobTitlePlaceholder")}
          />
        </Flex>

        <Flex className={classes["create-assignee__footer"]}>
          <Button color="gray" onClick={onClose} variant="light">
            {t("addAssigneeModal.cancel")}
          </Button>

          <Button
            form={createAssignee}
            loading={
              fetcher.state === "loading" || fetcher.state === "submitting"
            }
            type="submit"
          >
            {t("addAssigneeModal.addAssignee")}
          </Button>
        </Flex>
      </fetcher.Form>
    </Modal>
  )
}
