export default function EmptyChart() {
  return (
    <div>
      <svg
        fill="none"
        height="300"
        viewBox="0 0 1440 768"
        width="100%"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect fill="#FAFBFC" height="767" width="1439" x="0.5" y="0.5" />

        <rect
          fill="#EDF2F5"
          fillOpacity="0.56"
          height="361.337"
          width="87.8109"
          x="38"
          y="347.663"
        />

        <rect
          fill="#EDF2F5"
          fillOpacity="0.56"
          height="445.369"
          width="87.8109"
          x="144.3"
          y="263.631"
        />

        <rect
          fill="#EDF2F5"
          fillOpacity="0.56"
          height="361.337"
          width="87.8109"
          x="250.591"
          y="347.663"
        />

        <rect
          fill="#EDF2F5"
          fillOpacity="0.56"
          height="540"
          width="87.8109"
          x="356.889"
          y="169"
        />

        <rect
          fill="#EDF2F5"
          fillOpacity="0.56"
          height="445.369"
          width="87.8109"
          x="463.183"
          y="263.631"
        />

        <rect
          fill="#EDF2F5"
          fillOpacity="0.56"
          height="420.159"
          width="87.8109"
          x="569.487"
          y="288.841"
        />

        <rect
          fill="#EDF2F5"
          fillOpacity="0.56"
          height="495.788"
          width="92.4325"
          x="675.781"
          y="213.212"
        />

        <rect
          fill="#EDF2F5"
          fillOpacity="0.56"
          height="445.369"
          width="92.4325"
          x="782.078"
          y="263.631"
        />

        <rect
          fill="#EDF2F5"
          fillOpacity="0.56"
          height="445.369"
          width="92.4325"
          x="888.382"
          y="263.631"
        />

        <rect
          fill="#EDF2F5"
          fillOpacity="0.56"
          height="495.788"
          width="87.8109"
          x="999.302"
          y="213.212"
        />

        <rect
          fill="#EDF2F5"
          fillOpacity="0.56"
          height="445.369"
          width="87.8109"
          x="1105.6"
          y="263.631"
        />

        <rect
          fill="#EDF2F5"
          fillOpacity="0.56"
          height="445.369"
          width="87.8109"
          x="1211.89"
          y="263.631"
        />

        <rect
          fill="#EDF2F5"
          fillOpacity="0.56"
          height="495.788"
          width="87.8109"
          x="1318.19"
          y="213.212"
        />
      </svg>
    </div>
  )
}
