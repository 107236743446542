/* eslint-disable @typescript-eslint/no-explicit-any */
import { Table as CoreTable, Group, TableProps } from "@mantine/core"
import { ReactElement, forwardRef, useMemo } from "react"

import { Column } from "@kiosk/reporting/components/generic/types"

const HEADER_HEIGHT = 74
const { Thead, Td, Th, Tr, Tbody, Tfoot } = CoreTable

/**
 * @deprecated use ~/pages/Tasks/CategoryTaskTable
 **/

interface Props<RecordType extends object = any> extends TableProps {
  readonly columns: Array<Column<RecordType>>
  readonly dataSource: Array<Record<string, RecordType>>
  readonly onRowClick?: (_record: RecordType, index?: number) => void
  readonly getRowKey?: (record: RecordType, index?: number) => string
  readonly footer?: ReactElement | null
  readonly isHeaderSticky?: boolean
}

export const Table = forwardRef<HTMLTableElement, Props>(
  (
    {
      columns,
      dataSource,
      onRowClick,
      getRowKey,
      footer,
      isHeaderSticky = false,
      ...rest
    }: Props,
    ref,
  ) => {
    const rows = useMemo(
      () =>
        dataSource.map((record, index) => (
          <Tr
            key={getRowKey ? getRowKey(record, index) : `row-${index}`}
            onClick={() => (onRowClick ? onRowClick(record, index) : null)}
            style={{ cursor: onRowClick ? "pointer" : "initial" }}
          >
            {columns.map(({ key, colId, render }) => (
              <Td
                c="gray.7"
                key={`${getRowKey ? getRowKey(record, index) : ""}-${key}-${index}`}
              >
                <Group gap={12}>
                  {render
                    ? render(record[colId], record, index)
                    : (record[colId] as string)}
                </Group>
              </Td>
            ))}
          </Tr>
        )),
      [dataSource, columns, onRowClick, getRowKey],
    )

    const cols = columns.map(({ key, title, width }) => (
      <Th
        c="gray.6"
        fw={500}
        fz="sm"
        h={44}
        key={key}
        lh="md"
        px={10}
        py={9}
        style={{
          width,
          borderInlineEnd: "none",
        }}
      >
        {title}
      </Th>
    ))

    return (
      <CoreTable {...rest} highlightOnHover ref={ref}>
        <Thead
          pos={isHeaderSticky ? "sticky" : "unset"}
          style={{
            backgroundColor: "white",
            boxShadow: isHeaderSticky
              ? "0 1px 0 var(--mantine-color-gray-2)"
              : "none",
          }}
          top={HEADER_HEIGHT}
        >
          <Tr>{cols}</Tr>
        </Thead>

        <Tbody>{rows}</Tbody>

        {footer ? (
          <Tfoot>
            <Tr>{footer}</Tr>
          </Tfoot>
        ) : null}
      </CoreTable>
    )
  },
)

Table.displayName = "Table"
