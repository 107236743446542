import { Text, TextInput } from "@mantine/core"
import { useHover } from "@mantine/hooks"
import { useState } from "react"
import { useTranslation } from "react-i18next"

import { colors } from "@kiosk/reporting/assets/theme/colors"

export const TablePageDescription = () => {
  const { t } = useTranslation("tables")
  const [description, setDescription] = useState(t("newDescription"))
  const { hovered, ref } = useHover()

  return (
    <div ref={ref}>
      {hovered ? (
        <TextInput
          height={42}
          my={20}
          onChange={(v) => setDescription(v.target.value)}
          value={description}
          variant="unstyled"
        />
      ) : (
        <Text c={colors.gray[4]} h={42} my={20} variant="unstyled">
          {description}
        </Text>
      )}
    </div>
  )
}
