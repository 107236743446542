import { Box, Container } from "@mantine/core"
import React from "react"

interface PageLayoutProps {
  readonly children: React.ReactNode
  readonly contained?: boolean // force the page to be contained in the main layout (no overlaping)
}

export const PageLayout = ({ children, contained }: PageLayoutProps) => {
  return (
    <Container h={contained ? "100%" : undefined} maw={1440} mx="auto" p={0}>
      <Box h={contained ? "100%" : undefined} p={24} w="100%">
        {children}
      </Box>
    </Container>
  )
}
