import { Dispatch, SetStateAction } from "react"

import { Files } from "@kiosk/reporting/routes/answer_survey.$id/AnswerSurveyTable/AnswerSurveyTable"
import { SurveyFileUploaded } from "@kiosk/reporting/routes/answer_survey.$id/AnswerSurveyTable/SurveyFileUploaded"
import { UploadAnswerFileButton } from "@kiosk/reporting/routes/answer_survey.$id/AnswerSurveyTable/UploadAnswerFileButton"

type FilesCellProps = {
  readonly files: Files
  readonly surveyID: string
  readonly setFiles: Dispatch<SetStateAction<Files>>
}

export const FilesCell = ({ files, surveyID, setFiles }: FilesCellProps) => {
  return files[surveyID] ? (
    <SurveyFileUploaded
      file={files[surveyID]!}
      onReset={() => setFiles((files) => ({ ...files, [surveyID]: null }))}
    />
  ) : (
    <UploadAnswerFileButton
      onSuccess={(name, path, signedUrl) =>
        setFiles((files) => ({
          ...files,
          [surveyID]: { name, path, signedUrl },
        }))
      }
    />
  )
}
