import { Container, Flex, Loader, Stack } from "@mantine/core"

/**
 * @interface SpinnerProps
 *
 * @description Props for the QueryWrapper spinner
 *
 * @prop {boolean} fluid - Whether the spinner should be centered and take all space
 * @prop {boolean} absolute - Whether the spinner should be absolutely positioned
 */

interface SpinnerProps {
  readonly fluid?: boolean
  readonly absolute?: boolean
}

export const Spinner = ({ fluid, absolute }: SpinnerProps) => {
  if (fluid) {
    return (
      <Flex
        align="center"
        direction="row"
        h="100%"
        justify="center"
        pos={absolute ? "absolute" : undefined}
        w="100%"
      >
        <Loader />
      </Flex>
    )
  }

  return (
    <Container mt="5%">
      <Stack align="center">
        <Loader />
      </Stack>
    </Container>
  )
}
