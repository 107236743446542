import {
  ScrollArea,
  Table,
  Checkbox,
  Badge,
  Group,
  Text,
  Loader,
} from "@mantine/core"
import { useViewportSize } from "@mantine/hooks"
import { Dispatch, SetStateAction } from "react"

import { useListDimensionsQuery } from "@kiosk/reporting/api/dimensions/queries"
import { Question } from "@kiosk/reporting/legacy/types/data/dato"
import { HIDDEN_DIMENSION } from "@kiosk/reporting/utils/constants"

type Props = {
  readonly currentQuestions: Question[]
  readonly selectedQuestions: Question[]
  readonly setSelectedQuestions: Dispatch<SetStateAction<Question[]>>
}

export const QuestionTable = ({
  currentQuestions,
  selectedQuestions,
  setSelectedQuestions,
}: Props) => {
  const { height: viewportHeight } = useViewportSize()
  // TODO: that could be fetched upper in the react tree
  const { data: dimensions } = useListDimensionsQuery()

  // TODO: handle all query states
  if (!dimensions) return <Loader size="xs" />

  return (
    <ScrollArea.Autosize mah={(viewportHeight - 280) * 0.8}>
      {/* 280 is the height of the header + footer of the modal */}
      {/* TODO find a better way to do that */}
      <Table highlightOnHover highlightOnHoverColor="emerald.0">
        <Table.Tbody>
          {currentQuestions?.map((question) => {
            const isChecked = selectedQuestions.some(
              (q) => q.id === question.id,
            )

            const key = [question.id, ...question.dimensionIds].join("-")

            return (
              <Table.Tr
                key={key}
                {...(isChecked ? { bg: "emerald.1" } : {})}
                onClick={(event) => {
                  setSelectedQuestions((state) =>
                    state.some(({ id }) => id === question.id)
                      ? state.filter((q) => q.id !== question.id)
                      : [...state, question],
                  )
                  event.stopPropagation()
                }}
                style={{ cursor: "pointer" }}
              >
                <Table.Td pl={24} pr={8} py={12} width={40}>
                  <Checkbox checked={isChecked} readOnly />
                </Table.Td>

                <Table.Td pl={8} py={12}>
                  <Group align="center" justify="space-between" wrap="nowrap">
                    <Text>{question.label}</Text>

                    <Group gap={4} justify="end" maw="50%">
                      {question.dimensionIds.map((dimensionId) => {
                        const dimension = dimensions.find(
                          ({ id }) => id === dimensionId,
                        )

                        if (
                          !dimension ||
                          dimension.options.length === 1 ||
                          HIDDEN_DIMENSION.includes(dimension.id)
                        )
                          return

                        return (
                          <Badge
                            color="gray"
                            fw={500}
                            key={dimensionId}
                            tt="none"
                            variant="light"
                          >
                            {dimension.label}
                          </Badge>
                        )
                      })}
                    </Group>
                  </Group>
                </Table.Td>
              </Table.Tr>
            )
          })}
        </Table.Tbody>
      </Table>
    </ScrollArea.Autosize>
  )
}
