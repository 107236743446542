import { Container, Modal, Tabs, TextInput } from "@mantine/core"
import { useDebouncedState } from "@mantine/hooks"
import { IconSearch } from "@tabler/icons-react"
import _ from "lodash"
import { ChangeEventHandler, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { useSurveyQuestionsDataModel } from "@kiosk/reporting/api/surveys/questions/model/surveyQuestionsDataModel"
import { useUser } from "@kiosk/reporting/components/auth/UserContext"
import { QueryWrapper } from "@kiosk/reporting/components/layout/QueryWrapper"
import { QuestionTable } from "@kiosk/reporting/components/questions/AddQuestionsModal/QuestionTable"
import { QuestionTableFooter } from "@kiosk/reporting/components/questions/AddQuestionsModal/QuestionTableFooter"
import { QuestionsPlaceholder } from "@kiosk/reporting/components/questions/AddQuestionsModal/QuestionsPlaceholder"
import { SelectTopicPanel } from "@kiosk/reporting/components/questions/AddQuestionsModal/SelectTopicPanel"
import { useCurrentQuestions } from "@kiosk/reporting/components/questions/AddQuestionsModal/useCurrentQuestions"
import { CsrdCategory, Question } from "@kiosk/reporting/legacy/types/data/dato"
import { LocalizedCompanySurveyQuestion } from "@kiosk/reporting/lib/services/company-survey-question.service.server"
import { withCompanySurveyQuestion } from "@kiosk/reporting/utils/withCompanySurveyQuestion"

type SearchInputProps = {
  readonly onChange: ChangeEventHandler<HTMLInputElement>
}

const SearchInput = ({ onChange }: SearchInputProps) => {
  const { t } = useTranslation()

  return (
    <Container
      fluid
      px={24}
      py={16}
      style={{
        borderTop: "1px solid var(--mantine-color-gray-3)",
        borderBottom: "1px solid var(--mantine-color-gray-3)",
      }}
    >
      <TextInput
        leftSection={<IconSearch size={18} />}
        onChange={onChange}
        placeholder={t("survey:placeholders.findQuestion")}
      />
    </Container>
  )
}

type AddQuestionModalContentProps = {
  readonly csrdCategories: CsrdCategory[]
  readonly onSave: (questions: Question[]) => void
  readonly onClose: () => void
  readonly companySurveyQuestions?: LocalizedCompanySurveyQuestion[]
}

const AddQuestionModalContent = ({
  csrdCategories,
  onSave,
  onClose,
  companySurveyQuestions,
}: AddQuestionModalContentProps) => {
  const user = useUser()
  const [search, setSearch] = useDebouncedState("", 500)
  const csrdCategoriesWithCompanyQuestions = companySurveyQuestions
    ? withCompanySurveyQuestion(csrdCategories, companySurveyQuestions)
    : csrdCategories
  const [categoryId, setCategoryId] = useState<string | null>(
    _.minBy(csrdCategoriesWithCompanyQuestions, "order")?.id ?? null,
  )
  const [topicId, setTopicId] = useState<string>()
  const [selectedQuestions, setSelectedQuestions] = useState<Question[]>([])

  const currentQuestions = useCurrentQuestions(
    csrdCategoriesWithCompanyQuestions,
    categoryId,
    topicId,
    search,
  )

  useEffect(() => {
    const topics = csrdCategoriesWithCompanyQuestions.find(
      ({ id }) => id === categoryId,
    )?.topics

    setTopicId(_.minBy(topics, "name")?.id)
    // categoryId only must affect this use effect to avoid additional rerenders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId])

  if (!csrdCategoriesWithCompanyQuestions) return

  return (
    <Tabs onChange={setCategoryId} value={categoryId}>
      <Tabs.List>
        {_.orderBy(
          csrdCategoriesWithCompanyQuestions,
          ({ order }) => order,
        ).map(({ id, name, companies }) => {
          if (companies && !companies.includes(user.company.name)) return

          return (
            <Tabs.Tab fz="md" key={id} value={id}>
              {name}
            </Tabs.Tab>
          )
        })}
      </Tabs.List>

      {csrdCategoriesWithCompanyQuestions.map((csrdCategory) => (
        <SelectTopicPanel
          csrdCategory={csrdCategory}
          key={csrdCategory.id}
          setTopicId={setTopicId}
          topicId={topicId}
        />
      ))}

      <SearchInput onChange={(e) => setSearch(e.target.value)} />

      {currentQuestions.length > 0 ? (
        <QuestionTable
          currentQuestions={currentQuestions}
          selectedQuestions={selectedQuestions}
          setSelectedQuestions={setSelectedQuestions}
        />
      ) : (
        <QuestionsPlaceholder />
      )}

      <QuestionTableFooter
        onClose={onClose}
        onSave={() => onSave(selectedQuestions)}
      />
    </Tabs>
  )
}

type RootProps = {
  readonly opened: boolean
  readonly rawDataPointsQuestions: CsrdCategory[]
  readonly onClose: () => void
  readonly onSave: (questions: Question[]) => void
  readonly companySurveyQuestions?: LocalizedCompanySurveyQuestion[]
}

export const AddQuestionsModal = ({
  opened,
  onClose,
  onSave,
  companySurveyQuestions,
  rawDataPointsQuestions,
}: RootProps) => {
  const surveyQuestionsDataModelQuery = useSurveyQuestionsDataModel()
  const { t } = useTranslation()

  return (
    <Modal
      onClose={onClose}
      opened={opened}
      size="70%"
      styles={{
        body: surveyQuestionsDataModelQuery.isLoading
          ? {
              paddingTop: 100,
              paddingBottom: 200,
            }
          : {
              padding: 0,
            },
      }}
      title={t("survey:questions.questionSelect")}
    >
      <QueryWrapper query={surveyQuestionsDataModelQuery}>
        {({ data }) => {
          const merged = mergeCategories(data, rawDataPointsQuestions)

          return (
            <AddQuestionModalContent
              companySurveyQuestions={companySurveyQuestions}
              csrdCategories={merged}
              onClose={onClose}
              onSave={onSave}
            />
          )
        }}
      </QueryWrapper>
    </Modal>
  )
}

// Merges an array of CSRD Categories by trying to merge categories, topics,
// and disclosure requirements.
//
// If the same (category, topic, disclosure requirement) is found in the update,
// questions from the original item and the update are concatenated.
//
// The pattern is aways the same:
// - go over original structure
// - if there is no corresponding entry in the update’s structure, return original
// - if there is a corresponding entry in te update’s structure, merge
// - append the entries in the update that don’t have a correspondance in the original
//
// TODO: consolidate this in the domain layer
export const mergeCategory = (
  categories: CsrdCategory[],
  category: CsrdCategory,
) => {
  const matchingCategory = categories.find(
    (_category) => _category.id === category.id,
  )

  if (!matchingCategory) return [...categories, category]

  return categories.map((_category) => {
    if (_category.id !== matchingCategory.id) return _category

    const topics = _category.topics.map((topic) => {
      const matchingTopic = category.topics.find(
        (_topic) => _topic.id === topic.id,
      )

      if (!matchingTopic) return topic

      const disclosureRequirements = topic.disclosureRequirements.map(
        (disclosureRequirement) => {
          const matchingDisclosureRequirement =
            matchingTopic.disclosureRequirements.find(
              (_disclosureRequirement) =>
                _disclosureRequirement.id === disclosureRequirement.id,
            )

          if (!matchingDisclosureRequirement) return disclosureRequirement

          return {
            ...disclosureRequirement,
            questions: [
              ...disclosureRequirement.questions,
              ...matchingDisclosureRequirement.questions,
            ],
          }
        },
      )

      const newDisclosureRequirements =
        matchingTopic.disclosureRequirements.filter(
          (disclosureRequirement) =>
            !disclosureRequirements
              .map((dr) => dr.id)
              .includes(disclosureRequirement.id),
        )

      return {
        ...topic,
        disclosureRequirements: [
          ...disclosureRequirements,
          ...newDisclosureRequirements,
        ],
      }
    })

    const newTopics = matchingCategory.topics.filter(
      (topic) => !topics.map((topic) => topic.id).includes(topic.id),
    )

    return {
      ..._category,
      topics: [...topics, ...newTopics],
    }
  })
}

export const mergeCategories = (catA: CsrdCategory[], catB: CsrdCategory[]) =>
  catB.reduce((acc, category) => mergeCategory(acc, category), catA)

/*
// TODO:
  const { mutateAsync: createSurveyQuestion, isPending } =
    useCreateSurveyQuestionMutation(surveyId)

selectedQuestions.forEach((question) =>
            createSurveyQuestion({
              ...omit(question, "id"),
              datoId: question.id,
            }),
          )
*/
