import { notifications } from "@mantine/notifications"
import { useMutation } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"

import { assignmentsKeys } from "@kiosk/reporting/api/surveys/assignments/assignmentsKeys"
import { surveysKeys } from "@kiosk/reporting/api/surveys/surveysKeys"
import { useUser } from "@kiosk/reporting/components/auth/UserContext"
import { CreateAssignmentBody } from "@kiosk/reporting/legacy/shared/schemas/assignments"
import { AssignmentResponses } from "@kiosk/reporting/legacy/types/endpoints/assignments"
import { apiClient } from "@kiosk/reporting/lib/apiClient"
import { authorizationHeader } from "@kiosk/reporting/lib/headers"
import { queryClient } from "@kiosk/reporting/lib/queryClient"

export const useBulkCreateAssignmentsMutation = (
  questionId: string,
  surveyId: string,
) => {
  const { token } = useUser()
  const { t } = useTranslation()

  return useMutation({
    mutationFn: async (
      data: CreateAssignmentBody[],
    ): Promise<AssignmentResponses.Assignment> => {
      const response = await apiClient.post(
        `/survey_questions/${questionId}/assignments/bulk`,
        data,
        { headers: authorizationHeader(token) },
      )

      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: assignmentsKeys.list(questionId),
      })
      queryClient.invalidateQueries({
        queryKey: surveysKeys.get(surveyId),
      })
    },
    onError: ({ message }) => {
      notifications.show({
        title: t("common:messages.error"),
        message,
        color: "red",
      })
    },
  })
}
