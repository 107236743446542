import {
  IconClipboardText,
  IconFileDescription,
  IconPuzzle,
  IconTableRow,
} from "@tabler/icons-react"

import { i18n } from "@kiosk/reporting/legacy/i18n/index"
import { SOURCE_TYPES, SourceType } from "@kiosk/reporting/legacy/types/sources"
import { TablerIconComponent } from "@kiosk/reporting/utils/icon"

type SourceTypeInfo = {
  key: SourceType
  name: string
  cta: string
  ctaLink?: string
  icon: TablerIconComponent
  isActive: boolean
}

export const SOURCE_TYPES_INFO: SourceTypeInfo[] = [
  {
    key: SOURCE_TYPES.SURVEY,
    name: i18n.t("sources:sourceTypes.survey"),
    cta: i18n.t("sources:createSource.survey"),
    ctaLink: "/sources/surveys",
    isActive: true,
    icon: IconClipboardText,
  },
  {
    key: SOURCE_TYPES.FILE,
    name: i18n.t("sources:sourceTypes.file"),
    cta: i18n.t("sources:createSource.file"),
    ctaLink: undefined,
    isActive: true,
    icon: IconFileDescription,
  },
  {
    key: SOURCE_TYPES.TABLE,
    name: i18n.t("sources:sourceTypes.table"),
    cta: i18n.t("sources:createSource.table"),
    ctaLink: "/sources/tables",
    isActive: false,
    icon: IconTableRow,
  },
  {
    key: SOURCE_TYPES.INTEGRATION,
    name: i18n.t("sources:sourceTypes.integration"),
    cta: i18n.t("sources:createSource.integration"),
    ctaLink: undefined,
    isActive: false,
    icon: IconPuzzle,
  },
]
