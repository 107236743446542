import { notifications } from "@mantine/notifications"
import { useMutation, useQuery } from "@tanstack/react-query"
import _ from "lodash"
import { useTranslation } from "react-i18next"

import { reportsKeys } from "@kiosk/reporting/api/reports/reportsKey"
import { useUser } from "@kiosk/reporting/components/auth/UserContext"
import { buildCSRDReportAnswerFormKey } from "@kiosk/reporting/components/csrd/utils"
import { clientConfig } from "@kiosk/reporting/config"
import { CSRDQuestionAnswer } from "@kiosk/reporting/legacy/types/csrd"
import { ReportsResponses } from "@kiosk/reporting/legacy/types/endpoints/reports"
import { apiClient } from "@kiosk/reporting/lib/apiClient"
import { authorizationHeader } from "@kiosk/reporting/lib/headers"
import { queryClient } from "@kiosk/reporting/lib/queryClient"
import { downloadFile } from "@kiosk/reporting/utils/files"

export const useDisclosureRequirementAnswersQuery = (
  disclosureRequirementId: string,
) => {
  const { token } = useUser()

  return useQuery<Record<string, CSRDQuestionAnswer>>({
    queryKey: reportsKeys.getDisclosureRequirementAnswers(
      disclosureRequirementId,
    ),
    queryFn: async () => {
      const response =
        await apiClient.get<ReportsResponses.GetDisclosureRequirementAnswersResponse>(
          `/reports/${disclosureRequirementId}`,
          { headers: authorizationHeader(token) },
        )

      return _.keyBy(
        response.data.map((answer) => ({
          datoQuestionId: answer.datoQuestionId,
          answer: answer.answer,
          dimensions: answer.dimensions,
          source: answer.source,
        })),
        (answer) =>
          buildCSRDReportAnswerFormKey(
            answer.datoQuestionId,
            answer.dimensions,
          ),
      )
    },
  })
}

export const useMaterialityQuery = () => {
  const { token } = useUser()

  return useQuery<string[]>({
    queryKey: reportsKeys.getMateriality(),
    queryFn: async () => {
      const response =
        await apiClient.get<ReportsResponses.MaterialityResponse>(
          "/reports/non-material-esrs",
          { headers: authorizationHeader(token) },
        )

      if ("data" in response.data) {
        return response.data.data.non_material_topics
      }

      return []
    },
  })
}

export const useMaterialityMutation = () => {
  const { t } = useTranslation()
  const { token } = useUser()

  return useMutation({
    mutationFn: async (esrsId: string) => {
      const response = await apiClient.post(
        "/reports/switch-materiality",
        { esrsId },
        { headers: authorizationHeader(token) },
      )

      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: reportsKeys.getMateriality(),
      })
      queryClient.invalidateQueries({
        queryKey: reportsKeys.getDisclosureRequirementAnswers(
          clientConfig.disclosureRequirements.iro2,
        ),
      })
      queryClient.invalidateQueries({
        queryKey: reportsKeys.getCategories(),
      })
    },
    onError: ({ message }) => {
      notifications.show({
        title: t("common:messages.error"),
        message,
        color: "red",
      })
    },
  })
}

export const useMaterialitiesMutation = () => {
  const { t } = useTranslation()
  const { token } = useUser()

  return useMutation({
    mutationFn: async (esrsIds: string[]) => {
      await Promise.all(
        esrsIds.map((esrsId) =>
          apiClient.post(
            "/reports/switch-materiality",
            { esrsId },
            { headers: authorizationHeader(token) },
          ),
        ),
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: reportsKeys.getMateriality(),
      })
    },
    onError: ({ message }) => {
      notifications.show({
        title: t("common:messages.error"),
        message,
        color: "red",
      })
    },
  })
}

export const useUpsertDisclosureRequirementAnswersMutation = (
  disclosureRequirementId: string,
) => {
  const { t } = useTranslation()
  const { token } = useUser()

  return useMutation({
    mutationFn: async (dataPoints: CSRDQuestionAnswer[]) => {
      const response = await apiClient.post(
        `/reports/${disclosureRequirementId}`,
        dataPoints,
        { headers: authorizationHeader(token) },
      )

      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: reportsKeys.getDisclosureRequirementAnswers(
          disclosureRequirementId,
        ),
      })
      queryClient.invalidateQueries({
        queryKey: reportsKeys.getCategories(),
      })
    },
    onError: ({ message }) => {
      notifications.show({
        title: t("common:messages.error"),
        message,
        color: "red",
      })
    },
  })
}

export const useGenerateXBRLMutation = () => {
  const { t } = useTranslation()
  const { token } = useUser()

  return useMutation({
    mutationFn: async () => {
      const response = await apiClient.get<ReportsResponses.GetGenerate>(
        "/reports/generate",
        { headers: authorizationHeader(token) },
      )

      return response.data
    },
    onSuccess: (data) => {
      downloadFile(
        data.xbrlContent,
        "xbrl-report.xhtml",
        "application/xhtml+xml",
      )
    },
    onError: ({ message }) => {
      notifications.show({
        title: t("common:messages.error"),
        message,
        color: "red",
      })
    },
  })
}

export const useDisclosureRequirementCategoriesQuery = () => {
  const { token } = useUser()

  return useQuery({
    queryKey: reportsKeys.getCategories(),
    queryFn: async () => {
      const response = await apiClient.get<ReportsResponses.GetCategories>(
        "/reports/categories",
        { headers: authorizationHeader(token) },
      )

      return response.data
    },
  })
}

export const useMarkDisclosureRequirementAsDoneMutation = () => {
  const { t } = useTranslation()
  const { token } = useUser()

  return useMutation({
    mutationFn: async (disclosureRequirementId: string) => {
      return await apiClient.post(
        `/reports/${disclosureRequirementId}/mark-as-done`,
        {},
        { headers: authorizationHeader(token) },
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: reportsKeys.getCategories(),
      })
    },
    onError: ({ message }) => {
      notifications.show({
        title: t("common:messages.error"),
        message,
        color: "red",
      })
    },
  })
}

export const useUnmarkDisclosureRequirementAsDoneMutation = () => {
  const { t } = useTranslation()
  const { token } = useUser()

  return useMutation({
    mutationFn: async (disclosureRequirementId: string) => {
      return await apiClient.delete(
        `/reports/${disclosureRequirementId}/mark-as-done`,
        { headers: authorizationHeader(token) },
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: reportsKeys.getCategories(),
      })
    },
    onError: ({ message }) => {
      notifications.show({
        title: t("common:messages.error"),
        message,
        color: "red",
      })
    },
  })
}
