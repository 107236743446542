import { notifications } from "@mantine/notifications"
import { TaskCategory } from "@prisma/client"
import { useMutation } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"

import { taskKeys } from "@kiosk/reporting/api/tasks/taskKeys"
import { useUser } from "@kiosk/reporting/components/auth/UserContext"
import { apiClient } from "@kiosk/reporting/lib/apiClient"
import { authorizationHeader } from "@kiosk/reporting/lib/headers"
import { queryClient } from "@kiosk/reporting/lib/queryClient"

export const createCategory = async (token: string): Promise<TaskCategory> => {
  const response = await apiClient.post<TaskCategory>(
    "/categories",
    {},
    {
      headers: authorizationHeader(token),
    },
  )

  return response.data
}

export const useCreateCategoryMutation = () => {
  const { t } = useTranslation(["common", "taskCategory"])
  const { token } = useUser()

  return useMutation({
    mutationFn: async () => await createCategory(token),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: taskKeys.all(),
      })
      notifications.show({
        title: t("common:messages.success"),
        message: t("taskCategory:success.createCategorySuccess"),
        color: "green",
      })
    },
    onError: (error: Error) => {
      //TODO: Handle errors
      notifications.show({
        title: t("common:messages.error"),
        message: error.message,
        color: "red",
      })
    },
  })
}
