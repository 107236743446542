import { Box, Flex, Group, Stack, Text, TextInput } from "@mantine/core"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"

import { useUser } from "@kiosk/reporting/components/auth/UserContext"

export const StructureDefintion = () => {
  const user = useUser()
  const { t } = useTranslation("onboarding")

  const structure = useMemo(
    () => [
      { label: t("definitions.company"), examples: user?.company?.name },
      { label: t("definitions.region"), examples: t("examples.region") },
      { label: t("definitions.site"), examples: t("examples.site") },
    ],
    [t, user?.company?.name],
  )

  return (
    <Flex direction="column" gap={0}>
      {structure.map(({ label, examples }, index) => (
        <Group gap={4} key={`tree-${index}`} ml={index * 8} wrap="nowrap">
          {index > 0 && (
            <Flex
              h="calc(100% - 40px)"
              mb={40}
              ml={(index - 1) * 13}
              style={{
                borderBottomLeftRadius: 8,
                borderBottom: "solid 1px var(--mantine-color-gray-4)",
                borderLeft: "solid 1px var(--mantine-color-gray-4)",
              }}
              w={9}
            />
          )}

          <Stack flex={1} gap={0} pt={index === 0 ? 0 : 20}>
            <TextInput
              label={t("level", { level: index + 1 })}
              readOnly
              value={label}
            />

            <Group align="flex-end">
              {index < structure.length - 1 && (
                <Flex
                  flex={0}
                  h={24}
                  ml={8}
                  style={{
                    borderLeft: "solid 1px var(--mantine-color-gray-4)",
                  }}
                  w={1}
                />
              )}

              <Box w={`calc(100% - ${index * 40 + 36}px)`}>
                <Text c="gray.4" fz="sm" truncate="end">
                  {examples}
                </Text>
              </Box>
            </Group>
          </Stack>
        </Group>
      ))}
    </Flex>
  )
}
