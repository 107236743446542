import { useQuery } from "@tanstack/react-query"
import _ from "lodash"

import { taskKeys } from "@kiosk/reporting/api/tasks/taskKeys"
import { useUser } from "@kiosk/reporting/components/auth/UserContext"
import { CategoryResponses } from "@kiosk/reporting/legacy/types/endpoints/categories"
import { TaskResponses } from "@kiosk/reporting/legacy/types/endpoints/tasks"
import { apiClient } from "@kiosk/reporting/lib/apiClient"
import { authorizationHeader } from "@kiosk/reporting/lib/headers"

export const getTasksByCategory = async (
  token: string,
  params?: TaskResponses.TaskParams,
): Promise<CategoryResponses.GetCategoriesAndTasks> => {
  const response = await apiClient.get<CategoryResponses.GetCategoriesAndTasks>(
    "/categories",
    { params, headers: authorizationHeader(token) },
  )

  return response.data
}

export const useTasksQuery = (params?: TaskResponses.TaskParams) => {
  const queryKey = params?.ownerId ? taskKeys.all(params) : taskKeys.all()
  const { token } = useUser()

  return useQuery({
    queryKey,
    queryFn: async () => {
      const categories = await getTasksByCategory(token, params)

      return {
        ...categories,
        data: categories.data.map((c) => ({
          ...c,
          tasks: _.sortBy(c.tasks, ["status", "dueDate"]),
        })),
      }
    },
  })
}
