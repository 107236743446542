import { Textarea, TextInput } from "@mantine/core"
import { useDebouncedCallback } from "@mantine/hooks"

import { InputProps } from "@kiosk/reporting/components/csrd/questions/types"
import { TextBlockRecord } from "@kiosk/reporting/legacy/types/data/dato"

export const TextBlockQuestion = ({
  content,
  formProps,
}: InputProps<TextBlockRecord>) => {
  const debouncedOnChange = useDebouncedCallback((value: string) => {
    formProps.onChange(value)
  }, 300)

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    debouncedOnChange(event.currentTarget.value)
  }

  return content.inputType === "text" ? (
    <TextInput {...formProps} onChange={handleChange} />
  ) : (
    <Textarea {...formProps} onChange={handleChange} />
  )
}
