import { Group, Stack, Text, ThemeIcon } from "@mantine/core"
import { useNavigate, NavLink } from "@remix-run/react"
import { IconChevronRight } from "@tabler/icons-react"

import { BackButton } from "@kiosk/reporting/components/BackButton"
import { Completion } from "@kiosk/reporting/components/Completion"
import { EsrsBadge } from "@kiosk/reporting/components/csrd/EsrsBadge"
import { ReportsResponses } from "@kiosk/reporting/legacy/types/endpoints/reports"
import classes from "@kiosk/reporting/routes/csrd_.$datoTopicId_.$datoSubTopicId/components/DisclosureRequirementSidebar.module.css"
import { routes } from "@kiosk/reporting/utils/constants/index"

type DisclosureRequirementSideBarProps = {
  readonly topic: ReportsResponses.GetCategories[number]["topics"][number]
  readonly esrsIsMaterial: boolean
}

export interface DisclosureRequirementInSidebarProps {
  readonly id: string
  readonly name: string
  readonly esrsCode: string
  readonly progress: number
  readonly topicId: string
  readonly esrsIsMaterial: boolean
}

const DisclosureRequirementInSidebar = ({
  id,
  name,
  esrsCode,
  progress,
  topicId,
  esrsIsMaterial,
}: DisclosureRequirementInSidebarProps) => {
  return (
    <NavLink
      to={routes.CSRD_DISCLOSURE_REQUIREMENT.path
        .replace(":datoTopicId", topicId)
        .replace(":datoSubTopicId", id)}
    >
      {({ isActive }) => (
        <Group
          className={`${classes["disclosure-requirement__item"]} ${isActive ? classes["disclosure-requirement__item--active"] : ""}`}
        >
          <Group className={classes["disclosure-requirement__left-item"]}>
            <Completion
              isNonMaterialEsrs={!esrsIsMaterial}
              progress={progress}
            />
            <EsrsBadge topic={esrsCode} variant="secondary" />
            <Text
              className={`${classes["disclosure-requirement__title"]} ${isActive ? classes["disclosure-requirement__title--active"] : ""} ${esrsIsMaterial ? "" : classes["disclosure-requirement__title--non-material"]}`}
            >
              {name}
            </Text>
          </Group>
          <ThemeIcon c="gray.7" h={22} w={22}>
            <IconChevronRight />
          </ThemeIcon>
        </Group>
      )}
    </NavLink>
  )
}

export const DisclosureRequirementSideBar = ({
  topic,
  esrsIsMaterial,
}: DisclosureRequirementSideBarProps) => {
  const navigate = useNavigate()

  const handleBack = () => {
    navigate(routes.CSRD.path.replace(":datoTopicId", topic.id))
  }

  return (
    <>
      <Group bg="white" p={16} pos="sticky" style={{ zIndex: 1 }} top={0}>
        <BackButton onClick={() => handleBack()} />
        <EsrsBadge topic={topic.code} />
        <Text c="gray.9" fw={600} fz="md">
          {topic.name}
        </Text>
      </Group>

      <Stack gap={0}>
        {topic.disclosureRequirements
          .filter((dr) => !dr.isHidden)
          .map(({ id, name, esrsCode, progress }) => {
            return (
              <DisclosureRequirementInSidebar
                esrsCode={esrsCode}
                esrsIsMaterial={esrsIsMaterial}
                id={id}
                key={id}
                name={name}
                progress={progress}
                topicId={topic.id}
              />
            )
          })}
      </Stack>
    </>
  )
}
