import { createContext } from "react"

import { User } from "@kiosk/reporting/legacy/types/user"
import { useAppRouteContext } from "@kiosk/reporting/lib/hooks/use-app-context"

type UserContextType = {
  user: User
}

export const UserContext = createContext<UserContextType>({
  // @ts-expect-error Creating this context to have an absolute to a defined user object.
  // Context shall not be used if user doesn't exist
  user: undefined,
})

export const useUser = () => {
  const { user } = useAppRouteContext()

  return user
}
