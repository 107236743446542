import _ from "lodash"
import { useMemo } from "react"

import { CsrdCategory } from "@kiosk/reporting/legacy/types/data/dato"
import { searchInArray } from "@kiosk/reporting/utils/text"

/**
 * This hook is used to filter questions in the question picker modal based on the current category, topic and search query.
 * It returns an array of questions that match the search query and are in the current category and topic.
 */
export const useCurrentQuestions = (
  // TODO: simplify this hook + remove memorization
  csrdCategories: CsrdCategory[],
  categoryId: string | null,
  topicId: string | undefined,
  search: string,
) => {
  const currentCategories = useMemo(
    () => csrdCategories?.filter(({ id }) => !categoryId || id === categoryId),
    [csrdCategories, categoryId],
  )

  const currentTopics = useMemo(
    () =>
      currentCategories
        ?.flatMap(({ topics }) => topics)
        .filter(({ id }) => !topicId || id === topicId),
    [currentCategories, topicId],
  )

  const currentQuestions = useMemo(
    () =>
      searchInArray(
        _.orderBy(
          currentTopics
            ?.flatMap(({ disclosureRequirements }) => disclosureRequirements)
            .flatMap(({ questions }) => questions),
          ({ label }) => label,
        ),
        search,
        "label",
      ),
    [currentTopics, search],
  )

  return currentQuestions
}
