import { Box, Button, Center, Image, Stack, Text } from "@mantine/core"
import { LoaderFunctionArgs } from "@remix-run/node"
import { Link } from "@remix-run/react"
import { useTranslation } from "react-i18next"
import { redirect } from "react-router-dom"
import { typedjson, useTypedLoaderData } from "remix-typedjson"

import emptyDashboard from "@kiosk/reporting/assets/img/empty-dashboard.png"
import { PageLayout } from "@kiosk/reporting/components/layout/PageLayout"
import { clientConfig } from "@kiosk/reporting/config"
import { serverConfig } from "@kiosk/reporting/config.server"
import { prisma } from "@kiosk/reporting/db.server"
import { User } from "@kiosk/reporting/legacy/types/user"
import classes from "@kiosk/reporting/routes/dashboard/Dashboard.module.css"
import DashboardHeader from "@kiosk/reporting/routes/dashboard/components/DashboardHeader"
import {
  DemoDashboardContent,
  loadDemoData,
} from "@kiosk/reporting/routes/dashboard/components/demo/DemoDashboard"
import { loadProductionData } from "@kiosk/reporting/routes/dashboard/components/production/loaders.server"
import { requireUser } from "@kiosk/reporting/utils/auth.server"
import { routes } from "@kiosk/reporting/utils/constants"

export const handle = {
  title: () => "dashboard",
}

/**
 * We don’t load data in te same way whether it’s on demo or production.
 *
 * In production, we don’t have the dimensions we can filter by.
 *
 * Since the two features don’t have the same data schema, the result
 * of the loader is under different keys.
 */
export async function loader(args: LoaderFunctionArgs) {
  try {
    const user = await requireUser(args)

    if (user.roles.includes("CONTRIBUTOR")) {
      return redirectContributorsToLastSurvey(user)
    }

    if (serverConfig.isDemo) {
      const url = new URL(args.request.url)

      const filters = {
        zone: url.searchParams.get("zone") ?? "all",
        time: url.searchParams.get("time") ?? "all",
        subsidiary: url.searchParams.get("subsidiary") ?? "all",
      }

      const data = loadDemoData(filters)

      return typedjson({ user, demoData: data })
    }

    const dashboardCategories = await loadProductionData(
      user.company.id,
      user.token,
    )

    return typedjson({
      user,
      dashboardCategories,
    })
  } catch (error) {
    console.error(error)
    // Optionally, return a meaningful response or redirect on error
    throw new Response("Failed to load data", { status: 500 })
  }
}

export default function Dashboard() {
  const data = useTypedLoaderData<typeof loader>()
  const { t } = useTranslation("dashboard")

  return (
    <PageLayout contained>
      <Stack gap={48} mih="100%" pb={40}>
        <DashboardHeader userFirstName={data.user.firstName} />
        {"demoData" in data && (
          <DemoDashboardContent categories={data.demoData} />
        )}
        {!clientConfig.isDemo && (
          <Box className={classes.dashboard__empty}>
            <Center my={28}>
              <Image alt="Empty Dashboard" src={emptyDashboard} w="100%" />
            </Center>
            <Center m={24}>
              <Text className={classes["dashboard__empty--text"]}>
                {t("empty.text")}
              </Text>
            </Center>
            <Center m={80}>
              <Button
                component={Link}
                to={routes.CSRD.path.replace(":datoTopicId", "")}
              >
                {t("empty.addData")}
              </Button>
            </Center>
          </Box>
        )}

        {/* {"demoData" in data ? (
          <DemoDashboardContent categories={data.demoData} />
        ) : (
          <ProductionDashboardContent categories={data.dashboardCategories} />
        )}

        {"dashboardCategories" in data &&
          data.dashboardCategories.length === 0 && <EmptyDashboard />}
          */}
      </Stack>
    </PageLayout>
  )
}

const redirectContributorsToLastSurvey = async (user: User) => {
  // Contributors land on the dashboard the first time
  // they log in because of the flow.
  // They need to be redirected to the last assigned survey.
  //
  // This is clearly a hack because we don’t have a lot of time
  // and needs to be thought through better.
  const lastEmail = await prisma.surveyEmail.findFirst({
    where: {
      assigneeId: user.id,
    },
    orderBy: {
      createdAt: "desc",
    },
  })

  if (!lastEmail) {
    throw redirect("/no-surveys")
  }

  const lastSurvey = await prisma.survey.findUnique({
    where: {
      id: lastEmail.surveyId,
    },
  })

  throw redirect(`/answer_survey/${lastSurvey!.id}`)
}
