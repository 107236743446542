import { Group, Select, Title } from "@mantine/core"
import { useSearchParams } from "@remix-run/react"
import { useTranslation } from "react-i18next"

import { clientConfig } from "@kiosk/reporting/config"

interface Props {
  readonly userFirstName: string
}

export default function DashboardHeader(props: Props) {
  const { t } = useTranslation("dashboard")

  return (
    <Group justify="space-between">
      <Title m={0} mb={12}>
        {t("title", { firstName: props.userFirstName })}
      </Title>

      <Group>
        {clientConfig.isDemo && <DemoFilters />}

        {/* <Link download reloadDocument to="/dashboard/export">
          <Button leftSection={<IconDownload />}>{t("actions.export")}</Button>
        </Link> */}
      </Group>
    </Group>
  )
}

function DemoFilters() {
  const { t } = useTranslation("dashboard")
  const [searchParams, setSearchParams] = useSearchParams()

  const handleFilterChange = (filterType: string, value: string) => {
    const newSearchParams = new URLSearchParams(searchParams)

    newSearchParams.set(filterType, value)
    setSearchParams(newSearchParams)
  }

  const zone = searchParams.get("zone") ?? "all"
  const subsidiary = searchParams.get("subsidiary") ?? "all"
  const time = searchParams.get("time") ?? "all"

  return (
    <Group>
      <Select
        data={[
          { value: "all", label: t("select.zone.all") },
          { value: "france", label: t("select.zone.france") },
          { value: "spain", label: t("select.zone.spain") },
          { value: "italia", label: t("select.zone.italia") },
        ]}
        onChange={(value) => {
          if (value) handleFilterChange("zone", value)
        }}
        placeholder="Zone"
        value={zone}
        w={150}
      />

      <Select
        data={[
          { value: "all", label: t("select.subsidiary.all") },
          { value: "hr", label: t("select.subsidiary.hr") },
          { value: "tech", label: t("select.subsidiary.tech") },
          {
            value: "operations",
            label: t("select.subsidiary.operations"),
          },
        ]}
        onChange={(value) => {
          if (value) handleFilterChange("subsidiary", value)
        }}
        value={subsidiary}
        w={150}
      />

      <Select
        data={[
          { value: "all", label: t("select.year.all") },
          { value: "2024", label: "2024" },
          { value: "2023", label: "2023" },
          { value: "2022", label: "2022" },
        ]}
        onChange={(value) => {
          if (value) handleFilterChange("time", value)
        }}
        value={time}
        w={150}
      />
    </Group>
  )
}
