import { Flex, Stack, Skeleton, Text as MantineText, Box } from "@mantine/core"
import { DisclosureRequirementParagraph } from "@prisma/client"
//@ts-ignore there is no type definition for this package
import HTMLRenderer from "react-html-renderer"
//@ts-ignore there is no type definition for this package
import XMLViewer from "react-xml-viewer"

import classes from "@kiosk/reporting/components/csrd/DisclosureRequirementPreview/DisclosureRequirementPreview.module.css"
import { PreviewFooter } from "@kiosk/reporting/components/csrd/DisclosureRequirementPreview/PreviewFooter"

const Text = ({ children }: { readonly children: JSX.Element }) => {
  return (
    <MantineText
      c="gray.6"
      fz="lg"
      span
      style={{
        textAlign: "justify",
      }}
    >
      {children}{" "}
    </MantineText>
  )
}

const Empty = ({ children }: { readonly children: JSX.Element }) => {
  return <>{children}</>
}

const DisclosureRequirementPreviewSkeleton = () => {
  return (
    <Stack h="50vh" justify="center" mx={90} my={15}>
      <Skeleton height={15} radius="xs" />

      <Skeleton height={15} mt={1} radius="xs" />

      <Skeleton height={15} mt={1} radius="xs" />

      <Skeleton height={15} mt={1} radius="xs" />

      <Skeleton height={15} mt={1} radius="xs" />

      <Skeleton height={15} mt={1} radius="xs" />

      <Skeleton height={15} mt={1} radius="xs" />

      <Skeleton height={15} mt={1} radius="xs" />

      <Skeleton height={15} mt={1} radius="xs" />

      <Skeleton height={15} mt={1} radius="xs" />

      <Skeleton height={15} mt={1} radius="xs" />

      <Skeleton height={15} mt={1} radius="xs" width="70%" />
    </Stack>
  )
}

type TableProps = {
  readonly children: React.ReactNode
}

const Thead = ({ children }: TableProps) => {
  return <thead style={{ textAlign: "left" }}>{children}</thead>
}

const Tbody = ({ children }: TableProps) => {
  return <tbody style={{ textAlign: "left" }}>{children}</tbody>
}

interface DisclosureRequirementPreview {
  readonly xmlMode: boolean
  readonly loading: boolean
  readonly previewParagraph: DisclosureRequirementParagraph
  readonly onGenerate: () => void
}

export const DisclosureRequirementPreview = (
  props: DisclosureRequirementPreview,
) => {
  return (
    <Box className={classes["dr-preview"]}>
      <Stack className={classes["dr-preview__container"]}>
        {props.loading ? (
          <DisclosureRequirementPreviewSkeleton />
        ) : (
          <Flex direction="row" mb="xl" wrap="wrap">
            {props.xmlMode ? (
              <Stack w="48vw">
                <XMLViewer xml={props.previewParagraph?.ixbrlParagraph} />
              </Stack>
            ) : (
              <Box mx={90} my="xl" style={{ textAlign: "justify" }}>
                <HTMLRenderer
                  components={{
                    thead: Thead,
                    tbody: Tbody,
                    span: Text,
                    "ix:nonfraction": Empty,
                    "ix:nonnumeric": Empty,
                  }}
                  html={props.previewParagraph?.ixbrlParagraph}
                />
              </Box>
            )}
          </Flex>
        )}

        <PreviewFooter
          isPendingGeneration={Boolean(props.loading)}
          onGenerate={props.onGenerate}
        />
      </Stack>
    </Box>
  )
}
