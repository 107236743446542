import { useQuery } from "@tanstack/react-query"

import { surveysKeys } from "@kiosk/reporting/api/surveys/surveysKeys"
import { useUser } from "@kiosk/reporting/components/auth/UserContext"
import { listSurveysSchema } from "@kiosk/reporting/legacy/shared/schemas/surveys"
import { SurveyResponses } from "@kiosk/reporting/legacy/types/endpoints/surveys"
import { apiClient } from "@kiosk/reporting/lib/apiClient"
import { authorizationHeader } from "@kiosk/reporting/lib/headers"

export const useListSurveysQuery = () => {
  const { token } = useUser()

  return useQuery({
    queryKey: surveysKeys.list(),
    queryFn: async (): Promise<SurveyResponses.Survey[]> => {
      const response = await apiClient.get<SurveyResponses.Survey[]>(
        "/surveys",
        { headers: authorizationHeader(token) },
      )

      return listSurveysSchema.parseAsync(response.data)
    },
  })
}
