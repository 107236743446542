import { Box, Divider, Group, Text } from "@mantine/core"
import { useTranslation } from "react-i18next"

import classes from "@kiosk/reporting/components/BulkAction/BulkAction.module.css"

type BulkActionProps = {
  readonly children: React.ReactNode
  readonly nbSelected: number
  readonly hasAllSelect?: boolean
}

export const BulkAction = ({
  children,
  nbSelected,
  hasAllSelect = false,
}: BulkActionProps) => {
  const { t } = useTranslation("tables")

  return (
    <Box
      className={`${classes["bulk-action"]} ${hasAllSelect ? classes["bulk-action__has-all-select"] : ""}`}
    >
      <Text className={classes["bulk-action__selected"]}>
        {t("nbSelected", { count: nbSelected })}
      </Text>
      <Divider orientation="vertical" />
      <Group className={classes["bulk-action__action"]}>{children}</Group>
    </Box>
  )
}
