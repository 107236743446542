import {
  BarChart,
  DonutChart,
  DonutChartCell,
  LineChart,
} from "@mantine/charts"
import { Box, Group, Stack, Text } from "@mantine/core"

import { DimensionedDataPointDemo } from "@kiosk/reporting/api/dashboard/types"
import { formatNumber } from "@kiosk/reporting/utils/format"

type Props = Pick<
  DimensionedDataPointDemo,
  "baseColor" | "chart" | "info" | "unit" | "values"
> & { label: string }

export default function DataPointsDimensionedDemo({
  baseColor,
  chart,
  label,
  unit,
  values,
}: Readonly<Props>) {
  function formatBarAndLineData(values: DimensionedDataPointDemo["values"]) {
    return (values ?? []).map((item) => ({
      quarter: item.dimensions[0].dimensionLabel, // Récupère le dimensionLabel comme nom
      value: item.value, // Récupère la valeur
      color: item.baseColor, // Récupère la couleur de base
    }))
  }

  function formatDonutChartData(
    values: DimensionedDataPointDemo["values"],
  ): DonutChartCell[] {
    return (values ?? []).map((item) => ({
      name: item.dimensions[0].dimensionLabel, // Récupère le dimensionLabel comme nom
      value: item.value, // Récupère la valeur
      color: item.baseColor!, // Récupère la couleur de base
    }))
  }

  if (chart === "bar") {
    const data = formatBarAndLineData(values)

    return (
      <Stack gap={24} h="100%">
        <Text c="gray.5" fw={500} fz="lg">
          {label}
        </Text>

        <Group gap={40} h="100%" style={{ flexGrow: 1 }}>
          <BarChart
            barProps={{ radius: 6 }}
            data={data}
            dataKey="quarter"
            h={244}
            series={[{ name: "value", color: baseColor }]}
            style={{ flexGrow: 1 }}
            tickLine="none"
            tooltipAnimationDuration={200}
            valueFormatter={(value) => formatNumber(value)}
          />
        </Group>
      </Stack>
    )
  }

  if (chart === "donut") {
    const data = formatDonutChartData(values)

    return (
      <Stack gap={12} h="100%">
        <Text c="gray.5" fw={500} fz="lg">
          {label}
        </Text>

        <Group gap={40} h="100%" style={{ flexGrow: 1 }}>
          <DonutChart
            data={data}
            h={244}
            style={{ flexGrow: 1 }}
            thickness={30}
            valueFormatter={(value) => formatNumber(value) + "%"}
          />

          <Stack gap={12} style={{ flexGrow: 1 }}>
            {(values ?? []).map((value, index) => (
              <Group justify="space-between" key={index}>
                <Group gap={16}>
                  <Box
                    bg={value?.baseColor}
                    h={24}
                    size={24}
                    style={{ borderRadius: "6px" }}
                    w={24}
                  />

                  <div>{value.dimensions[0].dimensionLabel}</div>
                </Group>

                <Text fw={700} fz={16}>
                  {formatNumber(value.value)}%
                </Text>
              </Group>
            ))}
          </Stack>
        </Group>
      </Stack>
    )
  }

  if (chart === "line") {
    const data = formatBarAndLineData(values)

    return (
      <Stack gap={12} h="100%">
        <Text c="gray.5" fw={500} fz="lg">
          {label}
        </Text>

        <Group gap={40}>
          <LineChart
            curveType="linear"
            data={data}
            dataKey="quarter"
            h={244}
            series={[{ name: "value", color: baseColor }]}
            style={{ flexGrow: 1 }}
            unit={unit}
            valueFormatter={(value) => formatNumber(value)}
          />
        </Group>
      </Stack>
    )
  }
}
