import {
  ActionIcon,
  Box,
  Button,
  Drawer,
  Group,
  Stack,
  TextInput,
} from "@mantine/core"
import { notifications } from "@mantine/notifications"
import { useFetcher } from "@remix-run/react"
import { IconPlus, IconTrash } from "@tabler/icons-react"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { CompanyDimensionAggregate } from "@kiosk/reporting/lib/aggregates/companyDimensionAggregate"
import { action } from "@kiosk/reporting/routes/dimensions/route"

type DrawerProps = {
  readonly dimension?: CompanyDimensionAggregate
  readonly onClose: () => void
}

type FormProps = DrawerProps & {
  readonly dimension: CompanyDimensionAggregate
}

export const updateDimension = "update-dimension-option"

const _EditDimensionDrawer = ({ dimension, onClose }: FormProps) => {
  const { t } = useTranslation("dimensions")
  const fetcher = useFetcher<typeof action>()

  const initialInputFields = dimension.companyDimensionOptions.map(
    (option) => ({
      id: option.id,
      label: option.label,
    }),
  )

  const [inputFields, setInputFields] = useState(initialInputFields)

  const handleAddInput = () => {
    setInputFields([
      ...inputFields,
      { id: Math.random().toString(), label: "" },
    ])
  }

  const handleRemoveInput = (index: number) => {
    setInputFields(inputFields.filter((_, i) => i !== index))
  }

  const handleInputChange = (index: number, value: string) => {
    setInputFields(
      inputFields.map((field, i) =>
        i === index ? { ...field, label: value } : field,
      ),
    )
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const formData = new FormData(event.currentTarget)

    fetcher.submit(formData, {
      method: "post",
      action: "/dimensions",
    })
  }

  useEffect(() => {
    if (fetcher.state === "idle" && fetcher.data) {
      if (fetcher.data.success) {
        notifications.show({
          title: t("notifications.edit.success.title"),
          message: t("notifications.edit.success.message"),
          color: "green",
        })
        onClose()
      } else {
        notifications.show({
          title: t("notifications.edit.error.title"),
          message: t("notifications.edit.error.message"),
          color: "red",
        })
      }
    }
  }, [fetcher.state, fetcher.data, onClose, t])

  return (
    <Stack gap={32} h="100%">
      <Drawer.Header
        p={24}
        style={{ borderBottom: "1px solid var(--mantine-color-gray-3)" }}
      >
        <Drawer.Title c="black" fw={600} fz="lg">
          {dimension.label}
        </Drawer.Title>

        <Drawer.CloseButton />
      </Drawer.Header>

      <Stack h="100%" justify="space-between">
        <fetcher.Form
          id={updateDimension}
          method="post"
          name={updateDimension}
          onSubmit={handleSubmit}
        >
          <Drawer.Body h="100%" px={24}>
            <input
              defaultValue={updateDimension}
              hidden
              name="intent"
              required
              type="text"
            />
            <input
              defaultValue={dimension.id}
              hidden
              name="dimensionID"
              type="text"
            />
            <Stack>
              {inputFields.map((field, index) => (
                <Group
                  justify="space-between"
                  key={`dimension-${index}`}
                  style={{
                    borderBottom: "1px solid var(--mantine-color-gray-3)",
                  }}
                  w="100%"
                  wrap="nowrap"
                >
                  <TextInput
                    name="labels"
                    onChange={(e) => handleInputChange(index, e.target.value)}
                    placeholder={t("drawer.fields.labelPlaceholder")}
                    value={field.label}
                    variant="unstyled"
                    w="90%"
                  />
                  <ActionIcon
                    color="gray.5"
                    onClick={() => handleRemoveInput(index)}
                    size="lg"
                    variant="transparent"
                  >
                    <IconTrash size={20} />
                  </ActionIcon>
                </Group>
              ))}

              <Box
                style={{
                  borderBottom: "1px solid var(--mantine-color-gray-3)",
                }}
              >
                <Button
                  color="gray.6"
                  leftSection={<IconPlus color="green" />}
                  miw={100}
                  ml={-20}
                  onClick={handleAddInput}
                  variant="transparent"
                >
                  {t("drawer.actions.newMember")}
                </Button>
              </Box>
            </Stack>
          </Drawer.Body>
        </fetcher.Form>
        <Group
          gap={10}
          justify="flex-end"
          p={24}
          style={{ borderTop: "1px solid var(--mantine-color-gray-3)" }}
        >
          <Button color="gray" onClick={onClose} variant="outline">
            {t("drawer.actions.cancel")}
          </Button>

          <Button
            disabled={
              JSON.stringify(inputFields) === JSON.stringify(initialInputFields)
            }
            form={updateDimension}
            type="submit"
          >
            {t("drawer.actions.save")}
          </Button>
        </Group>
      </Stack>
    </Stack>
  )
}

export const EditCompanyDimensionDrawer = ({
  dimension,
  onClose,
}: DrawerProps) => {
  return (
    <Drawer.Root
      onClose={onClose}
      opened={!!dimension}
      position="right"
      size="md"
    >
      <Drawer.Overlay />

      <Drawer.Content>
        {dimension && (
          <_EditDimensionDrawer dimension={dimension} onClose={onClose} />
        )}
      </Drawer.Content>
    </Drawer.Root>
  )
}
