import { Group, Text, ThemeIcon, Tooltip } from "@mantine/core"
import { IconExclamationCircle } from "@tabler/icons-react"

import { CsrdCategory } from "@kiosk/reporting/legacy/types/data/dato"
import { LocalizedCompanySurveyQuestion } from "@kiosk/reporting/lib/services/company-survey-question.service.server"
import { useQuestionLabel } from "@kiosk/reporting/utils/useQuestionLabel"

type QuestionsCellProps = {
  readonly questionDatoId: string
  readonly instructions?: string
  readonly rawDataPointsQuestions: CsrdCategory[]
  readonly companySurveyQuestions?: LocalizedCompanySurveyQuestion[]
}

export const QuestionsCell = ({
  questionDatoId,
  instructions,
  rawDataPointsQuestions,
  companySurveyQuestions,
}: QuestionsCellProps) => {
  const questionLabel = useQuestionLabel(
    questionDatoId,
    rawDataPointsQuestions,
    companySurveyQuestions,
  )

  return (
    <Group justify="space-between" w="100%" wrap="nowrap">
      <Text>{questionLabel}</Text>
      {instructions && (
        <Tooltip label={instructions} maw={220} multiline>
          <ThemeIcon size="sm" variant="light">
            <IconExclamationCircle />
          </ThemeIcon>
        </Tooltip>
      )}
    </Group>
  )
}
