import { Group } from "@mantine/core"

import { SourceType } from "@kiosk/reporting/legacy/types/sources"
import { SOURCE_TYPES_INFO } from "@kiosk/reporting/routes/sources/utils"

type Props = { readonly sourceType: SourceType }

export const SourceTypeItem = ({ sourceType }: Props) => {
  const { name, icon: Icon } = SOURCE_TYPES_INFO.find(
    (type) => type.key === sourceType,
  )!

  return (
    <Group gap={8}>
      <Icon size={16} />
      {name}
    </Group>
  )
}
