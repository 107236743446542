import { Button, Divider, Drawer, Group, Stack, Text } from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import { User } from "@prisma/client"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"

import { useUpdateUserMutation } from "@kiosk/reporting/api/users/updateUser"
import { PictureUploader } from "@kiosk/reporting/components/PictureUploader"
import { useUser } from "@kiosk/reporting/components/auth/UserContext"
import { Locale } from "@kiosk/reporting/i18n"
import {
  UserBody,
  userSchema,
} from "@kiosk/reporting/legacy/shared/schemas/users"
import { UserFormFields } from "@kiosk/reporting/routes/settings.users/components/UserFormFields"
import { getInitials } from "@kiosk/reporting/utils/helpers"

type Props = {
  readonly close: () => void
  readonly opened: boolean
  readonly selectedUserId: string
  readonly selectedUserData: User
}

export const UpdateUser = ({
  close,
  opened,
  selectedUserId,
  selectedUserData,
}: Props) => {
  const { t } = useTranslation()
  const currentUser = useUser()

  const { reset, getInputProps, isValid, onSubmit, setValues } =
    useForm<UserBody>({
      initialValues: {
        email: selectedUserData.email,
        firstName: selectedUserData.firstName,
        lastName: selectedUserData.lastName,
        avatarUrl: selectedUserData.avatarUrl,
        jobTitle: selectedUserData.jobTitle,
        locale: selectedUserData.locale as Locale,
        roles: selectedUserData.roles,
        department: selectedUserData.department,
      },
      validate: zodResolver(userSchema),
    })

  useEffect(() => {
    if (selectedUserData) {
      setValues({
        email: selectedUserData.email,
        firstName: selectedUserData.firstName,
        lastName: selectedUserData.lastName,
        avatarUrl: selectedUserData.avatarUrl,
        jobTitle: selectedUserData.jobTitle,
        roles: selectedUserData.roles,
        department: selectedUserData.department,
        locale: selectedUserData.locale as Locale,
      })
    }
  }, [selectedUserData, setValues])

  const handleCancel = () => {
    reset()
    close()
  }

  const { mutate: updateAccount, isPending } =
    useUpdateUserMutation(selectedUserId)

  const handleUpdateUser = onSubmit(async (data, event) => {
    event?.preventDefault()
    updateAccount({ userId: selectedUserId, data })
    handleCancel()
  })

  return (
    <Drawer.Root onClose={close} opened={opened} position="right" size="lg">
      <Drawer.Overlay />
      <Drawer.Content>
        <Stack gap={32} h="100%">
          <Drawer.Header
            p={24}
            style={{
              borderBottom: "1px solid var(--mantine-color-gray-3)",
            }}
          >
            <Drawer.Title c="black" fw={600} fz="lg">
              {t("settings:users.updateUser")}
            </Drawer.Title>

            <Drawer.CloseButton />
          </Drawer.Header>

          <Stack px={24}>
            <Text c="black" fw={500}>
              {t("settings:account.profileImage")}
            </Text>
            <Group gap={24}>
              <PictureUploader
                destination="user-avatars"
                placeholder={getInitials(
                  selectedUserData.firstName,
                  selectedUserData.lastName,
                )}
                {...getInputProps("avatarUrl")}
              />
            </Group>
            <Divider />
          </Stack>

          <Stack h="100%" justify="space-between">
            <Drawer.Body px={24}>
              <form id="update-user" onSubmit={handleUpdateUser}>
                <UserFormFields
                  currentUser={currentUser}
                  disabledEmail
                  getInputProps={getInputProps}
                />
              </form>
            </Drawer.Body>
            <Group
              gap={10}
              justify="flex-end"
              p={24}
              style={{ borderTop: "1px solid var(--mantine-color-gray-3)" }}
            >
              <Button color="gray" onClick={handleCancel} variant="outline">
                {t("buttons.cancel")}
              </Button>
              <Button
                disabled={!isValid() || isPending}
                form="update-user"
                loading={isPending}
                type="submit"
              >
                {t("settings:users.updateUser")}
              </Button>
            </Group>
          </Stack>
        </Stack>
      </Drawer.Content>
    </Drawer.Root>
  )
}
