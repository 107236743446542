import { Button, FileButton } from "@mantine/core"
import { useTranslation } from "react-i18next"

import { useUploadPrivateFileMutation } from "@kiosk/reporting/api/uploadFile"

export const UploadAnswerFileButton = ({
  onSuccess,
}: {
  readonly onSuccess: (name: string, path: string, signedUrl: string) => void
}) => {
  const { t } = useTranslation("survey")

  const { mutateAsync: uploadFile, isPending: isUploading } =
    useUploadPrivateFileMutation({
      destination: "survey-answer-file/",
      sendNotificationOnError: true,
    })

  return (
    <FileButton
      onChange={async (f) => {
        const response = await uploadFile(f)

        if (response)
          onSuccess(
            response.file.originalname,
            response.file.path,
            response.signedUrl,
          )
      }}
    >
      {(props) => (
        <Button color="gray" loading={isUploading} variant="outline" {...props}>
          {t("actions.upload")}
        </Button>
      )}
    </FileButton>
  )
}
