import { LoaderFunctionArgs } from "@remix-run/server-runtime"
import { typedjson, useTypedLoaderData } from "remix-typedjson"

import { CategoryAggregateDatoRepository } from "@kiosk/reporting/features/dato/infrastructure/repositories/category-aggregates"
import { Locale } from "@kiosk/reporting/i18n"
import { CompanyDimensionPostgresRepository } from "@kiosk/reporting/lib/repositories/company-dimension-repository.server"
import { CompanySurveyQuestionRepository } from "@kiosk/reporting/lib/repositories/company-survey-question.server"
import { createCompanySurveyQuestionService } from "@kiosk/reporting/lib/services/company-survey-question.service.server"
import { EditQuestion } from "@kiosk/reporting/routes/sources_.edit_question.$id/components/EditQuestion"
import { requireUser } from "@kiosk/reporting/utils/auth.server"

export const handle = {
  title: () => "editQuestion",
}

export type Assignee = {
  id: string
  email: string
  fullName: string
  locale: string
}

const companySurveyQuestionService = createCompanySurveyQuestionService(
  CompanySurveyQuestionRepository,
)

export const loader = async (args: LoaderFunctionArgs) => {
  const { company, token, locale } = await requireUser(args)

  // companyDimensions is expected by another component downstream.
  const [companyDimensions, rawDataPointsQuestions, companySurveyQuestions] =
    await Promise.all([
      CompanyDimensionPostgresRepository.getDimensionsWithOptions(company.id),
      CategoryAggregateDatoRepository.fetchRawDataPoints(
        token,
        locale as Locale,
      ),
      companySurveyQuestionService.getLocalizedCompanySurveyQuestions(
        company.id,
        locale as Locale,
      ),
    ])

  return typedjson({
    companyDimensions,
    rawDataPointsQuestions,
    companySurveyQuestions,
  })
}

export default function EditQuestionPage() {
  const { rawDataPointsQuestions, companySurveyQuestions } =
    useTypedLoaderData<typeof loader>()

  return (
    <EditQuestion
      companySurveyQuestions={companySurveyQuestions}
      rawDataPointsQuestions={rawDataPointsQuestions}
    />
  )
}
