export const routes = {
  SETTINGS: {
    path: "/settings/:tab",
  },
  TASKS: { path: "/tasks" },
  SOURCES: { path: "/sources" },
  TABLES: { path: "/sources/tables" },
  SURVEYS: {
    path: "/sources/surveys",
  },
  SURVEY_DOWNLOAD: {
    path: "/sources/survey/:id/download",
  },
  EDIT_SURVEY: {
    path: "/sources/edit_survey/:id",
  },
  EDIT_QUESTION: {
    path: "/sources/edit_question/:id",
  },
  SURVEY_ANSWERS: {
    path: "/sources/survey_answers/:id",
  },
  ANSWER_SURVEY: {
    path: "/answer_survey/:id",
  },
  DIMENSIONS: {
    path: "/dimensions",
  },
  DASHBOARD: { path: "/dashboard" },
  COMPANIES: { path: "/companies" },
  USERS: {
    path: "/companies/:id/users",
  },
  // NOTE: Added this route just for testing purposes, will decide in next spring on how the onboarding should look like
  SETUP_ACCOUNT: {
    path: "/onboarding/setup-account",
  },
  CSRD: {
    path: "/csrd/:datoTopicId?",
  },
  CSRD_DISCLOSURE_REQUIREMENT: {
    path: "/csrd/:datoTopicId/:datoSubTopicId",
  },
  CONGRATULATIONS: {
    path: "/congratulations",
  },
  ONBOARDING: {
    path: "/onboarding",
  },
} as const

export type SettingsTab = "account" | "users" | "integrations" | "language"

export const composeSettingsTabRoute = (tab: SettingsTab) =>
  routes.SETTINGS.path.replace(":tab", tab)
