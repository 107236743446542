import { Group, Text } from "@mantine/core"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"

type Props = {
  readonly status: "TODO" | "IN_PROGRESS" | "DONE"
}

export const StatusPill = ({ status }: Props) => {
  const { t } = useTranslation()

  const { label, color } = useMemo(() => {
    const statusMap = {
      TODO: { label: t("statuses.todo"), color: "gray.1" },
      IN_PROGRESS: { label: t("statuses.inProgress"), color: "yellow.1" },
      DONE: { label: t("statuses.done"), color: "green.1" },
    }

    return statusMap[status]
  }, [t, status])

  return (
    <Group bg={color} h={26} px={10} style={{ borderRadius: 6 }}>
      <Text>{label}</Text>
    </Group>
  )
}
