import { Group, ActionIcon, Text } from "@mantine/core"
import { IconDownload, IconX } from "@tabler/icons-react"

export const SurveyFileUploaded = ({
  file,
  onReset,
}: {
  readonly file: { name: string; signedUrl: string }
  readonly onReset: () => void
}) => (
  <Group flex={1} justify="space-between" maw={200} wrap="nowrap">
    <Group style={{ overflow: "hidden" }} wrap="nowrap">
      <ActionIcon
        color="gray.4"
        onClick={() => window.open(file.signedUrl, "_blank")}
        variant="subtle"
      >
        <IconDownload size={24} />
      </ActionIcon>

      <Text truncate="end">{file.name}</Text>
    </Group>

    <ActionIcon color="gray.4" onClick={onReset} variant="subtle">
      <IconX size={24} />
    </ActionIcon>
  </Group>
)
