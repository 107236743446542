import { useQuery } from "@tanstack/react-query"

import { assignmentsKeys } from "@kiosk/reporting/api/surveys/assignments/assignmentsKeys"
import { useUser } from "@kiosk/reporting/components/auth/UserContext"
import { AssignmentResponses } from "@kiosk/reporting/legacy/types/endpoints/assignments"
import { apiClient } from "@kiosk/reporting/lib/apiClient"
import { authorizationHeader } from "@kiosk/reporting/lib/headers"

export const useGetContributorAssignmentsQuery = (surveyId: string) => {
  const { token } = useUser()

  return useQuery({
    queryKey: assignmentsKeys.getContributorAssignments(surveyId),
    queryFn: async (): Promise<AssignmentResponses.ContributorAssignments> => {
      const response =
        await apiClient.get<AssignmentResponses.ContributorAssignments>(
          "/assignments",
          { params: { surveyId }, headers: authorizationHeader(token) },
        )

      return response.data
    },
  })
}
