import { Alert, Container } from "@mantine/core"
import { IconAlertCircle } from "@tabler/icons-react"
import { t } from "i18next"

interface LoaderErrorProps {
  readonly title?: string
  readonly body?: string
}

export const LoaderError = ({ title, body }: LoaderErrorProps) => {
  return (
    <Container mt="5%">
      <Alert
        color="red"
        icon={<IconAlertCircle />}
        title={title ?? t("queryWrapper.error.title")}
      >
        {body ?? t("queryWrapper.error.message")}
      </Alert>
    </Container>
  )
}
