import { ActionIcon } from "@mantine/core"
import { IconEdit, IconTrash } from "@tabler/icons-react"
import { useTranslation } from "react-i18next"

import { Column } from "@kiosk/reporting/components/generic/types"
import { userColumns } from "@kiosk/reporting/components/userTable/userColumns"
import { User } from "@kiosk/reporting/legacy/types/user"

export const useSettingsColumns = (
  showDeleteModal: (value: string) => void,
  showUpdateModal: (value: string) => void,
  isPendingDelete: boolean,
  selectedUserId: string | null,
): Array<Column<User>> => {
  const { t } = useTranslation()

  const actionsColumn = {
    key: "actions",
    colId: "id",
    title: t("company:columns.actions"),
    render: (id: string) => (
      <>
        <ActionIcon
          color="gray.5"
          onClick={() => {
            showUpdateModal(id)
          }}
          size="lg"
          variant="transparent"
        >
          <IconEdit size={20} />
        </ActionIcon>

        <ActionIcon
          color="red.5"
          loading={selectedUserId === id && isPendingDelete}
          onClick={() => showDeleteModal(id)}
          size="lg"
          variant="transparent"
        >
          <IconTrash size={20} />
        </ActionIcon>
      </>
    ),
  }

  return [...userColumns, actionsColumn]
}
