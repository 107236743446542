import { LoaderFunctionArgs, defer } from "@remix-run/node"
import { useLoaderData, Await } from "@remix-run/react"
import { Suspense } from "react"

import { SubjectFormSkeleton } from "@kiosk/reporting/components/onboarding/SubjectFormSkeleton"
import { Locale } from "@kiosk/reporting/i18n"
import { getSubjectsData } from "@kiosk/reporting/lib/infra/subjects-infra.server"
import { SubjectSettingsPage } from "@kiosk/reporting/routes/settings.subjects/components/SubjectsSettingsPage"
import { requireUser } from "@kiosk/reporting/utils/auth.server"

export async function loader(args: LoaderFunctionArgs) {
  const { companyId, locale, token } = await requireUser(args)

  return defer({
    promise: getSubjectsData({ companyId, locale: locale as Locale, token }),
  })
}

export const handle = {
  breadcrumb: () => "subjects",
  title: () => "subjects",
}

export default function SubjectsSettingsPage() {
  const loaderState = useLoaderData<typeof loader>()

  return (
    <Suspense fallback={<SubjectFormSkeleton />}>
      <Await resolve={loaderState.promise}>
        <SubjectSettingsPage />
      </Await>
    </Suspense>
  )
}
