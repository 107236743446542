import { PageLayout } from "@kiosk/reporting/components/layout/PageLayout"
import { CompanyStep } from "@kiosk/reporting/routes/_onboarding.onboarding/components/CompanyStep"

export const CompanySettings = () => {
  return (
    <PageLayout>
      <CompanyStep />
    </PageLayout>
  )
}
