import { ActionIcon, Menu } from "@mantine/core"
import { IconDotsVertical } from "@tabler/icons-react"
import { UseMutateFunction } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"

import { Column } from "@kiosk/reporting/components/generic/types"
import { userColumns } from "@kiosk/reporting/components/userTable/userColumns"
import { AccountResponses } from "@kiosk/reporting/legacy/types/endpoints/account"
import { User } from "@kiosk/reporting/legacy/types/user"

export const useUsersColumns = (
  showDeleteModal: (value: string) => void,
  impersonateUserMutation: UseMutateFunction<
    AccountResponses.Impersonation,
    Error,
    string,
    unknown
  >,
): Array<Column<User>> => {
  const { t } = useTranslation()

  const actionsColumn = {
    colId: "id",
    title: "",
    key: "actions",
    render: (id: string, { clerkId }: { clerkId: string }) => (
      <Menu offset={6} position="right">
        <Menu.Target>
          <ActionIcon color="black" variant="transparent">
            <IconDotsVertical size={20} />
          </ActionIcon>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item onClick={() => impersonateUserMutation(clerkId)}>
            {t("impersonateUser")}
          </Menu.Item>

          <Menu.Item onClick={() => showDeleteModal(id)}>
            {t("delete")}
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    ),
  }

  return [...userColumns, actionsColumn]
}
