import { notifications } from "@mantine/notifications"
import { Survey } from "@prisma/client"
import { useMutation } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"

import { surveysKeys } from "@kiosk/reporting/api/surveys/surveysKeys"
import { useUser } from "@kiosk/reporting/components/auth/UserContext"
import { apiClient } from "@kiosk/reporting/lib/apiClient"
import { authorizationHeader } from "@kiosk/reporting/lib/headers"
import { queryClient } from "@kiosk/reporting/lib/queryClient"

export const buildDeleteSurvey = (id: string, token: string) => () => {
  const cachedSurveys = queryClient.getQueryData(surveysKeys.list()) as
    | Survey[]
    | undefined

  if (cachedSurveys) {
    queryClient.setQueryData(
      surveysKeys.list(),
      cachedSurveys.filter((survey: Survey) => survey.id !== id),
    )
  }

  return apiClient.delete(`/surveys/${id}`, {
    headers: authorizationHeader(token),
  })
}

export const useDeleteSurveyMutation = (id: string) => {
  const { t } = useTranslation()
  const { token } = useUser()

  return useMutation({
    mutationFn: buildDeleteSurvey(id, token),
    onError: ({ message }) => {
      queryClient.invalidateQueries({
        queryKey: surveysKeys.list(),
      })
      notifications.show({
        title: t("messages.error"),
        message,
        color: "red",
      })
    },
  })
}
