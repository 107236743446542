import { Flex, Text } from "@mantine/core"
import { IconInfoCircle } from "@tabler/icons-react"
import { StructuredText, StructuredTextGraphQlResponse } from "react-datocms"

import { Callout } from "@kiosk/reporting/components/Callout"
import { getStrongMarkRule } from "@kiosk/reporting/utils/datocms/markRules"
import {
  getLinkNodeRule,
  getParagraphNodeRule,
} from "@kiosk/reporting/utils/datocms/nodeRules"

type CalloutStructuredTextBlock = {
  __typename: "CalloutRecord"
  id: string
  hint: string
}

export type StructuredTextBlock = CalloutStructuredTextBlock

interface Props {
  content: StructuredTextGraphQlResponse<StructuredTextBlock>
}

export const DatoStructuredText: React.FC<Props> = ({ content }) => {
  return (
    <Flex direction="column" gap={8}>
      <StructuredText<StructuredTextBlock>
        customMarkRules={[getStrongMarkRule()]}
        customNodeRules={[getParagraphNodeRule(), getLinkNodeRule()]}
        data={content}
        // This will render anything that’s not structured.
        // For example, a paragraph or a bullet list is not structured.
        // A callout block is structured.
        renderBlock={({ record }) => {
          switch (record.__typename) {
            case "CalloutRecord":
              return <Callout icon={<IconInfoCircle />} message={record.hint} />
            default:
              return null
          }
        }}
        renderText={(text, key) => (
          <Text
            c="inherit"
            component="span"
            fw="inherit"
            key={key}
            size="inherit"
          >
            {text}
          </Text>
        )}
      />
    </Flex>
  )
}
