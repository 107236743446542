import {
  parseThemeColor,
  SimpleGrid,
  Stack,
  useMantineTheme,
} from "@mantine/core"
import _ from "lodash"
import { useTranslation } from "react-i18next"

import {
  DashboardTopicDemo,
  DataPointDemo,
  DimensionedDataPointDemo,
  SingleValueDataPointDemo,
} from "@kiosk/reporting/api/dashboard/types"
import { isDefined } from "@kiosk/reporting/legacy/shared/utils/helpers"
import EmptyChart from "@kiosk/reporting/routes/dashboard/components/EmptyChart"
import DataPointsDimensionedDemo from "@kiosk/reporting/routes/dashboard/components/demo/DataPointsDimensionedDemo"
import DataPointsSingleDemo from "@kiosk/reporting/routes/dashboard/components/demo/DataPointsSingleDemo"
import { TopicTitleDemo } from "@kiosk/reporting/routes/dashboard/components/demo/TopicTitleDemo"

type Props = {
  readonly color?: string
  readonly index?: number
  readonly topic: DashboardTopicDemo
}

// TODO: I think it will be better if we simplify this, with chart and pie components and choose witch one to use, here all the data come from Dato, and its really not good
export function TopicDashboardDemo({ color, index, topic }: Props) {
  const filterDataPointsByType = (dataPoints: DataPointDemo[]) => {
    const singleValueDataPoints = dataPoints.filter(
      (dataPoint) => dataPoint.type === "single-value",
    )

    const dimensionedDataPoints = dataPoints.filter(
      (dataPoint) => dataPoint.type === "dimensioned",
    )

    return { singleValueDataPoints, dimensionedDataPoints }
  }
  const { i18n } = useTranslation(["dashboard"])
  const currentLanguage = i18n.language

  const { singleValueDataPoints, dimensionedDataPoints } =
    filterDataPointsByType(topic.dataPoints)

  function aggregateData(objects: SingleValueDataPointDemo[]) {
    return _(objects)
      .groupBy(
        (obj) =>
          `${currentLanguage === "fr" ? obj.labelFR : obj.labelEN}-${obj.type}`,
      )
      .mapValues((points) => {
        const { id, info, labelFR, labelEN, type, unit, value } = points[0]
        const attributes = {
          id,
          info,
          label: currentLanguage === "fr" ? labelFR : labelEN,
          type,
          unit,
          value,
        }

        return {
          ...attributes,
          value: points.some(isDefined)
            ? _.sumBy(
                points.filter((p) => isDefined(p.value)),
                (p) => p.value!,
              )
            : undefined,
        }
      })
      .values()
      .value()
  }

  function aggregateDimensionedData(
    dataPoints: DimensionedDataPointDemo[],
  ): DimensionedDataPointDemo[] {
    const aggregatedData: Record<string, DimensionedDataPointDemo> = {}

    dataPoints.forEach((dataPoint) => {
      const {
        id,
        dimensionId,
        info,
        baseColor,
        chart,
        isMandatoryInDashboard,
        labelFR,
        labelEN,
        numberType,
        time,
        type,
        unit,
        values,
      } = dataPoint

      // Utiliser une clé unique en combinant le label, type et chart
      const uniqueKey = `${currentLanguage === "fr" ? labelFR : labelEN}-${type}-${chart}`

      // Si la clé unique n'existe pas dans l'objet agrégé, on l'initialise avec le dataPoint courant
      if (!aggregatedData[uniqueKey]) {
        aggregatedData[uniqueKey] = {
          id,
          dimensionId,
          info,
          baseColor,
          chart,
          isMandatoryInDashboard,
          labelFR,
          labelEN,
          numberType,
          time,
          type,
          unit,
          values: [...(values ?? [])], // Copier les valeurs initiales
        }
      } else {
        // Si la clé existe déjà, additionner les valeurs pour chaque dimension
        ;(values ?? []).forEach((value) => {
          const existingValue = aggregatedData[uniqueKey].values!.find((v) =>
            v.dimensions.some(
              (d) => d.dimensionLabel === value.dimensions[0].dimensionLabel,
            ),
          )

          if (existingValue) {
            existingValue.value += value.value
          } else {
            aggregatedData[uniqueKey].values!.push({ ...value })
          }
        })
      }
    })

    // Retourner un tableau d'objets à partir des valeurs agrégées
    return Object.values(aggregatedData)
  }

  const theme = useMantineTheme()
  const parsedColor = parseThemeColor({ color, theme })

  return (
    <Stack gap={40} p={24}>
      {(topic.nameFR || topic.nameEN) && (
        <TopicTitleDemo
          icon={topic.icon}
          iconBackgroundColor={topic.iconBackgroundColor}
          iconColor={topic.iconColor}
          name={currentLanguage === "fr" ? topic.nameFR : topic.nameEN}
          topicId={topic.id}
        />
      )}

      {topic.grid === 3 ? (
        <SimpleGrid cols={topic.grid} spacing={48}>
          {aggregateData(singleValueDataPoints).map((dataPoint, index) => (
            <DataPointsSingleDemo
              info={dataPoint.info}
              key={index}
              label={dataPoint.label}
              unit={dataPoint.unit}
              value={dataPoint.value}
            />
          ))}
        </SimpleGrid>
      ) : (
        <SimpleGrid cols={2} spacing={0}>
          {index! % 2 === 0 ? (
            <>
              <SimpleGrid cols={2} pr={24} spacing={24} verticalSpacing={60}>
                {aggregateData(singleValueDataPoints).map(
                  (dataPoint, index) => (
                    <DataPointsSingleDemo
                      info={dataPoint.info}
                      key={index}
                      label={dataPoint.label}
                      unit={dataPoint.unit}
                      value={dataPoint.value}
                    />
                  ),
                )}
              </SimpleGrid>

              <Stack
                justify="center"
                pl={24}
                style={{ borderLeft: `1px solid ${parsedColor.value}` }}
              >
                {aggregateDimensionedData(dimensionedDataPoints).filter(
                  (points) => !_.isEmpty(points.values ?? []),
                ).length > 0 ? (
                  <>
                    {aggregateDimensionedData(dimensionedDataPoints).map(
                      (dataPoint, index) => (
                        <DataPointsDimensionedDemo
                          baseColor={dataPoint.baseColor}
                          chart={dataPoint.chart}
                          key={index}
                          label={
                            currentLanguage === "fr"
                              ? dataPoint.labelFR
                              : dataPoint.labelEN
                          }
                          unit={dataPoint.unit}
                          values={dataPoint.values}
                        />
                      ),
                    )}
                  </>
                ) : (
                  <EmptyChart />
                )}
              </Stack>
            </>
          ) : (
            <>
              <Stack
                justify="center"
                pr={24}
                style={{ borderRight: `1px solid ${parsedColor.value}` }}
              >
                {aggregateDimensionedData(dimensionedDataPoints).filter(
                  (points) => !_.isEmpty(points.values ?? []),
                ).length > 0 ? (
                  <>
                    {aggregateDimensionedData(dimensionedDataPoints).map(
                      (dataPoint, index) => (
                        <DataPointsDimensionedDemo
                          baseColor={dataPoint.baseColor}
                          chart={dataPoint.chart}
                          key={index}
                          label={
                            currentLanguage === "fr"
                              ? dataPoint.labelFR
                              : dataPoint.labelEN
                          }
                          unit={dataPoint.unit}
                          values={dataPoint.values}
                        />
                      ),
                    )}
                  </>
                ) : (
                  <EmptyChart />
                )}
              </Stack>

              <SimpleGrid cols={2} pl={24} spacing={24} verticalSpacing={60}>
                {aggregateData(singleValueDataPoints).map(
                  (dataPoint, index) => (
                    <DataPointsSingleDemo
                      info={dataPoint.info}
                      key={index}
                      label={dataPoint.label}
                      unit={dataPoint.unit}
                      value={dataPoint.value}
                    />
                  ),
                )}
              </SimpleGrid>
            </>
          )}
        </SimpleGrid>
      )}
    </Stack>
  )
}
