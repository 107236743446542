import { LoaderFunctionArgs } from "@remix-run/server-runtime"

import { Congratulations } from "@kiosk/reporting/routes/congratulations/components/Congratulations"
import { requireUser } from "@kiosk/reporting/utils/auth.server"

export const handle = {
  title: () => "congratulations",
}

export async function loader(args: LoaderFunctionArgs) {
  const user = await requireUser(args)

  const branding = user.company.branding

  return { branding }
}

export default function CongratulationsPage() {
  return <Congratulations />
}
