import { rem, Stepper, StepperProps } from "@mantine/core"
import { Dispatch, SetStateAction } from "react"
import { useTranslation } from "react-i18next"

import { CompanyStep } from "@kiosk/reporting/routes/_onboarding.onboarding/components/CompanyStep"
import { GeoDimensionStep } from "@kiosk/reporting/routes/_onboarding.onboarding/components/GeoDimensionStep"
import { SubjectsStep } from "@kiosk/reporting/routes/_onboarding.onboarding/components/SubjectsStep"
import { UploadFileStep } from "@kiosk/reporting/routes/_onboarding.onboarding/components/UploadFileStep"
import { UserCreationStep } from "@kiosk/reporting/routes/_onboarding.onboarding/components/UserCreationStep"

interface Props extends Omit<StepperProps, "children" | "onStepClick"> {
  readonly setActive: Dispatch<SetStateAction<number>>
  readonly handleOnboardingSkip: () => void
}

export const OnboardingStepper = ({
  handleOnboardingSkip,
  setActive,
  active,
  ...rest
}: Props) => {
  const { t } = useTranslation("common")

  return (
    <Stepper
      active={active}
      styles={{
        root: {
          height: "100%",
          display: "flex",
          gap: rem(72),
          flex: "1 1 0%",
          flexDirection: "column",
          alignItems: "center",
        },
        stepBody: {
          position: "absolute",
          top: rem(52),
          width: 150,
          right: "-125%",
          textAlign: "center",
          marginInlineStart: 0,
        },
        stepLabel: {
          fontSize: 14,
          color: "black",
        },
        step: {
          position: "relative",
          padding: 0,
        },
        stepIcon: {
          borderWidth: rem(2),
        },
        separator: {
          marginLeft: rem(-2),
          marginRight: rem(-2),
          height: rem(10),
          background: "var(--mantine-color-gray-1)",
        },
        content: {
          paddingTop: 0,
          display: "flex",
          flex: "1 1 0%",
          width: "100%",
        },
        steps: {
          width: rem(706),
          paddingLeft: rem(48),
          paddingRight: rem(48),
        },
      }}
      w="100%"
      {...rest}
    >
      <Stepper.Step label={t("onboarding.userInfo")} step={1}>
        <UserCreationStep setActive={setActive} />
      </Stepper.Step>

      <Stepper.Step label={t("onboarding.company")} step={2}>
        <CompanyStep setActive={setActive} />
      </Stepper.Step>

      <Stepper.Step label={t("onboarding.subjects")} step={3}>
        <SubjectsStep
          handleOnboardingSkip={handleOnboardingSkip}
          setActive={setActive}
        />
      </Stepper.Step>

      <Stepper.Step label={t("onboarding.geoStructure")} step={4}>
        <GeoDimensionStep
          handleOnboardingSkip={handleOnboardingSkip}
          setActive={setActive}
        />
      </Stepper.Step>

      <Stepper.Step label={t("onboarding.firstUpload")} step={5}>
        <UploadFileStep handleOnboardingSkip={handleOnboardingSkip} />
      </Stepper.Step>
    </Stepper>
  )
}
