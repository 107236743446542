import { z } from "zod"

import { USER_ROLES } from "@kiosk/reporting/legacy/types/user"

export const userSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email(),
  phone: z.string().optional().nullable(),
  avatarUrl: z.string().optional().nullable(),
  jobTitle: z.string().optional().nullable(),
  roles: z.array(z.nativeEnum(USER_ROLES)),
  locale: z.enum(["fr", "en"]),
  department: z.string().optional().nullable(),
})
export type UserBody = z.infer<typeof userSchema>

export const userUpdateSchema = userSchema.partial()
export const inviteUserSchema = userSchema.partial()

export type UserUpdateBody = z.infer<typeof userUpdateSchema>
export type InviteUserBody = z.infer<typeof inviteUserSchema>

export const setupUserAccountSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
})

export type UserSetupAccountBody = z.infer<typeof setupUserAccountSchema>

export const createCompanyContributorSchema = z.object({
  firstName: z.string().min(1, "fieldEmpty"),
  lastName: z.string().min(1, "fieldEmpty"),
  email: z.string().email("emailInvalid"),
  jobTitle: z.string().nullish().optional(),
  locale: z.string().min(1, "localeRequired"),
})
export type CreateCompanyContributorBody = z.infer<
  typeof createCompanyContributorSchema
>
