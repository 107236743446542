import { Container, Stack, Text } from "@mantine/core"
import { useTranslation } from "react-i18next"

export const SurveyAnswered = () => {
  const { t } = useTranslation("survey")

  return (
    <Container h="100%" pt={64}>
      <Stack align="center" gap={0}>
        <Text fz={64}>🎉</Text>

        <Text fz={32} mb={24}>
          {t("surveyClosed")}
        </Text>

        <Text c="gray" size="xxl" style={{ whiteSpace: "pre-line" }}>
          {t("surveyClosedMessage")}
        </Text>
      </Stack>
    </Container>
  )
}
