import { NumberInput, NumberInputProps } from "@mantine/core"
import { forwardRef } from "react"

type UnitNumberInputProps = {
  readonly unit: string
  readonly placeholder?: string
} & NumberInputProps

export const UnitNumberInput = forwardRef<
  HTMLInputElement,
  UnitNumberInputProps
>(({ unit, placeholder, ...rest }: UnitNumberInputProps, ref) => {
  const displayPlaceholder =
    placeholder && unit
      ? `${placeholder} ${unit}`
      : placeholder || unit || undefined

  return (
    <NumberInput
      allowedDecimalSeparators={[",", "."]}
      hideControls
      placeholder={displayPlaceholder}
      ref={ref}
      suffix={unit ? ` ${unit}` : undefined}
      {...rest}
    />
  )
})

UnitNumberInput.displayName = "UnitNumberInput"
