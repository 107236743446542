import { Button, Group, Modal, rem, Stack, Text } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { useNavigate, useParams } from "@remix-run/react"
import { IconChevronLeft } from "@tabler/icons-react"
import { useState } from "react"
import { useTranslation } from "react-i18next"

import { useImpersonateUser } from "@kiosk/reporting/api/account/impersonateUser"
import { useCompanyUsersQuery } from "@kiosk/reporting/api/companies/queryCompanyUsers"
import { useDeleteUserMutation } from "@kiosk/reporting/api/users/deleteUser"
import { Table } from "@kiosk/reporting/components/generic"
import { PageLayout } from "@kiosk/reporting/components/layout/PageLayout"
import { QueryWrapper } from "@kiosk/reporting/components/layout/QueryWrapper"
import { useUsersColumns } from "@kiosk/reporting/routes/companies_.$companyId.users/components/columns"
import { routes } from "@kiosk/reporting/utils/constants"

export const Users = () => {
  const { t } = useTranslation(["common", "settings"])

  const params = useParams()
  const navigate = useNavigate()
  const { companyId = "" } = params
  const [opened, { open, close }] = useDisclosure(false)
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null)

  const companyUsersQuery = useCompanyUsersQuery(companyId)

  const handleBack = () => navigate(routes.COMPANIES.path)

  const { mutateAsync: deleteAccount } = useDeleteUserMutation(companyId)

  const handleDeleteAccount = (id: string | null) => () => {
    if (id) {
      void deleteAccount(id)
    }
    setSelectedUserId(null)
    close()
  }

  const showDeleteModal = (id: string) => {
    setSelectedUserId(id)
    open()
  }

  const { mutate: impersonateUserMutation } = useImpersonateUser()

  const columns = useUsersColumns(showDeleteModal, impersonateUserMutation)

  return (
    <PageLayout>
      <Stack align="flex-start" gap={30}>
        <Button c="black" onClick={handleBack} p={0} variant="transparent">
          <Group gap={12}>
            <IconChevronLeft />

            {t("common:buttons.back")}
          </Group>
        </Button>

        <QueryWrapper query={companyUsersQuery}>
          {({ data: companyUsers }) => (
            <Table columns={columns} dataSource={companyUsers.data} />
          )}
        </QueryWrapper>

        <Modal.Root centered onClose={close} opened={opened}>
          <Modal.Overlay />

          <Modal.Content p={8} style={{ borderRadius: rem(14) }}>
            <Modal.Header>
              <Modal.Title c="gray.9" fw={600} fz="xxl">
                {t("settings:account.deleteAccount")}
              </Modal.Title>

              <Modal.CloseButton />
            </Modal.Header>

            <Modal.Body>
              <Text c="gray.7" fz="md" mb={34}>
                {t("settings:account.deleteAccountDescription")}
              </Text>

              <Group justify="flex-end">
                <Button c="gray.9" color="gray.2" onClick={close}>
                  {t("common:buttons.cancel")}
                </Button>

                <Button
                  color="red.8"
                  onClick={handleDeleteAccount(selectedUserId)}
                >
                  {t("settings:account.deleteAccount")}
                </Button>
              </Group>
            </Modal.Body>
          </Modal.Content>
        </Modal.Root>
      </Stack>
    </PageLayout>
  )
}
