import { Flex, Stack, Text, Title } from "@mantine/core"
import { useNavigate } from "@remix-run/react"
import { useState } from "react"
import { useTranslation } from "react-i18next"

import { useUploadPrivateFileMutation } from "@kiosk/reporting/api/uploadFile"
import { DashedDropzone } from "@kiosk/reporting/components/DashedDropzone"
import { Footer } from "@kiosk/reporting/routes/_onboarding.onboarding/components/Footer"
import { useCompleteOnBoardingMutation } from "@kiosk/reporting/routes/_onboarding.onboarding/utils/complete-onboarding"
import { routes } from "@kiosk/reporting/utils/constants/routes"

interface SomeFile {
  originalname: string
  path: string
}

type UploadFileStepProps = {
  readonly handleOnboardingSkip: () => void
}

export const UploadFileStep = ({
  handleOnboardingSkip,
}: UploadFileStepProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [uploadedFile, setUploadedFile] = useState<SomeFile | undefined>(
    undefined,
  )

  const {
    error: uploadError,
    isError: hasUploadError,
    isPending: isUploading,
  } = useUploadPrivateFileMutation({
    destination: "dsn-file/",
    sendNotificationOnError: true,
    onSuccess: (uploadedFile) => {
      if (uploadedFile) {
        setUploadedFile(uploadedFile.file)
      }
    },
  })

  const { mutate: completeOnBoarding, isPending } =
    useCompleteOnBoardingMutation(() => navigate(routes.CONGRATULATIONS.path))

  const handleFormSubmit = () => {
    if (!uploadedFile) return
    completeOnBoarding({
      file: {
        filename: uploadedFile.originalname,
        filePath: uploadedFile.path,
      },
    })
  }

  const clearFileUrl = () => {
    setUploadedFile(undefined)
  }

  return (
    <Flex
      align="center"
      direction="column"
      flex={1}
      gap={40}
      justify="space-between"
      w="100%"
    >
      <Stack flex={1} gap={48} h="100%" maw={830} px={24} w="100%">
        <Stack>
          <Title c="black" fw={600} fz="xxl">
            &#128218; &nbsp;
            {t("onboarding.almostDoneTitle")}
          </Title>
          <Text c="gray.5" fz={16}>
            {t("onboarding.uploadDescription")}
          </Text>
        </Stack>
        <Stack gap={0}>
          <DashedDropzone
            clearFileUrl={clearFileUrl}
            description={t("onboarding.uploadDsnFileDescription")}
            hasUploadError={hasUploadError}
            isUploading={isUploading}
            title={t("onboarding.uploadDsnFile")}
            uploadError={uploadError}
          />
        </Stack>
      </Stack>
      <Footer
        disableContinue={!uploadedFile || isUploading || isUploading}
        isLoading={isPending}
        onContinue={handleFormSubmit}
        onSkipOnboarding={handleOnboardingSkip}
      />
    </Flex>
  )
}
