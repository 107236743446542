import { useLoaderData } from "@remix-run/react"
import { LoaderFunctionArgs } from "@remix-run/server-runtime"

import { CategoryAggregateDatoRepository } from "@kiosk/reporting/features/dato/infrastructure/repositories/category-aggregates"
import { Locale } from "@kiosk/reporting/i18n"
import { TablePage } from "@kiosk/reporting/routes/sources/components/Tables/TablePage"
import { requireUser } from "@kiosk/reporting/utils/auth.server"

export const handle = {
  breadcrumb: () => "users",
  title: () => "tables",
}

export const loader = async (args: LoaderFunctionArgs) => {
  const { token, locale } = await requireUser(args)
  const rawDataPointsQuestions =
    await CategoryAggregateDatoRepository.fetchRawDataPoints(
      token,
      locale as Locale,
    )

  return {
    rawDataPointsQuestions,
  }
}

export default function SourcesTablesPage() {
  const { rawDataPointsQuestions } = useLoaderData<typeof loader>()

  return <TablePage rawDataPointsQuestions={rawDataPointsQuestions} />
}
