import { Button, Group, Modal, rem, Stack, Text } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { useState } from "react"
import { useTranslation } from "react-i18next"

import { useCompanyUsersQuery } from "@kiosk/reporting/api/companies/queryCompanyUsers"
import { useDeleteUserMutation } from "@kiosk/reporting/api/users/deleteUser"
import { useUserQuery } from "@kiosk/reporting/api/users/getUser"
import { useUser } from "@kiosk/reporting/components/auth/UserContext"
import { Table } from "@kiosk/reporting/components/generic"
import { PageLayout } from "@kiosk/reporting/components/layout/PageLayout"
import { QueryWrapper } from "@kiosk/reporting/components/layout/QueryWrapper"
import { AddUser } from "@kiosk/reporting/routes/settings.users/components/AddUser"
import { UpdateUser } from "@kiosk/reporting/routes/settings.users/components/UpdateUser"
import { useSettingsColumns } from "@kiosk/reporting/routes/settings.users/components/columns"

export default function Users() {
  const { companyId } = useUser()
  const companyUsersQuery = useCompanyUsersQuery(companyId)

  const [
    deleteUserModalOpened,
    { close: closeUserDeleteModal, open: openUserDeleteModal },
  ] = useDisclosure(false)

  const [
    updateUserOpened,
    { close: closeUpdateUserModal, open: openUpdateUserModal },
  ] = useDisclosure(false)

  const { t } = useTranslation(["settings", "common"])
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null)
  const { data: selectedUserData } = useUserQuery(selectedUserId ?? "")

  const { mutateAsync: deleteAccount, isPending: isDeletingAccount } =
    useDeleteUserMutation(companyId)

  const handleDeleteAccount = (id: string | null) => async () => {
    if (id) {
      await deleteAccount(id)
    }
    setSelectedUserId(null)
    closeUserDeleteModal()
  }

  const showDeleteModal = (id: string) => {
    setSelectedUserId(id)
    openUserDeleteModal()
  }

  const showUpdateModal = (id: string) => {
    setSelectedUserId(id)
    openUpdateUserModal()
  }

  const columns = useSettingsColumns(
    showDeleteModal,
    showUpdateModal,
    isDeletingAccount,
    selectedUserId,
  )

  return (
    <PageLayout>
      <Stack gap={30}>
        <Group gap={16} justify="flex-end" w="100%">
          <AddUser />
        </Group>

        <QueryWrapper query={companyUsersQuery}>
          {({ data: companyUsers }) => (
            <Table columns={columns} dataSource={companyUsers.data} />
          )}
        </QueryWrapper>

        <Modal.Root
          centered
          onClose={closeUserDeleteModal}
          opened={deleteUserModalOpened}
        >
          <Modal.Overlay />

          <Modal.Content p={8} style={{ borderRadius: rem(14) }}>
            <Modal.Header>
              <Modal.Title c="gray.9" fw={600} fz="xxl">
                {t("settings:account.deleteAccount")}
              </Modal.Title>

              <Modal.CloseButton />
            </Modal.Header>

            <Modal.Body>
              <Text c="gray.7" fz="md" mb={34}>
                {t("settings:account.deleteAccountDescription")}
              </Text>

              <Group justify="flex-end">
                <Button
                  color="gray"
                  onClick={closeUserDeleteModal}
                  variant="outline"
                >
                  {t("common:buttons.cancel")}
                </Button>

                <Button
                  color="red.8"
                  onClick={handleDeleteAccount(selectedUserId)}
                >
                  {t("settings:account.deleteAccount")}
                </Button>
              </Group>
            </Modal.Body>
          </Modal.Content>
        </Modal.Root>

        {selectedUserData && (
          <UpdateUser
            close={closeUpdateUserModal}
            opened={updateUserOpened}
            selectedUserData={selectedUserData}
            selectedUserId={selectedUserId!}
          />
        )}
      </Stack>
    </PageLayout>
  )
}
