import { Box, Tooltip } from "@mantine/core"
import { useTranslation } from "react-i18next"

import { UnitNumberInput } from "@kiosk/reporting/components/UnitNumberInput/UnitNumberInput"
import classes from "@kiosk/reporting/components/csrd/questions/NumberQuestion.module.css"
import {
  GetInputPropsReturnType,
  InputProps,
} from "@kiosk/reporting/components/csrd/questions/types"
import { CSRDQuestionAnswer } from "@kiosk/reporting/legacy/types/csrd"
import { NumberRecord } from "@kiosk/reporting/legacy/types/data/dato"

type NumberQuestionProps = {
  readonly externalAnswers: Record<string, CSRDQuestionAnswer>
  readonly formProps: GetInputPropsReturnType & { "data-path"?: string }
} & InputProps<NumberRecord>

export const NumberQuestion = ({
  content,
  formProps,
  externalAnswers,
}: NumberQuestionProps) => {
  const { t } = useTranslation("csrd")
  const suffix = content.numberType === "currency" ? "€" : content.unit

  const questionId = formProps["data-path"]

  const externalAnswer = questionId
    ? (externalAnswers[questionId]?.answer.value as number)
    : undefined

  return (
    <Box maw={250} style={{ position: "relative" }}>
      {externalAnswer && (
        <Tooltip label={t("externalAnswer")} withArrow>
          <Box className={classes["number-question__dot--container"]}>
            <Box className={classes["number-question__dot"]} />
          </Box>
        </Tooltip>
      )}
      <UnitNumberInput
        maw="250"
        placeholder={externalAnswer ? String(externalAnswer.toFixed()) : ""}
        unit={suffix}
        {...formProps}
        classNames={{
          input:
            (formProps.value === "" || formProps.value === undefined) &&
            externalAnswer
              ? classes["number-question__input--external-source"]
              : undefined,
        }}
        onChange={(value) =>
          formProps.onChange(value === "" ? undefined : +value)
        }
      />
    </Box>
  )
}
