import { useClerk } from "@clerk/remix"
import { Center, Stack, Button, Text, useMantineTheme } from "@mantine/core"
import { useLoaderData } from "@remix-run/react"
import { useTranslation } from "react-i18next"

import Logo from "@kiosk/reporting/components/Logo"
import { loader } from "@kiosk/reporting/root"

export default function ErrorPage() {
  const { branding } = useLoaderData<typeof loader>()

  const theme = useMantineTheme()
  const { signOut } = useClerk()
  const { t } = useTranslation()

  return (
    <Center bg={theme.colors.gray[0]} h="100vh" pos="fixed" w="100vw">
      <Stack>
        <Logo branding={branding} />
        <Text m="24px">{t("messages.invalidAccount")}</Text>
        <Center>
          <Button
            onClick={() => {
              signOut({ redirectUrl: "/" })
            }}
          >
            {t("buttons.back")}
          </Button>
        </Center>
      </Stack>
    </Center>
  )
}
