import { HoverCard, RingProgress, Text } from "@mantine/core"
import { IconCircleCheckFilled } from "@tabler/icons-react"

type CompletionProps = {
  readonly progress: number
  readonly isNonMaterialEsrs?: boolean
}

export function Completion({ progress, isNonMaterialEsrs }: CompletionProps) {
  const componentSize = 22

  if (isNonMaterialEsrs) {
    return (
      <IconCircleCheckFilled
        color="var(--mantine-color-gray-2)"
        size={componentSize}
        style={{ flexShrink: 0 }}
      />
    )
  }

  if (progress === 100) {
    return (
      <IconCircleCheckFilled
        color="var(--mantine-color-kiosk-green-0)"
        size={componentSize}
        style={{ flexShrink: 0 }}
      />
    )
  }

  return (
    <HoverCard closeDelay={0} openDelay={100} position="top">
      <HoverCard.Target>
        <RingProgress
          sections={[
            { value: progress, color: "var(--mantine-color-kiosk-green-0)" },
          ]}
          size={componentSize}
          thickness={2}
        />
      </HoverCard.Target>

      <HoverCard.Dropdown>
        <Text>{Math.round(progress)}%</Text>
      </HoverCard.Dropdown>
    </HoverCard>
  )
}
