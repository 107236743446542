import { ReportsResponses } from "@kiosk/reporting/legacy/types/endpoints/reports"

export type Topic = ReportsResponses.GetCategories[number]["topics"][number]
export type HasOrder = { order: number }

export type DisclosureRequirement = Omit<
  Topic["disclosureRequirements"][number],
  "progress"
> & {
  progress?: number
}

export function topicSorter<T extends HasOrder>(topic1: T, topic2: T) {
  return topic1.order - topic2.order
}
