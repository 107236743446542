import {
  Button,
  Divider,
  Group,
  Modal,
  Stack,
  Text,
  TextInput,
  rem,
} from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import { useDisclosure } from "@mantine/hooks"
import { useTranslation } from "react-i18next"

import { useDeleteUserMutation } from "@kiosk/reporting/api/users/deleteUser"
import { useUpdateUserMutation } from "@kiosk/reporting/api/users/updateUser"
import { PictureUploader } from "@kiosk/reporting/components/PictureUploader"
import { PageLayout } from "@kiosk/reporting/components/layout/PageLayout"
import { Locale } from "@kiosk/reporting/i18n"
import {
  UserBody,
  userSchema,
} from "@kiosk/reporting/legacy/shared/schemas/users"
import { useAppRouteContext } from "@kiosk/reporting/lib/hooks/use-app-context"
import { getInitials } from "@kiosk/reporting/utils/helpers"

export const Account = () => {
  const { t } = useTranslation(["common", "settings"])

  const { user } = useAppRouteContext()

  // TODO: update when our DB model is implemented
  const [opened, { open, close }] = useDisclosure(false)

  const form = useForm<UserBody>({
    validate: zodResolver(userSchema),
    initialValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phone: user.phone,
      avatarUrl: user.avatarUrl,
      locale: user.locale as Locale,
      roles: user.roles,
      department: user.department,
    },
  })

  const { mutateAsync: deleteAccount, isPending: isDeletingAccount } =
    // TODO : Better handling because there is kinda a double call to delete here. Either we pass the id / companyId to the hook or we handle it in the hook
    useDeleteUserMutation(undefined as unknown as string)
  const { mutateAsync: updateAccount, isPending: isUpdatingAccount } =
    useUpdateUserMutation(user.id)

  const handleDeleteAccount = () => {
    void deleteAccount(user.id)
    close()
  }

  return (
    <PageLayout>
      <Stack align="center" w="100%">
        <Stack gap={24} w={650}>
          <Text c="black" fw={500}>
            {t("settings:account.profileImage")}
          </Text>

          <Group gap={24}>
            <PictureUploader
              destination="user-avatars"
              placeholder={getInitials(user.firstName, user.lastName)}
              {...form.getInputProps("avatarUrl")}
            />
          </Group>

          <Divider />

          <Text c="black" fw={500}>
            {t("settings:account.yourInformation")}
          </Text>

          <form
            id="update-user"
            onSubmit={form.onSubmit((values) =>
              updateAccount({ userId: user.id, data: values }),
            )}
          >
            <Stack gap={24}>
              <TextInput
                label={t("settings:account.firstName")}
                placeholder="John"
                {...form.getInputProps("firstName")}
              />

              <TextInput
                label={t("settings:account.lastName")}
                placeholder="Doe"
                {...form.getInputProps("lastName")}
              />

              <TextInput
                label={t("settings:account.email")}
                placeholder="john.doe@gmail.com"
                {...form.getInputProps("email")}
                disabled
              />

              <TextInput
                label={t("settings:account.phoneNumber")}
                placeholder="+33 1 09 75 83 51"
                {...form.getInputProps("phone")}
              />
            </Stack>
          </form>

          <Divider />

          <Group justify="space-between">
            <Text c="black" fw={500}>
              {t("settings:account.permanentlyDeleteAccount")}
            </Text>

            <Button
              c="red.8"
              loading={isDeletingAccount}
              onClick={open}
              style={{ borderColor: "var(--mantine-color-red-8)" }}
              variant="outline"
            >
              {t("settings:account.deleteAccount")}
            </Button>

            <Modal.Root centered onClose={close} opened={opened}>
              <Modal.Overlay />

              <Modal.Content p={8} style={{ borderRadius: rem(14) }}>
                <Modal.Header>
                  <Modal.Title c="gray.9" fw={600} fz="xxl">
                    {t("settings:account.deleteAccount")}
                  </Modal.Title>

                  <Modal.CloseButton />
                </Modal.Header>

                <Modal.Body>
                  <Text c="gray.7" fz="md" mb={34}>
                    {t("settings:account.deleteAccountDescription")}
                  </Text>

                  <Group justify="flex-end">
                    <Button color="gray" onClick={close} variant="outline">
                      {t("common:buttons.cancel")}
                    </Button>

                    <Button color="red.8" onClick={handleDeleteAccount}>
                      {t("settings:account.deleteAccount")}
                    </Button>
                  </Group>
                </Modal.Body>
              </Modal.Content>
            </Modal.Root>
          </Group>

          <Button
            disabled={!form.isValid()}
            form="update-user"
            loading={isUpdatingAccount}
            type="submit"
            w="fit-content"
          >
            {t("settings:account.save")}
          </Button>
        </Stack>
      </Stack>
    </PageLayout>
  )
}
