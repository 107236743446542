import { showNotification } from "@mantine/notifications"
import { DisclosureRequirementParagraph } from "@prisma/client"
import { UseMutationResult, useMutation } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"

import { dashboardKeys } from "@kiosk/reporting/api/dashboard/dashboardKeys"
import { apiClient } from "@kiosk/reporting/lib/apiClient"
import { authorizationHeader } from "@kiosk/reporting/lib/headers"
import { useAppRouteContext } from "@kiosk/reporting/lib/hooks/use-app-context"
import { queryClient } from "@kiosk/reporting/lib/queryClient"

/**
 * @function useAIGenerateDisclosureRequirementParagraph
 * @param {string} disclosureRequirementId
 * @param {string} companyId
 * @returns {UseMutationResult<DisclosureRequirementParagraph, Error, string, unknown> }
 */

export const useAIGenerateDisclosureRequirementParagraphMutation = (actions: {
  onSuccess: (data: DisclosureRequirementParagraph) => void
}): UseMutationResult<
  DisclosureRequirementParagraph,
  Error,
  string,
  unknown
> => {
  const { t } = useTranslation(["csrd"])

  const { user } = useAppRouteContext()
  const companyId = user.company.id

  return useMutation({
    mutationFn: async (disclosureRequirementId: string) => {
      const res = await apiClient.put<DisclosureRequirementParagraph>(
        `/disclosureRequirement/${companyId}/${disclosureRequirementId}/xbrl_paragraph`,
        {},
        { headers: authorizationHeader(user.token) },
      )

      return res.data
    },
    onSuccess: async (data: DisclosureRequirementParagraph) => {
      await queryClient.invalidateQueries({
        queryKey: dashboardKeys.allDataPointsWithDependency(
          data.datoDisclosureRequirementId,
        ),
      })
      showNotification({
        message: t("aiGeneration.generated"),
      })
      actions.onSuccess(data)
    },
    onError: () => {
      showNotification({ color: "red", message: t("aiGeneration.error") })
    },
  })
}
