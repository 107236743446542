import { Button, Group, Switch } from "@mantine/core"
import {
  IconCheck,
  IconRefresh,
  IconRotateClockwise,
  IconSparkles,
} from "@tabler/icons-react"
import { useState } from "react"
import { useTranslation } from "react-i18next"

import classes from "@kiosk/reporting/components/csrd/SaveFooter/SaveFooter.module.css"
import { SparklingButton } from "@kiosk/reporting/components/generic/SparklingButton"

// TODO: translations

interface SaveFooterProps {
  readonly hasUnsavedChanges: boolean
  readonly isPendingUpdate: boolean
  readonly isPendingGeneration: boolean
  readonly onMarkAsDone: () => void
  readonly onUnmarkAsDone: () => void
  readonly onSave: () => void
  readonly onGenerate: () => void
  readonly isCompleted: boolean
}

export const SaveFooter = ({
  hasUnsavedChanges,
  isPendingUpdate,
  isPendingGeneration,
  onMarkAsDone,
  onUnmarkAsDone,
  onSave,
  onGenerate,
  isCompleted,
}: SaveFooterProps) => {
  const { t } = useTranslation(["common", "csrd"])
  const [optimisticCompleted, setOptimisticCompleted] = useState(isCompleted)

  const handleMarkAsDoneChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newValue = event.currentTarget.checked

    setOptimisticCompleted(newValue)

    if (newValue) {
      onMarkAsDone()
      onSave()
    } else {
      onUnmarkAsDone()
    }
  }

  return (
    <Group className={classes["save-footer"]}>
      <Group>
        <Switch
          checked={optimisticCompleted}
          label={t("saveFooter.markAsDone")}
          onChange={handleMarkAsDoneChange}
        />
        {optimisticCompleted && (
          <SparklingButton
            icon={<IconSparkles />}
            loading={isPendingGeneration}
            loadingLabel={t("csrd:aiGeneration.loading")}
            onPress={onGenerate}
          >
            {t("csrd:aiGeneration.generate")}
          </SparklingButton>
        )}
      </Group>

      <Group>
        <SaveStatusMessage
          hasUnsavedChanges={hasUnsavedChanges}
          isPendingUpdate={isPendingUpdate}
        />

        <Button
          className={classes["save-footer__save-button"]}
          disabled={isPendingUpdate}
          onClick={onSave}
          type="submit"
        >
          {t("saveFooter.save")}
        </Button>
      </Group>
    </Group>
  )
}

const SaveStatusMessage = ({
  hasUnsavedChanges,
  isPendingUpdate,
}: {
  readonly hasUnsavedChanges: boolean
  readonly isPendingUpdate: boolean
}) => {
  const { t } = useTranslation(["common", "csrd"])

  if (hasUnsavedChanges) {
    return (
      <Group className={classes["save-footer__status--unsaved"]}>
        <IconRefresh size={22} />
        {t("saveFooter.unsavedChanges")}
      </Group>
    )
  }

  if (isPendingUpdate) {
    return (
      <Group className={classes["save-footer__status"]}>
        <IconRotateClockwise
          className={`${classes["save-footer__status--icon"]} ${classes["save-footer__status--icon-rotating"]}`}
          size={22}
        />
        {t("saveFooter.savingChanges")}
      </Group>
    )
  }

  return (
    <Group className={classes["save-footer__status"]}>
      <IconCheck className={classes["save-footer__status--icon"]} size={22} />
      {t("saveFooter.changesSaved")}
    </Group>
  )
}
