import { Button, Menu, Stack, Table, Text } from "@mantine/core"
import {
  IconChevronDown,
  IconChevronRight,
  IconPlus,
} from "@tabler/icons-react"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

import { StatusPill } from "@kiosk/reporting/components/StatusPill"
import { CategoryResponses } from "@kiosk/reporting/legacy/types/endpoints/categories"
import { TaskResponses } from "@kiosk/reporting/legacy/types/endpoints/tasks"

interface TaskStatusMenuProps {
  readonly updateTaskStatus: (payload: TaskResponses.UpdateTaskPayload) => void
  readonly taskStatus: "TODO" | "IN_PROGRESS" | "DONE"
  readonly taskId: string
}

const TaskStatusMenu = (props: TaskStatusMenuProps) => {
  const { t } = useTranslation("task")

  return (
    <Menu>
      <Menu.Target>
        <Button color="gray.6" p={0} variant="transparent">
          <StatusPill status={props.taskStatus} />

          <IconChevronDown height={20} width={20} />
        </Button>
      </Menu.Target>

      <Menu.Dropdown p={0} pb={8} pt={8}>
        <Stack gap={8}>
          <Menu.Item
            fz="sm"
            onClick={() =>
              props.updateTaskStatus({
                id: props.taskId,
                data: { status: "TODO" },
              })
            }
            px={14}
            py={0}
          >
            {t("status.todo")}
          </Menu.Item>

          <Menu.Divider />

          <Menu.Item
            fz="sm"
            onClick={() =>
              props.updateTaskStatus({
                id: props.taskId,
                data: { status: "IN_PROGRESS" },
              })
            }
            px={14}
            py={0}
          >
            {t("status.inProgress")}
          </Menu.Item>

          <Menu.Divider />

          <Menu.Item
            fz="sm"
            onClick={() =>
              props.updateTaskStatus({
                id: props.taskId,
                data: { status: "DONE" },
              })
            }
            px={14}
            py={0}
          >
            {t("status.done")}
          </Menu.Item>
        </Stack>
      </Menu.Dropdown>
    </Menu>
  )
}

interface TaskRowProps {
  readonly updateTaskStatus: (payload: TaskResponses.UpdateTaskPayload) => void
  readonly openEditTaskModal: (value: string) => void
  readonly taskStatus: "TODO" | "IN_PROGRESS" | "DONE"
  readonly taskId: string
  readonly taskName: string
  readonly taskOwner: string
  readonly taskDueDate: string
}

export const CategoryTaskRow = (props: TaskRowProps) => {
  return (
    <Table.Tr>
      <Table.Td>
        <TaskStatusMenu
          taskId={props.taskId}
          taskStatus={props.taskStatus}
          updateTaskStatus={props.updateTaskStatus}
        />
      </Table.Td>

      <Table.Td>{props.taskName}</Table.Td>

      <Table.Td>{props.taskOwner}</Table.Td>

      <Table.Td>{props.taskDueDate}</Table.Td>

      <Table.Td>
        <Button
          c="gray.5"
          onClick={() => props.openEditTaskModal(props.taskId)}
          variant="transparent"
        >
          <IconChevronRight />
        </Button>
      </Table.Td>
    </Table.Tr>
  )
}

interface CategoryTaskTableColumnProps {
  readonly width?: number
  readonly children?: React.ReactNode | string
}

const CategoryTaskTableColumn = (props: CategoryTaskTableColumnProps) => {
  return (
    <Table.Th
      fw={500}
      fz="sm"
      h={44}
      lh="md"
      px={10}
      py={9}
      style={{
        borderInlineEnd: "none",
      }}
      w={props.width}
    >
      {props.children}
    </Table.Th>
  )
}

interface CategoryTaskTableProps {
  readonly updateTaskStatus: (payload: TaskResponses.UpdateTaskPayload) => void
  readonly openEditTaskModal: (value: string) => void
  readonly openCreateTask: () => void
  readonly tasks: CategoryResponses.GetCategoriesAndTasks["data"][number]["tasks"]
}

export const CategoryTaskTable = (props: CategoryTaskTableProps) => {
  const { t } = useTranslation("task")

  return (
    <Table highlightOnHover withColumnBorders>
      <Table.Thead>
        <Table.Tr>
          <CategoryTaskTableColumn width={100}>
            {t("fields.status")}
          </CategoryTaskTableColumn>

          <CategoryTaskTableColumn>{t("fields.task")}</CategoryTaskTableColumn>

          <CategoryTaskTableColumn width={200}>
            {t("fields.owner")}
          </CategoryTaskTableColumn>

          <CategoryTaskTableColumn width={200}>
            {t("fields.dueDate")}
          </CategoryTaskTableColumn>

          <CategoryTaskTableColumn width={100} />
        </Table.Tr>
      </Table.Thead>

      <Table.Tbody>
        {props.tasks.map((task, index) => (
          <CategoryTaskRow
            key={index}
            openEditTaskModal={() => props.openEditTaskModal(task.id)}
            taskDueDate={
              task.dueDate ? dayjs(task.dueDate).format("MMM, YYYY") : ""
            }
            taskId={task.id}
            taskName={task.name}
            taskOwner={
              task.owner
                ? `${task.owner.firstName} ${task.owner.lastName}`
                : t("noAssignee")
            }
            taskStatus={task.status}
            updateTaskStatus={props.updateTaskStatus}
          />
        ))}
      </Table.Tbody>

      <Table.Tfoot>
        <Table.Tr>
          <Table.Td>
            <Button
              color="green"
              fw={400}
              onClick={props.openCreateTask}
              variant="transparent"
            >
              <IconPlus />
              &nbsp;
              <Text c="gray.6" fz="sm">
                {t("newTask")}
              </Text>
            </Button>
          </Table.Td>
        </Table.Tr>
      </Table.Tfoot>
    </Table>
  )
}
