import { notifications } from "@mantine/notifications"
import { Task } from "@prisma/client"
import { useMutation } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"

import { taskKeys } from "@kiosk/reporting/api/tasks/taskKeys"
import { useUser } from "@kiosk/reporting/components/auth/UserContext"
import { apiClient } from "@kiosk/reporting/lib/apiClient"
import { authorizationHeader } from "@kiosk/reporting/lib/headers"
import { queryClient } from "@kiosk/reporting/lib/queryClient"

export const deleteTask = async (id: string, token: string): Promise<Task> => {
  const response = await apiClient.delete(`/tasks/${id}`, {
    headers: authorizationHeader(token),
  })

  return response.data
}

export const useDeleteTaskMutation = () => {
  const { t } = useTranslation(["task", "common"])
  const { token } = useUser()

  return useMutation({
    mutationFn: async (id: string) => await deleteTask(id, token),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: taskKeys.all() })
      await queryClient.invalidateQueries({ queryKey: taskKeys.owners() })
      notifications.show({
        title: t("common:messages.success"),
        message: t("deleteTaskSuccess"),
        color: "green",
      })
    },
    onError: ({ message }) => {
      notifications.show({
        title: t("common:messages.error"),
        message,
        color: "red",
      })
    },
  })
}
