import { Flex, Text, TextInput, Title } from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import _ from "lodash"
import { Dispatch, SetStateAction } from "react"
import { useTranslation } from "react-i18next"

import { useAuthState } from "@kiosk/reporting/api/account/queryMe"
import { Footer } from "@kiosk/reporting/routes/_onboarding.onboarding/components/Footer"
import { StructureDefintion } from "@kiosk/reporting/routes/_onboarding.onboarding/components/StructureDefintion"
import { TreeStructure } from "@kiosk/reporting/routes/_onboarding.onboarding/components/TreeStructure"
import { useCreateGeoDimensionMutation } from "@kiosk/reporting/routes/_onboarding.onboarding/utils/create-geo-dimensions"
import {
  GeoDimensionForm,
  geoDimensionSchema,
} from "@kiosk/reporting/routes/_onboarding.onboarding/utils/types"

type Props = {
  readonly setActive: Dispatch<SetStateAction<number>>
  readonly handleOnboardingSkip: () => void
}

export const GeoDimensionStep = ({
  setActive,
  handleOnboardingSkip,
}: Props) => {
  const { t } = useTranslation("onboarding")
  const { user } = useAuthState()

  const form = useForm<GeoDimensionForm>({
    initialValues: { tree: [] },
    validate: zodResolver(geoDimensionSchema),
  })

  const { mutateAsync: createGeoDimension, isPending } =
    useCreateGeoDimensionMutation(() => setActive(4))

  const onSubmit = async () => {
    const flattenedTree = form.values.tree.flatMap((region) => {
      return (region.children ?? []).concat(_.omit(region, "children"))
    })

    await createGeoDimension(flattenedTree)
  }

  return (
    <Flex align="center" direction="column" flex={1} gap={40} w="100%">
      <Flex
        align="flex-start"
        direction="column"
        gap={16}
        justify="flex-start"
        w={837}
      >
        <Title c="gray.9" fw={600} fz="xxl">
          🕵️‍&nbsp;
          {t("geoDimension.title")}
        </Title>

        <Text c="gray.5" fz="md">
          {t("geoDimension.description")}
        </Text>
      </Flex>

      <Flex flex={1} gap={30}>
        <Flex direction="column" gap={16} w={470}>
          <Text c="gray.9" fw={600} fz="lg">
            {t("createYourStructure")}
          </Text>

          <Flex
            direction="column"
            p={16}
            style={{
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: "var(--mantine-color-gray-3)",
              borderRadius: 8,
            }}
          >
            <TextInput readOnly value={user?.company.name ?? ""} />

            <TreeStructure
              depth={1}
              form={form}
              formKey="tree"
              treeLevel={form.values.tree}
            />
          </Flex>
        </Flex>

        <Flex direction="column" gap={16} w={337}>
          <Text c="gray.9" fw={600} fz="lg">
            {t("defineEachDimension")}
          </Text>

          <Flex
            bg="gray.0"
            direction="column"
            p={16}
            style={{
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: "var(--mantine-color-gray-3)",
              borderRadius: 8,
            }}
          >
            <StructureDefintion />
          </Flex>
        </Flex>
      </Flex>

      <Footer
        disableContinue={!form.isValid()}
        isLoading={isPending}
        onContinue={onSubmit}
        onSkipOnboarding={handleOnboardingSkip}
      />
    </Flex>
  )
}
