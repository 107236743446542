import { useQuery } from "@tanstack/react-query"
import _ from "lodash"

import {
  datoClientSideQuery,
  makeDatoPaginatedRequest,
} from "@kiosk/reporting/api/datocms/datocms"
import {
  DatoAllQuestionsResponse,
  DatoAllNumbersResponse,
} from "@kiosk/reporting/api/datocms/types"
import { useUser } from "@kiosk/reporting/components/auth/UserContext"
import {
  ALL_QUESTIONS,
  ALL_NUMBERS,
  CSRD_REPORT_TREE_STRUCTURE,
} from "@kiosk/reporting/legacy/shared/datocms/queries"
import {
  CsrdCategory,
  Question,
  CSRDReportTreeStructure,
} from "@kiosk/reporting/legacy/types/data/dato"

export const getSurveyQuestionsDataModel = async (
  token: string,
): Promise<CsrdCategory[]> => {
  const [tree, allQuestions, numberQuestions] = await Promise.all([
    datoClientSideQuery<CSRDReportTreeStructure>({
      query: CSRD_REPORT_TREE_STRUCTURE,
      token,
    }),
    makeDatoPaginatedRequest<DatoAllQuestionsResponse>({
      query: ALL_QUESTIONS,
      fieldName: "allQuestions",
      metaFieldName: "_allQuestionsMeta",
      token,
    }),
    makeDatoPaginatedRequest<DatoAllNumbersResponse>({
      query: ALL_NUMBERS,
      fieldName: "allNumbers",
      metaFieldName: "_allNumbersMeta",
      token,
    }),
  ])

  return assembleTree(tree, allQuestions, numberQuestions)
}

export function assembleTree(
  tree: CSRDReportTreeStructure,
  allQuestions: DatoAllQuestionsResponse,
  numberQuestions: DatoAllNumbersResponse,
): CsrdCategory[] {
  // Dimensions come from one collection
  const dimensionsByQuestionId = _(allQuestions.allQuestions)
    .keyBy((q) => q.id)
    .mapValues((values) => values.dimensions.map((dimension) => dimension.id))
    .value()

  // Maps a question to its children questions
  const questionRelatedQuestions = _(allQuestions.allQuestions)
    .flatMap((question) =>
      question._allReferencingQuestions.map((referencingQuestion) => ({
        question,
        referencingQuestion,
      })),
    )
    .groupBy(({ referencingQuestion }) => referencingQuestion.id)
    .mapValues((values) => values.map((value) => value.question.id))
    .value()

  const questionByQuestionId = _(numberQuestions.allNumbers)
    .flatMap((numberType) =>
      numberType._allReferencingQuestions.map((question) => ({
        question,
        numberType,
      })),
    )
    .keyBy((q) => q.question.id)
    .value()

  // Table questions are the bearer of dimensions, but they are not numeric questions.
  // Therefore, this function returns either a question, a list of dimension IDs, or nothing.
  function findQuestionById(id: string): Question | undefined | string[] {
    const dimensions = dimensionsByQuestionId[id] ?? []

    const match = questionByQuestionId[id]

    if (!match) {
      // not a numeric question
      return dimensions.length ? dimensions : undefined
    }
    const { question, numberType } = match

    return {
      id,
      label: question.label,
      unit: numberType.unit,
      numberType: numberType.numberType,
      dimensionIds: dimensions,
      isMandatoryInDashboard: question.isMandatoryInDashboard,
    }
  }

  // We need to propagate the dimensions from the parent to the children.
  function flattenNumericQuestions(
    questionId: string,
    parentDimensions: string[],
  ): Question[] {
    const relatedQuestions = questionRelatedQuestions[questionId] ?? []
    const question = findQuestionById(questionId)

    if (question === undefined) {
      return relatedQuestions.flatMap((q) =>
        flattenNumericQuestions(q, parentDimensions),
      )
    }

    if (_.isArray(question)) {
      // we found a question
      const newDimensions = [...parentDimensions, ...question]

      return relatedQuestions.flatMap((q) =>
        flattenNumericQuestions(q, newDimensions),
      )
    }

    const newDimensions = [...parentDimensions, ...question.dimensionIds]

    return [
      ...[{ ...question, dimensionIds: newDimensions }],
      ...relatedQuestions.flatMap((q) =>
        flattenNumericQuestions(q, newDimensions),
      ),
    ]
  }

  return tree.allCsrdCategories.map((category) => {
    return {
      ...category,
      topics: category.topics.map((topic) => {
        return {
          ...topic,
          disclosureRequirements: topic.disclosureRequirements.map(
            (disclosureRequirement) => {
              return {
                ...disclosureRequirement,
                esrs_code: disclosureRequirement.esrsCode,
                questions: disclosureRequirement.questions.flatMap((question) =>
                  flattenNumericQuestions(question.id, []),
                ),
              }
            },
          ),
        }
      }),
    }
  })
}

export const useSurveyQuestionsDataModel = () => {
  const { token } = useUser()

  return useQuery({
    queryKey: ["dato", "surveys"],
    queryFn: async () => {
      return getSurveyQuestionsDataModel(token)
    },
  })
}
