import { Badge, Group, Loader } from "@mantine/core"
import { useMemo } from "react"

import { useListDimensionsQuery } from "@kiosk/reporting/api/dimensions/queries"
import { useSurveyQuestionsDataModel } from "@kiosk/reporting/api/surveys/questions/model/surveyQuestionsDataModel"
import { HIDDEN_DIMENSION } from "@kiosk/reporting/utils/constants"

type Props = {
  readonly questionDatoId: string
}

export const DimensionsCell = ({ questionDatoId }: Props) => {
  // TODO: that could be fetched upper in the react tree
  const { data: allDimensions } = useListDimensionsQuery()
  const { data: csrdCategories } = useSurveyQuestionsDataModel()

  const dimensions = useMemo(() => {
    if (!allDimensions || !csrdCategories) return []

    const allQuestions = csrdCategories.flatMap(({ topics }) =>
      topics.flatMap(({ disclosureRequirements }) =>
        disclosureRequirements.flatMap(({ questions }) => questions),
      ),
    )

    const datoQuestion = allQuestions.find(({ id }) => id === questionDatoId)

    return allDimensions.filter((dimension) =>
      datoQuestion?.dimensionIds.includes(dimension.id),
    )
  }, [allDimensions, csrdCategories, questionDatoId])

  // TODO: handle all query states
  if (!dimensions) return <Loader size="xs" />

  return (
    <Group gap={8}>
      {dimensions.map((dimension) => {
        if (
          dimension.options.length === 1 ||
          HIDDEN_DIMENSION.includes(dimension.id)
        )
          return

        return (
          <Badge
            color="gray"
            fw={500}
            key={dimension.id}
            size="lg"
            tt="none"
            variant="light"
          >
            {dimension.label}
          </Badge>
        )
      })}
    </Group>
  )
}
