// Lists all of the available categories with related topics & subtopics
export const CSRD_REPORT_TREE_STRUCTURE = `
query CsrdCategoriesWithTopics($locale: SiteLocale) {
  allCsrdCategories(locale: $locale) {
    id
    name
    order
    baseColor
    companies
    topics {
      id
      name
      code
      phaseInYearForAll
      phaseInYearForSmallAndMediumEnterprises
      isTitleInReport
      reportName
      disclosureRequirements {
        phaseInYearForAll
        phaseInYearForSmallAndMediumEnterprises
        id
        esrsCode
        name
        order
        questions {
          id
        }
      }
    }
  }
}
`

// This SUBQUERY allow to fetch the same content structure for the global DISCOSURE_REQUIREMNT query and for the contend of ConditionalAnswerRecord
const CONTENT_QUERY_WITHOUT_CONDITIONAL_ANSWER = `
... on BooleanRecord {
  __typename
  id
  yesLabel
  noLabel
}
... on CardRecord {
  __typename
  id
  cardType {
    __typename
  }
}
... on ChoiceRecord {
  __typename
  id
  isMultiple
  enumeration {
    id
    label
    xbrlCode
    options {
      id
      label
      xbrlCode
    }
  }
}
... on DateRecord {
  __typename
  id
}
... on NumberRecord {
  __typename
  id
  numberType
  unit
}
... on TextBlockRecord {
  __typename
  id
  inputType
}
... on UploadRecord {
  __typename
  id
}
... on TableRecord {
  __typename
  id
}
`

const CONTENT_QUERY = `
${CONTENT_QUERY_WITHOUT_CONDITIONAL_ANSWER}
        ... on ConditionalAnswerRecord {
          __typename
          id
          followUpTriggerAnswer
          initialQuestion {
            __typename
            id
            noLabel
            yesLabel
          }
          followUpQuestions {
            id
            label
            content {
              ${CONTENT_QUERY_WITHOUT_CONDITIONAL_ANSWER}
              ... on ConditionalAnswerRecord {
                __typename
                id
                followUpTriggerAnswer
                initialQuestion {
                  __typename
                  id
                  noLabel
                  yesLabel
                }
                followUpQuestions {
                  id
                  label
                  content {
                    ${CONTENT_QUERY_WITHOUT_CONDITIONAL_ANSWER}
                  }
                }
              }
            }
          }
        }
`
const DIMENSIONS_QUERY = `
dimensions {
  id
  label
  options {
    group
    id
    label
    xbrlCode
  }
  xbrlCode
}`

export const DATO_DIMENSIONS_QUERY = `
query GetDimensions($ids: [ItemId], $locale: SiteLocale) {
  allDimensions(filter: {id: {in: $ids}}, skip: 0, first: 100, locale: $locale) {
    id
    label
    options {
      id
      label
    }
  }
}
`

// Get details of a specific Disclosure Requirement by ID
export const CSRD_DISCLOSURE_REQUIREMENT_BY_ID = `
query CsrdDisclosureRequirementByIds($locale: SiteLocale, $id: ItemId) {
  disclosureRequirement(locale: $locale, filter: {id: {eq: $id}}) {
    id
    name
    xbrlCode
    esrsCode
    instructions {
      blocks {
        ... on CalloutRecord {
          __typename
          id
          hint
        }
      }
      links
      value
    }
    questions {
      id
      label
      hint
      hiddenInForm
      reportAnswer
      xbrlCode
      isTitle
      isVoluntary
      phaseInYearsForAll
        phaseInYearsForSmallAndMediumEnterprises

      content {
        ${CONTENT_QUERY}
      }
      ${DIMENSIONS_QUERY}
      relatedQuestions {
        isVoluntary
        phaseInYearsForAll
        phaseInYearsForSmallAndMediumEnterprises
        id
        label
        xbrlCode
        isTitle
        hint
        hiddenInForm
        content {
          ${CONTENT_QUERY}
        }
        ${DIMENSIONS_QUERY}
        relatedQuestions {
          isVoluntary
          phaseInYearsForAll
          phaseInYearsForSmallAndMediumEnterprises
          id
          label
          xbrlCode
          isTitle
          hint
          hiddenInForm
          content {
            ${CONTENT_QUERY}
          }
          ${DIMENSIONS_QUERY}
          relatedQuestions {
            isVoluntary
            phaseInYearsForAll
            phaseInYearsForSmallAndMediumEnterprises
            id
            label
            xbrlCode
            isTitle
            hint
            hiddenInForm
            content {
              ${CONTENT_QUERY}
            }
            ${DIMENSIONS_QUERY}
            relatedQuestions {
              isVoluntary
              phaseInYearsForAll
              phaseInYearsForSmallAndMediumEnterprises
              id
              label
              xbrlCode
              isTitle
              hint
              hiddenInForm
              content {
                ${CONTENT_QUERY}
              }
              ${DIMENSIONS_QUERY}
              relatedQuestions {
                isVoluntary
                phaseInYearsForAll
                phaseInYearsForSmallAndMediumEnterprises
                id
                label
                xbrlCode
                isTitle
                hint
                hiddenInForm
                content {
                  ${CONTENT_QUERY_WITHOUT_CONDITIONAL_ANSWER}
                }
              }
            }
          }
        }
      }
    }
  }
}`

export const CSRD_PHASE_IN_DISCLOSURE_REQUIREMENTS = `
query GetPhaseInDrs($locale: SiteLocale) {
  allDisclosureRequirements(locale: $locale, filter:{OR: [{phaseInYearForSmallAndMediumEnterprises: {neq: null}}, {phaseInYearForAll: {neq: null}}]}){
    id
    name
    phaseInYearForAll
    phaseInYearForSmallAndMediumEnterprises
  }
}
`

export const CSRD_ALL_ESRS = `
  query AllEsrs($locale: SiteLocale) {
    allCsrdTopics(locale: $locale) {
      id
      name
      phaseInYearForAll
      phaseInYearForSmallAndMediumEnterprises
    }
  }
`

export const CSRD_DISCLOSURE_REQUIREMENT_BY_ESRS_EXCLUSION = `
  query CSRDDisclosureRequirementsByExclusion($excludedEsrsIds: [ItemId]) {
    allCsrdTopics(filter: {id: {notIn: $excludedEsrsIds}}) {
      disclosureRequirements {
        id
      }
    }
  }
`

export const CSRD_ESRS_BY_IDS = `
query CSRDESRSByIds($ids : [ItemId], $locale: SiteLocale){
  allCsrdTopics(filter: {id: {in: $ids}}, locale: $locale){
    id,
    name
  }
}
`

export const VOLUNTARY_QUESTION_IDS = `
query VoluntaryQuestionIds {
  allQuestions(filter: {isVoluntary: {eq: true}}){
    id
  }
}
`

export const PHASE_IN_THEMATIC_QUESTION_GROUPS = `
query PhaseInThematicQuestionGroups($locale: SiteLocale) {
  allPhaseInThematicQuestionGroups(locale: $locale) {
    id
    label
    questions {
      id
    }
    phaseInYearForAll
    phaseInYearForSmallAndMediumEnterprises
  }
}
`

export const PHASE_IN_OPTIONS_LABELS = `
query GetPhaseInOptionsLabels($locale: SiteLocale, $esrs: [ItemId], $dr: [ItemId], $questions: [ItemId]) {
	allCsrdTopics(locale: $locale, filter: {id: {in: $esrs}}){
    name
    id
  }
  allDisclosureRequirements(locale: $locale, filter: {id: {in: $dr}}){
    name
    id
  }
  allQuestions(locale: $locale, filter: {id:{in: $questions}}, first: 100){
    label
    id
  }
}
`

export const CSRD_DISCLOSURE_REQUIREMENT_BY_ESRS_ID = `
  query CSRDDisclosureRequirementsByEsrs($esrsId: ItemId) {
    csrdTopic(filter: {id: {eq: $esrsId}}) {
      disclosureRequirements {
        id
      }
    }
  }
`
export const CSRD_DISCLOSURE_REQUIREMENT_BY_IDS = `
query CsrdDisclosureRequirementByIds($in: [ItemId] = "") {
  allDisclosureRequirements(filter: {id: {in: $in}}) {
    id
    xbrlCode
    esrsCode
    name
  }
}`

export const ALL_QUESTIONS = `
query AllQuestions($locale: SiteLocale, $first: IntType, $skip: IntType) {
  allQuestions(locale: $locale, first: $first, skip: $skip) {
    id
    dimensions {
      id
    }
    _allReferencingQuestions {
      id
    }
  }
  _allQuestionsMeta {
    count
  }
}`

export const ALL_NUMBERS = `
query AllNumbers($locale: SiteLocale, $first: IntType, $skip: IntType) {
  allNumbers(locale: $locale, first: $first, skip: $skip) {
    numberType
    unit
    _allReferencingQuestions {
      id
      label
      isMandatoryInDashboard
    }
  }
  _allNumbersMeta {
    count
  }
}`

export const ALL_QUESTIONS_TO_CSRD_CATEGORY = `
query AllQuestionsToCsrdCategory($locale: SiteLocale, $first: IntType, $skip: IntType) {
  allQuestions(locale: $locale, first: $first, skip: $skip) {
    id
    _allReferencingDisclosureRequirements {
      id
      name
      _allReferencingCsrdTopics {
        id
        name
        _allReferencingCsrdCategories {
          id
          name
          order
          baseColor
          companies
        }
      }
    }
  }
  _allQuestionsMeta {
    count
  }
}
`

export const ALL_TYPED_DIMENSIONS_QUERY = `
query AllTypedDimensions($locale: SiteLocale) {
  allDimensions(locale: $locale, first: "100", filter: {dimensionType: {eq: "typed"}}) {
    id
    label
    category
  }
}
`

export const ALL_DIMENSIONS_QUERY = `
query AllDimensions($locale: SiteLocale, $first: IntType, $skip: IntType) {
  allDimensions(locale: $locale, first: $first, skip: $skip) {
    id
    dimensionType
    label
    category
    xbrlCode
    options {
      label
      xbrlCode
      id
      group
    }
    _allReferencingQuestions(first: "1") {
      periodType
    }
  }

  _allDimensionsMeta {
    count
  }
}
`
export const DIMENSIONS_BY_IDS = `
query AllDimensionsByIDs($ids: [ItemId], $first: IntType, $skip: IntType) {
  allDimensions(filter: {id: {in: $ids}}, first: $first, skip: $skip) {
    id
    _allLabelLocales {
      locale,
      value
    }
  }
  _allDimensionsMeta {
    count
  }
}
`

export const ALL_DIMENSION_IDS = `
query AllDimensions($first: IntType, $skip: IntType) {
  allDimensions(first: $first, skip: $skip) {
    id
  }
  _allDimensionsMeta {
    count
  }
}`

export const OPTIONS_BY_IDS = `
query AllOptionsByIDs($ids: [ItemId], $first: IntType, $skip: IntType) {
  allDimensionMembers(filter: {id: {in: $ids}}, first: $first, skip: $skip) {
    id
    _allLabelLocales {
      locale,
      value
    }
  }
  _allDimensionMembersMeta {
    count
  }
}
`

export const ALL_OPTION_IDS = `
query AllOptionIDs($first: IntType, $skip: IntType) {
  allDimensionMembers(first: $first, skip: $skip) {
    id
  }
  _allDimensionMembersMeta {
    count
  }
}`

// Lists the disclosure requirements by name with their IDs and names only.
export const ALL_CATEGORIES_TOPICS_NAMES_QUERY = `
query CategoriesTopicsNames($locale: SiteLocale) {
  allCsrdCategories(locale: $locale) {
    id
    name
    topics {
      id
      name
    }
  }
}
`
