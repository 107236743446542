import { Group, Text, ThemeIcon, Tooltip } from "@mantine/core"
import { IconExclamationCircle } from "@tabler/icons-react"
import { useTranslation } from "react-i18next"

type AssigneesCountCellProps = {
  readonly assignments: number
  readonly assignees: number
}

export const AssigneesCountCell = ({
  assignments,
  assignees,
}: AssigneesCountCellProps) => {
  const { t } = useTranslation("survey")

  const isMissingBreakdown = assignments === 0
  const isMissingAssignees = assignees < assignments
  const isIncomplete = isMissingBreakdown || isMissingAssignees

  const tooltipLabel = isMissingBreakdown
    ? t("errors.missingBreakdown")
    : t("errors.missingAssignees", {
        count: assignments - assignees,
      })

  return (
    <Group flex={1} justify="space-between" wrap="nowrap">
      <Text
        c={isIncomplete ? "red.8" : "var(--mantine-color-gray-8)"}
        size="md"
        style={{ whiteSpace: "nowrap" }}
      >
        {assignees} / {assignments}
      </Text>

      {isIncomplete && (
        <Tooltip label={tooltipLabel}>
          <ThemeIcon c="red.8" size="sm">
            <IconExclamationCircle />
          </ThemeIcon>
        </Tooltip>
      )}
    </Group>
  )
}
