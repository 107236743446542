import {
  Button,
  Drawer,
  Group,
  Select,
  Stack,
  Text,
  TextInput,
} from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import { useDisclosure } from "@mantine/hooks"
import { IconBuilding } from "@tabler/icons-react"
import { useTranslation } from "react-i18next"

import { useCreateCompanyMutation } from "@kiosk/reporting/api/companies/createCompany"
import { PictureUploader } from "@kiosk/reporting/components/PictureUploader"
import {
  CompanyBody,
  companySchema,
} from "@kiosk/reporting/legacy/shared/schemas/companies"

export const CompanyForm = () => {
  const { t } = useTranslation(["common", "company"])
  const [opened, { open, close }] = useDisclosure(false)

  const form = useForm<CompanyBody>({
    validate: zodResolver(companySchema),
    validateInputOnBlur: true,
    validateInputOnChange: true,
    initialValues: {
      name: "",
      email: "",
      firstName: "",
      lastName: "",
      logoUrl: "",
      logoInitials: "",
      branding: "KIOSK",
      userRole: "KIOSK_ADMIN",
    },
  })

  const handleCancel = () => {
    form.reset()
    close()
  }
  const { mutateAsync: createOrganization, isPending } =
    useCreateCompanyMutation(handleCancel)

  const handleOrganizationCreation = async () => {
    await createOrganization(form.values)
  }

  return (
    <>
      <Button onClick={open}>{t("company:newCompany")}</Button>

      <Drawer.Root onClose={close} opened={opened} position="right" size="md">
        <Drawer.Overlay />

        <Drawer.Content>
          <Stack gap={32} h="100%">
            <Drawer.Header
              p={24}
              style={{ borderBottom: "1px solid var(--mantine-color-gray-3)" }}
            >
              <Drawer.Title c="black" fw={600} fz="lg">
                {t("company:addCompany")}
              </Drawer.Title>

              <Drawer.CloseButton />
            </Drawer.Header>

            <Stack h="100%" justify="space-between">
              <Drawer.Body px={24}>
                <form
                  id="add-company"
                  onSubmit={form.onSubmit(handleOrganizationCreation)}
                >
                  <Stack>
                    <Text fw={500}>{t("company:columns.logo")}</Text>

                    <PictureUploader
                      destination="company-logo/"
                      placeholder={<IconBuilding />}
                      {...form.getInputProps("logoUrl")}
                    />

                    <TextInput
                      label={t("company:logoInitials")}
                      placeholder="Ki"
                      w="100%"
                      {...form.getInputProps("logoInitials")}
                    />

                    <TextInput
                      label={t("company:companyName")}
                      placeholder={t("company:companyName")}
                      w="100%"
                      withAsterisk
                      {...form.getInputProps("name")}
                    />

                    <TextInput
                      label={t("fields.firstNameLabel")}
                      placeholder={t("fields.firstNamePlaceholder")}
                      withAsterisk
                      {...form.getInputProps("firstName")}
                    />

                    <TextInput
                      error
                      label={t("fields.lastNameLabel")}
                      placeholder={t("fields.lastNamePlaceholder")}
                      withAsterisk
                      {...form.getInputProps("lastName")}
                    />

                    <TextInput
                      label={t("company:companyContact")}
                      placeholder={t("columns.email")}
                      type="email"
                      withAsterisk
                      {...form.getInputProps("email")}
                    />

                    <Select
                      allowDeselect={false}
                      data={["KIOSK", "GREENSTER"]}
                      defaultValue="KIOSK"
                      label={t("company:branding")}
                      {...form.getInputProps("branding")}
                    />

                    <Select
                      allowDeselect={false}
                      data={["KIOSK_ADMIN", "COMPANY_ADMIN"]}
                      defaultValue="KIOSK_ADMIN"
                      label={t("company:userRole")}
                      {...form.getInputProps("userRole")}
                    />
                  </Stack>
                </form>
              </Drawer.Body>

              <Group
                gap={10}
                justify="flex-end"
                p={24}
                style={{ borderTop: "1px solid var(--mantine-color-gray-3)" }}
              >
                <Button color="gray" onClick={handleCancel} variant="outline">
                  {t("buttons.cancel")}
                </Button>

                <Button
                  disabled={!form.isValid()}
                  form="add-company"
                  loading={isPending}
                  type="submit"
                >
                  {t("buttons.save")}
                </Button>
              </Group>
            </Stack>
          </Stack>
        </Drawer.Content>
      </Drawer.Root>
    </>
  )
}
