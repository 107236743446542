import { Flex, Group, Text, Box, Tooltip, ActionIcon } from "@mantine/core"
import { IconTag, IconTagOff } from "@tabler/icons-react"
import _ from "lodash"
import { Dispatch, SetStateAction } from "react"
import { useTranslation } from "react-i18next"
import { useFetcher } from "react-router-dom"

import { QuestionTooltip } from "@kiosk/reporting/components/csrd/QuestionTooltip"
import { PhaseInSelect } from "@kiosk/reporting/components/csrd/phaseIn/PhaseInSelect"
import { VoluntaryBadge } from "@kiosk/reporting/components/csrd/voluntary/VoluntaryBadge"
import { CURRENT_REPORT_YEAR } from "@kiosk/reporting/legacy/shared/constants/common/phaseIn"
import { isDefined } from "@kiosk/reporting/legacy/shared/utils/helpers"
import { Content } from "@kiosk/reporting/legacy/types/data/dato"
import { action as setQuestionPhaseInAction } from "@kiosk/reporting/routes/csrd_.$datoTopicId_.$datoSubTopicId_.$datoQuestionId/route"
import { EnrichedDimensionBreakdown } from "@kiosk/reporting/utils/dimensions"
import {
  ContentWithCompanyData,
  CSRDQuestionsWithCompanyData,
} from "@kiosk/reporting/utils/types/csrd"

type CSRDQuestionHeaderProps = {
  readonly question: CSRDQuestionsWithCompanyData<ContentWithCompanyData>
  readonly hasOnlyOneDimensionWithOneDimensionValue: boolean
  readonly showDimensionsBreakdown: boolean
  readonly setShowDimensionsBreakdown: (
    showDimensionsBreakdown: boolean,
  ) => void
  readonly isTitle?: boolean
  readonly setDimensionBreakdowns: Dispatch<
    SetStateAction<EnrichedDimensionBreakdown[] | undefined>
  >
  readonly onResetBreakdown?: () => void
  readonly datoTopicId?: string
  readonly datoSubTopicId?: string
}

export const CSRDQuestionHeader = ({
  question,
  hasOnlyOneDimensionWithOneDimensionValue,
  showDimensionsBreakdown,
  setShowDimensionsBreakdown,
  isTitle,
  setDimensionBreakdowns,
  onResetBreakdown,
  datoTopicId,
  datoSubTopicId,
}: CSRDQuestionHeaderProps) => {
  const { t } = useTranslation("csrd")

  const updateQuestionPhaseInFetcher =
    useFetcher<typeof setQuestionPhaseInAction>()

  const showBreakdownButton =
    question.dimensions &&
    !_.isEmpty(question.dimensions) &&
    !hasOnlyOneDimensionWithOneDimensionValue

  const getPhaseInAvailableYears = (
    postponableQuestion: CSRDQuestionsWithCompanyData<Content>,
  ) => {
    const startYear = CURRENT_REPORT_YEAR
    const phaseInYears = _.range(0, postponableQuestion.phaseInYears! + 1).map(
      (dy) => startYear + dy,
    )

    return phaseInYears
  }

  const questionPhaseInHandlingPath = `/csrd/${datoTopicId}/${datoSubTopicId}/${question.id}`

  return (
    <Flex direction="row">
      {question.isVoluntary && !isDefined(question.phaseInYear) && (
        <VoluntaryBadge />
      )}
      {isDefined(question.phaseInYear) && (
        <PhaseInSelect
          availableYears={getPhaseInAvailableYears(question)}
          onChange={(value) => {
            if (value) {
              updateQuestionPhaseInFetcher.submit(
                {
                  datoQuestionId: question.id,
                  newPhaseInYear: Number(value),
                },
                { action: questionPhaseInHandlingPath, method: "PATCH" },
              )
            }
          }}
          selectedYear={question.phaseInYear}
        />
      )}
      <Group flex={1} justify="space-between" wrap="nowrap">
        <Group gap={8}>
          <Text fw={isTitle ? 700 : 500} size={isTitle ? "md" : "sm"} span>
            {question.label}
            {question.isVoluntary && isDefined(question.phaseInYear) && (
              <Box style={{ display: "inline-flex" }} w={100}>
                <VoluntaryBadge withPhaseIn />
              </Box>
            )}
          </Text>
          {question.hint && <QuestionTooltip content={question.hint} />}
        </Group>
        {showBreakdownButton && (
          <Tooltip
            label={
              showDimensionsBreakdown
                ? t("breakdown.removeBreakdown")
                : t("breakdown.addBreakdown")
            }
            withArrow
          >
            <ActionIcon
              onClick={() => {
                setShowDimensionsBreakdown(!showDimensionsBreakdown)
                setDimensionBreakdowns(undefined)
                showDimensionsBreakdown &&
                  onResetBreakdown &&
                  onResetBreakdown()
              }}
              size={36}
              style={{
                border: "1px solid var(--mantine-color-gray-2)",
                backgroundColor: "var(--mantine-color-gray-0)",
              }}
            >
              {showDimensionsBreakdown ? (
                <IconTagOff color="var(--mantine-color-gray-5)" size={20} />
              ) : (
                <IconTag color="var(--mantine-color-gray-5)" size={20} />
              )}
            </ActionIcon>
          </Tooltip>
        )}
      </Group>
    </Flex>
  )
}
