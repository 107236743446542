import { showNotification } from "@mantine/notifications"
import { useMutation } from "@tanstack/react-query"

import { useUser } from "@kiosk/reporting/components/auth/UserContext"
import { AccountResponses } from "@kiosk/reporting/legacy/types/endpoints/account"
import { apiClient } from "@kiosk/reporting/lib/apiClient"
import { authorizationHeader } from "@kiosk/reporting/lib/headers"

export const impersonateUser = async (clerkUserId: string, token: string) => {
  const response = await apiClient.post<AccountResponses.Impersonation>(
    `/account/impersonate/${clerkUserId}`,
    { headers: authorizationHeader(token) },
  )

  return response.data
}

export const useImpersonateUser = () => {
  const { token } = useUser()

  return useMutation({
    mutationFn: (clerkId: string) => impersonateUser(clerkId, token),
    onSuccess: async (data: AccountResponses.Impersonation) => {
      if (data && data.url) {
        localStorage.clear()
        window.location.href = data.url
      }
    },
    onError: (error: Error) => {
      //TODO: Handle errors
      showNotification({ message: error.message })
    },
  })
}
