import { notifications } from "@mantine/notifications"
import { useMutation } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"

import { accountKeys } from "@kiosk/reporting/api/account/accountKeys"
import { useUser } from "@kiosk/reporting/components/auth/UserContext"
import { OnboardingRequests } from "@kiosk/reporting/legacy/types/endpoints/onboarding"
import { apiClient } from "@kiosk/reporting/lib/apiClient"
import { authorizationHeader } from "@kiosk/reporting/lib/headers"
import { queryClient } from "@kiosk/reporting/lib/queryClient"

export const completeOnBoarding = async (
  token: string,
  data?: OnboardingRequests.UploadFile,
) => {
  const response = await apiClient.post("/onboarding/file-upload", data, {
    headers: authorizationHeader(token),
  })

  return response.data
}

export const useCompleteOnBoardingMutation = (onSuccess: () => void) => {
  const { t } = useTranslation()
  const user = useUser()

  return useMutation({
    mutationFn: (data?: OnboardingRequests.UploadFile) =>
      completeOnBoarding(user.token, data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: accountKeys.me(user?.clerkId),
      })
      onSuccess()
    },
    onError: (error: Error) => {
      notifications.show({
        title: t("messages.error"),
        message: error.message,
        color: "red",
      })
    },
  })
}
