import { notifications } from "@mantine/notifications"
import { useMutation } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"

import { taskKeys } from "@kiosk/reporting/api/tasks/taskKeys"
import { useUser } from "@kiosk/reporting/components/auth/UserContext"
import { apiClient } from "@kiosk/reporting/lib/apiClient"
import { authorizationHeader } from "@kiosk/reporting/lib/headers"
import { queryClient } from "@kiosk/reporting/lib/queryClient"

export const deleteCategory = async (
  categoryId: string,
  token: string,
): Promise<{ message: string }> => {
  const response = await apiClient.delete(`/categories/${categoryId}`, {
    headers: authorizationHeader(token),
  })

  return response.data
}

export const useDeleteCategoryMutation = (close: () => void) => {
  const { t } = useTranslation(["task", "common"])
  const { token } = useUser()

  return useMutation({
    mutationFn: async (categoryId: string) =>
      await deleteCategory(categoryId, token),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: taskKeys.all(),
      })
      await queryClient.invalidateQueries({ queryKey: taskKeys.owners() })
      close()
      notifications.show({
        title: t("common:messages.success"),
        message: `${t("actions.deleteCategorySuccess")}`,
        color: "green",
      })
    },
    onError: ({ message }) => {
      notifications.show({
        title: t("common:messages.error"),
        message,
        color: "red",
      })
    },
  })
}
