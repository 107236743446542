import { Stack } from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import _ from "lodash"
import { Dispatch, SetStateAction } from "react"

import { CSRDDimensionsQuestion } from "@kiosk/reporting/components/csrd/CSRDQuestionTree/CSRDDimensionsQuestion/CSRDDimensionsQuestion"
import CSRDQuestionTree from "@kiosk/reporting/components/csrd/CSRDQuestionTree/CSRDQuestionTree"
import { PhaseInVoluntaryQuestionsWrapper } from "@kiosk/reporting/components/csrd/PhaseInVoluntaryWrapper/PhaseInVoluntaryWrapper"
import { RelatedQuestionsWrapper } from "@kiosk/reporting/components/csrd/RelatedQuestionsWrapper"
import { FormShape } from "@kiosk/reporting/components/csrd/types"
import { DimensionsBreakdownSelect } from "@kiosk/reporting/components/dimensions/DimensionsBreakdownSelect"
import { CSRDQuestionAnswer } from "@kiosk/reporting/legacy/types/csrd"
import { DimensionBreakdownValue } from "@kiosk/reporting/legacy/types/dimensions"
import { Framework } from "@kiosk/reporting/lib/entities/company"
import { EnrichedDimensionBreakdown } from "@kiosk/reporting/utils/dimensions"
import {
  ContentWithCompanyData,
  CSRDQuestionsWithCompanyData,
} from "@kiosk/reporting/utils/types/csrd"

type CSRDQuestionProps = {
  readonly question: CSRDQuestionsWithCompanyData<ContentWithCompanyData>
  readonly form: UseFormReturnType<FormShape>
  readonly relatedQuestions: CSRDQuestionsWithCompanyData<ContentWithCompanyData>[]
  readonly isParentCollapsed?: boolean
  readonly isTitle?: boolean
  readonly isHiddenInForm?: boolean
  readonly breakdown?: EnrichedDimensionBreakdown
  readonly showDimensionsBreakdown: boolean
  readonly dimensionBreakdowns?: EnrichedDimensionBreakdown[]
  readonly setDimensionBreakdowns: Dispatch<
    SetStateAction<EnrichedDimensionBreakdown[] | undefined>
  >
  readonly totalQuestions?: number
  readonly onResetBreakdown?: () => void
  readonly initialBreakdowns: DimensionBreakdownValue[]
  readonly externalAnswers: Record<string, CSRDQuestionAnswer>
  readonly setExternalAnswers: Dispatch<
    SetStateAction<Record<string, CSRDQuestionAnswer>>
  >
  readonly framework: Framework
}

export const CSRDQuestion = ({
  question,
  form,
  relatedQuestions,
  isParentCollapsed,
  isTitle,
  isHiddenInForm,
  breakdown,
  showDimensionsBreakdown,
  dimensionBreakdowns,
  setDimensionBreakdowns,
  onResetBreakdown,
  initialBreakdowns,
  externalAnswers,
  setExternalAnswers,
  totalQuestions,
  framework,
}: CSRDQuestionProps) => {
  const hasDimensions = question.dimensions && !_.isEmpty(question.dimensions)
  const hasRelatedQuestions = !_.isEmpty(relatedQuestions)

  if (!hasRelatedQuestions) {
    return null
  }

  if (!hasDimensions || hasOneDimensionWithOneOption(question)) {
    return (
      <RelatedQuestionsWrapper
        hiddenInForm={question.hiddenInForm}
        isTitle={isTitle}
      >
        <PhaseInVoluntaryQuestionsWrapper
          framework={framework}
          isParentCollapsed={isParentCollapsed ?? false}
          questions={relatedQuestions}
          totalQuestions={totalQuestions}
        >
          {(relatedQuestion, parentCollapsed) => (
            <CSRDQuestionTree
              breakdown={breakdown}
              externalAnswers={externalAnswers}
              form={form}
              framework={framework}
              isPreviousIsTitle={isTitle && !isHiddenInForm}
              parentCollapsed={parentCollapsed}
              question={relatedQuestion}
              setExternalAnswers={setExternalAnswers}
              totalQuestions={totalQuestions}
            />
          )}
        </PhaseInVoluntaryQuestionsWrapper>
      </RelatedQuestionsWrapper>
    )
  }

  return (
    <Stack>
      <DimensionsBreakdownSelect
        dimensionIds={question.dimensions!.map((d) => d.id)}
        initialBreakdowns={initialBreakdowns}
        isCSRD
        onGenerateBreakdowns={setDimensionBreakdowns}
        onResetBreakdown={onResetBreakdown}
        showDimensionsBreakdown={showDimensionsBreakdown}
      />
      <CSRDDimensionsQuestion
        breakdown={breakdown}
        dimensionBreakdowns={dimensionBreakdowns}
        externalAnswers={externalAnswers}
        form={form}
        framework={framework}
        hasDimensions={hasDimensions}
        isParentCollapsed={isParentCollapsed}
        question={question}
        relatedQuestions={relatedQuestions}
        setExternalAnswers={setExternalAnswers}
        showDimensionsBreakdown={showDimensionsBreakdown}
        totalQuestions={totalQuestions}
      />
    </Stack>
  )
}

const hasOneDimensionWithOneOption = (
  question: CSRDQuestionsWithCompanyData<ContentWithCompanyData>,
) => {
  return (
    question?.dimensions?.length === 1 &&
    question.dimensions[0]?.options?.length === 1
  )
}
