import { useListDimensionsQuery } from "@kiosk/reporting/api/dimensions/queries"
import { TypedDimension } from "@kiosk/reporting/api/dimensions/types"
import { QueryWrapper } from "@kiosk/reporting/components/layout/QueryWrapper"
import { ConsolidatedDimension } from "@kiosk/reporting/legacy/types/utils/dimensions"
import { CompanyDimensionAggregate } from "@kiosk/reporting/lib/aggregates/companyDimensionAggregate"

type TypedDimensionFillerWrapperProps = {
  readonly datoDimensionIds: string[]
  readonly children: (props: {
    dimensions: ConsolidatedDimension[]
  }) => JSX.Element | null
}

export const TypedDimensionFillerWrapper = ({
  datoDimensionIds,
  children,
}: TypedDimensionFillerWrapperProps) => {
  const dimensionsQuery = useListDimensionsQuery()

  return (
    <QueryWrapper query={dimensionsQuery}>
      {({ data }) => {
        const dimensions = data
          .filter((d) => datoDimensionIds.includes(d.id))
          .sort((a, b) => {
            const aIsTyped = isTypedDimension(a)
            const bIsTyped = isTypedDimension(b)

            if (aIsTyped === bIsTyped) return 0

            return aIsTyped ? 1 : -1
          })

        return children({ dimensions })
      }}
    </QueryWrapper>
  )
}

export const isTypedDimension = (
  dimension: ConsolidatedDimension | CompanyDimensionAggregate | TypedDimension,
): dimension is TypedDimension =>
  "dimensionType" in dimension && dimension.dimensionType === "typed"
