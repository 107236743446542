import { Prisma } from "@prisma/client/index"

export type User = Prisma.UserGetPayload<{ include: { company: true } }>

export const USER_ROLES = {
  KIOSK_ADMIN: "KIOSK_ADMIN",
  COMPANY_ADMIN: "COMPANY_ADMIN",
  CONTRIBUTOR: "CONTRIBUTOR",
  AUDITOR: "AUDITOR",
  VIEWER: "VIEWER",
  VERIFIER: "VERIFIER",
  FINANCE_OWNER: "FINANCE_OWNER",
  HR_OWNER: "HR_OWNER",
} as const

export type UserRole = keyof typeof USER_ROLES
