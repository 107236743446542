import { notifications } from "@mantine/notifications"
import { useMutation } from "@tanstack/react-query"
import { Dispatch, SetStateAction } from "react"
import { useTranslation } from "react-i18next"

import { taskKeys } from "@kiosk/reporting/api/tasks/taskKeys"
import { useUser } from "@kiosk/reporting/components/auth/UserContext"
import { CategoryBody } from "@kiosk/reporting/legacy/shared/schemas/categories"
import { apiClient } from "@kiosk/reporting/lib/apiClient"
import { authorizationHeader } from "@kiosk/reporting/lib/headers"
import { queryClient } from "@kiosk/reporting/lib/queryClient"

export interface UpdateCategoryPayload {
  categoryId: string
  data: CategoryBody
}

export const updateCategory = async ({
  categoryId,
  data,
  token,
}: UpdateCategoryPayload & { token: string }) => {
  const response = await apiClient.put(`/categories/${categoryId}`, data, {
    headers: authorizationHeader(token),
  })

  return response.data
}

export const useUpdateCategoryMutation = (
  setEditable: Dispatch<SetStateAction<boolean>>,
) => {
  const { t } = useTranslation(["task", "common"])
  const { token } = useUser()

  return useMutation({
    mutationFn: async (args: UpdateCategoryPayload) =>
      await updateCategory({ ...args, token }),
    onSuccess: async () => {
      setEditable(false)
      await queryClient.invalidateQueries({
        queryKey: taskKeys.all(),
      })
      notifications.show({
        title: t("common:messages.success"),
        message: t("task:actions.updateCategorySuccess"),
        color: "green",
      })
    },
    onError: ({ message }) => {
      notifications.show({
        title: t("common:messages.error"),
        message,
        color: "red",
      })
    },
  })
}
