import { Button, Flex, Group, Stack, TextInput } from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import { IconPlus, IconSubtask, IconX } from "@tabler/icons-react"
import { useTranslation } from "react-i18next"

import { GeoDimensionForm } from "@kiosk/reporting/routes/_onboarding.onboarding/utils/types"

interface TreeBranchProps {
  readonly treeLevel: GeoDimensionForm["tree"]
  readonly form: UseFormReturnType<GeoDimensionForm>
  readonly formKey: string
  readonly depth: number
}

export const TreeStructure = ({
  treeLevel,
  depth,
  formKey,
  form,
}: TreeBranchProps) => {
  const { t } = useTranslation("onboarding")

  return (
    <Flex direction="column" gap={0}>
      <Flex gap={0} h="fit-content">
        {treeLevel.length > 0 || depth === 1 ? (
          <Flex
            bg="gray.4"
            h="calc(100% - 64px)"
            mb={64}
            ml={8}
            mr={8}
            pt={16}
            w={1}
          />
        ) : null}

        <Flex direction="column" flex={1} gap={0}>
          {treeLevel.map((level, index) => (
            <Stack gap={0} key={`tree-${index}`}>
              <Group align="flex-start" gap={4} pos="relative" pt={16}>
                <Flex className="tree-branch" />

                <Group flex={1} grow ml={4}>
                  <TextInput
                    defaultValue={level.label}
                    key={index}
                    placeholder={
                      depth === 1
                        ? t("fields.regionPlaceHolder")
                        : t("fields.sitePlaceHolder")
                    }
                    {...form.getInputProps(`${formKey}.${index}.label`)}
                  />
                </Group>

                <Group gap={0} grow={false}>
                  {depth < 2 ? (
                    <Button
                      color="gray"
                      h={36}
                      onClick={() =>
                        form.insertListItem(`${formKey}.${index}.children`, {
                          label: "",
                          dimensionType: "site",
                          children: [],
                        })
                      }
                      p={8}
                      variant="transparent"
                      w={36}
                    >
                      <IconSubtask height={20} width={20} />
                    </Button>
                  ) : null}

                  <Button
                    color="gray"
                    h={36}
                    onClick={() => form.removeListItem(formKey, index)}
                    p={8}
                    variant="transparent"
                    w={36}
                  >
                    <IconX height={20} width={20} />
                  </Button>
                </Group>
              </Group>

              {level.children ? (
                <TreeStructure
                  depth={depth + 1}
                  form={form}
                  formKey={`${formKey}.${index}.children`}
                  key={index}
                  treeLevel={level.children}
                />
              ) : null}
            </Stack>
          ))}

          {treeLevel && treeLevel?.length === 0 && depth > 1 ? null : (
            <Group align="flex-start" gap={0} pos="relative" pr={8} py={16}>
              <Flex
                h="calc(100% - 36px)"
                left={-17}
                mb={64}
                ml={8}
                mr={8}
                pt={16}
                style={{
                  position: "absolute",
                  borderBottomLeftRadius: 8,
                  borderBottom: "solid 1px var(--mantine-color-gray-4)",
                  borderLeft: "solid 1px var(--mantine-color-gray-4)",
                }}
                top={0}
                w={8}
              />

              <Button
                onClick={() =>
                  form.insertListItem(formKey, {
                    label: "",
                    dimensionType: depth === 1 ? "region" : "site",
                    children: depth < 3 ? [] : undefined,
                  })
                }
                p={8}
                variant="transparent"
              >
                <IconPlus height={20} width={20} /> {t("actions.add")}
              </Button>
            </Group>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}
