import { Account } from "@kiosk/reporting/routes/settings.account.edit/components/Account"

export const handle = {
  breadcrumb: () => "account",
  title: () => "account",
}

export default function SettingsUserPage() {
  return <Account />
}
