import { DateInput } from "@mantine/dates"
import { IconCalendar } from "@tabler/icons-react"
import dayjs from "dayjs"

import { useUser } from "@kiosk/reporting/components/auth/UserContext"
import { InputProps } from "@kiosk/reporting/components/csrd/questions/types"
import { DateRecord } from "@kiosk/reporting/legacy/types/data/dato"

export const DateQuestion = ({ formProps }: InputProps<DateRecord>) => {
  const user = useUser()

  const { defaultValue, ...restProps } = formProps
  const minimumDate = dayjs("2020-01-01").toDate()
  const firstDayOfPreviousYear = dayjs()
    .subtract(1, "year")
    .startOf("year")
    .toDate()

  return (
    <DateInput
      defaultDate={firstDayOfPreviousYear}
      minDate={minimumDate}
      {...restProps}
      defaultValue={defaultValue ? new Date(defaultValue) : undefined}
      leftSection={<IconCalendar />}
      size="md"
      valueFormat={user.locale === "fr" ? "DD MMMM YYYY" : "MMMM DD YYYY"}
    />
  )
}
