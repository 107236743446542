import { Dispatch, SetStateAction } from "react"

import { ProgressState } from "@kiosk/reporting/components/loader/ExportProgressModal/ExportProgressModal"
import classes from "@kiosk/reporting/components/loader/RingProgressBar/RingProgressBar.module.css"

export const RingProgressBar = ({
  delay = 0,
  duration = 2,
  completionValue = 100,
  size = 22,
  strokeWidth = 2.5,
  setProgressState,
}: {
  readonly delay?: number
  readonly duration?: number
  readonly completionValue?: number
  readonly size?: number
  readonly strokeWidth?: number
  readonly setProgressState?: Dispatch<SetStateAction<ProgressState>>
}) => {
  return (
    <svg
      className={classes["circular-progress"]}
      height={size}
      onAnimationEnd={() => setProgressState?.("completed")}
      onAnimationStart={() => setProgressState?.("inProgress")}
      style={
        {
          animationDelay: `${delay}s`,
          animationDuration: `${duration}s`,
          "--completion-value": completionValue,
          "--size": `${size}px`,
          "--stroke-width": `${strokeWidth}px`,
        } as React.CSSProperties
      }
      viewBox={`0 0 ${size + 1} ${size + 1}`}
      width={size}
    >
      <circle className={classes["bg"]} />
      <circle className={classes["fg"]} />
    </svg>
  )
}
