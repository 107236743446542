import { SegmentedControl } from "@mantine/core"
import { useTranslation } from "react-i18next"

interface DisclosureRequirementTabProps {
  readonly onChange: (tab: string) => void
  readonly value: string
}

export const DisclosureRequirementTab = (
  props: DisclosureRequirementTabProps,
) => {
  const { t } = useTranslation("csrd")

  return (
    <SegmentedControl
      data={[t("preview.tabForm"), t("preview.tabPreview")]}
      onChange={props.onChange}
      value={props.value}
    />
  )
}
