import {
  Checkbox,
  Group,
  Table,
  TableTbody,
  TableTd,
  TableTh,
  TableThead,
  TableTr,
} from "@mantine/core"
import _ from "lodash"
import { Dispatch, SetStateAction, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import { BulkAction } from "@kiosk/reporting/components/BulkAction/BulkAction"
import { AssignmentDimensionsCell } from "@kiosk/reporting/components/surveys/AssignmentDimensionsCell"
import { CreateAssignmentBody } from "@kiosk/reporting/legacy/shared/schemas/assignments"
import { CompanyResponses } from "@kiosk/reporting/legacy/types/endpoints/companies"
import { ConsolidatedDimension } from "@kiosk/reporting/legacy/types/utils/dimensions"
import { CompanyDimensionAggregate } from "@kiosk/reporting/lib/aggregates/companyDimensionAggregate"
import { AssigneeSelect } from "@kiosk/reporting/routes/sources_.edit_question.$id/components/AssigneeSelect"
import { Assignee } from "@kiosk/reporting/routes/sources_.edit_question.$id/components/EditQuestion"

type AssignmentsTableProps = {
  readonly currentAssignments: CreateAssignmentBody[]
  readonly allDimensions: Array<
    ConsolidatedDimension | CompanyDimensionAggregate
  >
  readonly setCurrentAssignments: Dispatch<
    SetStateAction<CreateAssignmentBody[]>
  >
  readonly companyUsers: CompanyResponses.GetCompanyUsers
}

export const AssignmentsTable = ({
  currentAssignments,
  allDimensions,
  setCurrentAssignments,
  companyUsers,
}: AssignmentsTableProps) => {
  const { t } = useTranslation("survey")
  const [selectedIndices, setSelectedIndices] = useState<number[]>([])

  const showDimensionsColumn = currentAssignments.some(
    ({ assignmentDimensions }) =>
      assignmentDimensions.some(({ optionId }) => optionId !== "N/A"),
  )

  const handleAssign = (index: number) => (assignee: Assignee | null) =>
    setCurrentAssignments((prev) =>
      prev.map((assignment, i) =>
        i === index
          ? { ...assignment, assigneeId: assignee?.id ?? null }
          : assignment,
      ),
    )

  const handleBulkAssign = (user: Assignee | null) => {
    selectedIndices.forEach((index) => handleAssign(index)(user))
    setSelectedIndices([])
  }

  const companyContributors = useMemo(
    () =>
      _(companyUsers.data)
        .map(({ id, email, firstName, lastName, locale }) => ({
          id,
          email,
          fullName: `${firstName} ${lastName}`,
          locale,
        }))
        .uniqBy((u) => u.email)
        .sortBy((u) => u.fullName)
        .value(),
    [companyUsers],
  )

  return (
    <Table withColumnBorders>
      <TableThead>
        <TableTr>
          {showDimensionsColumn && (
            <TableTh>
              {selectedIndices.length > 0 ? (
                <BulkAction nbSelected={selectedIndices.length}>
                  <AssigneeSelect
                    assignee={null}
                    companyContributors={companyContributors}
                    label={t("assign")}
                    onSelect={handleBulkAssign}
                  />
                </BulkAction>
              ) : (
                t("breakdownsHeader")
              )}
            </TableTh>
          )}
          <TableTh w={259}>{t("assigneeHeader")}</TableTh>
        </TableTr>
      </TableThead>
      <TableTbody>
        <AssignmentsRow
          allDimensions={allDimensions}
          companyContributors={companyContributors}
          currentAssignments={currentAssignments}
          handleAssign={handleAssign}
          selectedIndices={selectedIndices}
          setSelectedIndices={setSelectedIndices}
          showDimensionsColumn={showDimensionsColumn}
        />
      </TableTbody>
    </Table>
  )
}

type AssignmentsRowProps = {
  currentAssignments: CreateAssignmentBody[]
  allDimensions: Array<ConsolidatedDimension | CompanyDimensionAggregate>
  selectedIndices: number[]
  setSelectedIndices: Dispatch<SetStateAction<number[]>>
  companyContributors: Assignee[]
  handleAssign: (index: number) => (assignee: Assignee | null) => void
  showDimensionsColumn: boolean
}

const AssignmentsRow = ({
  currentAssignments,
  allDimensions,
  selectedIndices,
  showDimensionsColumn,
  setSelectedIndices,
  handleAssign,
  companyContributors,
}: AssignmentsRowProps) => {
  const { t } = useTranslation("survey")

  return currentAssignments.map((assignment, index) => (
    <TableTr
      key={
        assignment.assignmentDimensions
          .map(({ optionId }) => optionId)
          .join("-") + index
      }
    >
      {showDimensionsColumn && (
        <TableTd>
          {assignment.assignmentDimensions.length > 0 ? (
            <Group wrap="nowrap">
              <Checkbox
                checked={selectedIndices.includes(index)}
                onChange={() =>
                  selectedIndices.includes(index)
                    ? setSelectedIndices((l) => l.filter((i) => i !== index))
                    : setSelectedIndices((l) => [
                        ...l.filter((i) => i !== index),
                        index,
                      ])
                }
              />
              <AssignmentDimensionsCell
                allDimensions={allDimensions}
                assignmentDimensions={assignment.assignmentDimensions}
              />
            </Group>
          ) : (
            t("noBreakdownPlaceholder")
          )}
        </TableTd>
      )}
      <TableTd>
        <AssigneeSelect
          assignee={
            companyContributors.find(
              ({ id }) => id === assignment.assigneeId,
            ) ?? null
          }
          companyContributors={companyContributors}
          label={t("select")}
          onSelect={handleAssign(index)}
        />
      </TableTd>
    </TableTr>
  ))
}
