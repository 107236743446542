import { Button, Flex } from "@mantine/core"
import { useTranslation } from "react-i18next"

type Props = {
  readonly onClose: () => void
  readonly onSave: () => void
}

export const QuestionTableFooter = ({ onSave, onClose }: Props) => {
  const { t } = useTranslation("survey")

  return (
    <Flex
      gap={12}
      justify="end"
      p={16}
      style={{ borderTop: "1px solid var(--mantine-color-gray-3)" }}
    >
      <Button color="gray" onClick={onClose} variant="light">
        {t("cancel")}
      </Button>

      <Button
        onClick={() => {
          onSave()
          onClose()
        }}
      >
        {t("questions.selectQuestions")}
      </Button>
    </Flex>
  )
}
