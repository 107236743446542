import { LoaderFunctionArgs } from "@remix-run/node"

import { getCompanyData } from "@kiosk/reporting/lib/infra/company-infra.server"
import { CompanySettings } from "@kiosk/reporting/routes/settings.company/components/CompanySettings"
import { requireUser } from "@kiosk/reporting/utils/auth.server"

export async function loader(args: LoaderFunctionArgs) {
  const { companyId, company } = await requireUser(args)

  const onboarding = await getCompanyData({ companyId })

  return { company, onboarding }
}

export const handle = {
  breadcrumb: () => "company",
  title: () => "company",
}

export default function CompanySettingsPage() {
  return <CompanySettings />
}
