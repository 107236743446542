import {
  Button,
  Drawer,
  Group,
  Select,
  Stack,
  Text,
  TextInput,
  Textarea,
} from "@mantine/core"
import { DatePickerInput } from "@mantine/dates"
import { useForm, zodResolver } from "@mantine/form"
import { IconChevronDown } from "@tabler/icons-react"
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import { useTranslation } from "react-i18next"

import { useCompanyUsersQuery } from "@kiosk/reporting/api/companies/queryCompanyUsers"
import { useCreateTaskMutation } from "@kiosk/reporting/api/tasks/createTask"
import { useTasksQuery } from "@kiosk/reporting/api/tasks/tasksQuery"
import { useUser } from "@kiosk/reporting/components/auth/UserContext"
import {
  CreateTaskBody,
  TASK_STATUS,
  createTaskSchema,
} from "@kiosk/reporting/legacy/shared/schemas/tasks"

dayjs.extend(relativeTime)

interface Props {
  readonly ownerId: string | undefined
  readonly categoryId: string
  readonly opened: boolean
  readonly close: () => void
  readonly open: () => void
}

const secondColumnWidth = 250

export const CreateTaskDrawer = ({
  close,
  opened,
  ownerId,
  categoryId,
}: Props) => {
  const { t } = useTranslation(["task"])
  const { companyId } = useUser()
  const { onSubmit, reset, getInputProps, isValid } = useForm<CreateTaskBody>({
    initialValues: {
      name: "",
      categoryId,
    },
    validateInputOnChange: true,
    validate: zodResolver(createTaskSchema),
  })

  const tasksQuery = useTasksQuery({ ownerId })
  const companyUsersQuery = useCompanyUsersQuery(companyId)
  const { mutate: createTask, isPending: isPending } = useCreateTaskMutation()

  const handleCancel = () => {
    reset()
    close()
  }

  const handleOnSave = onSubmit((data, event) => {
    event?.preventDefault()
    createTask(data)
    handleCancel()
  })

  const statusLabel: Record<keyof typeof TASK_STATUS, string> = {
    TODO: t("status.todo"),
    IN_PROGRESS: t("status.inProgress"),
    DONE: t("status.done"),
  }

  return (
    <Drawer.Root
      onClose={handleCancel}
      opened={opened}
      position="right"
      size="lg"
    >
      <Drawer.Overlay />

      <Drawer.Content>
        <Stack gap={32} h="100%">
          <Drawer.Header
            p={24}
            style={{
              borderBottom: "1px solid var(--mantine-color-gray-3)",
            }}
          >
            <Drawer.Title c="black" fw={600} fz="lg">
              {t("taskOverview")}
            </Drawer.Title>

            <Group align="center" gap={18}>
              <Drawer.CloseButton c="gray.6" size="lg" />
            </Group>
          </Drawer.Header>

          <Stack h="100%" justify="space-between">
            <Drawer.Body px={24}>
              <form id="create-task" onSubmit={handleOnSave}>
                <Stack>
                  <TextInput
                    label={t("fields.name")}
                    placeholder={t("fields.name")}
                    {...getInputProps("name")}
                  />

                  <Textarea
                    label={t("fields.description")}
                    placeholder={t("fields.description")}
                    {...getInputProps("description")}
                  />

                  <TextInput
                    label={t("fields.stakeholders")}
                    placeholder={t("fields.stakeholders")}
                    {...getInputProps("stakeholders")}
                  />

                  <TextInput
                    label={t("fields.deliverables")}
                    placeholder={t("fields.deliverables")}
                    {...getInputProps("deliverables")}
                  />

                  <Group
                    justify="space-between"
                    p={4}
                    pt={16}
                    style={{
                      borderBottom: "1px solid var(--mantine-color-gray-3)",
                    }}
                  >
                    <Text>{t("fields.category")}</Text>

                    <Select
                      allowDeselect={false}
                      data={tasksQuery.data?.data.map((option) => ({
                        value: option.id,
                        label: option.name,
                      }))}
                      miw={secondColumnWidth}
                      placeholder={t("placeholders.category")}
                      rightSection={<IconChevronDown size={20} />}
                      searchable
                      variant="unstyled"
                      w="30%"
                      {...getInputProps("categoryId")}
                    />
                  </Group>

                  <Group
                    justify="space-between"
                    p={4}
                    style={{
                      borderBottom: "1px solid var(--mantine-color-gray-3)",
                    }}
                  >
                    <Text>{t("fields.owner")}</Text>

                    <Select
                      data={companyUsersQuery.data?.data.map((option) => ({
                        value: option.id,
                        label: option.firstName + " " + option.lastName,
                      }))}
                      miw={secondColumnWidth}
                      placeholder={t("placeholders.owner")}
                      rightSection={<IconChevronDown size={20} />}
                      searchable
                      variant="unstyled"
                      w="30%"
                      {...getInputProps("ownerId")}
                    />
                  </Group>

                  <Group
                    justify="space-between"
                    p={4}
                    style={{
                      borderBottom: "1px solid var(--mantine-color-gray-3)",
                    }}
                  >
                    <Text>{t("fields.dueDate")}</Text>

                    <DatePickerInput
                      miw={secondColumnWidth}
                      placeholder={t("placeholders.dueDate")}
                      rightSection={<IconChevronDown size={20} />}
                      variant="unstyled"
                      w="30%"
                      {...getInputProps("dueDate")}
                    />
                  </Group>

                  <Group
                    justify="space-between"
                    p={4}
                    style={{
                      borderBottom: "1px solid var(--mantine-color-gray-3)",
                    }}
                  >
                    <Text>{t("fields.status")}</Text>

                    <Select
                      data={Object.values(TASK_STATUS).map((s) => ({
                        value: s,
                        label: statusLabel[s],
                      }))}
                      miw={secondColumnWidth}
                      placeholder={statusLabel.TODO}
                      rightSection={<IconChevronDown size={20} />}
                      searchable
                      variant="unstyled"
                      w="30%"
                      {...getInputProps("status")}
                    />
                  </Group>
                </Stack>
              </form>
            </Drawer.Body>

            <Group
              gap={10}
              justify="flex-end"
              p={24}
              style={{ borderTop: "1px solid var(--mantine-color-gray-3)" }}
            >
              <Button color="gray" onClick={handleCancel} variant="outline">
                {t("buttons.cancel")}
              </Button>

              <Button
                disabled={!isValid() || isPending}
                form="create-task"
                loading={isPending}
                type="submit"
              >
                {t("buttons.create")}
              </Button>
            </Group>
          </Stack>
        </Stack>
      </Drawer.Content>
    </Drawer.Root>
  )
}
