import { Button, Group, Stack, Text } from "@mantine/core"
import { useForm } from "@mantine/form"
import { IconFlag } from "@tabler/icons-react"
import dayjs from "dayjs"
import _ from "lodash"
import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import { useAnswerAssignmentsMutation } from "@kiosk/reporting/api/surveys/assignments/answerAssignments"
import { CsrdCategory } from "@kiosk/reporting/legacy/types/data/dato"
import { AssignmentResponses } from "@kiosk/reporting/legacy/types/endpoints/assignments"
import { CompanyResponses } from "@kiosk/reporting/legacy/types/endpoints/companies"
import { ConsolidatedDimension } from "@kiosk/reporting/legacy/types/utils/dimensions"
import { CompanyDimensionAggregate } from "@kiosk/reporting/lib/aggregates/companyDimensionAggregate"
import { LocalizedCompanySurveyQuestion } from "@kiosk/reporting/lib/services/company-survey-question.service.server"
import { AnswerSurveyTable } from "@kiosk/reporting/routes/answer_survey.$id/AnswerSurveyTable/AnswerSurveyTable"
import { SurveyAnswered } from "@kiosk/reporting/routes/answer_survey.$id/SurveyAnswered"

const answerSurvey = "answer-survey"

export type Assignee = {
  id: string
  email: string
  fullName: string
  locale: string
}

type AnswerSurveyContentProps = {
  readonly survey: AssignmentResponses.ContributorAssignments
  readonly companySurveyQuestions?: LocalizedCompanySurveyQuestion[]
  readonly rawDataPointsQuestions: CsrdCategory[]
  readonly companyUsers: CompanyResponses.GetCompanyUsers
  readonly allDimensions: Array<
    ConsolidatedDimension | CompanyDimensionAggregate
  >
}

export const AnswerSurveyContent = ({
  survey,
  allDimensions,
  companySurveyQuestions,
  rawDataPointsQuestions,
  companyUsers,
}: AnswerSurveyContentProps) => {
  const { t } = useTranslation(["survey", "common"])

  const { mutateAsync: persistAnswers, isPending: isSavingAnswers } =
    useAnswerAssignmentsMutation(survey.id)

  const dataSource = useMemo(
    () =>
      survey.surveyQuestions.flatMap((question) =>
        question.assignments.flatMap((assignment) => ({
          ...assignment,
          label: question.label,
          instructions: question.instructions,
          unit: question.unit,
          datoId: question.datoId,
          companySurveyQuestionId: question.companySurveyQuestionId,
          numberType: question.numberType,
          questionId: question.id,
        })),
      ),
    [survey],
  )

  const initialValues = useMemo(
    () =>
      _.mapValues(_.keyBy(dataSource, "id"), (assignment) => assignment.value),
    [dataSource],
  )

  const form = useForm({
    mode: "uncontrolled",
    initialValues,
  })

  const [comments, setComments] = useState<Record<string, string | null>>(
    _.mapValues(_.keyBy(dataSource, "id"), (assignment) => assignment.comment),
  )

  const [files, setFiles] = useState<
    Record<string, { name: string; path: string; signedUrl: string } | null>
  >(
    _.mapValues(_.keyBy(dataSource, "id"), (assignment) =>
      assignment.fileName && assignment.filePath && assignment.fileSignedUrl
        ? {
            name: assignment.fileName,
            path: assignment.filePath,
            signedUrl: assignment.fileSignedUrl,
          }
        : null,
    ),
  )

  const [assignees, setAssignees] = useState<
    Record<string, Assignee | undefined>
  >({})

  const [isSubmitted, setIsSubmitted] = useState(false)

  const formatAnswers = (values: Record<string, number | "" | null>) => {
    return Object.entries(values).map(([id, value]) => {
      const file = files[id]

      return {
        id,
        value: value === "" ? null : value,
        comment: comments[id] ?? null,
        fileName: file?.name ?? null,
        filePath: file?.path ?? null,
        assigneeId: assignees[id]?.id,
      }
    })
  }

  const handleSubmit = async (values: Record<string, number | "" | null>) => {
    persistAnswers(formatAnswers(values))
    setIsSubmitted(true)
    setAssignees({})
  }

  const today = new Date().setUTCHours(0, 0, 0, 0)
  const dueDate = new Date(survey.dueDate).setUTCHours(0, 0, 0, 0)

  if (dueDate < today) {
    return <SurveyAnswered />
  }

  return (
    <form id={answerSurvey} onSubmit={form.onSubmit(handleSubmit)}>
      <Stack gap={16}>
        <Group justify="space-between">
          <Text fw={500} size="xl">
            {survey.title}
          </Text>
          <Group>
            <IconFlag color="#FE8F28" />
            <Text c="gray">{t("dueDate")}</Text>
            <Text>{dayjs(survey.dueDate).format("ll")}</Text>
          </Group>
        </Group>

        <AnswerSurveyTable
          allDimensions={allDimensions}
          assignees={assignees}
          comments={comments}
          companySurveyQuestions={companySurveyQuestions}
          companyUsers={companyUsers}
          dataSource={dataSource}
          files={files}
          form={form}
          isSubmitted={isSubmitted}
          rawDataPointsQuestions={rawDataPointsQuestions}
          setAssignees={setAssignees}
          setComments={setComments}
          setFiles={setFiles}
        />

        <Group justify="end" p={16}>
          <Button form={answerSurvey} loading={isSavingAnswers} type="submit">
            {t("common:buttons.save")}
          </Button>
        </Group>
      </Stack>
    </form>
  )
}
