import { Button, MultiSelect, Stack, Text } from "@mantine/core"
import { IconChevronRight } from "@tabler/icons-react"
import { useState } from "react"
import { useTranslation } from "react-i18next"

import { TypedDimension } from "@kiosk/reporting/api/dimensions/types"
import { EditDimensionDrawer } from "@kiosk/reporting/components/dimensions/EditDimensionDrawer"
import classes from "@kiosk/reporting/components/dimensions/OptionsPicker.module.css"
import { isTypedDimension } from "@kiosk/reporting/components/dimensions/TypedDimensionFillerWrapper"
import { cleanUpDimensionLabel } from "@kiosk/reporting/legacy/shared/utils/dimensions/consolidateDimensions"
import {
  ConsolidatedDimension,
  ConsolidatedDimensionOption,
} from "@kiosk/reporting/legacy/types/utils/dimensions"
import { CompanyDimensionAggregate } from "@kiosk/reporting/lib/aggregates/companyDimensionAggregate"
import { CompanyDimensionOption } from "@kiosk/reporting/lib/entities/companyDimensionOption"

type Props = {
  readonly optionIds: string[]
  readonly dimension:
    | ConsolidatedDimension
    | CompanyDimensionAggregate
    | TypedDimension
  readonly onChange: (value: string[]) => void
  readonly isCommitted: boolean
  readonly isGeneratedClicked: boolean
  readonly hasSelectedOptions: boolean
}

/**
 * OptionPicker shows the label of a dimension and users can select
 * the values they want from a dropdown.
 */
export function OptionsPicker({
  optionIds,
  dimension,
  onChange,
  isCommitted,
}: Props) {
  const { t } = useTranslation(["survey", "dimensions"])

  const [editingDimension, setEditingDimension] = useState<TypedDimension>()

  const dimensionOptions: Array<
    ConsolidatedDimensionOption | CompanyDimensionOption
  > =
    "options" in dimension
      ? dimension.options
      : dimension.companyDimensionOptions

  const onlyHasOptionalDimensions = dimensionOptions.length === 1

  const handleValueSelect = (IDs: string[]) => {
    onChange(IDs)
  }

  if (onlyHasOptionalDimensions) return

  if (isTypedDimension(dimension) && dimensionOptions.length === 0) {
    return (
      <Stack className={classes["typed-dimensions"]}>
        <Text className={classes["typed-dimensions__label"]}>
          {cleanUpDimensionLabel(dimension.label)}
        </Text>
        <Button
          classNames={{
            root: classes["typed-dimensions__button"],
            inner: classes["typed-dimensions__button--inner"],
          }}
          onClick={() => setEditingDimension(dimension)}
          rightSection={<IconChevronRight />}
          size="sm"
          variant="outline"
        >
          {t("dimensions:addDimensionsOptions")}
        </Button>
        <EditDimensionDrawer
          dimension={editingDimension}
          onClose={() => setEditingDimension(undefined)}
        />
      </Stack>
    )
  }

  const multipleSelectOptions = dimensionOptions
    .map((option) => ({
      label: option.label,
      value: option.id,
    }))
    .sort((a, b) => a.label.localeCompare(b.label))

  return (
    <MultiSelect
      data={multipleSelectOptions}
      disabled={isCommitted}
      nothingFoundMessage={t("nothingFound")}
      onChange={handleValueSelect}
      placeholder={t("pickOptionsPlaceholder")}
      value={optionIds}
    />
  )
}
