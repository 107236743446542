import { Group, Text, ThemeIcon } from "@mantine/core"

import { TablerIconComponent } from "@kiosk/reporting/utils/icon"

type Props = {
  readonly backgroundColor: string
  readonly icon: TablerIconComponent
  readonly iconBackgroundColor: string
  readonly name: string
}

export function CategoryTitleDemo({
  backgroundColor,
  iconBackgroundColor,
  name,
  icon: Icon,
}: Props) {
  return (
    <Group bg={backgroundColor} gap={16} px={24} py={16}>
      <ThemeIcon
        bg={iconBackgroundColor}
        color="red"
        style={{ width: "40px", height: "40px" }}
        variant="light"
      >
        <Icon color="white" style={{ width: "24px", height: "24px" }} />
      </ThemeIcon>

      <Text fw={600} fz="xxl">
        {name}
      </Text>
    </Group>
  )
}
