import axios, { AxiosError } from "axios"

import { clientConfig } from "@kiosk/reporting/config"

declare global {
  interface Window {
    Clerk: {
      session: { getToken: () => Promise<string> }
    }
  }
}

const createHTTPClient = (baseURL: string) => {
  const client = axios.create({ baseURL })

  client.interceptors.request.use(
    async (config) => {
      // Running on the client, it has the token.
      if (typeof window === "undefined") {
        return config
      }

      const token = await window.Clerk.session?.getToken()

      if (!token) {
        return config
      }

      config.headers.Authorization = `Bearer ${token}`

      return config
    },
    (error) => Promise.reject(error),
  )

  client.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error instanceof AxiosError) {
        return Promise.reject(error.response?.data)
      }

      return Promise.reject(error)
    },
  )

  return client
}

export const apiClient = createHTTPClient(clientConfig.apiUrl)
