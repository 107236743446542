import { Group, Stack, Text, ThemeIcon } from "@mantine/core"
import { NavLink } from "@remix-run/react"
import { IconChevronRight } from "@tabler/icons-react"
import { useEffect, useState } from "react"

import { useMaterialityMutation } from "@kiosk/reporting/api/reports"
import { Completion } from "@kiosk/reporting/components/Completion"
import { EsrsBadge } from "@kiosk/reporting/components/csrd/EsrsBadge"
import { MaterialitySwitch } from "@kiosk/reporting/components/csrd/MaterialSwitch"
import { topicSorter } from "@kiosk/reporting/legacy/shared/utils/topics/topicSorter"
import { ReportsResponses } from "@kiosk/reporting/legacy/types/endpoints/reports"
import classes from "@kiosk/reporting/routes/csrd_.$datoTopicId/components/CSRDTopicsSideBar.module.css"
import { routes } from "@kiosk/reporting/utils/constants/index"

type Topic = ReportsResponses.GetCategories[number]["topics"][number]

type CSRDSubTopicsListProps = {
  readonly topic: Topic
  readonly nonMaterialEsrs: string[]
}

export const CSRDSubTopicsList = ({
  topic,
  nonMaterialEsrs,
}: CSRDSubTopicsListProps) => {
  const disclosureRequirements = topic.disclosureRequirements.sort(topicSorter)

  const hasMaterialitySwitch = topic.code !== "ESRS 2"

  const [isMaterial, setIsMaterial] = useState(
    !nonMaterialEsrs.includes(topic.id),
  )

  useEffect(
    () => setIsMaterial(!nonMaterialEsrs.includes(topic.id)),
    [nonMaterialEsrs, topic.id],
  )

  const {
    mutateAsync: switchMateriality,
    isPending: switchMaterialityIsPending,
  } = useMaterialityMutation()

  const completeRequirements = topic.disclosureRequirements.filter(
    (dr) => dr.progress === 100,
  )
  const topicProgress =
    (100 * completeRequirements.length) / topic.disclosureRequirements.length

  return (
    <Stack gap={16} p={24}>
      <Group justify="space-between">
        <Group gap={16}>
          <Group gap={8}>
            <Text c="gray.9" fz="sm">
              {isMaterial
                ? `${completeRequirements.length} /
                  ${topic.disclosureRequirements.length}`
                : "0 / 0"}
            </Text>

            <Completion
              isNonMaterialEsrs={!isMaterial}
              progress={topicProgress}
            />
          </Group>

          <Group gap={8}>
            <EsrsBadge topic={topic.code} />
            <Text c="gray.9" fw={600} fz="md">
              {topic.name}
            </Text>
          </Group>
        </Group>

        {hasMaterialitySwitch && (
          <MaterialitySwitch
            isPending={switchMaterialityIsPending}
            onClick={() => {
              setIsMaterial(!isMaterial)
              switchMateriality(topic.id)
            }}
            value={isMaterial}
          />
        )}
      </Group>

      <Stack gap={0}>
        {disclosureRequirements
          .filter((dr) => !dr.isHidden)
          .map(({ id, name, esrsCode, progress }) => (
            <NavLink
              key={id}
              to={routes.CSRD_DISCLOSURE_REQUIREMENT.path
                .replace(":datoTopicId", topic.id)
                .replace(":datoSubTopicId", id)}
            >
              <Group className={classes.subtopic__item}>
                <Completion
                  isNonMaterialEsrs={!isMaterial}
                  progress={progress}
                />
                <EsrsBadge
                  isNonMaterialEsrs={!isMaterial}
                  topic={esrsCode}
                  variant="secondary"
                />
                <Text
                  c={isMaterial ? "gray.7" : "gray.5"}
                  flex={1}
                  fw={500}
                  fz="sm"
                >
                  {name}
                </Text>
                <ThemeIcon c="gray.7" h={22} w={22}>
                  <IconChevronRight />
                </ThemeIcon>
              </Group>
            </NavLink>
          ))}
      </Stack>
    </Stack>
  )
}
