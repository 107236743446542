import _ from "lodash"

export const round = (x: number, n: number): number => Number(x.toFixed(n))

export type Option<T> = T | undefined

export type Optional<T, K extends keyof T> = Partial<Pick<T, K>> & Omit<T, K>

export const isDefined = <T>(x: Option<T>): x is T => x !== undefined

export const isNotNull = <T>(x: T | null): x is T => x !== null

export const isNotNil = <T>(x: Option<T> | null): x is T =>
  isDefined(x) && isNotNull(x)

export const mapOption = <I, O>(o: Option<I>, fn: (x: I) => O) =>
  isDefined(o) ? fn(o) : undefined

export const increment = (n: number): number => n + 1

export const isEmpty = <T>(a: Array<T>): boolean => a.length === 0

type Nilable<T> = T extends object
  ? { [K in keyof T]: Nilable<T[K]> }
  : T | null | undefined

type Pruned<T> =
  T extends Array<infer U>
    ? Array<Exclude<Pruned<U>, undefined>>
    : T extends object
      ? { [K in keyof T]: Exclude<Pruned<T[K]>, undefined> }
      : T

export function pruneNilValues<T>(it: Nilable<T>): Pruned<T> | undefined {
  if (_.isArray(it)) {
    return it.map(pruneNilValues).filter((v) => v !== undefined) as Pruned<T>
  }

  if (_.isObject(it)) {
    const pruned = _.chain(it as Record<string, unknown>)
      .mapValues(pruneNilValues)
      .omitBy(_.isUndefined)
      .value()

    return _.isEmpty(pruned) ? undefined : (pruned as Pruned<T>)
  }

  if (_.isNull(it)) return undefined
  if (_.isUndefined(it)) return undefined

  return it as Pruned<T>
}
