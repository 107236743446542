import { notifications } from "@mantine/notifications"
import { useMutation } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"

import { accountKeys } from "@kiosk/reporting/api/account/accountKeys"
import { companyKeys } from "@kiosk/reporting/api/companies/companyKeys"
import { usersKeys } from "@kiosk/reporting/api/users/usersKeys"
import { useUser } from "@kiosk/reporting/components/auth/UserContext"
import { UserUpdateBody } from "@kiosk/reporting/legacy/shared/schemas/users"
import { apiClient } from "@kiosk/reporting/lib/apiClient"
import { queryClient } from "@kiosk/reporting/lib/queryClient"

export interface UpdateUserPayload {
  userId: string
  data: UserUpdateBody
}

export const updateUser = async (
  { userId, data }: UpdateUserPayload,
  token: string,
) => {
  const response = await apiClient.put(`/users/${userId}`, data, {
    headers: { Authorization: `Bearer ${token}` },
  })

  return response.data
}

export const useUpdateUserMutation = (userId?: string) => {
  const { t } = useTranslation()
  const { token } = useUser()

  return useMutation({
    mutationFn: (payload: UpdateUserPayload) => updateUser(payload, token),
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({
        queryKey: accountKeys.me(data.clerkId),
      })
      await queryClient.invalidateQueries({
        queryKey: companyKeys.companyUsers(data.companyId),
      })
      await queryClient.invalidateQueries({
        queryKey: usersKeys.get(userId),
      })
      notifications.show({
        title: t("messages.success"),
        message: t("messages.updateUserSuccess"),
        color: "green",
      })
    },
    onError: ({ message }) => {
      notifications.show({
        title: t("messages.error"),
        message,
        color: "red",
      })
    },
  })
}
