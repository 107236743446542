import { Flex, Stack, Title, Text } from "@mantine/core"
import { useTranslation } from "react-i18next"

import { Footer } from "@kiosk/reporting/routes/_onboarding.onboarding/components/Footer"

type UserCreationLayoutProps = {
  readonly children: JSX.Element
  readonly disableContinue: boolean
  readonly isPending: boolean
  readonly handleUserCreation?: () => void
}

export const UserCreationLayout = ({
  children,
  handleUserCreation,
  isPending,
  disableContinue,
}: UserCreationLayoutProps) => {
  const { t } = useTranslation(["common"])

  return (
    <Flex align="center" direction="column" flex={1} gap={40}>
      <Stack flex={1} gap={48} h="100%" maw={506} w="100%">
        <Stack gap={16} w="100%">
          <Title c="black" fw={600} fz="xxl">
            &#128075; &nbsp;
            {t("welcome")}
          </Title>

          <Text c="gray.5" fz="md">
            {t("welcomeDescription")}
          </Text>

          {children}
        </Stack>
      </Stack>
      <Footer
        disableContinue={disableContinue}
        isLoading={isPending}
        onContinue={handleUserCreation}
      />
    </Flex>
  )
}
