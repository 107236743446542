import { Box, Group, Text, ThemeIcon } from "@mantine/core"
import { DateInput as MantineDateInput, DateInputProps } from "@mantine/dates"

import { useUser } from "@kiosk/reporting/components/auth/UserContext"
import { TablerIconComponent } from "@kiosk/reporting/utils/icon"

type Props = DateInputProps & {
  readonly icon: TablerIconComponent
}

export const DateInput = ({ label, icon: Icon, ...dateInputProps }: Props) => {
  const user = useUser()

  return (
    <Box
      p={10}
      style={(theme) => ({
        border: `1px solid ${theme.colors.gray[2]}`,
        borderRadius: 6,
      })}
    >
      <Group gap={8}>
        <ThemeIcon
          bg="gray.0"
          color="gray.5"
          radius={6}
          size="lg"
          variant="light"
        >
          <Icon />
        </ThemeIcon>

        <Text c="gray.6">{label}:</Text>

        <MantineDateInput
          {...dateInputProps}
          valueFormat={user.locale === "fr" ? "DD MMMM YYYY" : "MMMM DD YYYY"}
          variant="unstyled"
        />
      </Group>
    </Box>
  )
}
