import { Flex, Group, Stack } from "@mantine/core"
import { useLoaderData, useNavigate } from "@remix-run/react"
import { useEffect, useState } from "react"

import Logo from "@kiosk/reporting/components/Logo"
import { useUser } from "@kiosk/reporting/components/auth/UserContext"
import "@kiosk/reporting/routes/_onboarding.onboarding/components/Onboarding.css"
import { OnboardingStepper } from "@kiosk/reporting/routes/_onboarding.onboarding/components/OnboardingStepper"
import { UserCreationStep } from "@kiosk/reporting/routes/_onboarding.onboarding/components/UserCreationStep"
import { loader } from "@kiosk/reporting/routes/_onboarding.onboarding/route"
import { routes } from "@kiosk/reporting/utils/constants"

import { useCompleteOnBoardingMutation } from "../utils/complete-onboarding"

export const companyStep = {
  ACCOUNT_SETUP: 0,
  COMPANY_SETUP: 1,
  SUBJECTS_SETUP: 2,
  GEO_STRUCTURE: 3,
  FIRST_UPLOAD: 4,
  ONBOARDED: 5,
}

export const Onboarding = () => {
  const { company } = useLoaderData<typeof loader>()
  const user = useUser()
  const [active, setActive] = useState(companyStep[user.company.status])
  const [companyOnboarding, setCompanyOnboarding] = useState(false)

  useEffect(() => {
    const isCompanyAdmin = user.roles.includes("KIOSK_ADMIN")
    const companyStatus = user.company.status

    if (companyStatus !== "ONBOARDED") {
      setCompanyOnboarding(isCompanyAdmin)
    }
  }, [user.roles, user.company.status])

  const navigate = useNavigate()

  const { mutate: completeOnBoarding } = useCompleteOnBoardingMutation(() => {
    navigate(routes.CONGRATULATIONS.path)
  })

  const handleOnboardingSkip = () => {
    completeOnBoarding(undefined)
  }

  return (
    <Stack align="center" gap={40} mih="100vh">
      <Group
        justify="center"
        mih={74}
        px={24}
        py={16}
        style={{
          borderBottom: "solid 1px var(--mantine-color-gray-3)",
          position: "sticky",
          top: 0,
          backgroundColor: "var(--mantine-color-white)",
          zIndex: 2,
        }}
        w="100%"
      >
        <Logo branding={company.branding} height={28} />
      </Group>

      {companyOnboarding ? (
        <OnboardingStepper
          active={active}
          handleOnboardingSkip={handleOnboardingSkip}
          setActive={setActive}
        />
      ) : (
        <Flex direction="column" flex={1} w="100%">
          <UserCreationStep setActive={setActive} />
        </Flex>
      )}
    </Stack>
  )
}
