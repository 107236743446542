import { useTranslation } from "react-i18next"

import { SubjectRow } from "@kiosk/reporting/components/onboarding/SubjectRow"
import { CURRENT_REPORT_YEAR } from "@kiosk/reporting/legacy/shared/constants/common/phaseIn"
import { UserPhaseInOption } from "@kiosk/reporting/lib/services/phase-in-service.server"

interface SubjectModuleProps {
  readonly subjects?: Array<{
    id: string
    label: string
    phaseInYears: number
  }>

  readonly scope: "esrs" | "dr" | "tqg" | "question"

  readonly phaseInOptions?: UserPhaseInOption[]
}

export const SubjectModule = ({
  subjects,
  phaseInOptions,
  scope,
}: SubjectModuleProps) => {
  const { t } = useTranslation("onboarding")

  const subjectsType = {
    esrs: t("subjects.esrs"),
    dr: t("subjects.dr"),
    question: t("subjects.question"),
    tqg: t("subjects.thematicQuestionsGroup"),
  }

  return (
    <>
      {subjects?.map((phaseInOption, index) => {
        return (
          <SubjectRow
            index={index}
            initialValue={
              (phaseInOptions?.find((pi) => pi.id === phaseInOption.id)
                ?.phaseInYear ?? CURRENT_REPORT_YEAR) - CURRENT_REPORT_YEAR
            }
            key={phaseInOption.id}
            max={phaseInOption.phaseInYears}
            phaseInId={phaseInOption.id}
            scope={scope}
            subject={{
              name: phaseInOption.label,
              example: subjectsType[scope],
            }}
          />
        )
      })}
    </>
  )
}
