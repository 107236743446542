import { Button, Group, Loader, Stack } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"

type SparkleProps = {
  readonly children: React.ReactNode
  readonly loading?: boolean
  readonly loadingLabel?: string
  readonly disabled?: boolean
  readonly icon?: React.ReactNode
  readonly onPress?: () => void
}

export const SparklingButton = ({
  children,
  loading,
  loadingLabel,
  disabled,
  icon,
  onPress,
}: SparkleProps) => {
  const isDisabled = disabled || loading
  const matches = useMediaQuery("(min-width: 100em)")

  return (
    <Stack pos="relative">
      <Button
        color="violet"
        onClick={() => !isDisabled && onPress?.()}
        style={{
          cursor: isDisabled ? "not-allowed" : "pointer",
        }}
        variant="outline"
      >
        <Group gap={8} justify="space-between">
          {loading ? <Loader color="violet" size="xs" /> : icon}
          {matches && ((loading && loadingLabel) || children)}
        </Group>
      </Button>
    </Stack>
  )
}
