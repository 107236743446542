import { Button, Modal, ModalBaseProps, Stack, Text } from "@mantine/core"
import { useTranslation } from "react-i18next"

import { colors } from "@kiosk/reporting/assets/theme/colors"
import { IntegrationRequest } from "@kiosk/reporting/routes/sources/components/Integrations/IntegrationRequest"
import { NetSuiteLogo } from "@kiosk/reporting/routes/sources/components/Integrations/logos/NetSuiteLogo"
import { PipedriveLogo } from "@kiosk/reporting/routes/sources/components/Integrations/logos/PipedriveLogo"
import { SalesforceLogo } from "@kiosk/reporting/routes/sources/components/Integrations/logos/SalesforceLogo"
import { XeroLogo } from "@kiosk/reporting/routes/sources/components/Integrations/logos/XeroLogo"

interface Integration {
  name: string
  logo: React.FC
}

interface IntegrationCategory {
  category: string
  integrations: Integration[]
}

const INTEGRATIONS: IntegrationCategory[] = [
  {
    category: "ERP",
    integrations: [
      { logo: NetSuiteLogo, name: "Netsuite" },
      { logo: XeroLogo, name: "Xero" },
    ],
  },
  {
    category: "CRM",
    integrations: [
      { logo: SalesforceLogo, name: "Salesforce" },
      { logo: PipedriveLogo, name: "Pipedrive" },
    ],
  },
]

interface NewIntegrationModalProps extends ModalBaseProps {
  readonly onRequest: () => void
}

export const NewIntegrationModal = (props: NewIntegrationModalProps) => {
  const { t } = useTranslation("sources")

  const categoryTranslation: Record<string, string> = {
    CRM: t("integrationCategories.CRM"),
    ERP: t("integrationCategories.ERP"),
  }

  return (
    <Modal
      centered={true}
      onClose={props.onClose}
      opened={props.opened}
      padding={0}
      size="lg"
      title={t("newIntegration")}
    >
      <Stack px={40}>
        {INTEGRATIONS.map(({ category, integrations }) => (
          <Stack gap={12} key="category">
            <Text c={colors.gray[5]} fw="bold">
              {categoryTranslation[category] ?? category}
            </Text>

            {integrations.map((i) => (
              <IntegrationRequest
                key={i.name}
                Logo={i.logo}
                name={i.name}
                onRequest={props.onRequest}
              />
            ))}
          </Stack>
        ))}
      </Stack>

      <Button
        c="black"
        color="gray.1"
        fullWidth={true}
        h={60}
        mb={1}
        mt={20}
        radius={0}
      >
        <Text c="gray.6" py={16} ta="center" td="underline">
          {t("seeAllIntegrations")}
        </Text>
      </Button>
    </Modal>
  )
}
