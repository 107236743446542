import _ from "lodash"

import { CsrdCategory } from "@kiosk/reporting/legacy/types/data/dato"

type Predicate<T> = _.ListIterateeCustom<T, boolean>

export const findOrCreate = <T>(
  collection: T[],
  predicate: Predicate<T>,
  newItem: T,
): T => {
  let item = _.find(collection, predicate)

  if (!item) {
    item = newItem
    collection.push(item)
  }

  return item
}

export const findQuestionById = (
  csrdCategories: CsrdCategory[],
  questionId: string | null,
) => {
  for (const csrdCategory of csrdCategories) {
    for (const topic of csrdCategory.topics) {
      for (const disclosureRequirement of topic.disclosureRequirements) {
        for (const question of disclosureRequirement.questions)
          if (question.id === questionId) return question
      }
    }
  }

  throw new Error(`Could not find Dato question with ID: ${questionId}`)
}
