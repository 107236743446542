import {
  ActionIcon,
  Button,
  Table,
  TableTbody,
  TableTd,
  TableTfoot,
  TableTh,
  TableThead,
  TableTr,
  ThemeIcon,
} from "@mantine/core"
import { IconEye, IconPencil, IconPlus } from "@tabler/icons-react"
import { Dispatch, SetStateAction } from "react"
import { useTranslation } from "react-i18next"

import { CsrdCategory } from "@kiosk/reporting/legacy/types/data/dato"
import { SurveyQuestionResponses } from "@kiosk/reporting/legacy/types/endpoints/surveyQuestions"
import { SurveyResponses } from "@kiosk/reporting/legacy/types/endpoints/surveys"
import { LocalizedCompanySurveyQuestion } from "@kiosk/reporting/lib/services/company-survey-question.service.server"
import { AssigneesCountCell } from "@kiosk/reporting/routes/sources_.edit_survey.$id/components/QuestionsListTable/AssignneesCountCell"
import { DeleteQuestions } from "@kiosk/reporting/routes/sources_.edit_survey.$id/components/QuestionsListTable/DeleteQuestions"
import { DimensionsCell } from "@kiosk/reporting/routes/sources_.edit_survey.$id/components/QuestionsListTable/DimensionsCell"
import { QuestionsCell } from "@kiosk/reporting/routes/sources_.edit_survey.$id/components/QuestionsListTable/QuestionsCell"
import { isSurveyStarted } from "@kiosk/reporting/routes/sources_.edit_survey.$id/utils"

type QuestionsListTableProps = {
  readonly surveyQuestions: SurveyQuestionResponses.SurveyQuestion[]
  readonly companySurveyQuestions?: LocalizedCompanySurveyQuestion[]
  readonly rawDataPointsQuestions: CsrdCategory[]
  readonly selectedIds: string[]
  readonly setSelectedIds: Dispatch<SetStateAction<string[]>>
  readonly survey: SurveyResponses.Survey
  readonly getOnNavigate: (url: string) => () => void
  readonly open: () => void
  readonly deleteSurveyQuestion: (id: string) => Promise<void>
  readonly isPending: boolean
}

export const QuestionsListTable = ({
  surveyQuestions,
  companySurveyQuestions,
  rawDataPointsQuestions,
  selectedIds,
  setSelectedIds,
  survey,
  getOnNavigate,
  open,
  deleteSurveyQuestion,
  isPending,
}: QuestionsListTableProps) => {
  const { t } = useTranslation("survey")

  return (
    <Table withColumnBorders>
      <TableThead>
        <TableTr>
          <TableTh>
            {selectedIds.length > 0 ? (
              <DeleteQuestions
                deleteSurveyQuestion={deleteSurveyQuestion}
                isPending={isPending}
                selectedIds={selectedIds}
                setSelectedIds={setSelectedIds}
              />
            ) : (
              t("questions.label")
            )}
          </TableTh>
          <TableTh>{t("questions.dimensions")}</TableTh>
          <TableTh>{t("questions.assignmentsCount")}</TableTh>
          <TableTh />
        </TableTr>
      </TableThead>
      <TableTbody>
        <QuestionsListRow
          companySurveyQuestions={companySurveyQuestions}
          getOnNavigate={getOnNavigate}
          rawDataPointsQuestions={rawDataPointsQuestions}
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
          survey={survey}
          surveyQuestions={surveyQuestions}
        />
      </TableTbody>
      <TableTfoot>
        <TableTr>
          <TableTd>
            <Button
              color="gray.6"
              leftSection={<IconPlus color="green" />}
              onClick={() => {
                setSelectedIds([])
                open()
              }}
              pl={4}
              size="sm"
              variant="transparent"
            >
              {t("questions.new_question")}
            </Button>
          </TableTd>
        </TableTr>
      </TableTfoot>
    </Table>
  )
}

type QuestionsListRowProps = {
  surveyQuestions: SurveyQuestionResponses.SurveyQuestion[]
  companySurveyQuestions?: LocalizedCompanySurveyQuestion[]
  selectedIds: string[]
  setSelectedIds: Dispatch<SetStateAction<string[]>>
  survey: SurveyResponses.Survey
  getOnNavigate: (url: string) => () => void
  rawDataPointsQuestions: CsrdCategory[]
}

const QuestionsListRow = ({
  surveyQuestions,
  companySurveyQuestions,
  selectedIds,
  setSelectedIds,
  survey,
  getOnNavigate,
  rawDataPointsQuestions,
}: QuestionsListRowProps) => {
  return surveyQuestions.map((question, index) => (
    <TableTr key={question.datoId ?? question.companySurveyQuestionId! + index}>
      <TableTd>
        <QuestionsCell
          companySurveyQuestions={companySurveyQuestions}
          questionDatoId={question.datoId ?? question.companySurveyQuestionId!}
          rawDataPointsQuestions={rawDataPointsQuestions}
          record={question}
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
          survey={survey}
        />
      </TableTd>
      <TableTd>
        <DimensionsCell
          questionDatoId={question.datoId ?? question.companySurveyQuestionId!}
        />
      </TableTd>
      <TableTd>
        <AssigneesCountCell
          assignees={question._count.assignees}
          assignments={question._count.assignments}
        />
      </TableTd>
      <TableTd>
        <ActionIcon
          h={26}
          onClick={getOnNavigate(`/sources/edit_question/${question.id}`)}
          variant="transparent"
          w={46}
        >
          <ThemeIcon c="gray.5" h={22} w={22}>
            <IconPencil />
          </ThemeIcon>
        </ActionIcon>
      </TableTd>
      {isSurveyStarted(survey) && (
        <TableTd>
          <ActionIcon
            h={26}
            onClick={getOnNavigate(`/sources/survey_answers/${question.id}`)}
            variant="transparent"
            w={46}
          >
            <ThemeIcon c="gray.5" h={22} w={22}>
              <IconEye />
            </ThemeIcon>
          </ActionIcon>
        </TableTd>
      )}
    </TableTr>
  ))
}
