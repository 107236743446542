import { Button, Group, Stack, Text, VisuallyHidden } from "@mantine/core"
import { useLoaderData } from "@remix-run/react"
import { IconCheck, IconEdit, IconTag } from "@tabler/icons-react"
import _ from "lodash"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import { TypedDimension } from "@kiosk/reporting/api/dimensions/types"
import { OptionsPicker } from "@kiosk/reporting/components/dimensions/OptionsPicker"
import { TypedDimensionFillerWrapper } from "@kiosk/reporting/components/dimensions/TypedDimensionFillerWrapper"
import { DimensionBreakdownValue } from "@kiosk/reporting/legacy/types/dimensions"
import { ConsolidatedDimension } from "@kiosk/reporting/legacy/types/utils/dimensions"
import { CompanyDimensionAggregate } from "@kiosk/reporting/lib/aggregates/companyDimensionAggregate"
import { loader } from "@kiosk/reporting/routes/sources_.edit_question.$id/route"
import { HIDDEN_DIMENSION } from "@kiosk/reporting/utils/constants"
import {
  enrichDimensionBreakdowns,
  EnrichedDimensionBreakdown,
  generateCartesianProduct,
} from "@kiosk/reporting/utils/dimensions"

type SharedProps = {
  readonly initialBreakdowns: DimensionBreakdownValue[]
  readonly onGenerateBreakdowns: (
    breakdowns: EnrichedDimensionBreakdown[] | undefined,
  ) => void
  readonly onResetBreakdown?: () => void
}

type RootProps = SharedProps & {
  readonly dimensionIds: string[]
  readonly showDimensionsBreakdown: boolean
  readonly isCSRD?: boolean
}

type FormProps = SharedProps & {
  readonly dimensions: Array<
    ConsolidatedDimension | CompanyDimensionAggregate | TypedDimension
  >
  readonly isCSRD?: boolean
}

const BreakdownSelectForm = ({
  dimensions,
  initialBreakdowns,
  onGenerateBreakdowns,
  onResetBreakdown,
  isCSRD,
}: FormProps) => {
  const { t } = useTranslation("survey")

  // Some dimensions have only one option.
  // For example, `esrs_TopicalESRSAxis` will have only one topic in each disclosure requirement.
  // These breakdowns should be prefilled with their only value and hidden to the user.
  const singleOptionDimensions = _(dimensions)
    .filter((dimension) =>
      "options" in dimension
        ? dimension.options.length === 1
        : dimension.companyDimensionOptions.length === 1,
    )
    .groupBy((dimension) => dimension.id)
    .mapValues(([dimension]) =>
      "options" in dimension
        ? dimension.options!.map((option) => option.id)
        : dimension.companyDimensionOptions!.map((option) => option.id),
    )
    .value()

  const onlyHasOptionalDimensions =
    _.keys(singleOptionDimensions).length === dimensions.length

  const { emptyState, initialState } = useMemo(() => {
    const dimensionsById = _.keyBy(
      dimensions.filter((dimension) =>
        "options" in dimension
          ? dimension.options.length !== 1
          : dimension.companyDimensionOptions.length !== 1,
      ),
      (dimension) => dimension.id,
    )

    const emptyState = _.mapValues(dimensionsById, () => [])

    const initialValues = _.mapValues(
      _.groupBy(initialBreakdowns, (breakdown) => breakdown.dimensionId),
      (breakdowns) => breakdowns.map((breakdown) => breakdown.optionId),
    )

    const initialState = {
      ...emptyState,
      ...initialValues,
    }

    return { emptyState, initialState }
  }, [dimensions, initialBreakdowns])

  // Record<dimensionId, optionId[]>
  const [selectedOptionIds, setSelectedOptionIds] =
    useState<Record<string, string[]>>(initialState)

  const [isCommitted, setIsCommitted] = useState(initialBreakdowns.length > 0)
  const [isGeneratedClicked, setIsGeneratedClicked] = useState(false)
  const hasSelectedOptions = Object.values(selectedOptionIds).some(
    (options) => options.length > 0,
  )

  const onGenerate = useCallback(() => {
    const combinations = generateCartesianProduct(selectedOptionIds)

    const enrichedBreakdowns = combinations.map((combination) => {
      return enrichDimensionBreakdowns(dimensions, combination)
    })

    const filteredBreakdowns = enrichedBreakdowns.map((breakdown) =>
      breakdown.filter((optionLabel) => optionLabel.optionLabel !== "N/A"),
    )

    onGenerateBreakdowns(filteredBreakdowns)
    setIsCommitted(hasSelectedOptions)
    setIsGeneratedClicked(true)
  }, [onGenerateBreakdowns, selectedOptionIds, dimensions, hasSelectedOptions])

  useEffect(() => {
    // Launch generate if initial breakdowns exist
    // This is launch only in the CSRD because in the survey it reset the default value (which we do not want)
    // TODO: Investigate what is this useEffect initially used for
    if (isCommitted && initialBreakdowns.length && isCSRD) {
      onGenerate()
    }
    // TODO: investigate why onGenerate creates an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialBreakdowns.length, isCommitted, isCSRD])

  if (!onlyHasOptionalDimensions) {
    return (
      <Stack
        bg="gray.0"
        gap={20}
        mt="8px"
        p={16}
        style={{
          border: "1px solid var(--mantine-color-gray-2)",
          borderRadius: 6,
        }}
      >
        <Stack gap="xs">
          <Group h={36} justify="space-between">
            <Group align="center" gap="sm">
              <IconTag />
              <Text fw={600}>{t("breakdownSelect")}</Text>
            </Group>
          </Group>

          <Group align="center" gap="sm">
            <Text ml={36}>{t("selectBreakdown")}</Text>
          </Group>
        </Stack>

        {isGeneratedClicked && !hasSelectedOptions && (
          <Text c="var(--mantine-color-red-6)">{t("chooseDimension")}</Text>
        )}

        {dimensions.map((dimension) => {
          if (HIDDEN_DIMENSION.includes(dimension.id)) return

          return (
            <OptionsPicker
              dimension={dimension}
              hasSelectedOptions={hasSelectedOptions}
              isCommitted={isCommitted}
              isGeneratedClicked={isGeneratedClicked}
              key={dimension.id}
              onChange={(options) =>
                setSelectedOptionIds((selectedOptionIds) => ({
                  ...selectedOptionIds,
                  [dimension.id]: options,
                }))
              }
              optionIds={selectedOptionIds[dimension.id] ?? []}
            />
          )
        })}

        {!isCommitted && (
          <Button
            bg="white"
            color="black"
            leftSection={<IconCheck size={20} />}
            onClick={() => {
              onResetBreakdown && onResetBreakdown()
              onGenerate()
            }}
            style={(theme) => ({
              border: `1px solid ${theme.colors.gray[3]}`,
              borderRadius: 6,
            })}
            variant="outline"
            w="fit-content"
          >
            {t("generateQuestion")}
          </Button>
        )}

        {isCommitted && (
          <Button
            bg="white"
            color="black"
            leftSection={<IconEdit size={20} />}
            onClick={() => {
              onResetBreakdown && onResetBreakdown()
              setSelectedOptionIds({
                ...emptyState,
              })
              onGenerateBreakdowns(undefined)
              setIsGeneratedClicked(false)
              setIsCommitted(false)
            }}
            size="sm"
            style={(theme) => ({
              border: `1px solid ${theme.colors.gray[3]}`,
              borderRadius: 6,
            })}
            variant="outline"
            w="fit-content"
          >
            {t("resetBreakdown")}
          </Button>
        )}
      </Stack>
    )
  }
}

export const DimensionsBreakdownSelect = ({
  dimensionIds,
  initialBreakdowns,
  onGenerateBreakdowns,
  showDimensionsBreakdown,
  onResetBreakdown,
  isCSRD,
}: RootProps) => {
  const { companyDimensions } = useLoaderData<typeof loader>()

  if (showDimensionsBreakdown) {
    return (
      <TypedDimensionFillerWrapper datoDimensionIds={dimensionIds}>
        {({ dimensions }) => (
          <BreakdownSelectForm
            dimensions={
              companyDimensions
                ? [...companyDimensions, ...dimensions]
                : dimensions
            }
            initialBreakdowns={initialBreakdowns}
            isCSRD={isCSRD}
            onGenerateBreakdowns={onGenerateBreakdowns}
            onResetBreakdown={onResetBreakdown}
          />
        )}
      </TypedDimensionFillerWrapper>
    )
  }

  return (
    <VisuallyHidden>
      <TypedDimensionFillerWrapper datoDimensionIds={dimensionIds}>
        {({ dimensions }) => (
          <BreakdownSelectForm
            dimensions={dimensions}
            initialBreakdowns={initialBreakdowns}
            isCSRD={isCSRD}
            onGenerateBreakdowns={onGenerateBreakdowns}
            onResetBreakdown={onResetBreakdown}
          />
        )}
      </TypedDimensionFillerWrapper>
    </VisuallyHidden>
  )
}
