import { Group, Progress, rem, Stack, Text } from "@mantine/core"
import { useTranslation } from "react-i18next"

import { round } from "@kiosk/reporting/legacy/shared/utils/helpers"

type Props = {
  readonly totalTasksCount: number
  readonly tasksByStatus: { status: string; _count: number }[]
}

export const ProgressBarAndInfo = ({
  totalTasksCount,
  tasksByStatus,
}: Props) => {
  const { t } = useTranslation("task")

  const findTaskCountByStatus = (taskStatus: string) =>
    tasksByStatus.find(({ status }) => status === taskStatus)?._count ?? 0

  const doneTasksCount = findTaskCountByStatus("DONE") ?? 0

  const progressPercentage = round(
    totalTasksCount > 0 ? (100 * doneTasksCount) / totalTasksCount : 0,
    2,
  )

  return (
    <Group gap={32} grow>
      <Stack
        gap={8}
        px={24}
        py={16}
        style={{
          borderStyle: "solid",
          borderRadius: rem(6),
          borderColor: "var(--mantine-color-gray-2)",
          borderWidth: rem(1),
        }}
      >
        <Group justify="space-between">
          <Text c="gray.5" fw={500} fz="sm">
            {t("completion")}
          </Text>

          <Text c="gray.9" fw={600} fz="md">
            {`${progressPercentage}%`}
          </Text>
        </Group>

        <Progress c="green" value={progressPercentage} />
      </Stack>

      <Group
        gap={8}
        justify="space-between"
        px={24}
        py={16}
        style={{
          borderStyle: "solid",
          borderRadius: rem(6),
          borderColor: "var(--mantine-color-gray-2)",
          borderWidth: rem(1),
        }}
      >
        <Stack gap={0}>
          <Text c="gray.5" fw={500} fz="sm">
            {t("status.done")}
          </Text>

          <Text c="gray.9" fw={600} fz="md">
            {`${findTaskCountByStatus("DONE")} / ${totalTasksCount}`}
          </Text>
        </Stack>

        <Stack gap={0}>
          <Text c="gray.5" fw={500} fz="sm">
            {t("status.inProgress")}
          </Text>

          <Text c="gray.9" fw={600} fz="md">
            {`${findTaskCountByStatus("IN_PROGRESS")} / ${totalTasksCount}`}
          </Text>
        </Stack>

        <Stack gap={0}>
          <Text c="gray.5" fw={500} fz="sm">
            {t("status.todo")}
          </Text>

          <Text c="gray.9" fw={600} fz="md">
            {`${findTaskCountByStatus("TODO")} / ${totalTasksCount}`}
          </Text>
        </Stack>
      </Group>
    </Group>
  )
}
