import { useQuery } from "@tanstack/react-query"

import { taskKeys } from "@kiosk/reporting/api/tasks/taskKeys"
import { useUser } from "@kiosk/reporting/components/auth/UserContext"
import { TaskResponses } from "@kiosk/reporting/legacy/types/endpoints/tasks"
import { apiClient } from "@kiosk/reporting/lib/apiClient"
import { authorizationHeader } from "@kiosk/reporting/lib/headers"

export const getTaskOwners = async (
  token: string,
): Promise<TaskResponses.GetOwners> => {
  const response = await apiClient.get<TaskResponses.GetOwners>(
    "/tasks/owners",
    { headers: authorizationHeader(token) },
  )

  return response.data
}

export const useTaskOwnersQuery = () => {
  const { token } = useUser()

  return useQuery({
    queryKey: taskKeys.owners(),
    queryFn: async () => await getTaskOwners(token),
  })
}
