import { useQuery } from "@tanstack/react-query"

import { assignmentsKeys } from "@kiosk/reporting/api/surveys/assignments/assignmentsKeys"
import { useUser } from "@kiosk/reporting/components/auth/UserContext"
import { listAssignmentsSchema } from "@kiosk/reporting/legacy/shared/schemas/assignments"
import { AssignmentResponses } from "@kiosk/reporting/legacy/types/endpoints/assignments"
import { apiClient } from "@kiosk/reporting/lib/apiClient"
import { authorizationHeader } from "@kiosk/reporting/lib/headers"

export const useListAssignmentsQuery = (questionId: string) => {
  const { token } = useUser()

  return useQuery({
    queryKey: assignmentsKeys.list(questionId),
    queryFn: async (): Promise<AssignmentResponses.Assignment[]> => {
      const response = await apiClient.get<AssignmentResponses.Assignment[]>(
        `/survey_questions/${questionId}/assignments`,
        { headers: authorizationHeader(token) },
      )

      return listAssignmentsSchema.parseAsync(response.data)
    },
  })
}
