import { Group, Stack } from "@mantine/core"
import { Company } from "@prisma/client"
import { useNavigate } from "@remix-run/react"

import { useCompaniesQuery } from "@kiosk/reporting/api/companies/queryCompanies"
import { Table } from "@kiosk/reporting/components/generic"
import { PageLayout } from "@kiosk/reporting/components/layout/PageLayout"
import { QueryWrapper } from "@kiosk/reporting/components/layout/QueryWrapper"
import { CompanyForm } from "@kiosk/reporting/routes/companies/components/CompanyForm"
import { useCompaniesColumns } from "@kiosk/reporting/routes/companies/components/columns"

export default function Companies() {
  const navigate = useNavigate()

  const handleOnRowClick = (record: Company) =>
    navigate(`/companies/${record.id}/users`)

  const companiesQuery = useCompaniesQuery()

  const columns = useCompaniesColumns()

  return (
    <PageLayout contained>
      <QueryWrapper fluid query={companiesQuery}>
        {({ data: companies }) => (
          <Stack gap={30}>
            <Group justify="flex-end">
              <CompanyForm />
            </Group>

            <Table
              columns={columns}
              dataSource={companies.data}
              onRowClick={handleOnRowClick}
            />
          </Stack>
        )}
      </QueryWrapper>
    </PageLayout>
  )
}
