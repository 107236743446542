import { Await, defer, useLoaderData } from "@remix-run/react"
import {
  ActionFunctionArgs,
  LoaderFunctionArgs,
} from "@remix-run/server-runtime"
import { Suspense } from "react"

import { listTypedDimensionsQuery } from "@kiosk/reporting/api/dimensions/queries"
import { TypedDimension } from "@kiosk/reporting/api/dimensions/types"
import { updateDimension } from "@kiosk/reporting/components/dimensions/EditCompanyDimensionDrawer"
import { PageLayout } from "@kiosk/reporting/components/layout/PageLayout"
import { parseLocale } from "@kiosk/reporting/legacy/i18n"
import { CompanyDimensionPostgresRepository } from "@kiosk/reporting/lib/repositories/company-dimension-repository.server"
import { DimensionsList } from "@kiosk/reporting/routes/dimensions/components/DimensionList"
import { DimensionLoaderError } from "@kiosk/reporting/routes/dimensions/components/DimensionLoaderError"
import { DimensionLoaderIndicator } from "@kiosk/reporting/routes/dimensions/components/DimensionLoaderIndicator"
import { updateDimensionOption } from "@kiosk/reporting/routes/dimensions/update-dimension-option.server"
import { requireUser } from "@kiosk/reporting/utils/auth.server"

export const handle = {
  title: () => "dimensions",
}

export const loader = async (args: LoaderFunctionArgs) => {
  const { token, locale, companyId } = await requireUser(args)

  // TODO: move to User type
  const userLocale = parseLocale(locale)

  if (!userLocale) {
    throw new Error(`Unsupported locale: ${locale}`)
  }

  const companyDimensions =
    await CompanyDimensionPostgresRepository.getDimensionsWithOptions(companyId)

  return defer({
    typedDimensionsList: listTypedDimensionsQuery(token, userLocale),
    companyDimensions,
  })
}

export const action = async (args: ActionFunctionArgs) => {
  const form = await args.request.formData()
  const intent = form.get("intent")?.toString() ?? ""

  switch (intent) {
    case updateDimension: {
      return await updateDimensionOption(form)
    }
    default:
      throw new Response("Invalid intent", { status: 422 })
  }
}

export default function DimensionsPage() {
  const { typedDimensionsList, companyDimensions } =
    useLoaderData<typeof loader>()

  return (
    <PageLayout contained>
      <Suspense fallback={<DimensionLoaderIndicator />}>
        <Await
          errorElement={<DimensionLoaderError />}
          resolve={typedDimensionsList}
        >
          {(data) => (
            <DimensionsList
              companyDimensions={companyDimensions}
              dimensions={data as unknown as TypedDimension[]}
            />
          )}
        </Await>
      </Suspense>
    </PageLayout>
  )
}
