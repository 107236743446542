import { notifications } from "@mantine/notifications"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"

import {
  makeDatoPaginatedRequest,
  makeDatoRequest,
} from "@kiosk/reporting/api/datocms/datocms"
import { DatoAllTypedDimensionsResponse } from "@kiosk/reporting/api/datocms/types"
import { dimensionsKeys } from "@kiosk/reporting/api/dimensions/dimensionsKeys"
import { TypedDimension } from "@kiosk/reporting/api/dimensions/types"
import { useUser } from "@kiosk/reporting/components/auth/UserContext"
import { Locale } from "@kiosk/reporting/i18n"
import {
  ALL_DIMENSIONS_QUERY,
  ALL_TYPED_DIMENSIONS_QUERY,
} from "@kiosk/reporting/legacy/shared/datocms/queries"
import { consolidateDimensions } from "@kiosk/reporting/legacy/shared/utils/dimensions/consolidateDimensions"
import { AllDimensions } from "@kiosk/reporting/legacy/types/data/dato"
import {
  DimensionResponses,
  EditDimensionForm,
} from "@kiosk/reporting/legacy/types/endpoints/dimensions"
import { ConsolidatedDimension } from "@kiosk/reporting/legacy/types/utils/dimensions"
import { apiClient } from "@kiosk/reporting/lib/apiClient"
import { authorizationHeader } from "@kiosk/reporting/lib/headers"
import { queryClient } from "@kiosk/reporting/lib/queryClient"

export const listDimensions = async (
  token: string,
): Promise<ConsolidatedDimension[]> => {
  const [datoDimensions, typedDimensionsOptionsByDatoId] = await Promise.all([
    makeDatoPaginatedRequest<AllDimensions>({
      query: ALL_DIMENSIONS_QUERY,
      fieldName: "allDimensions",
      metaFieldName: "_allDimensionsMeta",
      token,
    }),
    apiClient.get<DimensionResponses.TypedDimensions>("/dimensions/typed", {
      headers: authorizationHeader(token),
    }),
  ])

  return consolidateDimensions(
    datoDimensions,
    typedDimensionsOptionsByDatoId.data,
  )
}

export const useListDimensionsQuery = () => {
  const { token } = useUser()

  return useQuery<ConsolidatedDimension[]>({
    queryKey: dimensionsKeys.all(),
    queryFn: () => listDimensions(token),
  })
}

export const listTypedDimensionsQuery = async (
  token: string,
  locale: Locale,
): Promise<TypedDimension[]> => {
  const [datoTypedDimensions, typedDimensionsOptionsByDatoId] =
    await Promise.all([
      makeDatoRequest({
        query: ALL_TYPED_DIMENSIONS_QUERY,
        token,
        variables: { locale },
      }),
      apiClient.get<DimensionResponses.TypedDimensions>("/dimensions/typed", {
        headers: { Authorization: `Bearer ${token}` },
      }),
    ])

  return datoTypedDimensions.data.data.allDimensions.map(
    (d: DatoAllTypedDimensionsResponse["allDimensions"][number]) => ({
      ...d,
      options: typedDimensionsOptionsByDatoId.data[d.id] ?? [],
    }),
  )
}

export const useEditTypedDimensionMutation = (
  datoDimensionId: string,
  onSuccess: () => void,
) => {
  const { t } = useTranslation("dimensions")

  const { token } = useUser()

  return useMutation({
    mutationFn: async (payload: EditDimensionForm) => {
      const { data } = await apiClient.post(
        "/dimensions/typed",
        {
          datoDimensionId,
          ...payload,
        },
        { headers: authorizationHeader(token) },
      )

      return data
    },
    onSuccess: async () => {
      notifications.show({
        title: t("notifications.edit.success.title"),
        message: t("notifications.edit.success.message"),
        color: "green",
      })
      await queryClient.invalidateQueries({ queryKey: dimensionsKeys.all() })
      onSuccess()
    },
    onError: () => {
      notifications.show({
        title: t("notifications.edit.error.title"),
        message: t("notifications.edit.error.message"),
        color: "red",
      })
    },
  })
}
