import { z } from "zod"

export const companySchema = z.object({
  name: z.string().min(2),
  email: z.string().email(),
  firstName: z.string().min(2),
  lastName: z.string().min(2),
  logoUrl: z.string().optional(),
  logoInitials: z.string().max(2),
  branding: z.enum(["KIOSK", "GREENSTER"]),
  userRole: z.enum(["KIOSK_ADMIN", "COMPANY_ADMIN"]),
})

export type CompanyBody = z.infer<typeof companySchema>
