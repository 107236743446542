import { Button, Group, Modal, rem } from "@mantine/core"
import { useTranslation } from "react-i18next"

import { useDeleteTaskMutation } from "@kiosk/reporting/api/tasks/deleteTask"

type Props = {
  readonly taskId: string | undefined
  readonly close: () => void
  readonly ownerId: string | undefined
  readonly opened: boolean
  readonly closeEditTaskModal: () => void
  readonly resetForm: () => void
}

export const DeleteTaskModal = ({
  taskId,
  opened,
  close,
  closeEditTaskModal,
  resetForm,
}: Props) => {
  const { t } = useTranslation(["common", "task"])
  const { mutateAsync: deleteTask, isPending } = useDeleteTaskMutation()
  const onConfirm = async () => {
    if (taskId) {
      await deleteTask(taskId)
    }
    closeEditTaskModal()
    close()
    resetForm()
  }

  return (
    <Modal.Root centered onClose={close} opened={opened}>
      <Modal.Overlay />

      <Modal.Content p={8} style={{ borderRadius: rem(14) }}>
        <Modal.Header>
          <Modal.Title c="gray.9" fw={600} fz="xxl">
            {t("task:deleteTaskTitle")}
          </Modal.Title>

          <Modal.CloseButton />
        </Modal.Header>

        <Modal.Body>
          <Group justify="flex-end">
            <Button c="gray.9" color="gray.2" onClick={close}>
              {t("common:buttons.cancel")}
            </Button>

            <Button
              color="red.8"
              disabled={isPending}
              loading={isPending}
              onClick={onConfirm}
            >
              {t("task:deleteTask")}
            </Button>
          </Group>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  )
}
