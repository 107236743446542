import { Button } from "@mantine/core"
import { showNotification } from "@mantine/notifications"
import { IconTrash } from "@tabler/icons-react"
import { Dispatch, SetStateAction } from "react"
import { useTranslation } from "react-i18next"

import { BulkAction } from "@kiosk/reporting/components/BulkAction/BulkAction"

type DeleteQuestionsProps = {
  readonly selectedIds: string[]
  readonly setSelectedIds: Dispatch<SetStateAction<string[]>>
  readonly deleteSurveyQuestion: (id: string) => Promise<void>
  readonly isPending: boolean
}

export const DeleteQuestions = ({
  selectedIds,
  setSelectedIds,
  deleteSurveyQuestion,
  isPending,
}: DeleteQuestionsProps) => {
  const { t } = useTranslation(["survey", "common"])

  const handleDelete = async () => {
    try {
      await Promise.all(selectedIds.map((id) => deleteSurveyQuestion(id)))
      showNotification({
        color: "green",
        message: t("surveyQuestionsDeleted", {
          count: selectedIds.length,
        }),
      })
      setSelectedIds([])
    } catch (error) {
      if (error instanceof Error) {
        showNotification({
          color: "red",
          message: t("common:messages.somethingWentWrong"),
        })
      }
    }
  }

  return (
    <BulkAction nbSelected={selectedIds.length}>
      <Button
        c="gray"
        leftSection={<IconTrash />}
        loading={isPending}
        onClick={handleDelete}
        size="compact-sm"
        variant="transparent"
      >
        {`${t("actions.delete", { count: selectedIds.length })}`}
      </Button>
    </BulkAction>
  )
}
