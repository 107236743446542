import { useClerk } from "@clerk/remix"
import { Avatar, Flex, Group, Menu, Text } from "@mantine/core"
import { Link } from "@remix-run/react"
import { IconLogout2 } from "@tabler/icons-react"

import { User } from "@kiosk/reporting/legacy/types/user"

interface Props {
  readonly user: User & { token: string }
}

export default function UserPopover({ user }: Props) {
  const clerk = useClerk()

  return (
    <Group gap={14}>
      <Menu offset={12} position="bottom-end">
        <Menu.Target>
          <Group style={{ cursor: "pointer" }}>
            <Text c="gray.5" fw="bold">
              {user?.firstName} {user?.lastName}
            </Text>

            <Avatar
              alt="user-profile-picutre"
              bg={user?.avatarUrl ? "transparent" : "green.1"}
              color="green"
              radius="xl"
              src={user?.avatarUrl}
            />
          </Group>
        </Menu.Target>

        <Menu.Dropdown style={{ boxShadow: "0px 6px 12px 0px #75819240" }}>
          <Menu.Item>
            <Link to="/settings/account/edit">
              <Group gap={12} justify="space-between" p={0}>
                <Avatar
                  alt="user-profile-picture"
                  bg={user?.avatarUrl ? "transparent" : "green.1"}
                  color="green"
                  radius="xl"
                  src={user?.avatarUrl}
                />

                <Group>
                  <Flex direction="column">
                    <Text c="black" fw={600}>
                      {user?.firstName} {user?.lastName}
                    </Text>

                    <Text c="gray.5" fw={400} fz="xs">
                      {user.email}
                    </Text>
                  </Flex>
                </Group>
              </Group>
            </Link>
          </Menu.Item>

          <Menu.Divider />

          <Menu.Item
            leftSection={<IconLogout2 size={20} />}
            onClick={() => {
              clerk.signOut({ redirectUrl: "/" })
            }}
          >
            Sign out
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </Group>
  )
}
