import { useQuery } from "@tanstack/react-query"

import { surveyQuestionsKeys } from "@kiosk/reporting/api/surveys/questions/surveyQuestionsKeys"
import { useUser } from "@kiosk/reporting/components/auth/UserContext"
import { SurveyQuestionResponses } from "@kiosk/reporting/legacy/types/endpoints/surveyQuestions"
import { apiClient } from "@kiosk/reporting/lib/apiClient"
import { authorizationHeader } from "@kiosk/reporting/lib/headers"

export const useGetSurveyQuestionQuery = (id: string) => {
  const { token } = useUser()

  return useQuery({
    queryKey: surveyQuestionsKeys.get(id),
    queryFn: async (): Promise<SurveyQuestionResponses.SurveyQuestion> => {
      const response =
        await apiClient.get<SurveyQuestionResponses.SurveyQuestion>(
          `/survey_questions/${id}`,
          { headers: authorizationHeader(token) },
        )

      return response.data
    },
  })
}
