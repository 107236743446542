import { Button } from "@mantine/core"
import { useState } from "react"

import { InputProps } from "@kiosk/reporting/components/csrd/questions/types"
import { BooleanRecord } from "@kiosk/reporting/legacy/types/data/dato"

export const PlainBooleanChoice = ({
  content,
  formProps,
}: InputProps<BooleanRecord>) => {
  const [isTrue, setIsTrue] = useState(formProps.defaultValue)
  const { yesLabel = "Yes", noLabel = "No" } = content

  const handleClick = (value: boolean) => {
    setIsTrue(value)
    formProps.onChange(value)
  }

  return (
    <Button.Group>
      <Button
        color={isTrue === true ? "green" : "gray.5"}
        miw={140}
        onClick={() => handleClick(true)}
        style={{ borderBottomRightRadius: 0, borderTopRightRadius: 0 }}
        variant={isTrue === true ? "outlight" : "outline"}
      >
        {yesLabel}
      </Button>

      <Button
        color={isTrue === false ? "green" : "gray.5"}
        miw={140}
        onClick={() => handleClick(false)}
        style={{ borderBottomLeftRadius: 0, borderTopLeftRadius: 0 }}
        variant={isTrue === false ? "outlight" : "outline"}
      >
        {noLabel}
      </Button>
    </Button.Group>
  )
}
