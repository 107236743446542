import { useQuery } from "@tanstack/react-query"

import { surveysKeys } from "@kiosk/reporting/api/surveys/surveysKeys"
import { useUser } from "@kiosk/reporting/components/auth/UserContext"
import { listSurveyQuestionsSchema } from "@kiosk/reporting/legacy/shared/schemas/surveyQuestions"
import { getSurveySchema } from "@kiosk/reporting/legacy/shared/schemas/surveys"
import { SurveyQuestionResponses } from "@kiosk/reporting/legacy/types/endpoints/surveyQuestions"
import { SurveyResponses } from "@kiosk/reporting/legacy/types/endpoints/surveys"
import { apiClient } from "@kiosk/reporting/lib/apiClient"
import { authorizationHeader } from "@kiosk/reporting/lib/headers"

export const useGetSurveyQuery = (id: string) => {
  const { token } = useUser()

  return useQuery({
    queryKey: surveysKeys.get(id),
    queryFn: async (): Promise<{
      survey: SurveyResponses.Survey
      surveyQuestions: SurveyQuestionResponses.SurveyQuestion[]
    }> => {
      const surveyPromise = apiClient.get<SurveyResponses.Survey>(
        `/surveys/${id}`,
        { headers: authorizationHeader(token) },
      )
      const surveyQuestionsPromise = apiClient.get<
        SurveyQuestionResponses.SurveyQuestion[]
      >(`/surveys/${id}/questions`, { headers: authorizationHeader(token) })

      const [surveyResponse, surveyQuestionsResponse] = await Promise.all([
        surveyPromise,
        surveyQuestionsPromise,
      ])

      return {
        survey: await getSurveySchema.parseAsync(surveyResponse.data),
        surveyQuestions: (await listSurveyQuestionsSchema.parseAsync(
          surveyQuestionsResponse.data,
        )) as SurveyQuestionResponses.SurveyQuestion[],
      }
    },
  })
}
