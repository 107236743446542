import { showNotification } from "@mantine/notifications"
import { useMutation } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"

import { companyKeys } from "@kiosk/reporting/api/companies/companyKeys"
import { useUser } from "@kiosk/reporting/components/auth/UserContext"
import {
  CompanyRequests,
  CompanyResponses,
} from "@kiosk/reporting/legacy/types/endpoints/companies"
import { apiClient } from "@kiosk/reporting/lib/apiClient"
import { authorizationHeader } from "@kiosk/reporting/lib/headers"
import { queryClient } from "@kiosk/reporting/lib/queryClient"

export const createCompany = async (
  data: CompanyRequests.CreateCompany,
  token: string,
) => {
  const response = await apiClient.post<CompanyResponses.Company>(
    "/companies",
    data,
    { headers: authorizationHeader(token) },
  )

  return response.data
}

export const useCreateCompanyMutation = (onCancel: () => void) => {
  const { t } = useTranslation(["company"])
  const { token } = useUser()

  return useMutation({
    mutationFn: (data: CompanyRequests.CreateCompany) =>
      createCompany(data, token),
    onSuccess: async (data: CompanyResponses.Company) => {
      await queryClient.invalidateQueries({ queryKey: companyKeys.all() })
      showNotification({
        message: `${t("success.created")}: ` + data.name,
      })
      onCancel()
    },
    onError: (error: Error) => {
      //TODO: Handle errors
      showNotification({ message: error.message })
    },
  })
}
