import { ActionIcon, Flex, Group, Stack, Text } from "@mantine/core"
import { LoaderFunctionArgs } from "@remix-run/node"
import { IconDownload } from "@tabler/icons-react"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { typedjson, useTypedLoaderData } from "remix-typedjson"

import { listDimensions } from "@kiosk/reporting/api/dimensions/queries"
import { BackLink } from "@kiosk/reporting/components/BackButton"
import { Table } from "@kiosk/reporting/components/generic"
import { PageLayout } from "@kiosk/reporting/components/layout/PageLayout"
import { AssignmentDimensionsCell } from "@kiosk/reporting/components/surveys/AssignmentDimensionsCell"
import { listAssignmentsSchema } from "@kiosk/reporting/legacy/shared/schemas/assignments"
import { AssignmentResponses } from "@kiosk/reporting/legacy/types/endpoints/assignments"
import { SurveyQuestionResponses } from "@kiosk/reporting/legacy/types/endpoints/surveyQuestions"
import { ConsolidatedDimension } from "@kiosk/reporting/legacy/types/utils/dimensions"
import { CompanyDimensionAggregate } from "@kiosk/reporting/lib/aggregates/companyDimensionAggregate"
import { apiClient } from "@kiosk/reporting/lib/apiClient"
import { authorizationHeader } from "@kiosk/reporting/lib/headers"
import { CompanyDimensionPostgresRepository } from "@kiosk/reporting/lib/repositories/company-dimension-repository.server"
import { AnswerCell } from "@kiosk/reporting/routes/sources_.survey_answers.$id/components/AnswerCell"
import { requireUser } from "@kiosk/reporting/utils/auth.server"

export const handle = {
  title: () => "surveyAnswers",
}

type Props = {
  readonly surveyQuestion: SurveyQuestionResponses.SurveyQuestion
  readonly assignments: AssignmentResponses.Assignment[]
  readonly allDimensions: Array<
    ConsolidatedDimension | CompanyDimensionAggregate
  >
}

const SurveyAnswersContent = ({
  surveyQuestion,
  assignments,
  allDimensions,
}: Props) => {
  const { t } = useTranslation("survey")

  const columns = useMemo(
    () => [
      {
        key: "label",
        colId: "assignmentDimensions",
        title: t("breakdownsHeader"),
        render: (
          assignmentDimensions: AssignmentResponses.AssignmentDimension[],
        ) => (
          <AssignmentDimensionsCell
            allDimensions={allDimensions}
            assignmentDimensions={assignmentDimensions}
          />
        ),
      },
      {
        key: "answer",
        colId: "value",
        title: t("answersHeader"),
        render: (value: string, record: AssignmentResponses.Assignment) => (
          <AnswerCell {...{ value, record }} />
        ),
      },
      {
        key: "assignee",
        colId: "assignee",
        title: t("assigneeHeader"),
        render: (assignee: AssignmentResponses.Assignment["assignee"]) => (
          <Stack gap={0}>
            <Text>
              {assignee?.firstName} {assignee?.lastName}
            </Text>

            <Text c="gray.5">{assignee?.email}</Text>
          </Stack>
        ),
      },
      {
        key: "file",
        colId: "fileName",
        title: t("fileHeader"),
        width: 200,
        render: (
          _: string,
          { fileSignedUrl, fileName }: AssignmentResponses.Assignment,
        ) =>
          fileSignedUrl &&
          fileName && (
            <Group maw={200} wrap="nowrap">
              <ActionIcon
                color="gray.4"
                onClick={() => window.open(fileSignedUrl, "_blank")}
                variant="subtle"
              >
                <IconDownload size={24} />
              </ActionIcon>

              <Text truncate="end">{fileName}</Text>
            </Group>
          ),
      },
    ],
    [t, allDimensions],
  )

  return (
    <Stack gap={30}>
      <Flex align="center" gap={16} justify="space-between">
        <BackLink href={`/sources/edit_survey/${surveyQuestion.surveyId}`} />

        <Text flex={1} fw={600} size="xl" truncate="end" variant="unstyled">
          {surveyQuestion.label}
        </Text>
      </Flex>

      <Table
        columns={columns}
        dataSource={assignments}
        flex={1}
        footer={null}
        getRowKey={(assignment: AssignmentResponses.Assignment) =>
          assignment.id
        }
        highlightOnHoverColor="emerald.0"
        withColumnBorders
      />
    </Stack>
  )
}
const getSurveyQuestionById = async (token: string, questionId: string) => {
  const response = await apiClient.get<SurveyQuestionResponses.SurveyQuestion>(
    `/survey_questions/${questionId}`,
    { headers: authorizationHeader(token) },
  )

  return response.data
}
const getAssignmentsBySurveyQuestionId = async (
  token: string,
  questionId: string,
) => {
  const response = await apiClient.get<AssignmentResponses.Assignment[]>(
    `/survey_questions/${questionId}/assignments`,
    { headers: authorizationHeader(token) },
  )

  return listAssignmentsSchema.parseAsync(response.data)
}

export const loader = async (args: LoaderFunctionArgs) => {
  const { token, companyId } = await requireUser(args)

  const { params } = args

  if (!params.id) {
    throw new Response("Invalid request: missing survey ID", { status: 422 })
  }

  const surveyQuestion = await getSurveyQuestionById(token, params.id)
  const assignments = await getAssignmentsBySurveyQuestionId(token, params.id)
  const dimensions = await listDimensions(token)
  const companyDimensions =
    await CompanyDimensionPostgresRepository.getDimensionsWithOptions(companyId)

  return typedjson({
    surveyQuestion,
    assignments,
    dimensions,
    companyDimensions,
  })
}

export default function SurveyAnswers() {
  const { surveyQuestion, assignments, dimensions, companyDimensions } =
    useTypedLoaderData<typeof loader>()

  return (
    <PageLayout>
      <SurveyAnswersContent
        allDimensions={[...dimensions, ...companyDimensions]}
        assignments={assignments}
        surveyQuestion={surveyQuestion}
      />
    </PageLayout>
  )
}
