import { Text } from "@mantine/core"
import { renderMarkRule } from "react-datocms"
import {
  StructuredTextGraphQlResponseRecord,
  StructuredTextPropTypes,
} from "react-datocms/structured-text"

type RenderMarkRule<T extends StructuredTextGraphQlResponseRecord> =
  NonNullable<StructuredTextPropTypes<T>["customMarkRules"]>[number]

export const getStrongMarkRule = <
  T extends StructuredTextGraphQlResponseRecord,
>(): RenderMarkRule<T> =>
  renderMarkRule("strong", ({ children, key }) => (
    <Text c="inherit" component="b" fw={600} key={key} size="inherit">
      {children}
    </Text>
  ))
