import { Group, ThemeIcon, Tooltip } from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import { IconExclamationCircle } from "@tabler/icons-react"
import _ from "lodash"
import { useTranslation } from "react-i18next"

import { UnitNumberInput } from "@kiosk/reporting/components/UnitNumberInput/UnitNumberInput"

type AnswersCellProps = {
  readonly form: UseFormReturnType<
    {
      [x: string]: number | null
    },
    (values: { [x: string]: number | null }) => {
      [x: string]: number | null
    }
  >
  readonly question: { unit: string }
  readonly isSubmitted: boolean
  readonly id: string
}

export const AnswersCell = ({
  form,
  question,
  isSubmitted,
  id,
}: AnswersCellProps) => {
  const { t } = useTranslation("survey")

  return (
    <Group justify="space-between" w={170} wrap="nowrap">
      <UnitNumberInput
        unit={question.unit}
        variant="unstyled"
        {..._.omit(form.getInputProps(id), "error")}
        onChange={(value) => {
          form.setFieldValue(id, value === "" ? null : Number(value))
        }}
      />
      {isSubmitted &&
        (!form.getInputProps(id).defaultValue ||
          form.getInputProps(id).defaultValue === "") && (
          <Tooltip label={t("answerSurvey.missingAnswer")}>
            <ThemeIcon c="red.8" size="sm">
              <IconExclamationCircle />
            </ThemeIcon>
          </Tooltip>
        )}
    </Group>
  )
}
